import { useState } from "react";

import { Group, useUpdateGroupMutation } from "./groupsSlice";
import { Button, Anchor } from "@mantine/core";
import { EditGroupDetailsForm } from "../../components/EditGroupDetailsForm";
import {
  GenerateDiffPathsForGroup,
  GetIdentifierFromName,
} from "../../util/peopleProto";
import { DetailCardProps } from "../../components/DetailPage";
import { DetailCardBase } from "../../components/DetailCardBase";
import { ObjectTypeBadge } from "../../components/ObjectTypeBadge";
import { GroupObjectType } from "../../proto/sip/people/people_pb";
import { ResourceLink } from "../../components/ResourceLink";
import { Mailto } from "../../components/Mailto";
import {
  InfoTableBase,
  InfoTableElement,
} from "../../components/InfoTableBase";

interface GMProps extends DetailCardProps<Group> {
  allowedToUpdateGroup: boolean;
}

const tableElements: InfoTableElement<Group>[] = [
  {
    title: "Identifier",
    key: "name",
    formatter: GetIdentifierFromName,
  },
  {
    title: "Group Type",
    key: "objectType",
    formatter: (objectType: GroupObjectType) => (
      <ObjectTypeBadge objectType={objectType} />
    ),
  },
  {
    title: "Parent Groups",
    key: "parentNamesTransitiveList",
    formatter: (parents: string[]) => (
      <div>
        {parents.map((parent) => (
          <div>
            <ResourceLink key={parent} resourceName={parent} />
            <br />
          </div>
        ))}
      </div>
    ),
  },
  {
    title: "Display Name",
    key: "displayName",
  },
  {
    title: "Full Name - German",
    key: "titleDe",
  },
  {
    title: "Full Name - English",
    key: "titleEn",
  },
  {
    title: "Description",
    key: "description",
  },
  {
    title: "Email",
    key: "email",
    formatter: (email: string) => <Mailto email={email}>{email}</Mailto>,
  },
  {
    title: "Telephone",
    key: "telephone",
  },
  {
    title: "Website",
    key: "website",
    formatter: (website: string) => (
      <Anchor href={website} rel="noreferrer" target="_blank">
        {website}
      </Anchor>
    ),
  },
  {
    title: "Subgroups",
    key: "subGroupsList",
    formatter: (subgroups: string[]) => (
      <div>
        {subgroups.map((subgroup) => (
          <div>
            <ResourceLink key={subgroup} resourceName={subgroup} />
            <br />
          </div>
        ))}
      </div>
    ),
  },
];

// GroupInformation displays the general attributes of a group
export const GroupInformation = (props: GMProps) => {
  const { data: group, allowedToUpdateGroup } = props;
  const [updateGroup, { isLoading: isUpdating }] = useUpdateGroupMutation();

  const [isEditing, setIsEditing] = useState(false);

  const overviewElements = [
    {
      title: "Display Name",
      key: "displayName",
      value: group.displayName,
    },
    {
      title: "Description",
      key: "description",
      value: group.description,
    },
    {
      title: "Full Name DE",
      key: "titleDe",
      value: group.titleDe,
    },
  ];

  return (
    <DetailCardBase
      {...props}
      title={"General"}
      overviewElements={overviewElements}
    >
      {isEditing ? (
        <EditGroupDetailsForm
          data={group}
          isUpdating={isUpdating}
          onSubmit={(updatedGroup) => {
            const paths = GenerateDiffPathsForGroup(group, updatedGroup);
            const request = {
              paths,
              name: group.name,
              group: updatedGroup,
              auditMessage: "Update through group manager",
            };
            return updateGroup(request)
              .unwrap()
              .then(() => setIsEditing(false));
          }}
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <div>
          <InfoTableBase data={group} elements={tableElements} />
          <Button
            color="primary"
            className="float-right mb-3"
            disabled={!allowedToUpdateGroup}
            onClick={() => setIsEditing(true)}
          >
            Edit
          </Button>
        </div>
      )}
    </DetailCardBase>
  );
};
