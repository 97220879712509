import { Button } from "@mantine/core";

interface RBProps {
  isLoading: boolean;
  reloadAction?: () => void;
}

export const ReloadButton = ({ isLoading, reloadAction }: RBProps) => {
  const item = isLoading ? "Loading" : "Reload";

  return (
    <Button onClick={() => !isLoading && reloadAction && reloadAction()} loading={isLoading} loaderPosition="right">
      {item}
    </Button>
  );
};
