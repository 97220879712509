import React, { useEffect, useMemo, useState } from "react";
import { ProposedChange } from "../features/resources/resourcesSlice";
import { ResourceType } from "../proto/sip/resources/resources_pb";
import { GetStringForProposedChangeState, GetStringForResourceType } from "../util/resourcesProto";
import { ResourceLink } from "./ResourceLink";
import {
  DataTable,
  DataTableSortStatus
} from "mantine-datatable";
import { Flex } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { FocusTrap, Grid, MultiSelect } from "@mantine/core";
import { toInteger } from "lodash";
import { ExportCSVButton } from "./ExportCSVButton";
import { selectProposedChangesQuery, setProposedChangesQuery } from "../features/queries/queriesSlice";
import { useDispatch, useSelector } from "react-redux";
import { SearchBar } from "./SearchBar";
import { UserLink } from "./UserLink";
import { compareFn, dateToString, getRegexErr } from "../util/util";
import { GetIdentifierFromName } from "../util/peopleProto";

const columns = [
  {
    accessor: "name",
    title: "Identifier",
    sortable: true,
    render: (change: ProposedChange) => (
      GetIdentifierFromName(change.name)
    ),
  },
  {
    accessor: "resourceName",
    title: "Changed Resource",
    sortable: true,
    render: (change: ProposedChange) => (
      <ResourceLink resourceName={change.resourceName} key={change.resourceName} />
    ),
  },
  {
    accessor: "data.displayName",
    title: "Resource name",
    sortable: true,
  },
  {
    accessor: "lastUpdatedBy",
    title: "Last updated by",
    sortable: false,
    render: (change: ProposedChange) => (
      <UserLink user={change.lastUpdatedBy} />
    ),
  },
  {
    accessor: "updateTime",
    title: "Last updated at",
    sortable: true,
    render: (change: ProposedChange) => (
      change.updateTime ?
        dateToString(new Date(change.updateTime.seconds * 1000))
        : "Not updated"
    ),
  },
  {
    accessor: "state",
    title: "State",
    sortable: true,
    render: (change: ProposedChange) => (
      GetStringForProposedChangeState(change.state)
    ),
  },
];

interface RTProps {
  changes: ProposedChange[];
  onChangeSelect?: (name: string) => void;
}

// ProposedChangeTable displays a list of proposed changes
export const ProposedChangeTable: React.FC<RTProps> = ({
  changes,
  onChangeSelect,
}) => {

  const page_sizes = [10, 20, 50];
  const dispatch = useDispatch();

  const [numberOfObjects, setNumberOfObjects] = useState(changes.length);

  const afterFilter = (newResults: any[]) => {
    if (newResults) {
      setNumberOfObjects(newResults.length);
    }
  };

  const savedQuery = useSelector(selectProposedChangesQuery);
  const [query, setQuery] = useState(savedQuery ? savedQuery.text : "");
  const [debouncedQuery] = useDebouncedValue(query, 100);
  const [selectedResourceTypes, setSelectedResourceTypes] = useState<string[]>(savedQuery ? savedQuery.type : []);
  const [useRegex, setUseRegex] = useState(savedQuery.regex || false);

  const regexError = getRegexErr(useRegex, debouncedQuery);

  const [pageSize, setPageSize] = useState(page_sizes[1]);

  useEffect(() => {  // Go back to page 1 to avoid landing on nonexisting page when changing page size
    setPage(1);
  }, [pageSize, debouncedQuery]);

  useEffect(() => {   // Save query in redux store to reload later for convenicence
    dispatch(setProposedChangesQuery({ text: debouncedQuery, type: selectedResourceTypes, regex: useRegex }));
  }, [debouncedQuery, selectedResourceTypes, useRegex, dispatch]);

  const [page, setPage] = useState(1);
  const [records, setRecords] = useState(changes.slice(0, pageSize));
  const [sortableStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'name', direction: 'asc' });

  const resourceTypes = Object.values(ResourceType).map((type) => {
    const str = GetStringForResourceType(toInteger(type));
    return { value: String(type), label: str };
  }).filter((item) => {
    const unknownRTypeMessage = GetStringForResourceType(ResourceType.RESOURCE_TYPE_UNSPECIFIED);
    if (item.label === unknownRTypeMessage) {
      return false;           // Remove resource type without description string
    }
    return true;
  });

  const filterResources = useMemo(() => ((proposedChange: ProposedChange) => {
    if (proposedChange.data === null) {
      return false;
    }
    if ((selectedResourceTypes.length > 0) && !(selectedResourceTypes.includes(String(proposedChange.data?.resourceType)))) {
      return false;
    }
    if (debouncedQuery === "") return true;
    const searchInFields = columns.map((accessor) => {
      return String(proposedChange[accessor as unknown as keyof ProposedChange]);
    });
    for (const field of searchInFields) {
      if (useRegex) {
        try {    // regex can contain errors
          if (String(field).toLowerCase().match(debouncedQuery.toLowerCase())) {
            return true;
          }
        } catch (e) {
          return false;
        }
      } else {
        if (String(field).toLowerCase().includes(debouncedQuery.toLowerCase())) {
          return true;
        }
      }
    }
    return false;
  }), [debouncedQuery, selectedResourceTypes, useRegex]);

  useEffect(() => {
    const matchingResources = changes.filter(filterResources);
    afterFilter(matchingResources);
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    matchingResources.sort(compareFn(sortableStatus.direction, sortableStatus.columnAccessor as keyof ProposedChange));
    setRecords(matchingResources.slice(from, to));
  }, [page, pageSize, sortableStatus, changes, debouncedQuery, selectedResourceTypes, useRegex, filterResources])

  return (
    <div>
      <Flex
        justify="flex-end"
        direction="row"
        wrap="wrap"
      >
      <ExportCSVButton data={changes.filter(filterResources)} columns={columns}>Export CSV</ExportCSVButton>
      </Flex>
      <FocusTrap>
        <Grid>
          <Grid.Col span={7}>
            <SearchBar useRegex={useRegex} setUseRegex={setUseRegex} query={query} setQuery={setQuery} label="Search by resource field values" error={regexError} />
          </Grid.Col>
          <Grid.Col span="auto" >
            <MultiSelect
              data={resourceTypes}
              label="Filter by resource type"
              placeholder="All resources"
              value={selectedResourceTypes} onChange={(values) => setSelectedResourceTypes(values)}
            />
          </Grid.Col>
        </Grid>
      </FocusTrap>
      <hr />
      <DataTable
        striped
        highlightOnHover
        idAccessor="name"
        records={records}
        columns={columns}
        totalRecords={numberOfObjects}
        recordsPerPage={pageSize}
        onRowClick={(change, rowIndex) => onChangeSelect && onChangeSelect(change.name)}
        page={page}
        onPageChange={(p) => setPage(p)}
        recordsPerPageOptions={page_sizes}
        onRecordsPerPageChange={setPageSize}
        sortStatus={sortableStatus}
        onSortStatusChange={setSortStatus}
      />
    </div>

  );
};
