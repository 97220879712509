import {
  useChangeGroupMembersMutation,
  useGetGroupQuery,
  useListPeopleAuthorizationRolesQuery,
  useListUsersQuery,
} from "./groupsSlice";
import { useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { ObjectTypeBadge } from "../../components/ObjectTypeBadge";
import {
  ChangeGroupMembersRequest,
  Group,
  GroupObjectType,
} from "../../proto/sip/people/people_pb";
import {
  selectAllowedToEditMembersOfGroup,
  selectAllowedToListResources,
  selectAllowedToUpdateGroup,
  selectAllowedToViewGroup,
  selectAllowedToViewMembersOfGroup,
  selectUserRoles,
} from "../auth/authSlice";
import { NotAuthorizedScreen } from "../../components/NotAuthorizedScreen";
import { DetailPage } from "../../components/DetailPage";
import { GroupInformation } from "./GroupInformation";
import { GroupMembers } from "./GroupMembers";
import { GroupBreadcrumb } from "../../components/GroupBreadcrumb";
import {
  Resource,
  selectInheritedResourcePermissionsForGroup,
  selectResourcePermissionsForGroup,
  useListResourcesAuthorizationRolesQuery,
  useListResourcesQuery,
} from "../resources/resourcesSlice";
import { GroupPermissions } from "./GroupPermissions";
import { GroupResources } from "./GroupResources";
import { ResourcePermissionWithExtraFields } from "../../components/ResourcePermissionTable";


// GroupDetailPage displays the detailed information about a single group
export const GroupDetailPage = () => {
  // access the parameters
  const { id: groupIdentifier } = useParams<{ id: string }>();
  const groupName = `groups/${groupIdentifier}`;

  const { data: peopleRoles } = useListPeopleAuthorizationRolesQuery(undefined);
  const userRoles = useSelector(selectUserRoles);

  const allowedToViewGroup = selectAllowedToViewGroup(
    peopleRoles,
    userRoles,
    groupName
  );
  const allowedToUpdateGroup = selectAllowedToUpdateGroup(
    peopleRoles,
    userRoles
  );
  const allowedToViewMembers = selectAllowedToViewMembersOfGroup(
    peopleRoles,
    userRoles,
    groupName
  );
  const allowedToEditMembers = selectAllowedToEditMembersOfGroup(
    peopleRoles,
    userRoles,
    groupName
  );

  // fetch the group
  const {
    data: group,
    isLoading: isLoadingGroup,
    isFetching: isFetchingGroup,
    error,
    refetch,
  } = useGetGroupQuery(
    {
      name: groupName,
      view: allowedToViewMembers? Group.View.INCLUDE_MEMBERS : Group.View.BASIC,  // since GroupMember uses this data
    },
    {
      skip: !allowedToViewGroup,
    }
  );

  // and as soon as the group is fetched also fetch the details of the users
  const {
    data: transitiveMembers,
    isFetching: userDataFetching,
    isLoading: userDataLoading,
  } = useListUsersQuery(
    {
      groupMembershipFilter: [groupName],
    },
    {
      skip: !(group && allowedToViewMembers),
    }
  );


  const [changeGroupMembers, { isLoading: isUpdating }] =
    useChangeGroupMembersMutation();

  const { data: resourcesRoles } =
    useListResourcesAuthorizationRolesQuery(undefined);
  const allowedToListResources = selectAllowedToListResources(
    resourcesRoles,
    userRoles
  );

  // fetch the permissions for the group
  const { data: resources } = useListResourcesQuery(undefined);
  let ownedResources: Resource[] = [];
  let indirectOwnedResources: Resource[] = [];
  let permissions: ResourcePermissionWithExtraFields[] = [];
  let inheritedPermissions: ResourcePermissionWithExtraFields[] = [];
  if (resources !== undefined) {
    ownedResources = resources.filter(
      (resource) => resource.ownerGroup === groupName
    );
    if (group !== undefined) {
      indirectOwnedResources = resources.filter(
        (resource) =>
          group!.subGroupsTransitiveList.indexOf(resource.ownerGroup) > -1
      );
    }
    permissions = selectResourcePermissionsForGroup(groupName, {
      data: resources,
    });
    if (group !== undefined) {
      inheritedPermissions = selectInheritedResourcePermissionsForGroup(group, {
        data: resources,
      });
    }
  }

  const combinedLoadingAndUpdating =
    isLoadingGroup || isFetchingGroup || isUpdating;

  // check if the user is not allowed to view the group
  // todo: this check could be done by the protected route component, if it had access to the url path
  if (!allowedToViewGroup) {
    return <NotAuthorizedScreen />;
  }

  const onRemoveMembers = (userNamesList: string[]) => {
    const request = {
      action:
        ChangeGroupMembersRequest.ChangeGroupMembersAction
          .CHANGE_GROUP_MEMBERS_ACTION_REMOVE,
      name: groupName,
      auditMessage: "Testing removal",
      userNamesList: userNamesList,
    };
    return changeGroupMembers(request).unwrap();
  };

  return (
    <DetailPage
      data={group}
      error={error}
      isLoading={combinedLoadingAndUpdating}
      title={groupIdentifier || "Empty GroupID"}
      subtitle={ group?.email || ""}
      breadcrumb={(focusedElement) => (
        <GroupBreadcrumb group={group} focusKey={focusedElement} />
      )}
      icon={
        <ObjectTypeBadge
          objectType={group?.objectType || GroupObjectType.GROUP_OBJECT_TYPE_KS}
        />
      }
      reloadButton={true}
      reloadAction={refetch}
    >
      {[
        {
          key: "details",
          formatter: (props) => (
            <GroupInformation
              {...props}
              allowedToUpdateGroup={allowedToUpdateGroup}
            />
          ),
        },
        {
          key: "members",
          formatter: (props) => (
            <GroupMembers
              {...props}
              allowedToEditMembers={allowedToEditMembers}
              isLoading={userDataLoading || userDataFetching}
              allowedToViewMembers={allowedToViewMembers}
              transitiveMembers={transitiveMembers || []}
              onRemoveUsers={onRemoveMembers}
            />
          ),
        },
        {
          key: "permissions",
          formatter: (props) => (
            <GroupPermissions
              {...props}
              permissions={permissions}
              inheritedPermissions={inheritedPermissions}
              notAllowedToViewCard={!allowedToListResources}
            />
          ),
        },
        {
          key: "resources",
          formatter: (props) => (
            <GroupResources
              {...props}
              resources={ownedResources}
              indirectResources={indirectOwnedResources}
              notAllowedToViewCard={!allowedToListResources}
            />
          ),
        },
      ]}
    </DetailPage>
  );
};
