import { Button } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { ReactNode } from "react";
import { onlyText } from "react-children-utilities";

interface ExportCSVButtonProps<T> {
  data: Array<T>,
  columns: DataTableColumn<T>[],
  children: ReactNode,
}

function escapeCommas(val: string): string {
  if(val.includes("\n")){
    return val.replace('\n',' ');
  }

  if (val.includes(',')) {
    return ("\"" + val + "\"");
  } else {
    return val;
  }
}

function generateCSV<T>(data: Array<T>, columns: DataTableColumn<T>[]) {
  const csvHeader = columns.map((col) => escapeCommas(String(col.title)));
  const colAccessors = columns.map((col)=> col.accessor as Extract<keyof T, string>);
  const csvData = data.map((row,rowIndex) => {
    var rowList: string[] = []; 
    for(const key of colAccessors) {
      const matchingCol = columns.find(c => c.accessor === key);
      if(matchingCol){
        const rendered = matchingCol.render ? onlyText(matchingCol.render(row, rowIndex)) : "";
        const value = (rendered === "") ? (row[key]) : rendered;
        rowList.push(escapeCommas(String(value)));
      }
    }
    return rowList.join(',') + "\n";
  });

  const csv = csvHeader + "\n" + csvData.join('');
  return csv;
}

export function ExportCSVButton<T>({ data, columns, children }: ExportCSVButtonProps<T>): JSX.Element {

  function downloadCSV(csv: string){
    const blob = new Blob([csv], { type: "text/csv" }); 
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "export.csv";
    link.href = url;
    link.click();
  }

  return (
    <Button onClick={() => downloadCSV(generateCSV(data, columns))}>
      {children}
    </Button>
  );
};
