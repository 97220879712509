import { ReloadButton } from "./ReloadButton";
import {
  Card,
  Group,
  Text,
  Container,
  Grid
} from '@mantine/core';
import { useLocation, useParams } from "react-router-dom";
import { LoadScreen } from "./LoadScreen";
import { ErrorScreen } from "./ErrorScreen";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

const useQuery = () => new URLSearchParams(useLocation().search);

interface DPProps<T> {
  data?: T;

  isLoading: boolean;
  error?: FetchBaseQueryError | SerializedError;
  title: string | JSX.Element;
  subtitle: string;

  icon: JSX.Element;
  breadcrumb?: (focusElement: string) => JSX.Element;

  reloadButton?: boolean;
  reloadAction?: () => void;

  children: DetailCardConfig<T>[];
}

export interface DetailCardProps<T> {
  inFocus: boolean; // whether this card is currently in focus (expanded)
  inFocusKey: string; // the url key for putting this card in focus
  baseUrl: string; // base url used for linking
  data: T; // the data object to display
  isLoading?: boolean; // whether the data is currently loading
  notAllowedToViewCard?: boolean; // whether the user is allowed to see this detail card
}

interface DetailCardConfig<T> {
  key: string;
  formatter: (props: DetailCardProps<T>) => JSX.Element;
}

// DetailPage is the base component for a page displaying detailed information about a resource
export const DetailPage = <T extends object = any>({
  data,
  icon,
  children,
  isLoading,
  error,
  breadcrumb,
  title,
  subtitle,
  reloadButton,
  reloadAction,
}: DPProps<T>) => {
  const { id } = useParams<{ id: string }>();
  const query = useQuery();

  const inDetail = children
    .filter((component) => query.get(component.key))
    .map((component) => component.key);

  if (data !== undefined) {
    return (
      <Container my="md">
        {breadcrumb && breadcrumb(inDetail[0])}
        <Grid>
          <Grid.Col md={12} lg={12}>
            <Card shadow="sm" pr={42} pl={42} radius="md" withBorder>
              <Card.Section> 
                <Group spacing="md" position="apart" mt="md" mb="xs">
                  {icon}
                  {reloadButton && (
                    <ReloadButton
                      isLoading={isLoading}
                      reloadAction={reloadAction}
                    />
                  )}
                </Group>
                <Text fz="lg" className="h5">
                  {title}
                </Text>
                <Text fz="sm" mt={3} mb="xl">
                  {subtitle}
                </Text>
              </Card.Section>
            </Card>
          </Grid.Col>
          <Grid.Col md={12} lg={12}>
            {children.map((cfg) =>
              cfg.formatter({
                data,
                inFocus: inDetail.indexOf(cfg.key) > -1,
                inFocusKey: cfg.key,
                baseUrl: id || "",
              })
            )}
          </Grid.Col>
        </Grid>
      </Container>
    );
  } else if (isLoading) {
    return <LoadScreen />;
  } else if (error) {
    return <ErrorScreen error={error} />;
  }

  return <p>No such group/resource ID</p>;
};
