import {
  ResourceDetailCardBase,
  TableFormElement,
} from "./ResourceDetailCardBase";
import { EditableResourceDetailsProps } from "./MailResourceHelpers";
import { Resource } from "../resourcesSlice";

const tableElements: TableFormElement<Resource>[] = [
  {
    title: "Tag key",
    key: "groupTag.tagKey" as keyof Resource,
  },
];

export const GroupTag = (
  props: EditableResourceDetailsProps<Resource>
) => (
  <ResourceDetailCardBase {...props}>{tableElements}</ResourceDetailCardBase>
);

export const groupTagTableElements = tableElements;