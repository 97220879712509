import React, { ReactNode, useEffect, useState } from "react";
import { Link, Outlet, Route, RouterProvider, useLocation, createBrowserRouter, createRoutesFromElements, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";
import "@vseth/vseth-theme/dist/vseth-bootstrap-theme.css";
import "@vseth/vseth-theme/dist/vseth-bootstrap-webapp-theme.css";
import {
  makeVsethTheme,
  VSETHInternalApp, VSETHThemeProvider,
  ICONS,
} from "vseth-canine-ui";
import { GroupListPage } from "../features/groups/GroupListPage";
import { GroupDetailPage } from "../features/groups/GroupDetailPage";
import { OrgStrucPage } from "../features/groups/OrgStrucPage";
import { useSelector } from "react-redux";
import {
  selectAllowedToListGroups,
  selectAllowedToListProposedChanges,
  selectAllowedToListResources,
  selectIsAuthenticated,
  selectUserRoles,
} from "../features/auth/authSlice";
import { CustomAuthProvider } from "../components/CustomAuthProvider";
import { AboutPage } from "../features/about/AboutPage";
import { ResourceListPage } from "../features/resources/ResourceListPage";
import { ProposedChangeListPage } from "../features/resources/ProposedChangeListPage";
import { ResourceDetailPage } from "../features/resources/ResourceDetailPage";
import { ToastContainer } from "react-toastify";
import { LoadScreen } from "../components/LoadScreen";
import { useListResourcesAuthorizationRolesQuery } from "../features/resources/resourcesSlice";
import { useListPeopleAuthorizationRolesQuery } from "../features/groups/groupsSlice";
import { TestingFeedbackAffix } from "../components/TestingFeedbackAffix";
import { ErrorScreen } from "../components/ErrorScreen";
import { LookupPage } from "../features/lookup/LookupPage";
import { ProposedChangeDetailPage } from "../features/resources/ProposedChangeDetailPage";
import { ProtectedRoute } from "../features/auth/ProtectedRoute";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://a3ac16fbc40567ee8b8b15d35ee39277@sentry.vseth.ethz.ch/11",
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^.*groups.+ethz.ch.*$/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


const AppLayout = ({ curRoute, setCurRoute, prevRoute, setPrevRoute, isLoading, enableG, enablePC, enableR }:
  {
    curRoute: string,
    setCurRoute: ((arg0: string) => void),
    prevRoute: string, setPrevRoute: ((arg0: string) => void),
    isLoading: boolean,
    enableR?: boolean,
    enableG?: boolean,
    enablePC?: boolean,
  }) => {


  const theme = makeVsethTheme();
  theme.globalStyles = (theme) => ({
    'html': {
      fontSize: "1.15rem"
    }
  });
  const location = useLocation();

  useEffect(() => {
    const newRoute = "/" + location.pathname.split('/')[1];
    if (newRoute !== curRoute) {
      setPrevRoute(curRoute);
    }
    setCurRoute(newRoute);
  }, [location, curRoute, setCurRoute, setPrevRoute]);

  return (
    <CustomAuthProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <VSETHThemeProvider theme={theme}>
        <VSETHInternalApp
          title="Group Manager"
          nav={[
            { icon: ICONS.HOME, label: "Organization", href: "/" },
          ].concat(
            enableG ? [{ icon: ICONS.CONNECTION_OBJECT, label: "Groups", href: "/groups" }] : []).concat(
              enableR ? [{ icon: ICONS.FOLDER, label: "Resources", href: "/resources" }] : []).concat(
                enablePC ? [{ icon: ICONS.TASKLIST_OK, label: "Changes", href: "/proposedchanges" }] : []).concat([
                  { icon: ICONS.BOOK, label: "Lookup", href: "/lookup" },
                  { icon: ICONS.INFO, label: "About", href: "/about" },
                ])}
          activeHref={curRoute}
          makeWrapper={(url: string | undefined, child: ReactNode) => (
            <Link to={url ? url : ""} style={{ textDecoration: "none", color: "inherit" }} onClick={() => { setCurRoute("/"); setPrevRoute("/"); }} >
              {child}
            </Link>
          )}
        >
          {isLoading ? (
            <LoadScreen />
          ) : (
            <>
              <TestingFeedbackAffix />
              <Outlet />
            </>
          )}
        </VSETHInternalApp>
      </VSETHThemeProvider>
    </CustomAuthProvider >
  );
};

/**
 * `App` is the main component of the application it set up the main structural components
 * such as the navigation bar and the Routes.
 */
const App: React.FC = () => {
  const [curRoute, setCurRoute] = useState("/");
  const [prevRoute, setPrevRoute] = useState("/");

  const isAuthenticated = useSelector(selectIsAuthenticated);

  // load the people and resources api authorization information
  const { data: peopleRoles, isLoading: isLoadingPeopleRoles } =
    useListPeopleAuthorizationRolesQuery(undefined, {
      skip: !isAuthenticated,
    });
  const { data: resourceApiRoles, isLoading: isLoadingResourceRoles } =
    useListResourcesAuthorizationRolesQuery(undefined,
      { skip: !isAuthenticated }
    );

  // get the roles of the user (including debug roles)
  const userRoles = useSelector(selectUserRoles);
  const isAllowedToListResources = selectAllowedToListResources(resourceApiRoles || [], userRoles);
  const isAllowedToListGroups = selectAllowedToListGroups(peopleRoles, userRoles);

  const isAllowedToListProposedChanges = selectAllowedToListProposedChanges(resourceApiRoles || [], userRoles);

  const isLoadingAuthorizationInfo =
    isLoadingPeopleRoles || isLoadingResourceRoles;

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        //isAuthorized={allowedToListGroups}
        //notAuthorizedComponent={NotAuthorizedScreen}
        path="/"
        element={<AppLayout
          curRoute={curRoute} setCurRoute={setCurRoute} prevRoute={prevRoute} setPrevRoute={setPrevRoute} isLoading={isLoadingAuthorizationInfo}
          enableG={isAllowedToListGroups} enableR={isAllowedToListResources} enablePC={isAllowedToListProposedChanges} />}
        errorElement={<ErrorScreen error="route-error" />}
      >
        <Route index element={<ProtectedRoute><OrgStrucPage /></ProtectedRoute>} />
        <Route
          path="groups/:id/:detailPage?addmember=true"
          element={<ProtectedRoute><GroupDetailPage /></ProtectedRoute>} />
        <Route
          path="groups/:id/:detailPage"
          element={<ProtectedRoute><GroupDetailPage /></ProtectedRoute>} />
        <Route
          path="groups/:id"
          element={<ProtectedRoute><GroupDetailPage /></ProtectedRoute>} />
        <Route
          path="groups"
          element={<ProtectedRoute><GroupListPage /></ProtectedRoute>} />
        <Route
          //isAuthorized={allowedToListResources}
          //notAuthorizedComponent={NotAuthorizedScreen}
          path="resources/:id"
          element={<ProtectedRoute><ResourceDetailPage routeRef={prevRoute} /></ProtectedRoute>} />
        <Route
          //isAuthorized={allowedToListResources}
          //notAuthorizedComponent={NotAuthorizedScreen}
          path="resources"
          element={<ProtectedRoute><ResourceListPage /></ProtectedRoute>} />
        <Route
          path="proposedchanges/:id"
          element={<ProtectedRoute><ProposedChangeDetailPage routeRef={prevRoute} /></ProtectedRoute>}
        />
        <Route
          path="proposedchanges"
          element={<ProtectedRoute><ProposedChangeListPage /></ProtectedRoute>}
        />
        <Route
          path="about"
          element={<ProtectedRoute><AboutPage /></ProtectedRoute>}
        />
        <Route
          path="lookup"
          element={<ProtectedRoute><LookupPage /></ProtectedRoute>}
        />
      </Route>
    )
  );

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
};

export default App;