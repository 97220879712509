export const i18n_english = {
  translation: {
    lang: "en",
    ErrorScreen: {
      title: "An error occurred.",
      body: "Try reloading the page and contact the administrator if the error persists.",
    },
    NotAuthorizedScreen: {
      title: "Not Authorized to access this page",
      body: "You are not authorized to access this page, if you think this is an error please contact the VSETH ISG.",
    },
    "Display Name": "Display Name",
    "Email" : "Email",
    "Organization" : "Organization",
    "Title (DE)" : "Title (DE)",
    "Title (EN)" : "Title (EN)",
    "Group Type" : "Group type",
    "Identifier" : "Identifier",
  },
};
