import React, { useState } from "react";
import { Button } from "@mantine/core";

interface AABProps {
  onCancel: () => void;
  onAction: () => Promise<any>;
  buttonSize: "sm" | "lg";
  disabled?: boolean;
  children: React.ReactNode;
}

/**
 * AsyncActionButton creates a ButtonGroup component that can trigger an async action and displays a spinner while the
 * action is being executed.
 */
export const AsyncActionButton = ({
  disabled,
  onCancel,
  onAction,
  children,
  buttonSize,
}: AABProps) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Button.Group className="float-right">
      <Button size={buttonSize} onClick={onCancel} mb="md">
        Cancel
      </Button>
      <Button
        size={buttonSize}
        color={disabled ? "secondary" : "primary"}
        disabled={disabled}
        loading={isLoading}
        mb="md"
        onClick={() => {
          setIsLoading(true);

          onAction().then(() => setIsLoading(false));
        }}
      >
        {children}
      </Button>
    </Button.Group>
  );
};
