import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action: any) => { /// TODO(kledavid): any -  check how old vseth-canine-ui did this
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
    if (isRejectedWithValue(action)) {
      if(!(action.meta && action.meta.arg && action.meta.arg.endpointName === "getUser")){
        console.warn("We got a rejected action!");
        console.log(JSON.stringify(action.error));
        console.log(JSON.stringify(action.meta));
        // toast.warn({title: 'Async error!', message: action.error.message})

        // payload contains the error message

        toast.error("Query Error: " + action.payload.message);
      }
    }

    return next(action);
  };
