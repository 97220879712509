import { ActionIcon, Group, TextInput, Tooltip } from "@mantine/core";
import { forwardRef } from "react";
import { CloseIcon, SearchIcon } from "vseth-canine-ui";

interface SearchBarProps {
  useRegex?: boolean;
  query: string;
  setUseRegex?: (arg0: boolean) => void;
  setQuery: (arg0: string) => void;
  itemPlural?: string;
  error?: string;
  label?: string;
}

export const SearchBar = forwardRef<HTMLInputElement,SearchBarProps>(({ useRegex, query, setUseRegex, setQuery, itemPlural, label, error }: SearchBarProps, ref) => {
  const placeholder = itemPlural ?
    `Search ${itemPlural} for values in selected fields...`
    : "Query...";

  const rightSection = (
    <Group spacing="xs" position="left" pr={setUseRegex && "lg"} noWrap={true}>
      {setUseRegex && (<Tooltip label="Regex search">
        <ActionIcon variant={useRegex ? "filled" : "subtle"} onClick={(e) => setUseRegex(!useRegex)}>.*</ActionIcon>
      </Tooltip>)}
      <ActionIcon mr="lg" onClick={(e) => setQuery("")}><CloseIcon color="grey" /></ActionIcon>
    </Group>);

  return (
    <TextInput data-autofocus
      label={label}
      placeholder={placeholder}
      icon={<SearchIcon />}
      rightSection={rightSection}
      value={query}
      error={error}
      onChange={(e) => setQuery(e.currentTarget.value)}
      ref={ref}
    />
  );
});