/* eslint-disable */ 
// source: sip/resources/resources.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var github_com_mwitkow_go$proto$validators_validator_pb = require('../../github.com/mwitkow/go-proto-validators/validator_pb.js');
goog.object.extend(proto, github_com_mwitkow_go$proto$validators_validator_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.sip.resources.AuditLogEntry', null, global);
goog.exportSymbol('proto.sip.resources.AuditLogEntry.Filter', null, global);
goog.exportSymbol('proto.sip.resources.AuditLogEntry.Filter.SortBy', null, global);
goog.exportSymbol('proto.sip.resources.AuthorizationRole', null, global);
goog.exportSymbol('proto.sip.resources.ConfluenceSpaceResource', null, global);
goog.exportSymbol('proto.sip.resources.CreateProposedChangeRequest', null, global);
goog.exportSymbol('proto.sip.resources.CreateResourcePermissionRequest', null, global);
goog.exportSymbol('proto.sip.resources.CreateResourceRequest', null, global);
goog.exportSymbol('proto.sip.resources.DateRange', null, global);
goog.exportSymbol('proto.sip.resources.DateRange.FromCase', null, global);
goog.exportSymbol('proto.sip.resources.DateRange.UntilCase', null, global);
goog.exportSymbol('proto.sip.resources.DeleteProposedChangeRequest', null, global);
goog.exportSymbol('proto.sip.resources.DeleteResourcePermissionRequest', null, global);
goog.exportSymbol('proto.sip.resources.DeleteResourceRequest', null, global);
goog.exportSymbol('proto.sip.resources.EthIamGroupResource', null, global);
goog.exportSymbol('proto.sip.resources.GSuiteSharedDriveResource', null, global);
goog.exportSymbol('proto.sip.resources.GetResourceRequest', null, global);
goog.exportSymbol('proto.sip.resources.GitlabGroupResource', null, global);
goog.exportSymbol('proto.sip.resources.GlobalScope', null, global);
goog.exportSymbol('proto.sip.resources.GoogleCalendarResource', null, global);
goog.exportSymbol('proto.sip.resources.GroupBasedScope', null, global);
goog.exportSymbol('proto.sip.resources.GroupSpecificScope', null, global);
goog.exportSymbol('proto.sip.resources.GroupTagResource', null, global);
goog.exportSymbol('proto.sip.resources.KeycloakClientResource', null, global);
goog.exportSymbol('proto.sip.resources.KubernetesPermissionResource', null, global);
goog.exportSymbol('proto.sip.resources.ListAuditLogRequest', null, global);
goog.exportSymbol('proto.sip.resources.ListAuditLogResponse', null, global);
goog.exportSymbol('proto.sip.resources.ListAuthorizationRolesResponse', null, global);
goog.exportSymbol('proto.sip.resources.ListProposedChangesRequest', null, global);
goog.exportSymbol('proto.sip.resources.ListProposedChangesResponse', null, global);
goog.exportSymbol('proto.sip.resources.ListResourcePermissionsRequest', null, global);
goog.exportSymbol('proto.sip.resources.ListResourcePermissionsResponse', null, global);
goog.exportSymbol('proto.sip.resources.ListResourceTypesResponse', null, global);
goog.exportSymbol('proto.sip.resources.ListResourcesRequest', null, global);
goog.exportSymbol('proto.sip.resources.ListResourcesResponse', null, global);
goog.exportSymbol('proto.sip.resources.MailContactResource', null, global);
goog.exportSymbol('proto.sip.resources.MailDistributionListResource', null, global);
goog.exportSymbol('proto.sip.resources.MailDistributionListResource.DblType', null, global);
goog.exportSymbol('proto.sip.resources.MailServiceResource', null, global);
goog.exportSymbol('proto.sip.resources.MailServiceResource.MailDomainInfo', null, global);
goog.exportSymbol('proto.sip.resources.MailSharedMailboxResource', null, global);
goog.exportSymbol('proto.sip.resources.MailSpecialUserConfigurationsResource', null, global);
goog.exportSymbol('proto.sip.resources.MailboxQuota', null, global);
goog.exportSymbol('proto.sip.resources.NASStorageFolderResource', null, global);
goog.exportSymbol('proto.sip.resources.OfficeKeyResource', null, global);
goog.exportSymbol('proto.sip.resources.ProposedChange', null, global);
goog.exportSymbol('proto.sip.resources.ProposedChange.Filter', null, global);
goog.exportSymbol('proto.sip.resources.ProposedChange.Filter.SortBy', null, global);
goog.exportSymbol('proto.sip.resources.ProposedChangeState', null, global);
goog.exportSymbol('proto.sip.resources.Resource', null, global);
goog.exportSymbol('proto.sip.resources.Resource.DetailsCase', null, global);
goog.exportSymbol('proto.sip.resources.Resource.Filter', null, global);
goog.exportSymbol('proto.sip.resources.Resource.Filter.SortBy', null, global);
goog.exportSymbol('proto.sip.resources.Resource.View', null, global);
goog.exportSymbol('proto.sip.resources.ResourcePermission', null, global);
goog.exportSymbol('proto.sip.resources.ResourcePermission.Filter', null, global);
goog.exportSymbol('proto.sip.resources.ResourcePermission.Filter.SortBy', null, global);
goog.exportSymbol('proto.sip.resources.ResourcePermission.PrincipalType', null, global);
goog.exportSymbol('proto.sip.resources.ResourceState', null, global);
goog.exportSymbol('proto.sip.resources.ResourceType', null, global);
goog.exportSymbol('proto.sip.resources.ResourceTypeInfo', null, global);
goog.exportSymbol('proto.sip.resources.ResourceTypeInfo.ResourceRole', null, global);
goog.exportSymbol('proto.sip.resources.SympaMailingListResource', null, global);
goog.exportSymbol('proto.sip.resources.SympaMailingListResource.ListType', null, global);
goog.exportSymbol('proto.sip.resources.SympaMailingListResource.SendSetup', null, global);
goog.exportSymbol('proto.sip.resources.UpdateProposedChangeRequest', null, global);
goog.exportSymbol('proto.sip.resources.UpdateResourceRequest', null, global);
goog.exportSymbol('proto.sip.resources.ValidateDBResponse', null, global);
goog.exportSymbol('proto.sip.resources.ValidationError', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.GroupBasedScope = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.resources.GroupBasedScope.repeatedFields_, null);
};
goog.inherits(proto.sip.resources.GroupBasedScope, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.GroupBasedScope.displayName = 'proto.sip.resources.GroupBasedScope';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.AuthorizationRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.resources.AuthorizationRole.repeatedFields_, null);
};
goog.inherits(proto.sip.resources.AuthorizationRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.AuthorizationRole.displayName = 'proto.sip.resources.AuthorizationRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.ListAuthorizationRolesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.resources.ListAuthorizationRolesResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.resources.ListAuthorizationRolesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.ListAuthorizationRolesResponse.displayName = 'proto.sip.resources.ListAuthorizationRolesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.ResourceTypeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.resources.ResourceTypeInfo.repeatedFields_, null);
};
goog.inherits(proto.sip.resources.ResourceTypeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.ResourceTypeInfo.displayName = 'proto.sip.resources.ResourceTypeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.ResourceTypeInfo.ResourceRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.ResourceTypeInfo.ResourceRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.ResourceTypeInfo.ResourceRole.displayName = 'proto.sip.resources.ResourceTypeInfo.ResourceRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.ListResourceTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.resources.ListResourceTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.resources.ListResourceTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.ListResourceTypesResponse.displayName = 'proto.sip.resources.ListResourceTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.DateRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.sip.resources.DateRange.oneofGroups_);
};
goog.inherits(proto.sip.resources.DateRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.DateRange.displayName = 'proto.sip.resources.DateRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.ResourcePermission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.ResourcePermission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.ResourcePermission.displayName = 'proto.sip.resources.ResourcePermission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.ResourcePermission.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.ResourcePermission.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.ResourcePermission.Filter.displayName = 'proto.sip.resources.ResourcePermission.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.ListResourcePermissionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.ListResourcePermissionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.ListResourcePermissionsRequest.displayName = 'proto.sip.resources.ListResourcePermissionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.ListResourcePermissionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.resources.ListResourcePermissionsResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.resources.ListResourcePermissionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.ListResourcePermissionsResponse.displayName = 'proto.sip.resources.ListResourcePermissionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.CreateResourcePermissionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.CreateResourcePermissionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.CreateResourcePermissionRequest.displayName = 'proto.sip.resources.CreateResourcePermissionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.DeleteResourcePermissionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.DeleteResourcePermissionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.DeleteResourcePermissionRequest.displayName = 'proto.sip.resources.DeleteResourcePermissionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.ValidationError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.ValidationError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.ValidationError.displayName = 'proto.sip.resources.ValidationError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.ValidateDBResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.resources.ValidateDBResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.resources.ValidateDBResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.ValidateDBResponse.displayName = 'proto.sip.resources.ValidateDBResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.Resource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.resources.Resource.repeatedFields_, proto.sip.resources.Resource.oneofGroups_);
};
goog.inherits(proto.sip.resources.Resource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.Resource.displayName = 'proto.sip.resources.Resource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.Resource.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.resources.Resource.Filter.repeatedFields_, null);
};
goog.inherits(proto.sip.resources.Resource.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.Resource.Filter.displayName = 'proto.sip.resources.Resource.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.ListResourcesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.ListResourcesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.ListResourcesRequest.displayName = 'proto.sip.resources.ListResourcesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.ListResourcesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.resources.ListResourcesResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.resources.ListResourcesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.ListResourcesResponse.displayName = 'proto.sip.resources.ListResourcesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.GetResourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.GetResourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.GetResourceRequest.displayName = 'proto.sip.resources.GetResourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.CreateResourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.CreateResourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.CreateResourceRequest.displayName = 'proto.sip.resources.CreateResourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.UpdateResourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.UpdateResourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.UpdateResourceRequest.displayName = 'proto.sip.resources.UpdateResourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.DeleteResourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.DeleteResourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.DeleteResourceRequest.displayName = 'proto.sip.resources.DeleteResourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.ProposedChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.resources.ProposedChange.repeatedFields_, null);
};
goog.inherits(proto.sip.resources.ProposedChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.ProposedChange.displayName = 'proto.sip.resources.ProposedChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.ProposedChange.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.ProposedChange.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.ProposedChange.Filter.displayName = 'proto.sip.resources.ProposedChange.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.ListProposedChangesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.ListProposedChangesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.ListProposedChangesRequest.displayName = 'proto.sip.resources.ListProposedChangesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.ListProposedChangesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.resources.ListProposedChangesResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.resources.ListProposedChangesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.ListProposedChangesResponse.displayName = 'proto.sip.resources.ListProposedChangesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.CreateProposedChangeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.CreateProposedChangeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.CreateProposedChangeRequest.displayName = 'proto.sip.resources.CreateProposedChangeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.UpdateProposedChangeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.UpdateProposedChangeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.UpdateProposedChangeRequest.displayName = 'proto.sip.resources.UpdateProposedChangeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.DeleteProposedChangeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.DeleteProposedChangeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.DeleteProposedChangeRequest.displayName = 'proto.sip.resources.DeleteProposedChangeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.AuditLogEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.AuditLogEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.AuditLogEntry.displayName = 'proto.sip.resources.AuditLogEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.AuditLogEntry.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.AuditLogEntry.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.AuditLogEntry.Filter.displayName = 'proto.sip.resources.AuditLogEntry.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.ListAuditLogRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.ListAuditLogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.ListAuditLogRequest.displayName = 'proto.sip.resources.ListAuditLogRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.ListAuditLogResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.resources.ListAuditLogResponse.repeatedFields_, null);
};
goog.inherits(proto.sip.resources.ListAuditLogResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.ListAuditLogResponse.displayName = 'proto.sip.resources.ListAuditLogResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.MailServiceResource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.resources.MailServiceResource.repeatedFields_, null);
};
goog.inherits(proto.sip.resources.MailServiceResource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.MailServiceResource.displayName = 'proto.sip.resources.MailServiceResource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.MailServiceResource.MailDomainInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.resources.MailServiceResource.MailDomainInfo.repeatedFields_, null);
};
goog.inherits(proto.sip.resources.MailServiceResource.MailDomainInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.MailServiceResource.MailDomainInfo.displayName = 'proto.sip.resources.MailServiceResource.MailDomainInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.MailDistributionListResource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.resources.MailDistributionListResource.repeatedFields_, null);
};
goog.inherits(proto.sip.resources.MailDistributionListResource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.MailDistributionListResource.displayName = 'proto.sip.resources.MailDistributionListResource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.MailSharedMailboxResource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.resources.MailSharedMailboxResource.repeatedFields_, null);
};
goog.inherits(proto.sip.resources.MailSharedMailboxResource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.MailSharedMailboxResource.displayName = 'proto.sip.resources.MailSharedMailboxResource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.MailContactResource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.resources.MailContactResource.repeatedFields_, null);
};
goog.inherits(proto.sip.resources.MailContactResource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.MailContactResource.displayName = 'proto.sip.resources.MailContactResource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.EthIamGroupResource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.EthIamGroupResource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.EthIamGroupResource.displayName = 'proto.sip.resources.EthIamGroupResource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.SympaMailingListResource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.SympaMailingListResource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.SympaMailingListResource.displayName = 'proto.sip.resources.SympaMailingListResource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.GSuiteSharedDriveResource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.GSuiteSharedDriveResource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.GSuiteSharedDriveResource.displayName = 'proto.sip.resources.GSuiteSharedDriveResource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.ConfluenceSpaceResource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.ConfluenceSpaceResource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.ConfluenceSpaceResource.displayName = 'proto.sip.resources.ConfluenceSpaceResource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.GitlabGroupResource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.GitlabGroupResource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.GitlabGroupResource.displayName = 'proto.sip.resources.GitlabGroupResource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.GroupTagResource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.GroupTagResource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.GroupTagResource.displayName = 'proto.sip.resources.GroupTagResource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.KeycloakClientResource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.KeycloakClientResource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.KeycloakClientResource.displayName = 'proto.sip.resources.KeycloakClientResource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.NASStorageFolderResource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.NASStorageFolderResource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.NASStorageFolderResource.displayName = 'proto.sip.resources.NASStorageFolderResource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.GoogleCalendarResource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.GoogleCalendarResource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.GoogleCalendarResource.displayName = 'proto.sip.resources.GoogleCalendarResource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.OfficeKeyResource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.OfficeKeyResource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.OfficeKeyResource.displayName = 'proto.sip.resources.OfficeKeyResource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.KubernetesPermissionResource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sip.resources.KubernetesPermissionResource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.KubernetesPermissionResource.displayName = 'proto.sip.resources.KubernetesPermissionResource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sip.resources.MailSpecialUserConfigurationsResource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sip.resources.MailSpecialUserConfigurationsResource.repeatedFields_, null);
};
goog.inherits(proto.sip.resources.MailSpecialUserConfigurationsResource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sip.resources.MailSpecialUserConfigurationsResource.displayName = 'proto.sip.resources.MailSpecialUserConfigurationsResource';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.resources.GroupBasedScope.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.GroupBasedScope.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.GroupBasedScope.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.GroupBasedScope} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.GroupBasedScope.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inherit: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    scopesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.GroupBasedScope}
 */
proto.sip.resources.GroupBasedScope.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.GroupBasedScope;
  return proto.sip.resources.GroupBasedScope.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.GroupBasedScope} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.GroupBasedScope}
 */
proto.sip.resources.GroupBasedScope.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInherit(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.sip.resources.GroupSpecificScope>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addScopes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.GroupBasedScope.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.GroupBasedScope.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.GroupBasedScope} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.GroupBasedScope.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInherit();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getScopesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
};


/**
 * optional string group_name = 1;
 * @return {string}
 */
proto.sip.resources.GroupBasedScope.prototype.getGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.GroupBasedScope} returns this
 */
proto.sip.resources.GroupBasedScope.prototype.setGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool inherit = 2;
 * @return {boolean}
 */
proto.sip.resources.GroupBasedScope.prototype.getInherit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.resources.GroupBasedScope} returns this
 */
proto.sip.resources.GroupBasedScope.prototype.setInherit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated GroupSpecificScope scopes = 3;
 * @return {!Array<!proto.sip.resources.GroupSpecificScope>}
 */
proto.sip.resources.GroupBasedScope.prototype.getScopesList = function() {
  return /** @type {!Array<!proto.sip.resources.GroupSpecificScope>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.sip.resources.GroupSpecificScope>} value
 * @return {!proto.sip.resources.GroupBasedScope} returns this
 */
proto.sip.resources.GroupBasedScope.prototype.setScopesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.sip.resources.GroupSpecificScope} value
 * @param {number=} opt_index
 * @return {!proto.sip.resources.GroupBasedScope} returns this
 */
proto.sip.resources.GroupBasedScope.prototype.addScopes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.resources.GroupBasedScope} returns this
 */
proto.sip.resources.GroupBasedScope.prototype.clearScopesList = function() {
  return this.setScopesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.resources.AuthorizationRole.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.AuthorizationRole.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.AuthorizationRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.AuthorizationRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.AuthorizationRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    globalScopesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    groupBasedScopeList: jspb.Message.toObjectList(msg.getGroupBasedScopeList(),
    proto.sip.resources.GroupBasedScope.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.AuthorizationRole}
 */
proto.sip.resources.AuthorizationRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.AuthorizationRole;
  return proto.sip.resources.AuthorizationRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.AuthorizationRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.AuthorizationRole}
 */
proto.sip.resources.AuthorizationRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.sip.resources.GlobalScope>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGlobalScopes(values[i]);
      }
      break;
    case 4:
      var value = new proto.sip.resources.GroupBasedScope;
      reader.readMessage(value,proto.sip.resources.GroupBasedScope.deserializeBinaryFromReader);
      msg.addGroupBasedScope(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.AuthorizationRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.AuthorizationRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.AuthorizationRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.AuthorizationRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGlobalScopesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getGroupBasedScopeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.sip.resources.GroupBasedScope.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.resources.AuthorizationRole.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.AuthorizationRole} returns this
 */
proto.sip.resources.AuthorizationRole.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.sip.resources.AuthorizationRole.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.AuthorizationRole} returns this
 */
proto.sip.resources.AuthorizationRole.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated GlobalScope global_scopes = 3;
 * @return {!Array<!proto.sip.resources.GlobalScope>}
 */
proto.sip.resources.AuthorizationRole.prototype.getGlobalScopesList = function() {
  return /** @type {!Array<!proto.sip.resources.GlobalScope>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.sip.resources.GlobalScope>} value
 * @return {!proto.sip.resources.AuthorizationRole} returns this
 */
proto.sip.resources.AuthorizationRole.prototype.setGlobalScopesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.sip.resources.GlobalScope} value
 * @param {number=} opt_index
 * @return {!proto.sip.resources.AuthorizationRole} returns this
 */
proto.sip.resources.AuthorizationRole.prototype.addGlobalScopes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.resources.AuthorizationRole} returns this
 */
proto.sip.resources.AuthorizationRole.prototype.clearGlobalScopesList = function() {
  return this.setGlobalScopesList([]);
};


/**
 * repeated GroupBasedScope group_based_scope = 4;
 * @return {!Array<!proto.sip.resources.GroupBasedScope>}
 */
proto.sip.resources.AuthorizationRole.prototype.getGroupBasedScopeList = function() {
  return /** @type{!Array<!proto.sip.resources.GroupBasedScope>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.resources.GroupBasedScope, 4));
};


/**
 * @param {!Array<!proto.sip.resources.GroupBasedScope>} value
 * @return {!proto.sip.resources.AuthorizationRole} returns this
*/
proto.sip.resources.AuthorizationRole.prototype.setGroupBasedScopeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.sip.resources.GroupBasedScope=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.resources.GroupBasedScope}
 */
proto.sip.resources.AuthorizationRole.prototype.addGroupBasedScope = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.sip.resources.GroupBasedScope, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.resources.AuthorizationRole} returns this
 */
proto.sip.resources.AuthorizationRole.prototype.clearGroupBasedScopeList = function() {
  return this.setGroupBasedScopeList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.resources.ListAuthorizationRolesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.ListAuthorizationRolesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.ListAuthorizationRolesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.ListAuthorizationRolesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ListAuthorizationRolesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    proto.sip.resources.AuthorizationRole.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.ListAuthorizationRolesResponse}
 */
proto.sip.resources.ListAuthorizationRolesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.ListAuthorizationRolesResponse;
  return proto.sip.resources.ListAuthorizationRolesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.ListAuthorizationRolesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.ListAuthorizationRolesResponse}
 */
proto.sip.resources.ListAuthorizationRolesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.resources.AuthorizationRole;
      reader.readMessage(value,proto.sip.resources.AuthorizationRole.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.ListAuthorizationRolesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.ListAuthorizationRolesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.ListAuthorizationRolesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ListAuthorizationRolesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.resources.AuthorizationRole.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AuthorizationRole roles = 1;
 * @return {!Array<!proto.sip.resources.AuthorizationRole>}
 */
proto.sip.resources.ListAuthorizationRolesResponse.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.sip.resources.AuthorizationRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.resources.AuthorizationRole, 1));
};


/**
 * @param {!Array<!proto.sip.resources.AuthorizationRole>} value
 * @return {!proto.sip.resources.ListAuthorizationRolesResponse} returns this
*/
proto.sip.resources.ListAuthorizationRolesResponse.prototype.setRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.resources.AuthorizationRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.resources.AuthorizationRole}
 */
proto.sip.resources.ListAuthorizationRolesResponse.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.resources.AuthorizationRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.resources.ListAuthorizationRolesResponse} returns this
 */
proto.sip.resources.ListAuthorizationRolesResponse.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.resources.ResourceTypeInfo.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.ResourceTypeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.ResourceTypeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.ResourceTypeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ResourceTypeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resourceType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    documentationUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    iconUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    resourceRolesList: jspb.Message.toObjectList(msg.getResourceRolesList(),
    proto.sip.resources.ResourceTypeInfo.ResourceRole.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.ResourceTypeInfo}
 */
proto.sip.resources.ResourceTypeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.ResourceTypeInfo;
  return proto.sip.resources.ResourceTypeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.ResourceTypeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.ResourceTypeInfo}
 */
proto.sip.resources.ResourceTypeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.sip.resources.ResourceType} */ (reader.readEnum());
      msg.setResourceType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentationUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconUrl(value);
      break;
    case 7:
      var value = new proto.sip.resources.ResourceTypeInfo.ResourceRole;
      reader.readMessage(value,proto.sip.resources.ResourceTypeInfo.ResourceRole.deserializeBinaryFromReader);
      msg.addResourceRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.ResourceTypeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.ResourceTypeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.ResourceTypeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ResourceTypeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResourceType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDocumentationUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIconUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getResourceRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.sip.resources.ResourceTypeInfo.ResourceRole.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.ResourceTypeInfo.ResourceRole.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.ResourceTypeInfo.ResourceRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.ResourceTypeInfo.ResourceRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ResourceTypeInfo.ResourceRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.ResourceTypeInfo.ResourceRole}
 */
proto.sip.resources.ResourceTypeInfo.ResourceRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.ResourceTypeInfo.ResourceRole;
  return proto.sip.resources.ResourceTypeInfo.ResourceRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.ResourceTypeInfo.ResourceRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.ResourceTypeInfo.ResourceRole}
 */
proto.sip.resources.ResourceTypeInfo.ResourceRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.ResourceTypeInfo.ResourceRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.ResourceTypeInfo.ResourceRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.ResourceTypeInfo.ResourceRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ResourceTypeInfo.ResourceRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.resources.ResourceTypeInfo.ResourceRole.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ResourceTypeInfo.ResourceRole} returns this
 */
proto.sip.resources.ResourceTypeInfo.ResourceRole.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.sip.resources.ResourceTypeInfo.ResourceRole.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ResourceTypeInfo.ResourceRole} returns this
 */
proto.sip.resources.ResourceTypeInfo.ResourceRole.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.sip.resources.ResourceTypeInfo.ResourceRole.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ResourceTypeInfo.ResourceRole} returns this
 */
proto.sip.resources.ResourceTypeInfo.ResourceRole.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.resources.ResourceTypeInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ResourceTypeInfo} returns this
 */
proto.sip.resources.ResourceTypeInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ResourceType resource_type = 2;
 * @return {!proto.sip.resources.ResourceType}
 */
proto.sip.resources.ResourceTypeInfo.prototype.getResourceType = function() {
  return /** @type {!proto.sip.resources.ResourceType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sip.resources.ResourceType} value
 * @return {!proto.sip.resources.ResourceTypeInfo} returns this
 */
proto.sip.resources.ResourceTypeInfo.prototype.setResourceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.sip.resources.ResourceTypeInfo.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ResourceTypeInfo} returns this
 */
proto.sip.resources.ResourceTypeInfo.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.sip.resources.ResourceTypeInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ResourceTypeInfo} returns this
 */
proto.sip.resources.ResourceTypeInfo.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string documentation_url = 5;
 * @return {string}
 */
proto.sip.resources.ResourceTypeInfo.prototype.getDocumentationUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ResourceTypeInfo} returns this
 */
proto.sip.resources.ResourceTypeInfo.prototype.setDocumentationUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string icon_url = 6;
 * @return {string}
 */
proto.sip.resources.ResourceTypeInfo.prototype.getIconUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ResourceTypeInfo} returns this
 */
proto.sip.resources.ResourceTypeInfo.prototype.setIconUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated ResourceRole resource_roles = 7;
 * @return {!Array<!proto.sip.resources.ResourceTypeInfo.ResourceRole>}
 */
proto.sip.resources.ResourceTypeInfo.prototype.getResourceRolesList = function() {
  return /** @type{!Array<!proto.sip.resources.ResourceTypeInfo.ResourceRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.resources.ResourceTypeInfo.ResourceRole, 7));
};


/**
 * @param {!Array<!proto.sip.resources.ResourceTypeInfo.ResourceRole>} value
 * @return {!proto.sip.resources.ResourceTypeInfo} returns this
*/
proto.sip.resources.ResourceTypeInfo.prototype.setResourceRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.sip.resources.ResourceTypeInfo.ResourceRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.resources.ResourceTypeInfo.ResourceRole}
 */
proto.sip.resources.ResourceTypeInfo.prototype.addResourceRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.sip.resources.ResourceTypeInfo.ResourceRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.resources.ResourceTypeInfo} returns this
 */
proto.sip.resources.ResourceTypeInfo.prototype.clearResourceRolesList = function() {
  return this.setResourceRolesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.resources.ListResourceTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.ListResourceTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.ListResourceTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.ListResourceTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ListResourceTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceTypesList: jspb.Message.toObjectList(msg.getResourceTypesList(),
    proto.sip.resources.ResourceTypeInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.ListResourceTypesResponse}
 */
proto.sip.resources.ListResourceTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.ListResourceTypesResponse;
  return proto.sip.resources.ListResourceTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.ListResourceTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.ListResourceTypesResponse}
 */
proto.sip.resources.ListResourceTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.resources.ResourceTypeInfo;
      reader.readMessage(value,proto.sip.resources.ResourceTypeInfo.deserializeBinaryFromReader);
      msg.addResourceTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.ListResourceTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.ListResourceTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.ListResourceTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ListResourceTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.resources.ResourceTypeInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ResourceTypeInfo resource_types = 1;
 * @return {!Array<!proto.sip.resources.ResourceTypeInfo>}
 */
proto.sip.resources.ListResourceTypesResponse.prototype.getResourceTypesList = function() {
  return /** @type{!Array<!proto.sip.resources.ResourceTypeInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.resources.ResourceTypeInfo, 1));
};


/**
 * @param {!Array<!proto.sip.resources.ResourceTypeInfo>} value
 * @return {!proto.sip.resources.ListResourceTypesResponse} returns this
*/
proto.sip.resources.ListResourceTypesResponse.prototype.setResourceTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.resources.ResourceTypeInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.resources.ResourceTypeInfo}
 */
proto.sip.resources.ListResourceTypesResponse.prototype.addResourceTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.resources.ResourceTypeInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.resources.ListResourceTypesResponse} returns this
 */
proto.sip.resources.ListResourceTypesResponse.prototype.clearResourceTypesList = function() {
  return this.setResourceTypesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.sip.resources.DateRange.oneofGroups_ = [[1,2],[3,4]];

/**
 * @enum {number}
 */
proto.sip.resources.DateRange.FromCase = {
  FROM_NOT_SET: 0,
  UNLIMITED_FROM_RANGE: 1,
  TIMESTAMP_FROM: 2
};

/**
 * @return {proto.sip.resources.DateRange.FromCase}
 */
proto.sip.resources.DateRange.prototype.getFromCase = function() {
  return /** @type {proto.sip.resources.DateRange.FromCase} */(jspb.Message.computeOneofCase(this, proto.sip.resources.DateRange.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.sip.resources.DateRange.UntilCase = {
  UNTIL_NOT_SET: 0,
  UNLIMITED_UNTIL_RANGE: 3,
  TIMESTAMP_UNTIL: 4
};

/**
 * @return {proto.sip.resources.DateRange.UntilCase}
 */
proto.sip.resources.DateRange.prototype.getUntilCase = function() {
  return /** @type {proto.sip.resources.DateRange.UntilCase} */(jspb.Message.computeOneofCase(this, proto.sip.resources.DateRange.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.DateRange.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.DateRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.DateRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.DateRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    unlimitedFromRange: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    timestampFrom: (f = msg.getTimestampFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    unlimitedUntilRange: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    timestampUntil: (f = msg.getTimestampUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.DateRange}
 */
proto.sip.resources.DateRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.DateRange;
  return proto.sip.resources.DateRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.DateRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.DateRange}
 */
proto.sip.resources.DateRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnlimitedFromRange(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestampFrom(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnlimitedUntilRange(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestampUntil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.DateRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.DateRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.DateRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.DateRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getTimestampFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getTimestampUntil();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool unlimited_from_range = 1;
 * @return {boolean}
 */
proto.sip.resources.DateRange.prototype.getUnlimitedFromRange = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.resources.DateRange} returns this
 */
proto.sip.resources.DateRange.prototype.setUnlimitedFromRange = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.sip.resources.DateRange.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sip.resources.DateRange} returns this
 */
proto.sip.resources.DateRange.prototype.clearUnlimitedFromRange = function() {
  return jspb.Message.setOneofField(this, 1, proto.sip.resources.DateRange.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.DateRange.prototype.hasUnlimitedFromRange = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp timestamp_from = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.resources.DateRange.prototype.getTimestampFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.resources.DateRange} returns this
*/
proto.sip.resources.DateRange.prototype.setTimestampFrom = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.sip.resources.DateRange.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.DateRange} returns this
 */
proto.sip.resources.DateRange.prototype.clearTimestampFrom = function() {
  return this.setTimestampFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.DateRange.prototype.hasTimestampFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool unlimited_until_range = 3;
 * @return {boolean}
 */
proto.sip.resources.DateRange.prototype.getUnlimitedUntilRange = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.resources.DateRange} returns this
 */
proto.sip.resources.DateRange.prototype.setUnlimitedUntilRange = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.sip.resources.DateRange.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sip.resources.DateRange} returns this
 */
proto.sip.resources.DateRange.prototype.clearUnlimitedUntilRange = function() {
  return jspb.Message.setOneofField(this, 3, proto.sip.resources.DateRange.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.DateRange.prototype.hasUnlimitedUntilRange = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp timestamp_until = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.resources.DateRange.prototype.getTimestampUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.resources.DateRange} returns this
*/
proto.sip.resources.DateRange.prototype.setTimestampUntil = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.sip.resources.DateRange.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.DateRange} returns this
 */
proto.sip.resources.DateRange.prototype.clearTimestampUntil = function() {
  return this.setTimestampUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.DateRange.prototype.hasTimestampUntil = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.ResourcePermission.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.ResourcePermission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.ResourcePermission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ResourcePermission.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    principalName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    principalType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    resourceRole: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.ResourcePermission}
 */
proto.sip.resources.ResourcePermission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.ResourcePermission;
  return proto.sip.resources.ResourcePermission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.ResourcePermission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.ResourcePermission}
 */
proto.sip.resources.ResourcePermission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrincipalName(value);
      break;
    case 3:
      var value = /** @type {!proto.sip.resources.ResourcePermission.PrincipalType} */ (reader.readEnum());
      msg.setPrincipalType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceRole(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.ResourcePermission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.ResourcePermission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.ResourcePermission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ResourcePermission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrincipalName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrincipalType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getResourceRole();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.sip.resources.ResourcePermission.PrincipalType = {
  RESOURCE_PRINCIPAL_UNSPECIFIED: 0,
  USER: 1,
  GROUP: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.ResourcePermission.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.ResourcePermission.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.ResourcePermission.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ResourcePermission.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    principalName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    principalType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    resourceRole: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sortBy: jspb.Message.getFieldWithDefault(msg, 10, 0),
    sortDescending: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.ResourcePermission.Filter}
 */
proto.sip.resources.ResourcePermission.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.ResourcePermission.Filter;
  return proto.sip.resources.ResourcePermission.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.ResourcePermission.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.ResourcePermission.Filter}
 */
proto.sip.resources.ResourcePermission.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrincipalName(value);
      break;
    case 3:
      var value = /** @type {!proto.sip.resources.ResourcePermission.PrincipalType} */ (reader.readEnum());
      msg.setPrincipalType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceRole(value);
      break;
    case 10:
      var value = /** @type {!proto.sip.resources.ResourcePermission.Filter.SortBy} */ (reader.readEnum());
      msg.setSortBy(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSortDescending(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.ResourcePermission.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.ResourcePermission.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.ResourcePermission.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ResourcePermission.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrincipalName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrincipalType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getResourceRole();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSortBy();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getSortDescending();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sip.resources.ResourcePermission.Filter.SortBy = {
  NAME: 0,
  PRINCIPAL_NAME: 1,
  PRINCIPAL_TYPE: 2,
  RESOURCE_ROLE: 3,
  CREATE_TIME: 4,
  UPDATE_TIME: 5,
  LAST_UPDATED_BY: 6
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.resources.ResourcePermission.Filter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ResourcePermission.Filter} returns this
 */
proto.sip.resources.ResourcePermission.Filter.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string principal_name = 2;
 * @return {string}
 */
proto.sip.resources.ResourcePermission.Filter.prototype.getPrincipalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ResourcePermission.Filter} returns this
 */
proto.sip.resources.ResourcePermission.Filter.prototype.setPrincipalName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PrincipalType principal_type = 3;
 * @return {!proto.sip.resources.ResourcePermission.PrincipalType}
 */
proto.sip.resources.ResourcePermission.Filter.prototype.getPrincipalType = function() {
  return /** @type {!proto.sip.resources.ResourcePermission.PrincipalType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.sip.resources.ResourcePermission.PrincipalType} value
 * @return {!proto.sip.resources.ResourcePermission.Filter} returns this
 */
proto.sip.resources.ResourcePermission.Filter.prototype.setPrincipalType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string resource_role = 4;
 * @return {string}
 */
proto.sip.resources.ResourcePermission.Filter.prototype.getResourceRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ResourcePermission.Filter} returns this
 */
proto.sip.resources.ResourcePermission.Filter.prototype.setResourceRole = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional SortBy sort_by = 10;
 * @return {!proto.sip.resources.ResourcePermission.Filter.SortBy}
 */
proto.sip.resources.ResourcePermission.Filter.prototype.getSortBy = function() {
  return /** @type {!proto.sip.resources.ResourcePermission.Filter.SortBy} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.sip.resources.ResourcePermission.Filter.SortBy} value
 * @return {!proto.sip.resources.ResourcePermission.Filter} returns this
 */
proto.sip.resources.ResourcePermission.Filter.prototype.setSortBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional bool sort_descending = 11;
 * @return {boolean}
 */
proto.sip.resources.ResourcePermission.Filter.prototype.getSortDescending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.resources.ResourcePermission.Filter} returns this
 */
proto.sip.resources.ResourcePermission.Filter.prototype.setSortDescending = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.resources.ResourcePermission.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ResourcePermission} returns this
 */
proto.sip.resources.ResourcePermission.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string principal_name = 2;
 * @return {string}
 */
proto.sip.resources.ResourcePermission.prototype.getPrincipalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ResourcePermission} returns this
 */
proto.sip.resources.ResourcePermission.prototype.setPrincipalName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PrincipalType principal_type = 3;
 * @return {!proto.sip.resources.ResourcePermission.PrincipalType}
 */
proto.sip.resources.ResourcePermission.prototype.getPrincipalType = function() {
  return /** @type {!proto.sip.resources.ResourcePermission.PrincipalType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.sip.resources.ResourcePermission.PrincipalType} value
 * @return {!proto.sip.resources.ResourcePermission} returns this
 */
proto.sip.resources.ResourcePermission.prototype.setPrincipalType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string resource_role = 4;
 * @return {string}
 */
proto.sip.resources.ResourcePermission.prototype.getResourceRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ResourcePermission} returns this
 */
proto.sip.resources.ResourcePermission.prototype.setResourceRole = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp create_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.resources.ResourcePermission.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.resources.ResourcePermission} returns this
*/
proto.sip.resources.ResourcePermission.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.ResourcePermission} returns this
 */
proto.sip.resources.ResourcePermission.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.ResourcePermission.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp update_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.resources.ResourcePermission.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.resources.ResourcePermission} returns this
*/
proto.sip.resources.ResourcePermission.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.ResourcePermission} returns this
 */
proto.sip.resources.ResourcePermission.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.ResourcePermission.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.ListResourcePermissionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.ListResourcePermissionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.ListResourcePermissionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ListResourcePermissionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.sip.resources.ResourcePermission.Filter.toObject(includeInstance, f),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.ListResourcePermissionsRequest}
 */
proto.sip.resources.ListResourcePermissionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.ListResourcePermissionsRequest;
  return proto.sip.resources.ListResourcePermissionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.ListResourcePermissionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.ListResourcePermissionsRequest}
 */
proto.sip.resources.ListResourcePermissionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.resources.ResourcePermission.Filter;
      reader.readMessage(value,proto.sip.resources.ResourcePermission.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.ListResourcePermissionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.ListResourcePermissionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.ListResourcePermissionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ListResourcePermissionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.resources.ResourcePermission.Filter.serializeBinaryToWriter
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional ResourcePermission.Filter filter = 1;
 * @return {?proto.sip.resources.ResourcePermission.Filter}
 */
proto.sip.resources.ListResourcePermissionsRequest.prototype.getFilter = function() {
  return /** @type{?proto.sip.resources.ResourcePermission.Filter} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.ResourcePermission.Filter, 1));
};


/**
 * @param {?proto.sip.resources.ResourcePermission.Filter|undefined} value
 * @return {!proto.sip.resources.ListResourcePermissionsRequest} returns this
*/
proto.sip.resources.ListResourcePermissionsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.ListResourcePermissionsRequest} returns this
 */
proto.sip.resources.ListResourcePermissionsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.ListResourcePermissionsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.sip.resources.ListResourcePermissionsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.resources.ListResourcePermissionsRequest} returns this
 */
proto.sip.resources.ListResourcePermissionsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.sip.resources.ListResourcePermissionsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ListResourcePermissionsRequest} returns this
 */
proto.sip.resources.ListResourcePermissionsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.resources.ListResourcePermissionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.ListResourcePermissionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.ListResourcePermissionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.ListResourcePermissionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ListResourcePermissionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    permissionsList: jspb.Message.toObjectList(msg.getPermissionsList(),
    proto.sip.resources.ResourcePermission.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.ListResourcePermissionsResponse}
 */
proto.sip.resources.ListResourcePermissionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.ListResourcePermissionsResponse;
  return proto.sip.resources.ListResourcePermissionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.ListResourcePermissionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.ListResourcePermissionsResponse}
 */
proto.sip.resources.ListResourcePermissionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.resources.ResourcePermission;
      reader.readMessage(value,proto.sip.resources.ResourcePermission.deserializeBinaryFromReader);
      msg.addPermissions(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.ListResourcePermissionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.ListResourcePermissionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.ListResourcePermissionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ListResourcePermissionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.resources.ResourcePermission.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated ResourcePermission permissions = 1;
 * @return {!Array<!proto.sip.resources.ResourcePermission>}
 */
proto.sip.resources.ListResourcePermissionsResponse.prototype.getPermissionsList = function() {
  return /** @type{!Array<!proto.sip.resources.ResourcePermission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.resources.ResourcePermission, 1));
};


/**
 * @param {!Array<!proto.sip.resources.ResourcePermission>} value
 * @return {!proto.sip.resources.ListResourcePermissionsResponse} returns this
*/
proto.sip.resources.ListResourcePermissionsResponse.prototype.setPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.resources.ResourcePermission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.resources.ResourcePermission}
 */
proto.sip.resources.ListResourcePermissionsResponse.prototype.addPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.resources.ResourcePermission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.resources.ListResourcePermissionsResponse} returns this
 */
proto.sip.resources.ListResourcePermissionsResponse.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.resources.ListResourcePermissionsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ListResourcePermissionsResponse} returns this
 */
proto.sip.resources.ListResourcePermissionsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 total_size = 3;
 * @return {number}
 */
proto.sip.resources.ListResourcePermissionsResponse.prototype.getTotalSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.resources.ListResourcePermissionsResponse} returns this
 */
proto.sip.resources.ListResourcePermissionsResponse.prototype.setTotalSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.CreateResourcePermissionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.CreateResourcePermissionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.CreateResourcePermissionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.CreateResourcePermissionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    permission: (f = msg.getPermission()) && proto.sip.resources.ResourcePermission.toObject(includeInstance, f),
    auditMessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.CreateResourcePermissionRequest}
 */
proto.sip.resources.CreateResourcePermissionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.CreateResourcePermissionRequest;
  return proto.sip.resources.CreateResourcePermissionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.CreateResourcePermissionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.CreateResourcePermissionRequest}
 */
proto.sip.resources.CreateResourcePermissionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceName(value);
      break;
    case 2:
      var value = new proto.sip.resources.ResourcePermission;
      reader.readMessage(value,proto.sip.resources.ResourcePermission.deserializeBinaryFromReader);
      msg.setPermission(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.CreateResourcePermissionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.CreateResourcePermissionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.CreateResourcePermissionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.CreateResourcePermissionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPermission();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sip.resources.ResourcePermission.serializeBinaryToWriter
    );
  }
  f = message.getAuditMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string resource_name = 1;
 * @return {string}
 */
proto.sip.resources.CreateResourcePermissionRequest.prototype.getResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.CreateResourcePermissionRequest} returns this
 */
proto.sip.resources.CreateResourcePermissionRequest.prototype.setResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ResourcePermission permission = 2;
 * @return {?proto.sip.resources.ResourcePermission}
 */
proto.sip.resources.CreateResourcePermissionRequest.prototype.getPermission = function() {
  return /** @type{?proto.sip.resources.ResourcePermission} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.ResourcePermission, 2));
};


/**
 * @param {?proto.sip.resources.ResourcePermission|undefined} value
 * @return {!proto.sip.resources.CreateResourcePermissionRequest} returns this
*/
proto.sip.resources.CreateResourcePermissionRequest.prototype.setPermission = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.CreateResourcePermissionRequest} returns this
 */
proto.sip.resources.CreateResourcePermissionRequest.prototype.clearPermission = function() {
  return this.setPermission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.CreateResourcePermissionRequest.prototype.hasPermission = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string audit_message = 3;
 * @return {string}
 */
proto.sip.resources.CreateResourcePermissionRequest.prototype.getAuditMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.CreateResourcePermissionRequest} returns this
 */
proto.sip.resources.CreateResourcePermissionRequest.prototype.setAuditMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.DeleteResourcePermissionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.DeleteResourcePermissionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.DeleteResourcePermissionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.DeleteResourcePermissionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditMessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.DeleteResourcePermissionRequest}
 */
proto.sip.resources.DeleteResourcePermissionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.DeleteResourcePermissionRequest;
  return proto.sip.resources.DeleteResourcePermissionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.DeleteResourcePermissionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.DeleteResourcePermissionRequest}
 */
proto.sip.resources.DeleteResourcePermissionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.DeleteResourcePermissionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.DeleteResourcePermissionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.DeleteResourcePermissionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.DeleteResourcePermissionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.resources.DeleteResourcePermissionRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.DeleteResourcePermissionRequest} returns this
 */
proto.sip.resources.DeleteResourcePermissionRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string audit_message = 2;
 * @return {string}
 */
proto.sip.resources.DeleteResourcePermissionRequest.prototype.getAuditMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.DeleteResourcePermissionRequest} returns this
 */
proto.sip.resources.DeleteResourcePermissionRequest.prototype.setAuditMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.ValidationError.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.ValidationError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.ValidationError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ValidationError.toObject = function(includeInstance, msg) {
  var f, obj = {
    problem: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resourceName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.ValidationError}
 */
proto.sip.resources.ValidationError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.ValidationError;
  return proto.sip.resources.ValidationError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.ValidationError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.ValidationError}
 */
proto.sip.resources.ValidationError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProblem(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceName(value);
      break;
    case 3:
      var value = /** @type {!proto.sip.resources.ResourceType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.ValidationError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.ValidationError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.ValidationError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ValidationError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProblem();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResourceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string problem = 1;
 * @return {string}
 */
proto.sip.resources.ValidationError.prototype.getProblem = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ValidationError} returns this
 */
proto.sip.resources.ValidationError.prototype.setProblem = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string resource_name = 2;
 * @return {string}
 */
proto.sip.resources.ValidationError.prototype.getResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ValidationError} returns this
 */
proto.sip.resources.ValidationError.prototype.setResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ResourceType type = 3;
 * @return {!proto.sip.resources.ResourceType}
 */
proto.sip.resources.ValidationError.prototype.getType = function() {
  return /** @type {!proto.sip.resources.ResourceType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.sip.resources.ResourceType} value
 * @return {!proto.sip.resources.ValidationError} returns this
 */
proto.sip.resources.ValidationError.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.resources.ValidateDBResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.ValidateDBResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.ValidateDBResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.ValidateDBResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ValidateDBResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    problemsList: jspb.Message.toObjectList(msg.getProblemsList(),
    proto.sip.resources.ValidationError.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.ValidateDBResponse}
 */
proto.sip.resources.ValidateDBResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.ValidateDBResponse;
  return proto.sip.resources.ValidateDBResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.ValidateDBResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.ValidateDBResponse}
 */
proto.sip.resources.ValidateDBResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.resources.ValidationError;
      reader.readMessage(value,proto.sip.resources.ValidationError.deserializeBinaryFromReader);
      msg.addProblems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.ValidateDBResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.ValidateDBResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.ValidateDBResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ValidateDBResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProblemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.resources.ValidationError.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ValidationError problems = 1;
 * @return {!Array<!proto.sip.resources.ValidationError>}
 */
proto.sip.resources.ValidateDBResponse.prototype.getProblemsList = function() {
  return /** @type{!Array<!proto.sip.resources.ValidationError>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.resources.ValidationError, 1));
};


/**
 * @param {!Array<!proto.sip.resources.ValidationError>} value
 * @return {!proto.sip.resources.ValidateDBResponse} returns this
*/
proto.sip.resources.ValidateDBResponse.prototype.setProblemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.resources.ValidationError=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.resources.ValidationError}
 */
proto.sip.resources.ValidateDBResponse.prototype.addProblems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.resources.ValidationError, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.resources.ValidateDBResponse} returns this
 */
proto.sip.resources.ValidateDBResponse.prototype.clearProblemsList = function() {
  return this.setProblemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.resources.Resource.repeatedFields_ = [10,11];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.sip.resources.Resource.oneofGroups_ = [[21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37]];

/**
 * @enum {number}
 */
proto.sip.resources.Resource.DetailsCase = {
  DETAILS_NOT_SET: 0,
  MAIL_GLOBAL: 21,
  MAIL_SERVICE: 22,
  MAIL_DISTRIBUTION_LIST: 23,
  MAIL_SHARED_MAILBOX: 24,
  MAIL_CONTACT: 25,
  ETH_IAM_GROUP: 26,
  MAIL_SYMPA: 27,
  GSUITE_SHARED_DRIVE: 28,
  CONFLUENCE_SPACE: 29,
  GITLAB_GROUP: 30,
  GROUP_TAG: 31,
  KEYCLOAK_CLIENT: 32,
  NAS_STORAGE_FOLDER: 33,
  GOOGLE_CALENDAR: 34,
  OFFICE_KEY: 35,
  KUBERNETES_PERMISSION: 36,
  MAIL_SPECIAL_USER_CONFIGURATION: 37
};

/**
 * @return {proto.sip.resources.Resource.DetailsCase}
 */
proto.sip.resources.Resource.prototype.getDetailsCase = function() {
  return /** @type {proto.sip.resources.Resource.DetailsCase} */(jspb.Message.computeOneofCase(this, proto.sip.resources.Resource.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.Resource.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.Resource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.Resource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.Resource.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerGroup: jspb.Message.getFieldWithDefault(msg, 2, ""),
    resourceType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastUpdateBy: jspb.Message.getFieldWithDefault(msg, 6, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    description: jspb.Message.getFieldWithDefault(msg, 8, ""),
    resourceState: jspb.Message.getFieldWithDefault(msg, 9, 0),
    permissionsList: jspb.Message.toObjectList(msg.getPermissionsList(),
    proto.sip.resources.ResourcePermission.toObject, includeInstance),
    changesList: jspb.Message.toObjectList(msg.getChangesList(),
    proto.sip.resources.ProposedChange.toObject, includeInstance),
    view: jspb.Message.getFieldWithDefault(msg, 12, 0),
    mailGlobal: (f = msg.getMailGlobal()) && proto.sip.resources.MailServiceResource.toObject(includeInstance, f),
    mailService: (f = msg.getMailService()) && proto.sip.resources.MailServiceResource.toObject(includeInstance, f),
    mailDistributionList: (f = msg.getMailDistributionList()) && proto.sip.resources.MailDistributionListResource.toObject(includeInstance, f),
    mailSharedMailbox: (f = msg.getMailSharedMailbox()) && proto.sip.resources.MailSharedMailboxResource.toObject(includeInstance, f),
    mailContact: (f = msg.getMailContact()) && proto.sip.resources.MailContactResource.toObject(includeInstance, f),
    ethIamGroup: (f = msg.getEthIamGroup()) && proto.sip.resources.EthIamGroupResource.toObject(includeInstance, f),
    mailSympa: (f = msg.getMailSympa()) && proto.sip.resources.SympaMailingListResource.toObject(includeInstance, f),
    gsuiteSharedDrive: (f = msg.getGsuiteSharedDrive()) && proto.sip.resources.GSuiteSharedDriveResource.toObject(includeInstance, f),
    confluenceSpace: (f = msg.getConfluenceSpace()) && proto.sip.resources.ConfluenceSpaceResource.toObject(includeInstance, f),
    gitlabGroup: (f = msg.getGitlabGroup()) && proto.sip.resources.GitlabGroupResource.toObject(includeInstance, f),
    groupTag: (f = msg.getGroupTag()) && proto.sip.resources.GroupTagResource.toObject(includeInstance, f),
    keycloakClient: (f = msg.getKeycloakClient()) && proto.sip.resources.KeycloakClientResource.toObject(includeInstance, f),
    nasStorageFolder: (f = msg.getNasStorageFolder()) && proto.sip.resources.NASStorageFolderResource.toObject(includeInstance, f),
    googleCalendar: (f = msg.getGoogleCalendar()) && proto.sip.resources.GoogleCalendarResource.toObject(includeInstance, f),
    officeKey: (f = msg.getOfficeKey()) && proto.sip.resources.OfficeKeyResource.toObject(includeInstance, f),
    kubernetesPermission: (f = msg.getKubernetesPermission()) && proto.sip.resources.KubernetesPermissionResource.toObject(includeInstance, f),
    mailSpecialUserConfiguration: (f = msg.getMailSpecialUserConfiguration()) && proto.sip.resources.MailSpecialUserConfigurationsResource.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.Resource}
 */
proto.sip.resources.Resource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.Resource;
  return proto.sip.resources.Resource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.Resource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.Resource}
 */
proto.sip.resources.Resource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerGroup(value);
      break;
    case 3:
      var value = /** @type {!proto.sip.resources.ResourceType} */ (reader.readEnum());
      msg.setResourceType(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastUpdateBy(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 9:
      var value = /** @type {!proto.sip.resources.ResourceState} */ (reader.readEnum());
      msg.setResourceState(value);
      break;
    case 10:
      var value = new proto.sip.resources.ResourcePermission;
      reader.readMessage(value,proto.sip.resources.ResourcePermission.deserializeBinaryFromReader);
      msg.addPermissions(value);
      break;
    case 11:
      var value = new proto.sip.resources.ProposedChange;
      reader.readMessage(value,proto.sip.resources.ProposedChange.deserializeBinaryFromReader);
      msg.addChanges(value);
      break;
    case 12:
      var value = /** @type {!proto.sip.resources.Resource.View} */ (reader.readEnum());
      msg.setView(value);
      break;
    case 21:
      var value = new proto.sip.resources.MailServiceResource;
      reader.readMessage(value,proto.sip.resources.MailServiceResource.deserializeBinaryFromReader);
      msg.setMailGlobal(value);
      break;
    case 22:
      var value = new proto.sip.resources.MailServiceResource;
      reader.readMessage(value,proto.sip.resources.MailServiceResource.deserializeBinaryFromReader);
      msg.setMailService(value);
      break;
    case 23:
      var value = new proto.sip.resources.MailDistributionListResource;
      reader.readMessage(value,proto.sip.resources.MailDistributionListResource.deserializeBinaryFromReader);
      msg.setMailDistributionList(value);
      break;
    case 24:
      var value = new proto.sip.resources.MailSharedMailboxResource;
      reader.readMessage(value,proto.sip.resources.MailSharedMailboxResource.deserializeBinaryFromReader);
      msg.setMailSharedMailbox(value);
      break;
    case 25:
      var value = new proto.sip.resources.MailContactResource;
      reader.readMessage(value,proto.sip.resources.MailContactResource.deserializeBinaryFromReader);
      msg.setMailContact(value);
      break;
    case 26:
      var value = new proto.sip.resources.EthIamGroupResource;
      reader.readMessage(value,proto.sip.resources.EthIamGroupResource.deserializeBinaryFromReader);
      msg.setEthIamGroup(value);
      break;
    case 27:
      var value = new proto.sip.resources.SympaMailingListResource;
      reader.readMessage(value,proto.sip.resources.SympaMailingListResource.deserializeBinaryFromReader);
      msg.setMailSympa(value);
      break;
    case 28:
      var value = new proto.sip.resources.GSuiteSharedDriveResource;
      reader.readMessage(value,proto.sip.resources.GSuiteSharedDriveResource.deserializeBinaryFromReader);
      msg.setGsuiteSharedDrive(value);
      break;
    case 29:
      var value = new proto.sip.resources.ConfluenceSpaceResource;
      reader.readMessage(value,proto.sip.resources.ConfluenceSpaceResource.deserializeBinaryFromReader);
      msg.setConfluenceSpace(value);
      break;
    case 30:
      var value = new proto.sip.resources.GitlabGroupResource;
      reader.readMessage(value,proto.sip.resources.GitlabGroupResource.deserializeBinaryFromReader);
      msg.setGitlabGroup(value);
      break;
    case 31:
      var value = new proto.sip.resources.GroupTagResource;
      reader.readMessage(value,proto.sip.resources.GroupTagResource.deserializeBinaryFromReader);
      msg.setGroupTag(value);
      break;
    case 32:
      var value = new proto.sip.resources.KeycloakClientResource;
      reader.readMessage(value,proto.sip.resources.KeycloakClientResource.deserializeBinaryFromReader);
      msg.setKeycloakClient(value);
      break;
    case 33:
      var value = new proto.sip.resources.NASStorageFolderResource;
      reader.readMessage(value,proto.sip.resources.NASStorageFolderResource.deserializeBinaryFromReader);
      msg.setNasStorageFolder(value);
      break;
    case 34:
      var value = new proto.sip.resources.GoogleCalendarResource;
      reader.readMessage(value,proto.sip.resources.GoogleCalendarResource.deserializeBinaryFromReader);
      msg.setGoogleCalendar(value);
      break;
    case 35:
      var value = new proto.sip.resources.OfficeKeyResource;
      reader.readMessage(value,proto.sip.resources.OfficeKeyResource.deserializeBinaryFromReader);
      msg.setOfficeKey(value);
      break;
    case 36:
      var value = new proto.sip.resources.KubernetesPermissionResource;
      reader.readMessage(value,proto.sip.resources.KubernetesPermissionResource.deserializeBinaryFromReader);
      msg.setKubernetesPermission(value);
      break;
    case 37:
      var value = new proto.sip.resources.MailSpecialUserConfigurationsResource;
      reader.readMessage(value,proto.sip.resources.MailSpecialUserConfigurationsResource.deserializeBinaryFromReader);
      msg.setMailSpecialUserConfiguration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.Resource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.Resource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.Resource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.Resource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerGroup();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResourceType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastUpdateBy();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getResourceState();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.sip.resources.ResourcePermission.serializeBinaryToWriter
    );
  }
  f = message.getChangesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.sip.resources.ProposedChange.serializeBinaryToWriter
    );
  }
  f = message.getView();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getMailGlobal();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.sip.resources.MailServiceResource.serializeBinaryToWriter
    );
  }
  f = message.getMailService();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.sip.resources.MailServiceResource.serializeBinaryToWriter
    );
  }
  f = message.getMailDistributionList();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.sip.resources.MailDistributionListResource.serializeBinaryToWriter
    );
  }
  f = message.getMailSharedMailbox();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.sip.resources.MailSharedMailboxResource.serializeBinaryToWriter
    );
  }
  f = message.getMailContact();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.sip.resources.MailContactResource.serializeBinaryToWriter
    );
  }
  f = message.getEthIamGroup();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.sip.resources.EthIamGroupResource.serializeBinaryToWriter
    );
  }
  f = message.getMailSympa();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.sip.resources.SympaMailingListResource.serializeBinaryToWriter
    );
  }
  f = message.getGsuiteSharedDrive();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.sip.resources.GSuiteSharedDriveResource.serializeBinaryToWriter
    );
  }
  f = message.getConfluenceSpace();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.sip.resources.ConfluenceSpaceResource.serializeBinaryToWriter
    );
  }
  f = message.getGitlabGroup();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.sip.resources.GitlabGroupResource.serializeBinaryToWriter
    );
  }
  f = message.getGroupTag();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.sip.resources.GroupTagResource.serializeBinaryToWriter
    );
  }
  f = message.getKeycloakClient();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto.sip.resources.KeycloakClientResource.serializeBinaryToWriter
    );
  }
  f = message.getNasStorageFolder();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.sip.resources.NASStorageFolderResource.serializeBinaryToWriter
    );
  }
  f = message.getGoogleCalendar();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      proto.sip.resources.GoogleCalendarResource.serializeBinaryToWriter
    );
  }
  f = message.getOfficeKey();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      proto.sip.resources.OfficeKeyResource.serializeBinaryToWriter
    );
  }
  f = message.getKubernetesPermission();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.sip.resources.KubernetesPermissionResource.serializeBinaryToWriter
    );
  }
  f = message.getMailSpecialUserConfiguration();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      proto.sip.resources.MailSpecialUserConfigurationsResource.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.sip.resources.Resource.View = {
  VIEW_UNSPECIFIED: 0,
  BASIC: 1,
  INCLUDE_PERMISSIONS: 2,
  INCLUDE_TARGET_SYSTEM_REPRESENTATION: 3
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.resources.Resource.Filter.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.Resource.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.Resource.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.Resource.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.Resource.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerGroup: jspb.Message.getFieldWithDefault(msg, 2, ""),
    resourceType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lastUpdatedBy: jspb.Message.getFieldWithDefault(msg, 6, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    description: jspb.Message.getFieldWithDefault(msg, 8, ""),
    resourceState: jspb.Message.getFieldWithDefault(msg, 9, 0),
    permissionsList: jspb.Message.toObjectList(msg.getPermissionsList(),
    proto.sip.resources.ResourcePermission.Filter.toObject, includeInstance),
    sortBy: jspb.Message.getFieldWithDefault(msg, 20, 0),
    descending: jspb.Message.getBooleanFieldWithDefault(msg, 21, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.Resource.Filter}
 */
proto.sip.resources.Resource.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.Resource.Filter;
  return proto.sip.resources.Resource.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.Resource.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.Resource.Filter}
 */
proto.sip.resources.Resource.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerGroup(value);
      break;
    case 3:
      var value = /** @type {!proto.sip.resources.ResourceType} */ (reader.readEnum());
      msg.setResourceType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastUpdatedBy(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 9:
      var value = /** @type {!proto.sip.resources.ResourceState} */ (reader.readEnum());
      msg.setResourceState(value);
      break;
    case 10:
      var value = new proto.sip.resources.ResourcePermission.Filter;
      reader.readMessage(value,proto.sip.resources.ResourcePermission.Filter.deserializeBinaryFromReader);
      msg.addPermissions(value);
      break;
    case 20:
      var value = /** @type {!proto.sip.resources.Resource.Filter.SortBy} */ (reader.readEnum());
      msg.setSortBy(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDescending(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.Resource.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.Resource.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.Resource.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.Resource.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerGroup();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResourceType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLastUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getResourceState();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.sip.resources.ResourcePermission.Filter.serializeBinaryToWriter
    );
  }
  f = message.getSortBy();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getDescending();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sip.resources.Resource.Filter.SortBy = {
  NAME: 0,
  OWNER_GROUP: 1,
  RESOURCE_TYPE: 2,
  CREATE_TIME: 3,
  UPDATE_TIME: 4,
  LAST_UPDATED_BY: 5,
  DISPLAY_NAME: 6,
  DESCRIPTION: 7,
  RESOURCE_STATE: 8
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.resources.Resource.Filter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.Resource.Filter} returns this
 */
proto.sip.resources.Resource.Filter.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string owner_group = 2;
 * @return {string}
 */
proto.sip.resources.Resource.Filter.prototype.getOwnerGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.Resource.Filter} returns this
 */
proto.sip.resources.Resource.Filter.prototype.setOwnerGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ResourceType resource_type = 3;
 * @return {!proto.sip.resources.ResourceType}
 */
proto.sip.resources.Resource.Filter.prototype.getResourceType = function() {
  return /** @type {!proto.sip.resources.ResourceType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.sip.resources.ResourceType} value
 * @return {!proto.sip.resources.Resource.Filter} returns this
 */
proto.sip.resources.Resource.Filter.prototype.setResourceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string last_updated_by = 6;
 * @return {string}
 */
proto.sip.resources.Resource.Filter.prototype.getLastUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.Resource.Filter} returns this
 */
proto.sip.resources.Resource.Filter.prototype.setLastUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string display_name = 7;
 * @return {string}
 */
proto.sip.resources.Resource.Filter.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.Resource.Filter} returns this
 */
proto.sip.resources.Resource.Filter.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.sip.resources.Resource.Filter.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.Resource.Filter} returns this
 */
proto.sip.resources.Resource.Filter.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional ResourceState resource_state = 9;
 * @return {!proto.sip.resources.ResourceState}
 */
proto.sip.resources.Resource.Filter.prototype.getResourceState = function() {
  return /** @type {!proto.sip.resources.ResourceState} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.sip.resources.ResourceState} value
 * @return {!proto.sip.resources.Resource.Filter} returns this
 */
proto.sip.resources.Resource.Filter.prototype.setResourceState = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * repeated ResourcePermission.Filter permissions = 10;
 * @return {!Array<!proto.sip.resources.ResourcePermission.Filter>}
 */
proto.sip.resources.Resource.Filter.prototype.getPermissionsList = function() {
  return /** @type{!Array<!proto.sip.resources.ResourcePermission.Filter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.resources.ResourcePermission.Filter, 10));
};


/**
 * @param {!Array<!proto.sip.resources.ResourcePermission.Filter>} value
 * @return {!proto.sip.resources.Resource.Filter} returns this
*/
proto.sip.resources.Resource.Filter.prototype.setPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.sip.resources.ResourcePermission.Filter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.resources.ResourcePermission.Filter}
 */
proto.sip.resources.Resource.Filter.prototype.addPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.sip.resources.ResourcePermission.Filter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.resources.Resource.Filter} returns this
 */
proto.sip.resources.Resource.Filter.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};


/**
 * optional SortBy sort_by = 20;
 * @return {!proto.sip.resources.Resource.Filter.SortBy}
 */
proto.sip.resources.Resource.Filter.prototype.getSortBy = function() {
  return /** @type {!proto.sip.resources.Resource.Filter.SortBy} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.sip.resources.Resource.Filter.SortBy} value
 * @return {!proto.sip.resources.Resource.Filter} returns this
 */
proto.sip.resources.Resource.Filter.prototype.setSortBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional bool descending = 21;
 * @return {boolean}
 */
proto.sip.resources.Resource.Filter.prototype.getDescending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.resources.Resource.Filter} returns this
 */
proto.sip.resources.Resource.Filter.prototype.setDescending = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.resources.Resource.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string owner_group = 2;
 * @return {string}
 */
proto.sip.resources.Resource.prototype.getOwnerGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.setOwnerGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ResourceType resource_type = 3;
 * @return {!proto.sip.resources.ResourceType}
 */
proto.sip.resources.Resource.prototype.getResourceType = function() {
  return /** @type {!proto.sip.resources.ResourceType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.sip.resources.ResourceType} value
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.setResourceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp create_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.resources.Resource.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.resources.Resource} returns this
*/
proto.sip.resources.Resource.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.Resource.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp update_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.resources.Resource.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.resources.Resource} returns this
*/
proto.sip.resources.Resource.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.Resource.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string last_update_by = 6;
 * @return {string}
 */
proto.sip.resources.Resource.prototype.getLastUpdateBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.setLastUpdateBy = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string display_name = 7;
 * @return {string}
 */
proto.sip.resources.Resource.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.sip.resources.Resource.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional ResourceState resource_state = 9;
 * @return {!proto.sip.resources.ResourceState}
 */
proto.sip.resources.Resource.prototype.getResourceState = function() {
  return /** @type {!proto.sip.resources.ResourceState} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.sip.resources.ResourceState} value
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.setResourceState = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * repeated ResourcePermission permissions = 10;
 * @return {!Array<!proto.sip.resources.ResourcePermission>}
 */
proto.sip.resources.Resource.prototype.getPermissionsList = function() {
  return /** @type{!Array<!proto.sip.resources.ResourcePermission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.resources.ResourcePermission, 10));
};


/**
 * @param {!Array<!proto.sip.resources.ResourcePermission>} value
 * @return {!proto.sip.resources.Resource} returns this
*/
proto.sip.resources.Resource.prototype.setPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.sip.resources.ResourcePermission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.resources.ResourcePermission}
 */
proto.sip.resources.Resource.prototype.addPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.sip.resources.ResourcePermission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};


/**
 * repeated ProposedChange changes = 11;
 * @return {!Array<!proto.sip.resources.ProposedChange>}
 */
proto.sip.resources.Resource.prototype.getChangesList = function() {
  return /** @type{!Array<!proto.sip.resources.ProposedChange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.resources.ProposedChange, 11));
};


/**
 * @param {!Array<!proto.sip.resources.ProposedChange>} value
 * @return {!proto.sip.resources.Resource} returns this
*/
proto.sip.resources.Resource.prototype.setChangesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.sip.resources.ProposedChange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.resources.ProposedChange}
 */
proto.sip.resources.Resource.prototype.addChanges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.sip.resources.ProposedChange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.clearChangesList = function() {
  return this.setChangesList([]);
};


/**
 * optional View view = 12;
 * @return {!proto.sip.resources.Resource.View}
 */
proto.sip.resources.Resource.prototype.getView = function() {
  return /** @type {!proto.sip.resources.Resource.View} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.sip.resources.Resource.View} value
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.setView = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional MailServiceResource mail_global = 21;
 * @return {?proto.sip.resources.MailServiceResource}
 */
proto.sip.resources.Resource.prototype.getMailGlobal = function() {
  return /** @type{?proto.sip.resources.MailServiceResource} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.MailServiceResource, 21));
};


/**
 * @param {?proto.sip.resources.MailServiceResource|undefined} value
 * @return {!proto.sip.resources.Resource} returns this
*/
proto.sip.resources.Resource.prototype.setMailGlobal = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.sip.resources.Resource.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.clearMailGlobal = function() {
  return this.setMailGlobal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.Resource.prototype.hasMailGlobal = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional MailServiceResource mail_service = 22;
 * @return {?proto.sip.resources.MailServiceResource}
 */
proto.sip.resources.Resource.prototype.getMailService = function() {
  return /** @type{?proto.sip.resources.MailServiceResource} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.MailServiceResource, 22));
};


/**
 * @param {?proto.sip.resources.MailServiceResource|undefined} value
 * @return {!proto.sip.resources.Resource} returns this
*/
proto.sip.resources.Resource.prototype.setMailService = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.sip.resources.Resource.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.clearMailService = function() {
  return this.setMailService(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.Resource.prototype.hasMailService = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional MailDistributionListResource mail_distribution_list = 23;
 * @return {?proto.sip.resources.MailDistributionListResource}
 */
proto.sip.resources.Resource.prototype.getMailDistributionList = function() {
  return /** @type{?proto.sip.resources.MailDistributionListResource} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.MailDistributionListResource, 23));
};


/**
 * @param {?proto.sip.resources.MailDistributionListResource|undefined} value
 * @return {!proto.sip.resources.Resource} returns this
*/
proto.sip.resources.Resource.prototype.setMailDistributionList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.sip.resources.Resource.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.clearMailDistributionList = function() {
  return this.setMailDistributionList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.Resource.prototype.hasMailDistributionList = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional MailSharedMailboxResource mail_shared_mailbox = 24;
 * @return {?proto.sip.resources.MailSharedMailboxResource}
 */
proto.sip.resources.Resource.prototype.getMailSharedMailbox = function() {
  return /** @type{?proto.sip.resources.MailSharedMailboxResource} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.MailSharedMailboxResource, 24));
};


/**
 * @param {?proto.sip.resources.MailSharedMailboxResource|undefined} value
 * @return {!proto.sip.resources.Resource} returns this
*/
proto.sip.resources.Resource.prototype.setMailSharedMailbox = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.sip.resources.Resource.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.clearMailSharedMailbox = function() {
  return this.setMailSharedMailbox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.Resource.prototype.hasMailSharedMailbox = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional MailContactResource mail_contact = 25;
 * @return {?proto.sip.resources.MailContactResource}
 */
proto.sip.resources.Resource.prototype.getMailContact = function() {
  return /** @type{?proto.sip.resources.MailContactResource} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.MailContactResource, 25));
};


/**
 * @param {?proto.sip.resources.MailContactResource|undefined} value
 * @return {!proto.sip.resources.Resource} returns this
*/
proto.sip.resources.Resource.prototype.setMailContact = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.sip.resources.Resource.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.clearMailContact = function() {
  return this.setMailContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.Resource.prototype.hasMailContact = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional EthIamGroupResource eth_iam_group = 26;
 * @return {?proto.sip.resources.EthIamGroupResource}
 */
proto.sip.resources.Resource.prototype.getEthIamGroup = function() {
  return /** @type{?proto.sip.resources.EthIamGroupResource} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.EthIamGroupResource, 26));
};


/**
 * @param {?proto.sip.resources.EthIamGroupResource|undefined} value
 * @return {!proto.sip.resources.Resource} returns this
*/
proto.sip.resources.Resource.prototype.setEthIamGroup = function(value) {
  return jspb.Message.setOneofWrapperField(this, 26, proto.sip.resources.Resource.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.clearEthIamGroup = function() {
  return this.setEthIamGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.Resource.prototype.hasEthIamGroup = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional SympaMailingListResource mail_sympa = 27;
 * @return {?proto.sip.resources.SympaMailingListResource}
 */
proto.sip.resources.Resource.prototype.getMailSympa = function() {
  return /** @type{?proto.sip.resources.SympaMailingListResource} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.SympaMailingListResource, 27));
};


/**
 * @param {?proto.sip.resources.SympaMailingListResource|undefined} value
 * @return {!proto.sip.resources.Resource} returns this
*/
proto.sip.resources.Resource.prototype.setMailSympa = function(value) {
  return jspb.Message.setOneofWrapperField(this, 27, proto.sip.resources.Resource.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.clearMailSympa = function() {
  return this.setMailSympa(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.Resource.prototype.hasMailSympa = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional GSuiteSharedDriveResource gsuite_shared_drive = 28;
 * @return {?proto.sip.resources.GSuiteSharedDriveResource}
 */
proto.sip.resources.Resource.prototype.getGsuiteSharedDrive = function() {
  return /** @type{?proto.sip.resources.GSuiteSharedDriveResource} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.GSuiteSharedDriveResource, 28));
};


/**
 * @param {?proto.sip.resources.GSuiteSharedDriveResource|undefined} value
 * @return {!proto.sip.resources.Resource} returns this
*/
proto.sip.resources.Resource.prototype.setGsuiteSharedDrive = function(value) {
  return jspb.Message.setOneofWrapperField(this, 28, proto.sip.resources.Resource.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.clearGsuiteSharedDrive = function() {
  return this.setGsuiteSharedDrive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.Resource.prototype.hasGsuiteSharedDrive = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional ConfluenceSpaceResource confluence_space = 29;
 * @return {?proto.sip.resources.ConfluenceSpaceResource}
 */
proto.sip.resources.Resource.prototype.getConfluenceSpace = function() {
  return /** @type{?proto.sip.resources.ConfluenceSpaceResource} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.ConfluenceSpaceResource, 29));
};


/**
 * @param {?proto.sip.resources.ConfluenceSpaceResource|undefined} value
 * @return {!proto.sip.resources.Resource} returns this
*/
proto.sip.resources.Resource.prototype.setConfluenceSpace = function(value) {
  return jspb.Message.setOneofWrapperField(this, 29, proto.sip.resources.Resource.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.clearConfluenceSpace = function() {
  return this.setConfluenceSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.Resource.prototype.hasConfluenceSpace = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional GitlabGroupResource gitlab_group = 30;
 * @return {?proto.sip.resources.GitlabGroupResource}
 */
proto.sip.resources.Resource.prototype.getGitlabGroup = function() {
  return /** @type{?proto.sip.resources.GitlabGroupResource} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.GitlabGroupResource, 30));
};


/**
 * @param {?proto.sip.resources.GitlabGroupResource|undefined} value
 * @return {!proto.sip.resources.Resource} returns this
*/
proto.sip.resources.Resource.prototype.setGitlabGroup = function(value) {
  return jspb.Message.setOneofWrapperField(this, 30, proto.sip.resources.Resource.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.clearGitlabGroup = function() {
  return this.setGitlabGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.Resource.prototype.hasGitlabGroup = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional GroupTagResource group_tag = 31;
 * @return {?proto.sip.resources.GroupTagResource}
 */
proto.sip.resources.Resource.prototype.getGroupTag = function() {
  return /** @type{?proto.sip.resources.GroupTagResource} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.GroupTagResource, 31));
};


/**
 * @param {?proto.sip.resources.GroupTagResource|undefined} value
 * @return {!proto.sip.resources.Resource} returns this
*/
proto.sip.resources.Resource.prototype.setGroupTag = function(value) {
  return jspb.Message.setOneofWrapperField(this, 31, proto.sip.resources.Resource.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.clearGroupTag = function() {
  return this.setGroupTag(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.Resource.prototype.hasGroupTag = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional KeycloakClientResource keycloak_client = 32;
 * @return {?proto.sip.resources.KeycloakClientResource}
 */
proto.sip.resources.Resource.prototype.getKeycloakClient = function() {
  return /** @type{?proto.sip.resources.KeycloakClientResource} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.KeycloakClientResource, 32));
};


/**
 * @param {?proto.sip.resources.KeycloakClientResource|undefined} value
 * @return {!proto.sip.resources.Resource} returns this
*/
proto.sip.resources.Resource.prototype.setKeycloakClient = function(value) {
  return jspb.Message.setOneofWrapperField(this, 32, proto.sip.resources.Resource.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.clearKeycloakClient = function() {
  return this.setKeycloakClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.Resource.prototype.hasKeycloakClient = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional NASStorageFolderResource nas_storage_folder = 33;
 * @return {?proto.sip.resources.NASStorageFolderResource}
 */
proto.sip.resources.Resource.prototype.getNasStorageFolder = function() {
  return /** @type{?proto.sip.resources.NASStorageFolderResource} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.NASStorageFolderResource, 33));
};


/**
 * @param {?proto.sip.resources.NASStorageFolderResource|undefined} value
 * @return {!proto.sip.resources.Resource} returns this
*/
proto.sip.resources.Resource.prototype.setNasStorageFolder = function(value) {
  return jspb.Message.setOneofWrapperField(this, 33, proto.sip.resources.Resource.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.clearNasStorageFolder = function() {
  return this.setNasStorageFolder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.Resource.prototype.hasNasStorageFolder = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional GoogleCalendarResource google_calendar = 34;
 * @return {?proto.sip.resources.GoogleCalendarResource}
 */
proto.sip.resources.Resource.prototype.getGoogleCalendar = function() {
  return /** @type{?proto.sip.resources.GoogleCalendarResource} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.GoogleCalendarResource, 34));
};


/**
 * @param {?proto.sip.resources.GoogleCalendarResource|undefined} value
 * @return {!proto.sip.resources.Resource} returns this
*/
proto.sip.resources.Resource.prototype.setGoogleCalendar = function(value) {
  return jspb.Message.setOneofWrapperField(this, 34, proto.sip.resources.Resource.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.clearGoogleCalendar = function() {
  return this.setGoogleCalendar(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.Resource.prototype.hasGoogleCalendar = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional OfficeKeyResource office_key = 35;
 * @return {?proto.sip.resources.OfficeKeyResource}
 */
proto.sip.resources.Resource.prototype.getOfficeKey = function() {
  return /** @type{?proto.sip.resources.OfficeKeyResource} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.OfficeKeyResource, 35));
};


/**
 * @param {?proto.sip.resources.OfficeKeyResource|undefined} value
 * @return {!proto.sip.resources.Resource} returns this
*/
proto.sip.resources.Resource.prototype.setOfficeKey = function(value) {
  return jspb.Message.setOneofWrapperField(this, 35, proto.sip.resources.Resource.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.clearOfficeKey = function() {
  return this.setOfficeKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.Resource.prototype.hasOfficeKey = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional KubernetesPermissionResource kubernetes_permission = 36;
 * @return {?proto.sip.resources.KubernetesPermissionResource}
 */
proto.sip.resources.Resource.prototype.getKubernetesPermission = function() {
  return /** @type{?proto.sip.resources.KubernetesPermissionResource} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.KubernetesPermissionResource, 36));
};


/**
 * @param {?proto.sip.resources.KubernetesPermissionResource|undefined} value
 * @return {!proto.sip.resources.Resource} returns this
*/
proto.sip.resources.Resource.prototype.setKubernetesPermission = function(value) {
  return jspb.Message.setOneofWrapperField(this, 36, proto.sip.resources.Resource.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.clearKubernetesPermission = function() {
  return this.setKubernetesPermission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.Resource.prototype.hasKubernetesPermission = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional MailSpecialUserConfigurationsResource mail_special_user_configuration = 37;
 * @return {?proto.sip.resources.MailSpecialUserConfigurationsResource}
 */
proto.sip.resources.Resource.prototype.getMailSpecialUserConfiguration = function() {
  return /** @type{?proto.sip.resources.MailSpecialUserConfigurationsResource} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.MailSpecialUserConfigurationsResource, 37));
};


/**
 * @param {?proto.sip.resources.MailSpecialUserConfigurationsResource|undefined} value
 * @return {!proto.sip.resources.Resource} returns this
*/
proto.sip.resources.Resource.prototype.setMailSpecialUserConfiguration = function(value) {
  return jspb.Message.setOneofWrapperField(this, 37, proto.sip.resources.Resource.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.Resource} returns this
 */
proto.sip.resources.Resource.prototype.clearMailSpecialUserConfiguration = function() {
  return this.setMailSpecialUserConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.Resource.prototype.hasMailSpecialUserConfiguration = function() {
  return jspb.Message.getField(this, 37) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.ListResourcesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.ListResourcesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.ListResourcesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ListResourcesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.sip.resources.Resource.Filter.toObject(includeInstance, f),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    view: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.ListResourcesRequest}
 */
proto.sip.resources.ListResourcesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.ListResourcesRequest;
  return proto.sip.resources.ListResourcesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.ListResourcesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.ListResourcesRequest}
 */
proto.sip.resources.ListResourcesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.resources.Resource.Filter;
      reader.readMessage(value,proto.sip.resources.Resource.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 4:
      var value = /** @type {!proto.sip.resources.Resource.View} */ (reader.readEnum());
      msg.setView(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.ListResourcesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.ListResourcesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.ListResourcesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ListResourcesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.resources.Resource.Filter.serializeBinaryToWriter
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getView();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional Resource.Filter filter = 1;
 * @return {?proto.sip.resources.Resource.Filter}
 */
proto.sip.resources.ListResourcesRequest.prototype.getFilter = function() {
  return /** @type{?proto.sip.resources.Resource.Filter} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.Resource.Filter, 1));
};


/**
 * @param {?proto.sip.resources.Resource.Filter|undefined} value
 * @return {!proto.sip.resources.ListResourcesRequest} returns this
*/
proto.sip.resources.ListResourcesRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.ListResourcesRequest} returns this
 */
proto.sip.resources.ListResourcesRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.ListResourcesRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.sip.resources.ListResourcesRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.resources.ListResourcesRequest} returns this
 */
proto.sip.resources.ListResourcesRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.sip.resources.ListResourcesRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ListResourcesRequest} returns this
 */
proto.sip.resources.ListResourcesRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Resource.View view = 4;
 * @return {!proto.sip.resources.Resource.View}
 */
proto.sip.resources.ListResourcesRequest.prototype.getView = function() {
  return /** @type {!proto.sip.resources.Resource.View} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.sip.resources.Resource.View} value
 * @return {!proto.sip.resources.ListResourcesRequest} returns this
 */
proto.sip.resources.ListResourcesRequest.prototype.setView = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.resources.ListResourcesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.ListResourcesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.ListResourcesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.ListResourcesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ListResourcesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourcesList: jspb.Message.toObjectList(msg.getResourcesList(),
    proto.sip.resources.Resource.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.ListResourcesResponse}
 */
proto.sip.resources.ListResourcesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.ListResourcesResponse;
  return proto.sip.resources.ListResourcesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.ListResourcesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.ListResourcesResponse}
 */
proto.sip.resources.ListResourcesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.resources.Resource;
      reader.readMessage(value,proto.sip.resources.Resource.deserializeBinaryFromReader);
      msg.addResources(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.ListResourcesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.ListResourcesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.ListResourcesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ListResourcesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.resources.Resource.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated Resource resources = 1;
 * @return {!Array<!proto.sip.resources.Resource>}
 */
proto.sip.resources.ListResourcesResponse.prototype.getResourcesList = function() {
  return /** @type{!Array<!proto.sip.resources.Resource>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.resources.Resource, 1));
};


/**
 * @param {!Array<!proto.sip.resources.Resource>} value
 * @return {!proto.sip.resources.ListResourcesResponse} returns this
*/
proto.sip.resources.ListResourcesResponse.prototype.setResourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.resources.Resource=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.resources.Resource}
 */
proto.sip.resources.ListResourcesResponse.prototype.addResources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.resources.Resource, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.resources.ListResourcesResponse} returns this
 */
proto.sip.resources.ListResourcesResponse.prototype.clearResourcesList = function() {
  return this.setResourcesList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.resources.ListResourcesResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ListResourcesResponse} returns this
 */
proto.sip.resources.ListResourcesResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 total_size = 3;
 * @return {number}
 */
proto.sip.resources.ListResourcesResponse.prototype.getTotalSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.resources.ListResourcesResponse} returns this
 */
proto.sip.resources.ListResourcesResponse.prototype.setTotalSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.GetResourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.GetResourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.GetResourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.GetResourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    view: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.GetResourceRequest}
 */
proto.sip.resources.GetResourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.GetResourceRequest;
  return proto.sip.resources.GetResourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.GetResourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.GetResourceRequest}
 */
proto.sip.resources.GetResourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.sip.resources.Resource.View} */ (reader.readEnum());
      msg.setView(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.GetResourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.GetResourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.GetResourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.GetResourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getView();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.resources.GetResourceRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.GetResourceRequest} returns this
 */
proto.sip.resources.GetResourceRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Resource.View view = 2;
 * @return {!proto.sip.resources.Resource.View}
 */
proto.sip.resources.GetResourceRequest.prototype.getView = function() {
  return /** @type {!proto.sip.resources.Resource.View} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sip.resources.Resource.View} value
 * @return {!proto.sip.resources.GetResourceRequest} returns this
 */
proto.sip.resources.GetResourceRequest.prototype.setView = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.CreateResourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.CreateResourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.CreateResourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.CreateResourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resource: (f = msg.getResource()) && proto.sip.resources.Resource.toObject(includeInstance, f),
    auditMessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.CreateResourceRequest}
 */
proto.sip.resources.CreateResourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.CreateResourceRequest;
  return proto.sip.resources.CreateResourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.CreateResourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.CreateResourceRequest}
 */
proto.sip.resources.CreateResourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.resources.Resource;
      reader.readMessage(value,proto.sip.resources.Resource.deserializeBinaryFromReader);
      msg.setResource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.CreateResourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.CreateResourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.CreateResourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.CreateResourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.resources.Resource.serializeBinaryToWriter
    );
  }
  f = message.getAuditMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Resource resource = 1;
 * @return {?proto.sip.resources.Resource}
 */
proto.sip.resources.CreateResourceRequest.prototype.getResource = function() {
  return /** @type{?proto.sip.resources.Resource} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.Resource, 1));
};


/**
 * @param {?proto.sip.resources.Resource|undefined} value
 * @return {!proto.sip.resources.CreateResourceRequest} returns this
*/
proto.sip.resources.CreateResourceRequest.prototype.setResource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.CreateResourceRequest} returns this
 */
proto.sip.resources.CreateResourceRequest.prototype.clearResource = function() {
  return this.setResource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.CreateResourceRequest.prototype.hasResource = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string audit_message = 2;
 * @return {string}
 */
proto.sip.resources.CreateResourceRequest.prototype.getAuditMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.CreateResourceRequest} returns this
 */
proto.sip.resources.CreateResourceRequest.prototype.setAuditMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.UpdateResourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.UpdateResourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.UpdateResourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.UpdateResourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resource: (f = msg.getResource()) && proto.sip.resources.Resource.toObject(includeInstance, f),
    auditMessage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.UpdateResourceRequest}
 */
proto.sip.resources.UpdateResourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.UpdateResourceRequest;
  return proto.sip.resources.UpdateResourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.UpdateResourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.UpdateResourceRequest}
 */
proto.sip.resources.UpdateResourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.resources.Resource;
      reader.readMessage(value,proto.sip.resources.Resource.deserializeBinaryFromReader);
      msg.setResource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditMessage(value);
      break;
    case 3:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.UpdateResourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.UpdateResourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.UpdateResourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.UpdateResourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.resources.Resource.serializeBinaryToWriter
    );
  }
  f = message.getAuditMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional Resource resource = 1;
 * @return {?proto.sip.resources.Resource}
 */
proto.sip.resources.UpdateResourceRequest.prototype.getResource = function() {
  return /** @type{?proto.sip.resources.Resource} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.Resource, 1));
};


/**
 * @param {?proto.sip.resources.Resource|undefined} value
 * @return {!proto.sip.resources.UpdateResourceRequest} returns this
*/
proto.sip.resources.UpdateResourceRequest.prototype.setResource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.UpdateResourceRequest} returns this
 */
proto.sip.resources.UpdateResourceRequest.prototype.clearResource = function() {
  return this.setResource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.UpdateResourceRequest.prototype.hasResource = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string audit_message = 2;
 * @return {string}
 */
proto.sip.resources.UpdateResourceRequest.prototype.getAuditMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.UpdateResourceRequest} returns this
 */
proto.sip.resources.UpdateResourceRequest.prototype.setAuditMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.FieldMask update_mask = 3;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.sip.resources.UpdateResourceRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 3));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.sip.resources.UpdateResourceRequest} returns this
*/
proto.sip.resources.UpdateResourceRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.UpdateResourceRequest} returns this
 */
proto.sip.resources.UpdateResourceRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.UpdateResourceRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.DeleteResourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.DeleteResourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.DeleteResourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.DeleteResourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditMessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.DeleteResourceRequest}
 */
proto.sip.resources.DeleteResourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.DeleteResourceRequest;
  return proto.sip.resources.DeleteResourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.DeleteResourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.DeleteResourceRequest}
 */
proto.sip.resources.DeleteResourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.DeleteResourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.DeleteResourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.DeleteResourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.DeleteResourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.resources.DeleteResourceRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.DeleteResourceRequest} returns this
 */
proto.sip.resources.DeleteResourceRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string audit_message = 2;
 * @return {string}
 */
proto.sip.resources.DeleteResourceRequest.prototype.getAuditMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.DeleteResourceRequest} returns this
 */
proto.sip.resources.DeleteResourceRequest.prototype.setAuditMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.resources.ProposedChange.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.ProposedChange.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.ProposedChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.ProposedChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ProposedChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resourceName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.sip.resources.Resource.toObject(includeInstance, f),
    pathList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    lastUpdatedBy: jspb.Message.getFieldWithDefault(msg, 5, ""),
    state: jspb.Message.getFieldWithDefault(msg, 6, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 7, ""),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    finishTime: (f = msg.getFinishTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.ProposedChange}
 */
proto.sip.resources.ProposedChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.ProposedChange;
  return proto.sip.resources.ProposedChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.ProposedChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.ProposedChange}
 */
proto.sip.resources.ProposedChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceName(value);
      break;
    case 3:
      var value = new proto.sip.resources.Resource;
      reader.readMessage(value,proto.sip.resources.Resource.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addPath(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastUpdatedBy(value);
      break;
    case 6:
      var value = /** @type {!proto.sip.resources.ProposedChangeState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFinishTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.ProposedChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.ProposedChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.ProposedChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ProposedChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResourceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sip.resources.Resource.serializeBinaryToWriter
    );
  }
  f = message.getPathList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getLastUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFinishTime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.ProposedChange.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.ProposedChange.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.ProposedChange.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ProposedChange.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resourceName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sortBy: jspb.Message.getFieldWithDefault(msg, 10, 0),
    sortDescending: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.ProposedChange.Filter}
 */
proto.sip.resources.ProposedChange.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.ProposedChange.Filter;
  return proto.sip.resources.ProposedChange.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.ProposedChange.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.ProposedChange.Filter}
 */
proto.sip.resources.ProposedChange.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceName(value);
      break;
    case 3:
      var value = /** @type {!proto.sip.resources.ProposedChangeState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 10:
      var value = /** @type {!proto.sip.resources.ProposedChange.Filter.SortBy} */ (reader.readEnum());
      msg.setSortBy(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSortDescending(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.ProposedChange.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.ProposedChange.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.ProposedChange.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ProposedChange.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResourceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSortBy();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getSortDescending();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sip.resources.ProposedChange.Filter.SortBy = {
  NAME: 0,
  RESOURCE_NAME: 1,
  DATA: 2,
  PATH: 3,
  LAST_UPDATED_BY: 4,
  STATE: 5,
  COMMENT: 6,
  CREATE_TIME: 7,
  UPDATE_TIME: 8,
  FINISH_TIME: 9
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.resources.ProposedChange.Filter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ProposedChange.Filter} returns this
 */
proto.sip.resources.ProposedChange.Filter.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string resource_name = 2;
 * @return {string}
 */
proto.sip.resources.ProposedChange.Filter.prototype.getResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ProposedChange.Filter} returns this
 */
proto.sip.resources.ProposedChange.Filter.prototype.setResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ProposedChangeState state = 3;
 * @return {!proto.sip.resources.ProposedChangeState}
 */
proto.sip.resources.ProposedChange.Filter.prototype.getState = function() {
  return /** @type {!proto.sip.resources.ProposedChangeState} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.sip.resources.ProposedChangeState} value
 * @return {!proto.sip.resources.ProposedChange.Filter} returns this
 */
proto.sip.resources.ProposedChange.Filter.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional SortBy sort_by = 10;
 * @return {!proto.sip.resources.ProposedChange.Filter.SortBy}
 */
proto.sip.resources.ProposedChange.Filter.prototype.getSortBy = function() {
  return /** @type {!proto.sip.resources.ProposedChange.Filter.SortBy} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.sip.resources.ProposedChange.Filter.SortBy} value
 * @return {!proto.sip.resources.ProposedChange.Filter} returns this
 */
proto.sip.resources.ProposedChange.Filter.prototype.setSortBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional bool sort_descending = 11;
 * @return {boolean}
 */
proto.sip.resources.ProposedChange.Filter.prototype.getSortDescending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.resources.ProposedChange.Filter} returns this
 */
proto.sip.resources.ProposedChange.Filter.prototype.setSortDescending = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.resources.ProposedChange.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ProposedChange} returns this
 */
proto.sip.resources.ProposedChange.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string resource_name = 2;
 * @return {string}
 */
proto.sip.resources.ProposedChange.prototype.getResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ProposedChange} returns this
 */
proto.sip.resources.ProposedChange.prototype.setResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Resource data = 3;
 * @return {?proto.sip.resources.Resource}
 */
proto.sip.resources.ProposedChange.prototype.getData = function() {
  return /** @type{?proto.sip.resources.Resource} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.Resource, 3));
};


/**
 * @param {?proto.sip.resources.Resource|undefined} value
 * @return {!proto.sip.resources.ProposedChange} returns this
*/
proto.sip.resources.ProposedChange.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.ProposedChange} returns this
 */
proto.sip.resources.ProposedChange.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.ProposedChange.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string path = 4;
 * @return {!Array<string>}
 */
proto.sip.resources.ProposedChange.prototype.getPathList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sip.resources.ProposedChange} returns this
 */
proto.sip.resources.ProposedChange.prototype.setPathList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sip.resources.ProposedChange} returns this
 */
proto.sip.resources.ProposedChange.prototype.addPath = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.resources.ProposedChange} returns this
 */
proto.sip.resources.ProposedChange.prototype.clearPathList = function() {
  return this.setPathList([]);
};


/**
 * optional string last_updated_by = 5;
 * @return {string}
 */
proto.sip.resources.ProposedChange.prototype.getLastUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ProposedChange} returns this
 */
proto.sip.resources.ProposedChange.prototype.setLastUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ProposedChangeState state = 6;
 * @return {!proto.sip.resources.ProposedChangeState}
 */
proto.sip.resources.ProposedChange.prototype.getState = function() {
  return /** @type {!proto.sip.resources.ProposedChangeState} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.sip.resources.ProposedChangeState} value
 * @return {!proto.sip.resources.ProposedChange} returns this
 */
proto.sip.resources.ProposedChange.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string comment = 7;
 * @return {string}
 */
proto.sip.resources.ProposedChange.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ProposedChange} returns this
 */
proto.sip.resources.ProposedChange.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp create_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.resources.ProposedChange.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.resources.ProposedChange} returns this
*/
proto.sip.resources.ProposedChange.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.ProposedChange} returns this
 */
proto.sip.resources.ProposedChange.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.ProposedChange.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp update_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.resources.ProposedChange.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.resources.ProposedChange} returns this
*/
proto.sip.resources.ProposedChange.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.ProposedChange} returns this
 */
proto.sip.resources.ProposedChange.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.ProposedChange.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp finish_time = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.resources.ProposedChange.prototype.getFinishTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.resources.ProposedChange} returns this
*/
proto.sip.resources.ProposedChange.prototype.setFinishTime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.ProposedChange} returns this
 */
proto.sip.resources.ProposedChange.prototype.clearFinishTime = function() {
  return this.setFinishTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.ProposedChange.prototype.hasFinishTime = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.ListProposedChangesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.ListProposedChangesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.ListProposedChangesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ListProposedChangesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.sip.resources.ProposedChange.Filter.toObject(includeInstance, f),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.ListProposedChangesRequest}
 */
proto.sip.resources.ListProposedChangesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.ListProposedChangesRequest;
  return proto.sip.resources.ListProposedChangesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.ListProposedChangesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.ListProposedChangesRequest}
 */
proto.sip.resources.ListProposedChangesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.resources.ProposedChange.Filter;
      reader.readMessage(value,proto.sip.resources.ProposedChange.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.ListProposedChangesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.ListProposedChangesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.ListProposedChangesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ListProposedChangesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.resources.ProposedChange.Filter.serializeBinaryToWriter
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional ProposedChange.Filter filter = 1;
 * @return {?proto.sip.resources.ProposedChange.Filter}
 */
proto.sip.resources.ListProposedChangesRequest.prototype.getFilter = function() {
  return /** @type{?proto.sip.resources.ProposedChange.Filter} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.ProposedChange.Filter, 1));
};


/**
 * @param {?proto.sip.resources.ProposedChange.Filter|undefined} value
 * @return {!proto.sip.resources.ListProposedChangesRequest} returns this
*/
proto.sip.resources.ListProposedChangesRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.ListProposedChangesRequest} returns this
 */
proto.sip.resources.ListProposedChangesRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.ListProposedChangesRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.sip.resources.ListProposedChangesRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.resources.ListProposedChangesRequest} returns this
 */
proto.sip.resources.ListProposedChangesRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.sip.resources.ListProposedChangesRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ListProposedChangesRequest} returns this
 */
proto.sip.resources.ListProposedChangesRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.resources.ListProposedChangesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.ListProposedChangesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.ListProposedChangesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.ListProposedChangesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ListProposedChangesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    changesList: jspb.Message.toObjectList(msg.getChangesList(),
    proto.sip.resources.ProposedChange.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.ListProposedChangesResponse}
 */
proto.sip.resources.ListProposedChangesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.ListProposedChangesResponse;
  return proto.sip.resources.ListProposedChangesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.ListProposedChangesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.ListProposedChangesResponse}
 */
proto.sip.resources.ListProposedChangesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.resources.ProposedChange;
      reader.readMessage(value,proto.sip.resources.ProposedChange.deserializeBinaryFromReader);
      msg.addChanges(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.ListProposedChangesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.ListProposedChangesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.ListProposedChangesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ListProposedChangesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.resources.ProposedChange.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated ProposedChange changes = 1;
 * @return {!Array<!proto.sip.resources.ProposedChange>}
 */
proto.sip.resources.ListProposedChangesResponse.prototype.getChangesList = function() {
  return /** @type{!Array<!proto.sip.resources.ProposedChange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.resources.ProposedChange, 1));
};


/**
 * @param {!Array<!proto.sip.resources.ProposedChange>} value
 * @return {!proto.sip.resources.ListProposedChangesResponse} returns this
*/
proto.sip.resources.ListProposedChangesResponse.prototype.setChangesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.resources.ProposedChange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.resources.ProposedChange}
 */
proto.sip.resources.ListProposedChangesResponse.prototype.addChanges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.resources.ProposedChange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.resources.ListProposedChangesResponse} returns this
 */
proto.sip.resources.ListProposedChangesResponse.prototype.clearChangesList = function() {
  return this.setChangesList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.resources.ListProposedChangesResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ListProposedChangesResponse} returns this
 */
proto.sip.resources.ListProposedChangesResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 total_size = 3;
 * @return {number}
 */
proto.sip.resources.ListProposedChangesResponse.prototype.getTotalSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.resources.ListProposedChangesResponse} returns this
 */
proto.sip.resources.ListProposedChangesResponse.prototype.setTotalSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.CreateProposedChangeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.CreateProposedChangeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.CreateProposedChangeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.CreateProposedChangeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    change: (f = msg.getChange()) && proto.sip.resources.ProposedChange.toObject(includeInstance, f),
    auditMessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.CreateProposedChangeRequest}
 */
proto.sip.resources.CreateProposedChangeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.CreateProposedChangeRequest;
  return proto.sip.resources.CreateProposedChangeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.CreateProposedChangeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.CreateProposedChangeRequest}
 */
proto.sip.resources.CreateProposedChangeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.resources.ProposedChange;
      reader.readMessage(value,proto.sip.resources.ProposedChange.deserializeBinaryFromReader);
      msg.setChange(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.CreateProposedChangeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.CreateProposedChangeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.CreateProposedChangeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.CreateProposedChangeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChange();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.resources.ProposedChange.serializeBinaryToWriter
    );
  }
  f = message.getAuditMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ProposedChange change = 1;
 * @return {?proto.sip.resources.ProposedChange}
 */
proto.sip.resources.CreateProposedChangeRequest.prototype.getChange = function() {
  return /** @type{?proto.sip.resources.ProposedChange} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.ProposedChange, 1));
};


/**
 * @param {?proto.sip.resources.ProposedChange|undefined} value
 * @return {!proto.sip.resources.CreateProposedChangeRequest} returns this
*/
proto.sip.resources.CreateProposedChangeRequest.prototype.setChange = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.CreateProposedChangeRequest} returns this
 */
proto.sip.resources.CreateProposedChangeRequest.prototype.clearChange = function() {
  return this.setChange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.CreateProposedChangeRequest.prototype.hasChange = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string audit_message = 2;
 * @return {string}
 */
proto.sip.resources.CreateProposedChangeRequest.prototype.getAuditMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.CreateProposedChangeRequest} returns this
 */
proto.sip.resources.CreateProposedChangeRequest.prototype.setAuditMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.UpdateProposedChangeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.UpdateProposedChangeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.UpdateProposedChangeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.UpdateProposedChangeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    change: (f = msg.getChange()) && proto.sip.resources.ProposedChange.toObject(includeInstance, f),
    auditMessage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.UpdateProposedChangeRequest}
 */
proto.sip.resources.UpdateProposedChangeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.UpdateProposedChangeRequest;
  return proto.sip.resources.UpdateProposedChangeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.UpdateProposedChangeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.UpdateProposedChangeRequest}
 */
proto.sip.resources.UpdateProposedChangeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.resources.ProposedChange;
      reader.readMessage(value,proto.sip.resources.ProposedChange.deserializeBinaryFromReader);
      msg.setChange(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditMessage(value);
      break;
    case 3:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.UpdateProposedChangeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.UpdateProposedChangeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.UpdateProposedChangeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.UpdateProposedChangeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChange();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.resources.ProposedChange.serializeBinaryToWriter
    );
  }
  f = message.getAuditMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProposedChange change = 1;
 * @return {?proto.sip.resources.ProposedChange}
 */
proto.sip.resources.UpdateProposedChangeRequest.prototype.getChange = function() {
  return /** @type{?proto.sip.resources.ProposedChange} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.ProposedChange, 1));
};


/**
 * @param {?proto.sip.resources.ProposedChange|undefined} value
 * @return {!proto.sip.resources.UpdateProposedChangeRequest} returns this
*/
proto.sip.resources.UpdateProposedChangeRequest.prototype.setChange = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.UpdateProposedChangeRequest} returns this
 */
proto.sip.resources.UpdateProposedChangeRequest.prototype.clearChange = function() {
  return this.setChange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.UpdateProposedChangeRequest.prototype.hasChange = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string audit_message = 2;
 * @return {string}
 */
proto.sip.resources.UpdateProposedChangeRequest.prototype.getAuditMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.UpdateProposedChangeRequest} returns this
 */
proto.sip.resources.UpdateProposedChangeRequest.prototype.setAuditMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.FieldMask update_mask = 3;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.sip.resources.UpdateProposedChangeRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 3));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.sip.resources.UpdateProposedChangeRequest} returns this
*/
proto.sip.resources.UpdateProposedChangeRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.UpdateProposedChangeRequest} returns this
 */
proto.sip.resources.UpdateProposedChangeRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.UpdateProposedChangeRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.DeleteProposedChangeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.DeleteProposedChangeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.DeleteProposedChangeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.DeleteProposedChangeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditMessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.DeleteProposedChangeRequest}
 */
proto.sip.resources.DeleteProposedChangeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.DeleteProposedChangeRequest;
  return proto.sip.resources.DeleteProposedChangeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.DeleteProposedChangeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.DeleteProposedChangeRequest}
 */
proto.sip.resources.DeleteProposedChangeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.DeleteProposedChangeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.DeleteProposedChangeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.DeleteProposedChangeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.DeleteProposedChangeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.resources.DeleteProposedChangeRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.DeleteProposedChangeRequest} returns this
 */
proto.sip.resources.DeleteProposedChangeRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string audit_message = 2;
 * @return {string}
 */
proto.sip.resources.DeleteProposedChangeRequest.prototype.getAuditMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.DeleteProposedChangeRequest} returns this
 */
proto.sip.resources.DeleteProposedChangeRequest.prototype.setAuditMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.AuditLogEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.AuditLogEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.AuditLogEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.AuditLogEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resourceName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    permissionName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    changeName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    userName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    auditMessage: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.AuditLogEntry}
 */
proto.sip.resources.AuditLogEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.AuditLogEntry;
  return proto.sip.resources.AuditLogEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.AuditLogEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.AuditLogEntry}
 */
proto.sip.resources.AuditLogEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPermissionName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangeName(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.AuditLogEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.AuditLogEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.AuditLogEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.AuditLogEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResourceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPermissionName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getChangeName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAuditMessage();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.AuditLogEntry.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.AuditLogEntry.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.AuditLogEntry.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.AuditLogEntry.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resourceName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    permissionName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    changeName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    auditMessage: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sortBy: jspb.Message.getFieldWithDefault(msg, 10, 0),
    sortDescending: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.AuditLogEntry.Filter}
 */
proto.sip.resources.AuditLogEntry.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.AuditLogEntry.Filter;
  return proto.sip.resources.AuditLogEntry.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.AuditLogEntry.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.AuditLogEntry.Filter}
 */
proto.sip.resources.AuditLogEntry.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPermissionName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangeName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditMessage(value);
      break;
    case 10:
      var value = /** @type {!proto.sip.resources.AuditLogEntry.Filter.SortBy} */ (reader.readEnum());
      msg.setSortBy(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSortDescending(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.AuditLogEntry.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.AuditLogEntry.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.AuditLogEntry.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.AuditLogEntry.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResourceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPermissionName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getChangeName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAuditMessage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSortBy();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getSortDescending();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sip.resources.AuditLogEntry.Filter.SortBy = {
  NAME: 0,
  RESOURCE_NAME: 1,
  PERMISSION_NAME: 2,
  CHANGE_NAME: 3,
  USER_NAME: 4,
  AUDIT_MESSAGE: 5,
  CREATE_TIME: 6
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.resources.AuditLogEntry.Filter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.AuditLogEntry.Filter} returns this
 */
proto.sip.resources.AuditLogEntry.Filter.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string resource_name = 2;
 * @return {string}
 */
proto.sip.resources.AuditLogEntry.Filter.prototype.getResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.AuditLogEntry.Filter} returns this
 */
proto.sip.resources.AuditLogEntry.Filter.prototype.setResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string permission_name = 3;
 * @return {string}
 */
proto.sip.resources.AuditLogEntry.Filter.prototype.getPermissionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.AuditLogEntry.Filter} returns this
 */
proto.sip.resources.AuditLogEntry.Filter.prototype.setPermissionName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string change_name = 4;
 * @return {string}
 */
proto.sip.resources.AuditLogEntry.Filter.prototype.getChangeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.AuditLogEntry.Filter} returns this
 */
proto.sip.resources.AuditLogEntry.Filter.prototype.setChangeName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string user_name = 5;
 * @return {string}
 */
proto.sip.resources.AuditLogEntry.Filter.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.AuditLogEntry.Filter} returns this
 */
proto.sip.resources.AuditLogEntry.Filter.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string audit_message = 6;
 * @return {string}
 */
proto.sip.resources.AuditLogEntry.Filter.prototype.getAuditMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.AuditLogEntry.Filter} returns this
 */
proto.sip.resources.AuditLogEntry.Filter.prototype.setAuditMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional SortBy sort_by = 10;
 * @return {!proto.sip.resources.AuditLogEntry.Filter.SortBy}
 */
proto.sip.resources.AuditLogEntry.Filter.prototype.getSortBy = function() {
  return /** @type {!proto.sip.resources.AuditLogEntry.Filter.SortBy} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.sip.resources.AuditLogEntry.Filter.SortBy} value
 * @return {!proto.sip.resources.AuditLogEntry.Filter} returns this
 */
proto.sip.resources.AuditLogEntry.Filter.prototype.setSortBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional bool sort_descending = 11;
 * @return {boolean}
 */
proto.sip.resources.AuditLogEntry.Filter.prototype.getSortDescending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.resources.AuditLogEntry.Filter} returns this
 */
proto.sip.resources.AuditLogEntry.Filter.prototype.setSortDescending = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sip.resources.AuditLogEntry.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.AuditLogEntry} returns this
 */
proto.sip.resources.AuditLogEntry.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string resource_name = 2;
 * @return {string}
 */
proto.sip.resources.AuditLogEntry.prototype.getResourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.AuditLogEntry} returns this
 */
proto.sip.resources.AuditLogEntry.prototype.setResourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string permission_name = 3;
 * @return {string}
 */
proto.sip.resources.AuditLogEntry.prototype.getPermissionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.AuditLogEntry} returns this
 */
proto.sip.resources.AuditLogEntry.prototype.setPermissionName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string change_name = 4;
 * @return {string}
 */
proto.sip.resources.AuditLogEntry.prototype.getChangeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.AuditLogEntry} returns this
 */
proto.sip.resources.AuditLogEntry.prototype.setChangeName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp create_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sip.resources.AuditLogEntry.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sip.resources.AuditLogEntry} returns this
*/
proto.sip.resources.AuditLogEntry.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.AuditLogEntry} returns this
 */
proto.sip.resources.AuditLogEntry.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.AuditLogEntry.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string user_name = 6;
 * @return {string}
 */
proto.sip.resources.AuditLogEntry.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.AuditLogEntry} returns this
 */
proto.sip.resources.AuditLogEntry.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string audit_message = 7;
 * @return {string}
 */
proto.sip.resources.AuditLogEntry.prototype.getAuditMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.AuditLogEntry} returns this
 */
proto.sip.resources.AuditLogEntry.prototype.setAuditMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.ListAuditLogRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.ListAuditLogRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.ListAuditLogRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ListAuditLogRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.sip.resources.AuditLogEntry.Filter.toObject(includeInstance, f),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.ListAuditLogRequest}
 */
proto.sip.resources.ListAuditLogRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.ListAuditLogRequest;
  return proto.sip.resources.ListAuditLogRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.ListAuditLogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.ListAuditLogRequest}
 */
proto.sip.resources.ListAuditLogRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.resources.AuditLogEntry.Filter;
      reader.readMessage(value,proto.sip.resources.AuditLogEntry.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.ListAuditLogRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.ListAuditLogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.ListAuditLogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ListAuditLogRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sip.resources.AuditLogEntry.Filter.serializeBinaryToWriter
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional AuditLogEntry.Filter filter = 1;
 * @return {?proto.sip.resources.AuditLogEntry.Filter}
 */
proto.sip.resources.ListAuditLogRequest.prototype.getFilter = function() {
  return /** @type{?proto.sip.resources.AuditLogEntry.Filter} */ (
    jspb.Message.getWrapperField(this, proto.sip.resources.AuditLogEntry.Filter, 1));
};


/**
 * @param {?proto.sip.resources.AuditLogEntry.Filter|undefined} value
 * @return {!proto.sip.resources.ListAuditLogRequest} returns this
*/
proto.sip.resources.ListAuditLogRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sip.resources.ListAuditLogRequest} returns this
 */
proto.sip.resources.ListAuditLogRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sip.resources.ListAuditLogRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.sip.resources.ListAuditLogRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.resources.ListAuditLogRequest} returns this
 */
proto.sip.resources.ListAuditLogRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.sip.resources.ListAuditLogRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ListAuditLogRequest} returns this
 */
proto.sip.resources.ListAuditLogRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.resources.ListAuditLogResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.ListAuditLogResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.ListAuditLogResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.ListAuditLogResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ListAuditLogResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    entryList: jspb.Message.toObjectList(msg.getEntryList(),
    proto.sip.resources.AuditLogEntry.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.ListAuditLogResponse}
 */
proto.sip.resources.ListAuditLogResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.ListAuditLogResponse;
  return proto.sip.resources.ListAuditLogResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.ListAuditLogResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.ListAuditLogResponse}
 */
proto.sip.resources.ListAuditLogResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sip.resources.AuditLogEntry;
      reader.readMessage(value,proto.sip.resources.AuditLogEntry.deserializeBinaryFromReader);
      msg.addEntry(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.ListAuditLogResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.ListAuditLogResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.ListAuditLogResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ListAuditLogResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sip.resources.AuditLogEntry.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated AuditLogEntry entry = 1;
 * @return {!Array<!proto.sip.resources.AuditLogEntry>}
 */
proto.sip.resources.ListAuditLogResponse.prototype.getEntryList = function() {
  return /** @type{!Array<!proto.sip.resources.AuditLogEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.resources.AuditLogEntry, 1));
};


/**
 * @param {!Array<!proto.sip.resources.AuditLogEntry>} value
 * @return {!proto.sip.resources.ListAuditLogResponse} returns this
*/
proto.sip.resources.ListAuditLogResponse.prototype.setEntryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sip.resources.AuditLogEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.resources.AuditLogEntry}
 */
proto.sip.resources.ListAuditLogResponse.prototype.addEntry = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sip.resources.AuditLogEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.resources.ListAuditLogResponse} returns this
 */
proto.sip.resources.ListAuditLogResponse.prototype.clearEntryList = function() {
  return this.setEntryList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.sip.resources.ListAuditLogResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ListAuditLogResponse} returns this
 */
proto.sip.resources.ListAuditLogResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 total_size = 3;
 * @return {number}
 */
proto.sip.resources.ListAuditLogResponse.prototype.getTotalSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.resources.ListAuditLogResponse} returns this
 */
proto.sip.resources.ListAuditLogResponse.prototype.setTotalSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.resources.MailServiceResource.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.MailServiceResource.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.MailServiceResource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.MailServiceResource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.MailServiceResource.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgPrefix: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mailboxQuota: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sharedMailboxQuota: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sharedMailboxOwner: jspb.Message.getFieldWithDefault(msg, 4, ""),
    domainsList: jspb.Message.toObjectList(msg.getDomainsList(),
    proto.sip.resources.MailServiceResource.MailDomainInfo.toObject, includeInstance),
    displayNameSuffix: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.MailServiceResource}
 */
proto.sip.resources.MailServiceResource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.MailServiceResource;
  return proto.sip.resources.MailServiceResource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.MailServiceResource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.MailServiceResource}
 */
proto.sip.resources.MailServiceResource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgPrefix(value);
      break;
    case 2:
      var value = /** @type {!proto.sip.resources.MailboxQuota} */ (reader.readEnum());
      msg.setMailboxQuota(value);
      break;
    case 3:
      var value = /** @type {!proto.sip.resources.MailboxQuota} */ (reader.readEnum());
      msg.setSharedMailboxQuota(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSharedMailboxOwner(value);
      break;
    case 5:
      var value = new proto.sip.resources.MailServiceResource.MailDomainInfo;
      reader.readMessage(value,proto.sip.resources.MailServiceResource.MailDomainInfo.deserializeBinaryFromReader);
      msg.addDomains(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayNameSuffix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.MailServiceResource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.MailServiceResource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.MailServiceResource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.MailServiceResource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgPrefix();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMailboxQuota();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSharedMailboxQuota();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSharedMailboxOwner();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.sip.resources.MailServiceResource.MailDomainInfo.serializeBinaryToWriter
    );
  }
  f = message.getDisplayNameSuffix();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.resources.MailServiceResource.MailDomainInfo.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.MailServiceResource.MailDomainInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.MailServiceResource.MailDomainInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.MailServiceResource.MailDomainInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.MailServiceResource.MailDomainInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    domain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    prestigeAlias: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    aliasDomainsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    primaryMailPriority: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.MailServiceResource.MailDomainInfo}
 */
proto.sip.resources.MailServiceResource.MailDomainInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.MailServiceResource.MailDomainInfo;
  return proto.sip.resources.MailServiceResource.MailDomainInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.MailServiceResource.MailDomainInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.MailServiceResource.MailDomainInfo}
 */
proto.sip.resources.MailServiceResource.MailDomainInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrestigeAlias(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addAliasDomains(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrimaryMailPriority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.MailServiceResource.MailDomainInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.MailServiceResource.MailDomainInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.MailServiceResource.MailDomainInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.MailServiceResource.MailDomainInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrestigeAlias();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAliasDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getPrimaryMailPriority();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string domain = 1;
 * @return {string}
 */
proto.sip.resources.MailServiceResource.MailDomainInfo.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.MailServiceResource.MailDomainInfo} returns this
 */
proto.sip.resources.MailServiceResource.MailDomainInfo.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool prestige_alias = 2;
 * @return {boolean}
 */
proto.sip.resources.MailServiceResource.MailDomainInfo.prototype.getPrestigeAlias = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.resources.MailServiceResource.MailDomainInfo} returns this
 */
proto.sip.resources.MailServiceResource.MailDomainInfo.prototype.setPrestigeAlias = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated string alias_domains = 3;
 * @return {!Array<string>}
 */
proto.sip.resources.MailServiceResource.MailDomainInfo.prototype.getAliasDomainsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sip.resources.MailServiceResource.MailDomainInfo} returns this
 */
proto.sip.resources.MailServiceResource.MailDomainInfo.prototype.setAliasDomainsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sip.resources.MailServiceResource.MailDomainInfo} returns this
 */
proto.sip.resources.MailServiceResource.MailDomainInfo.prototype.addAliasDomains = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.resources.MailServiceResource.MailDomainInfo} returns this
 */
proto.sip.resources.MailServiceResource.MailDomainInfo.prototype.clearAliasDomainsList = function() {
  return this.setAliasDomainsList([]);
};


/**
 * optional int32 primary_mail_priority = 4;
 * @return {number}
 */
proto.sip.resources.MailServiceResource.MailDomainInfo.prototype.getPrimaryMailPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.resources.MailServiceResource.MailDomainInfo} returns this
 */
proto.sip.resources.MailServiceResource.MailDomainInfo.prototype.setPrimaryMailPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string org_prefix = 1;
 * @return {string}
 */
proto.sip.resources.MailServiceResource.prototype.getOrgPrefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.MailServiceResource} returns this
 */
proto.sip.resources.MailServiceResource.prototype.setOrgPrefix = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional MailboxQuota mailbox_quota = 2;
 * @return {!proto.sip.resources.MailboxQuota}
 */
proto.sip.resources.MailServiceResource.prototype.getMailboxQuota = function() {
  return /** @type {!proto.sip.resources.MailboxQuota} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sip.resources.MailboxQuota} value
 * @return {!proto.sip.resources.MailServiceResource} returns this
 */
proto.sip.resources.MailServiceResource.prototype.setMailboxQuota = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional MailboxQuota shared_mailbox_quota = 3;
 * @return {!proto.sip.resources.MailboxQuota}
 */
proto.sip.resources.MailServiceResource.prototype.getSharedMailboxQuota = function() {
  return /** @type {!proto.sip.resources.MailboxQuota} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.sip.resources.MailboxQuota} value
 * @return {!proto.sip.resources.MailServiceResource} returns this
 */
proto.sip.resources.MailServiceResource.prototype.setSharedMailboxQuota = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string shared_mailbox_owner = 4;
 * @return {string}
 */
proto.sip.resources.MailServiceResource.prototype.getSharedMailboxOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.MailServiceResource} returns this
 */
proto.sip.resources.MailServiceResource.prototype.setSharedMailboxOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated MailDomainInfo domains = 5;
 * @return {!Array<!proto.sip.resources.MailServiceResource.MailDomainInfo>}
 */
proto.sip.resources.MailServiceResource.prototype.getDomainsList = function() {
  return /** @type{!Array<!proto.sip.resources.MailServiceResource.MailDomainInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sip.resources.MailServiceResource.MailDomainInfo, 5));
};


/**
 * @param {!Array<!proto.sip.resources.MailServiceResource.MailDomainInfo>} value
 * @return {!proto.sip.resources.MailServiceResource} returns this
*/
proto.sip.resources.MailServiceResource.prototype.setDomainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.sip.resources.MailServiceResource.MailDomainInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sip.resources.MailServiceResource.MailDomainInfo}
 */
proto.sip.resources.MailServiceResource.prototype.addDomains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.sip.resources.MailServiceResource.MailDomainInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.resources.MailServiceResource} returns this
 */
proto.sip.resources.MailServiceResource.prototype.clearDomainsList = function() {
  return this.setDomainsList([]);
};


/**
 * optional string display_name_suffix = 6;
 * @return {string}
 */
proto.sip.resources.MailServiceResource.prototype.getDisplayNameSuffix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.MailServiceResource} returns this
 */
proto.sip.resources.MailServiceResource.prototype.setDisplayNameSuffix = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.resources.MailDistributionListResource.repeatedFields_ = [3,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.MailDistributionListResource.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.MailDistributionListResource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.MailDistributionListResource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.MailDistributionListResource.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifier: jspb.Message.getFieldWithDefault(msg, 1, 0),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    emailAliasesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    orgPrefix: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dblType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    ethAdMemberEmailsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.MailDistributionListResource}
 */
proto.sip.resources.MailDistributionListResource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.MailDistributionListResource;
  return proto.sip.resources.MailDistributionListResource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.MailDistributionListResource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.MailDistributionListResource}
 */
proto.sip.resources.MailDistributionListResource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIdentifier(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmailAliases(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgPrefix(value);
      break;
    case 5:
      var value = /** @type {!proto.sip.resources.MailDistributionListResource.DblType} */ (reader.readEnum());
      msg.setDblType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addEthAdMemberEmails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.MailDistributionListResource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.MailDistributionListResource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.MailDistributionListResource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.MailDistributionListResource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifier();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmailAliasesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getOrgPrefix();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDblType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getEthAdMemberEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sip.resources.MailDistributionListResource.DblType = {
  DBL_TYPE_UNSPECIFIED: 0,
  GROUP_MEMBERS: 1,
  USE_GROUP_CONTACTS: 2
};

/**
 * optional int32 identifier = 1;
 * @return {number}
 */
proto.sip.resources.MailDistributionListResource.prototype.getIdentifier = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.resources.MailDistributionListResource} returns this
 */
proto.sip.resources.MailDistributionListResource.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.sip.resources.MailDistributionListResource.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.MailDistributionListResource} returns this
 */
proto.sip.resources.MailDistributionListResource.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string email_aliases = 3;
 * @return {!Array<string>}
 */
proto.sip.resources.MailDistributionListResource.prototype.getEmailAliasesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sip.resources.MailDistributionListResource} returns this
 */
proto.sip.resources.MailDistributionListResource.prototype.setEmailAliasesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sip.resources.MailDistributionListResource} returns this
 */
proto.sip.resources.MailDistributionListResource.prototype.addEmailAliases = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.resources.MailDistributionListResource} returns this
 */
proto.sip.resources.MailDistributionListResource.prototype.clearEmailAliasesList = function() {
  return this.setEmailAliasesList([]);
};


/**
 * optional string org_prefix = 4;
 * @return {string}
 */
proto.sip.resources.MailDistributionListResource.prototype.getOrgPrefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.MailDistributionListResource} returns this
 */
proto.sip.resources.MailDistributionListResource.prototype.setOrgPrefix = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional DblType dbl_type = 5;
 * @return {!proto.sip.resources.MailDistributionListResource.DblType}
 */
proto.sip.resources.MailDistributionListResource.prototype.getDblType = function() {
  return /** @type {!proto.sip.resources.MailDistributionListResource.DblType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.sip.resources.MailDistributionListResource.DblType} value
 * @return {!proto.sip.resources.MailDistributionListResource} returns this
 */
proto.sip.resources.MailDistributionListResource.prototype.setDblType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated string eth_ad_member_emails = 6;
 * @return {!Array<string>}
 */
proto.sip.resources.MailDistributionListResource.prototype.getEthAdMemberEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sip.resources.MailDistributionListResource} returns this
 */
proto.sip.resources.MailDistributionListResource.prototype.setEthAdMemberEmailsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sip.resources.MailDistributionListResource} returns this
 */
proto.sip.resources.MailDistributionListResource.prototype.addEthAdMemberEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.resources.MailDistributionListResource} returns this
 */
proto.sip.resources.MailDistributionListResource.prototype.clearEthAdMemberEmailsList = function() {
  return this.setEthAdMemberEmailsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.resources.MailSharedMailboxResource.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.MailSharedMailboxResource.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.MailSharedMailboxResource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.MailSharedMailboxResource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.MailSharedMailboxResource.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifier: jspb.Message.getFieldWithDefault(msg, 1, 0),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    emailAliasesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    orgPrefix: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.MailSharedMailboxResource}
 */
proto.sip.resources.MailSharedMailboxResource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.MailSharedMailboxResource;
  return proto.sip.resources.MailSharedMailboxResource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.MailSharedMailboxResource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.MailSharedMailboxResource}
 */
proto.sip.resources.MailSharedMailboxResource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIdentifier(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmailAliases(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgPrefix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.MailSharedMailboxResource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.MailSharedMailboxResource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.MailSharedMailboxResource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.MailSharedMailboxResource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifier();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmailAliasesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getOrgPrefix();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 identifier = 1;
 * @return {number}
 */
proto.sip.resources.MailSharedMailboxResource.prototype.getIdentifier = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.resources.MailSharedMailboxResource} returns this
 */
proto.sip.resources.MailSharedMailboxResource.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.sip.resources.MailSharedMailboxResource.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.MailSharedMailboxResource} returns this
 */
proto.sip.resources.MailSharedMailboxResource.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string email_aliases = 3;
 * @return {!Array<string>}
 */
proto.sip.resources.MailSharedMailboxResource.prototype.getEmailAliasesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sip.resources.MailSharedMailboxResource} returns this
 */
proto.sip.resources.MailSharedMailboxResource.prototype.setEmailAliasesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sip.resources.MailSharedMailboxResource} returns this
 */
proto.sip.resources.MailSharedMailboxResource.prototype.addEmailAliases = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.resources.MailSharedMailboxResource} returns this
 */
proto.sip.resources.MailSharedMailboxResource.prototype.clearEmailAliasesList = function() {
  return this.setEmailAliasesList([]);
};


/**
 * optional string org_prefix = 4;
 * @return {string}
 */
proto.sip.resources.MailSharedMailboxResource.prototype.getOrgPrefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.MailSharedMailboxResource} returns this
 */
proto.sip.resources.MailSharedMailboxResource.prototype.setOrgPrefix = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.resources.MailContactResource.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.MailContactResource.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.MailContactResource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.MailContactResource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.MailContactResource.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    emailAliasesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    shortName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    orgPrefix: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.MailContactResource}
 */
proto.sip.resources.MailContactResource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.MailContactResource;
  return proto.sip.resources.MailContactResource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.MailContactResource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.MailContactResource}
 */
proto.sip.resources.MailContactResource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmailAliases(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgPrefix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.MailContactResource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.MailContactResource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.MailContactResource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.MailContactResource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmailAliasesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrgPrefix();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.sip.resources.MailContactResource.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.MailContactResource} returns this
 */
proto.sip.resources.MailContactResource.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string email_aliases = 2;
 * @return {!Array<string>}
 */
proto.sip.resources.MailContactResource.prototype.getEmailAliasesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sip.resources.MailContactResource} returns this
 */
proto.sip.resources.MailContactResource.prototype.setEmailAliasesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sip.resources.MailContactResource} returns this
 */
proto.sip.resources.MailContactResource.prototype.addEmailAliases = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.resources.MailContactResource} returns this
 */
proto.sip.resources.MailContactResource.prototype.clearEmailAliasesList = function() {
  return this.setEmailAliasesList([]);
};


/**
 * optional string short_name = 3;
 * @return {string}
 */
proto.sip.resources.MailContactResource.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.MailContactResource} returns this
 */
proto.sip.resources.MailContactResource.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string full_name = 4;
 * @return {string}
 */
proto.sip.resources.MailContactResource.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.MailContactResource} returns this
 */
proto.sip.resources.MailContactResource.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string org_prefix = 5;
 * @return {string}
 */
proto.sip.resources.MailContactResource.prototype.getOrgPrefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.MailContactResource} returns this
 */
proto.sip.resources.MailContactResource.prototype.setOrgPrefix = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.EthIamGroupResource.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.EthIamGroupResource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.EthIamGroupResource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.EthIamGroupResource.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.EthIamGroupResource}
 */
proto.sip.resources.EthIamGroupResource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.EthIamGroupResource;
  return proto.sip.resources.EthIamGroupResource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.EthIamGroupResource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.EthIamGroupResource}
 */
proto.sip.resources.EthIamGroupResource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.EthIamGroupResource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.EthIamGroupResource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.EthIamGroupResource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.EthIamGroupResource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.SympaMailingListResource.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.SympaMailingListResource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.SympaMailingListResource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.SympaMailingListResource.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subjectTag: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sendSetup: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.SympaMailingListResource}
 */
proto.sip.resources.SympaMailingListResource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.SympaMailingListResource;
  return proto.sip.resources.SympaMailingListResource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.SympaMailingListResource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.SympaMailingListResource}
 */
proto.sip.resources.SympaMailingListResource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectTag(value);
      break;
    case 4:
      var value = /** @type {!proto.sip.resources.SympaMailingListResource.ListType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {!proto.sip.resources.SympaMailingListResource.SendSetup} */ (reader.readEnum());
      msg.setSendSetup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.SympaMailingListResource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.SympaMailingListResource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.SympaMailingListResource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.SympaMailingListResource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubjectTag();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSendSetup();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sip.resources.SympaMailingListResource.ListType = {
  LIST_TYPE_UNSPECIFIED: 0,
  IMPORT_FROM_FILE: 1,
  CONTACT_EMAIL_OF_GROUPS: 2,
  GROUP_MEMBERS: 3,
  NEWSLETTER_PUBLIC: 4,
  NEWSLETTER_SWITCH: 5,
  INTERNAL_SYMPA_MANAGED: 6
};

/**
 * @enum {number}
 */
proto.sip.resources.SympaMailingListResource.SendSetup = {
  SEND_SETUP_UNSPECIFIED: 0,
  PUBLIC: 1,
  MODERATED_FOR_NON_SUBSCRIBERS: 2,
  MODERATED: 3
};

/**
 * optional string email = 1;
 * @return {string}
 */
proto.sip.resources.SympaMailingListResource.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.SympaMailingListResource} returns this
 */
proto.sip.resources.SympaMailingListResource.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subject = 2;
 * @return {string}
 */
proto.sip.resources.SympaMailingListResource.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.SympaMailingListResource} returns this
 */
proto.sip.resources.SympaMailingListResource.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subject_tag = 3;
 * @return {string}
 */
proto.sip.resources.SympaMailingListResource.prototype.getSubjectTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.SympaMailingListResource} returns this
 */
proto.sip.resources.SympaMailingListResource.prototype.setSubjectTag = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ListType type = 4;
 * @return {!proto.sip.resources.SympaMailingListResource.ListType}
 */
proto.sip.resources.SympaMailingListResource.prototype.getType = function() {
  return /** @type {!proto.sip.resources.SympaMailingListResource.ListType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.sip.resources.SympaMailingListResource.ListType} value
 * @return {!proto.sip.resources.SympaMailingListResource} returns this
 */
proto.sip.resources.SympaMailingListResource.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional SendSetup send_setup = 5;
 * @return {!proto.sip.resources.SympaMailingListResource.SendSetup}
 */
proto.sip.resources.SympaMailingListResource.prototype.getSendSetup = function() {
  return /** @type {!proto.sip.resources.SympaMailingListResource.SendSetup} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.sip.resources.SympaMailingListResource.SendSetup} value
 * @return {!proto.sip.resources.SympaMailingListResource} returns this
 */
proto.sip.resources.SympaMailingListResource.prototype.setSendSetup = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.GSuiteSharedDriveResource.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.GSuiteSharedDriveResource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.GSuiteSharedDriveResource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.GSuiteSharedDriveResource.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifier: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.GSuiteSharedDriveResource}
 */
proto.sip.resources.GSuiteSharedDriveResource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.GSuiteSharedDriveResource;
  return proto.sip.resources.GSuiteSharedDriveResource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.GSuiteSharedDriveResource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.GSuiteSharedDriveResource}
 */
proto.sip.resources.GSuiteSharedDriveResource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.GSuiteSharedDriveResource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.GSuiteSharedDriveResource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.GSuiteSharedDriveResource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.GSuiteSharedDriveResource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string identifier = 1;
 * @return {string}
 */
proto.sip.resources.GSuiteSharedDriveResource.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.GSuiteSharedDriveResource} returns this
 */
proto.sip.resources.GSuiteSharedDriveResource.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.ConfluenceSpaceResource.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.ConfluenceSpaceResource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.ConfluenceSpaceResource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ConfluenceSpaceResource.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isPublic: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.ConfluenceSpaceResource}
 */
proto.sip.resources.ConfluenceSpaceResource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.ConfluenceSpaceResource;
  return proto.sip.resources.ConfluenceSpaceResource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.ConfluenceSpaceResource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.ConfluenceSpaceResource}
 */
proto.sip.resources.ConfluenceSpaceResource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPublic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.ConfluenceSpaceResource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.ConfluenceSpaceResource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.ConfluenceSpaceResource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.ConfluenceSpaceResource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsPublic();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.sip.resources.ConfluenceSpaceResource.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.ConfluenceSpaceResource} returns this
 */
proto.sip.resources.ConfluenceSpaceResource.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_public = 2;
 * @return {boolean}
 */
proto.sip.resources.ConfluenceSpaceResource.prototype.getIsPublic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.resources.ConfluenceSpaceResource} returns this
 */
proto.sip.resources.ConfluenceSpaceResource.prototype.setIsPublic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.GitlabGroupResource.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.GitlabGroupResource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.GitlabGroupResource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.GitlabGroupResource.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifier: jspb.Message.getFieldWithDefault(msg, 1, ""),
    path: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.GitlabGroupResource}
 */
proto.sip.resources.GitlabGroupResource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.GitlabGroupResource;
  return proto.sip.resources.GitlabGroupResource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.GitlabGroupResource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.GitlabGroupResource}
 */
proto.sip.resources.GitlabGroupResource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.GitlabGroupResource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.GitlabGroupResource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.GitlabGroupResource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.GitlabGroupResource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string identifier = 1;
 * @return {string}
 */
proto.sip.resources.GitlabGroupResource.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.GitlabGroupResource} returns this
 */
proto.sip.resources.GitlabGroupResource.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string path = 2;
 * @return {string}
 */
proto.sip.resources.GitlabGroupResource.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.GitlabGroupResource} returns this
 */
proto.sip.resources.GitlabGroupResource.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.GroupTagResource.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.GroupTagResource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.GroupTagResource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.GroupTagResource.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagKey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.GroupTagResource}
 */
proto.sip.resources.GroupTagResource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.GroupTagResource;
  return proto.sip.resources.GroupTagResource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.GroupTagResource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.GroupTagResource}
 */
proto.sip.resources.GroupTagResource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.GroupTagResource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.GroupTagResource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.GroupTagResource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.GroupTagResource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tag_key = 1;
 * @return {string}
 */
proto.sip.resources.GroupTagResource.prototype.getTagKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.GroupTagResource} returns this
 */
proto.sip.resources.GroupTagResource.prototype.setTagKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.KeycloakClientResource.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.KeycloakClientResource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.KeycloakClientResource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.KeycloakClientResource.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    clientProtocol: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.KeycloakClientResource}
 */
proto.sip.resources.KeycloakClientResource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.KeycloakClientResource;
  return proto.sip.resources.KeycloakClientResource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.KeycloakClientResource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.KeycloakClientResource}
 */
proto.sip.resources.KeycloakClientResource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientProtocol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.KeycloakClientResource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.KeycloakClientResource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.KeycloakClientResource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.KeycloakClientResource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getClientProtocol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string client_id = 1;
 * @return {string}
 */
proto.sip.resources.KeycloakClientResource.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.KeycloakClientResource} returns this
 */
proto.sip.resources.KeycloakClientResource.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool enabled = 2;
 * @return {boolean}
 */
proto.sip.resources.KeycloakClientResource.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.resources.KeycloakClientResource} returns this
 */
proto.sip.resources.KeycloakClientResource.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string client_protocol = 3;
 * @return {string}
 */
proto.sip.resources.KeycloakClientResource.prototype.getClientProtocol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.KeycloakClientResource} returns this
 */
proto.sip.resources.KeycloakClientResource.prototype.setClientProtocol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.NASStorageFolderResource.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.NASStorageFolderResource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.NASStorageFolderResource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.NASStorageFolderResource.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.NASStorageFolderResource}
 */
proto.sip.resources.NASStorageFolderResource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.NASStorageFolderResource;
  return proto.sip.resources.NASStorageFolderResource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.NASStorageFolderResource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.NASStorageFolderResource}
 */
proto.sip.resources.NASStorageFolderResource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.NASStorageFolderResource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.NASStorageFolderResource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.NASStorageFolderResource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.NASStorageFolderResource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string path = 1;
 * @return {string}
 */
proto.sip.resources.NASStorageFolderResource.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.NASStorageFolderResource} returns this
 */
proto.sip.resources.NASStorageFolderResource.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.GoogleCalendarResource.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.GoogleCalendarResource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.GoogleCalendarResource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.GoogleCalendarResource.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifier: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isPublic: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.GoogleCalendarResource}
 */
proto.sip.resources.GoogleCalendarResource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.GoogleCalendarResource;
  return proto.sip.resources.GoogleCalendarResource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.GoogleCalendarResource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.GoogleCalendarResource}
 */
proto.sip.resources.GoogleCalendarResource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPublic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.GoogleCalendarResource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.GoogleCalendarResource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.GoogleCalendarResource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.GoogleCalendarResource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsPublic();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string identifier = 1;
 * @return {string}
 */
proto.sip.resources.GoogleCalendarResource.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.GoogleCalendarResource} returns this
 */
proto.sip.resources.GoogleCalendarResource.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_public = 2;
 * @return {boolean}
 */
proto.sip.resources.GoogleCalendarResource.prototype.getIsPublic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.resources.GoogleCalendarResource} returns this
 */
proto.sip.resources.GoogleCalendarResource.prototype.setIsPublic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.OfficeKeyResource.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.OfficeKeyResource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.OfficeKeyResource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.OfficeKeyResource.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifier: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rooms: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.OfficeKeyResource}
 */
proto.sip.resources.OfficeKeyResource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.OfficeKeyResource;
  return proto.sip.resources.OfficeKeyResource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.OfficeKeyResource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.OfficeKeyResource}
 */
proto.sip.resources.OfficeKeyResource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRooms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.OfficeKeyResource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.OfficeKeyResource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.OfficeKeyResource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.OfficeKeyResource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRooms();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string identifier = 1;
 * @return {string}
 */
proto.sip.resources.OfficeKeyResource.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.OfficeKeyResource} returns this
 */
proto.sip.resources.OfficeKeyResource.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rooms = 2;
 * @return {string}
 */
proto.sip.resources.OfficeKeyResource.prototype.getRooms = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.OfficeKeyResource} returns this
 */
proto.sip.resources.OfficeKeyResource.prototype.setRooms = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.KubernetesPermissionResource.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.KubernetesPermissionResource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.KubernetesPermissionResource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.KubernetesPermissionResource.toObject = function(includeInstance, msg) {
  var f, obj = {
    namespace: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.KubernetesPermissionResource}
 */
proto.sip.resources.KubernetesPermissionResource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.KubernetesPermissionResource;
  return proto.sip.resources.KubernetesPermissionResource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.KubernetesPermissionResource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.KubernetesPermissionResource}
 */
proto.sip.resources.KubernetesPermissionResource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamespace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.KubernetesPermissionResource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.KubernetesPermissionResource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.KubernetesPermissionResource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.KubernetesPermissionResource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNamespace();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string namespace = 1;
 * @return {string}
 */
proto.sip.resources.KubernetesPermissionResource.prototype.getNamespace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.KubernetesPermissionResource} returns this
 */
proto.sip.resources.KubernetesPermissionResource.prototype.setNamespace = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sip.resources.MailSpecialUserConfigurationsResource.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sip.resources.MailSpecialUserConfigurationsResource.prototype.toObject = function(opt_includeInstance) {
  return proto.sip.resources.MailSpecialUserConfigurationsResource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sip.resources.MailSpecialUserConfigurationsResource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.MailSpecialUserConfigurationsResource.toObject = function(includeInstance, msg) {
  var f, obj = {
    prestigeName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    account: jspb.Message.getFieldWithDefault(msg, 2, ""),
    quota: jspb.Message.getFieldWithDefault(msg, 3, 0),
    primaryEmailAddress: jspb.Message.getFieldWithDefault(msg, 4, ""),
    emailAliasesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    hiddenInEthAddressBook: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sip.resources.MailSpecialUserConfigurationsResource}
 */
proto.sip.resources.MailSpecialUserConfigurationsResource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sip.resources.MailSpecialUserConfigurationsResource;
  return proto.sip.resources.MailSpecialUserConfigurationsResource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sip.resources.MailSpecialUserConfigurationsResource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sip.resources.MailSpecialUserConfigurationsResource}
 */
proto.sip.resources.MailSpecialUserConfigurationsResource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrestigeName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuota(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryEmailAddress(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmailAliases(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHiddenInEthAddressBook(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sip.resources.MailSpecialUserConfigurationsResource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sip.resources.MailSpecialUserConfigurationsResource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sip.resources.MailSpecialUserConfigurationsResource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sip.resources.MailSpecialUserConfigurationsResource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrestigeName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuota();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPrimaryEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmailAliasesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getHiddenInEthAddressBook();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string prestige_name = 1;
 * @return {string}
 */
proto.sip.resources.MailSpecialUserConfigurationsResource.prototype.getPrestigeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.MailSpecialUserConfigurationsResource} returns this
 */
proto.sip.resources.MailSpecialUserConfigurationsResource.prototype.setPrestigeName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account = 2;
 * @return {string}
 */
proto.sip.resources.MailSpecialUserConfigurationsResource.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.MailSpecialUserConfigurationsResource} returns this
 */
proto.sip.resources.MailSpecialUserConfigurationsResource.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 quota = 3;
 * @return {number}
 */
proto.sip.resources.MailSpecialUserConfigurationsResource.prototype.getQuota = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sip.resources.MailSpecialUserConfigurationsResource} returns this
 */
proto.sip.resources.MailSpecialUserConfigurationsResource.prototype.setQuota = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string primary_email_address = 4;
 * @return {string}
 */
proto.sip.resources.MailSpecialUserConfigurationsResource.prototype.getPrimaryEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sip.resources.MailSpecialUserConfigurationsResource} returns this
 */
proto.sip.resources.MailSpecialUserConfigurationsResource.prototype.setPrimaryEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string email_aliases = 5;
 * @return {!Array<string>}
 */
proto.sip.resources.MailSpecialUserConfigurationsResource.prototype.getEmailAliasesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sip.resources.MailSpecialUserConfigurationsResource} returns this
 */
proto.sip.resources.MailSpecialUserConfigurationsResource.prototype.setEmailAliasesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sip.resources.MailSpecialUserConfigurationsResource} returns this
 */
proto.sip.resources.MailSpecialUserConfigurationsResource.prototype.addEmailAliases = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sip.resources.MailSpecialUserConfigurationsResource} returns this
 */
proto.sip.resources.MailSpecialUserConfigurationsResource.prototype.clearEmailAliasesList = function() {
  return this.setEmailAliasesList([]);
};


/**
 * optional bool hidden_in_eth_address_book = 6;
 * @return {boolean}
 */
proto.sip.resources.MailSpecialUserConfigurationsResource.prototype.getHiddenInEthAddressBook = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sip.resources.MailSpecialUserConfigurationsResource} returns this
 */
proto.sip.resources.MailSpecialUserConfigurationsResource.prototype.setHiddenInEthAddressBook = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * @enum {number}
 */
proto.sip.resources.GlobalScope = {
  GLOBAL_SCOPE_UNSPECIFIED: 0,
  ADMIN: 1,
  RESOURCES_LIST_GLOBAL: 2,
  PERMISSIONS_LIST_GLOBAL: 3,
  CHANGES_LIST_GLOBAL: 4
};

/**
 * @enum {number}
 */
proto.sip.resources.GroupSpecificScope = {
  GROUP_SPECIFIC_SCOPE_UNSPECIFIED: 0,
  RESOURCES_LIST: 1,
  RESOURCES_CREATE: 2,
  RESOURCES_UPDATE: 3,
  RESOURCES_DELETE: 4,
  PERMISSIONS_LIST: 5,
  PERMISSIONS_CREATE: 6,
  PERMISSIONS_UPDATE: 7,
  PERMISSIONS_DELETE: 8,
  CHANGES_LIST: 9,
  CHANGES_CREATE: 10,
  CHANGES_UPDATE: 11,
  CHANGES_DELETE: 12
};

/**
 * @enum {number}
 */
proto.sip.resources.ResourceType = {
  RESOURCE_TYPE_UNSPECIFIED: 0,
  MAIL_GLOBAL: 1,
  MAIL_SERVICE: 2,
  MAIL_DISTRIBUTION_LIST: 3,
  MAIL_SHARED_MAILBOX: 4,
  MAIL_CONTACT: 5,
  ETH_IAM_GROUP: 6,
  SYMPA_MAILING_LIST: 7,
  GSUITE_SHARED_DRIVE: 8,
  CONFLUENCE_SPACE: 9,
  GITLAB_GROUP: 10,
  GROUP_TAG: 11,
  KEYCLOAK_CLIENT: 12,
  NAS_STORAGE_FOLDER: 13,
  GOOGLE_CALENDAR: 14,
  OFFICE_KEY: 15,
  KUBERNETES_PERMISSION: 16,
  MAIL_SPECIAL_USER_CONFIGURATION: 17
};

/**
 * @enum {number}
 */
proto.sip.resources.ResourceState = {
  RESOURCE_STATE_UNSPECIFIED: 0,
  CREATED: 1,
  DELETED: 2
};

/**
 * @enum {number}
 */
proto.sip.resources.ProposedChangeState = {
  PROPOSED_CHANGE_STATE_UNDEFINED: 0,
  OPEN: 1,
  ACCEPTED: 2,
  DENIED: 3
};

/**
 * @enum {number}
 */
proto.sip.resources.MailboxQuota = {
  MAILBOX_QUOTA_UNSPECIFIED: 0,
  GB_1: 1,
  GB_5: 2,
  GB_10: 3,
  GB_15: 4
};

goog.object.extend(exports, proto.sip.resources);
