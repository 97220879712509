import { Anchor, Skeleton, Text, Tooltip } from "@mantine/core";
import { useGetUserQuery } from "../features/groups/groupsSlice";

/* UserLink displays a link to an user
 currently not a link, but can be made to link to personenorakel */
export const UserLink = ({ user }: { user: string }) => {
  const fullUserId = (user.search("users/") === -1) ? "users/" + user : user;

  const {
    data: userData,
    isLoading: userDataLoading,
    isFetching: userDataFetching,
  } = useGetUserQuery(
    {
      userName: fullUserId,
    }, {}
  );

  return ((userData) ?
    <Tooltip label={userData.givenName + " " + userData.familyName}>
      <Anchor>{userData.username}</Anchor>
    </Tooltip>
    :
    ((userDataLoading || userDataFetching) ?
      <Skeleton height={8} mt={6} radius="xl" />
      :
      <Text>{user} (not found)</Text>
    )
  );
};
