import { Switch, Table } from "@mantine/core";
import { getIn } from "formik";

export interface InfoTableElement<T extends object> {
  title: string;
  key: keyof T;
  formatter?: (value: any, key: keyof T) => JSX.Element | string;
  sensitiveHidden?: boolean;
}

interface ITBProps<T extends object> {
  data: T;
  elements: InfoTableElement<T>[];
  enableToggles?: boolean;
}

// InfoTableBase can be used to display elements of an object in a simple table
export const InfoTableBase = <T extends object>({
  data,
  elements,
  enableToggles,
}: ITBProps<T>) => {
  const rows = elements.map((ele) => {
    let val = getIn(data, ele.key.toString().split('.'));
    return (
      <tr key={ele.key && String(ele.key)}>
        <td>
          <b>{ele.title}</b>
        </td>
        {ele.formatter ? (
          <td>{ele.formatter(val, ele.key)}</td>
        ) : (
          <td>{String(val)}</td>
        )}
        {
          enableToggles && <td> <Switch disabled /> </td>
        }
      </tr>
    );
  });

  /* <thead style={{ fontSize: "0.875rem", padding: "0.4375rem 0.625rem"}}><td></td><td></td><td><b>Request data</b></td></thead> */
  return (
    <Table>
      <tbody>{rows}</tbody>
    </Table>
  );
};
