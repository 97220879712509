import { InfoTableBase } from "../../../components/InfoTableBase";
import { MailDomainInfo, Resource } from "../resourcesSlice";
import { GetStringForMailboxQuota } from "../../../util/resourcesProto";
import {
  EditableResourceDetailsProps,
  domainInfoElementValidator,
  domainInfoTableElements,
  mailDomainInfoArray,
  mailboxQuotaField,
  yumTestArrayUniqueness,
} from "./MailResourceHelpers";
import {
  ResourceDetailCardBase,
  TableFormElement,
} from "./ResourceDetailCardBase";
import { regexConstants } from "../../../util/regexConstants";

import * as Yup from "yup";

const tableElements: TableFormElement<Resource>[] = [
  {
    title: "Org Prefix",
    key: "mailGlobal.orgPrefix" as keyof Resource,
    validation: Yup.string().matches(regexConstants.resource.orgPrefix, "Must be 3-6 lowercase letters"),
  },
  {
    title: "Mailbox Quota",
    key: "mailGlobal.mailboxQuota" as keyof Resource,
    formatter: GetStringForMailboxQuota,
    customFieldInputElement: mailboxQuotaField,
  },
  {
    title: "Shared Mailbox Quota",
    key: "mailGlobal.sharedMailboxQuota" as keyof Resource,
    formatter: GetStringForMailboxQuota,
    customFieldInputElement: mailboxQuotaField,
  },
  {
    title: "Shared Mailbox Owner",
    key: "mailGlobal.sharedMailboxOwner" as keyof Resource,
  },
  {
    title: "Domains",
    key: "mailGlobal.domainsList" as keyof Resource,
    formatter: (domains: MailDomainInfo[]) => (
      <div>
        {domains.map((domain: MailDomainInfo) => (
          <InfoTableBase data={domain} elements={domainInfoTableElements} key={domain.domain}/>
        ))}
      </div>
    ),
    completelyCustomField: (key, formik) =>
      mailDomainInfoArray(key as keyof Resource, formik.values.mailGlobal?.domainsList || [], formik.errors, true),
    validation: Yup.array()
      .of(Yup.object(domainInfoElementValidator))
      .max(5, `Maximum of 5 entries allowed`)
      .test('unique', "Duplicate email entry", yumTestArrayUniqueness),
  },
  {
    title: "Display Name Suffix",
    key: "mailGlobal.displayNameSuffix" as keyof Resource,
  },
];

export const MailGlobal = (
  props: EditableResourceDetailsProps<Resource>
) => (
  <ResourceDetailCardBase {...props}>{tableElements}</ResourceDetailCardBase>
)

export const mailGlobalTableElements = tableElements;