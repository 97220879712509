import { ActionIcon, Group, Popover, Text } from "@mantine/core";
import { HelpIcon } from "vseth-canine-ui";
import { useState } from "react";
import { ResourceTypeInfoRole, useListResourceTypesInfoQuery } from "../features/resources/resourcesSlice";

// ResourceRole displays the name and a hint about a role on a resource
export const ResourceRole = ({ resourceRoleName }: { resourceRoleName: string }) => {
  const [opened, setOpened] = useState(false);

  const resourceName = resourceRoleName.split("/roles")[0]

  // get resource type info, cached result to prevent requesting same data multiple times   
  const { resourceTypeInfo } =
    useListResourceTypesInfoQuery(undefined, {
      selectFromResult: ({ data, isLoading }) => ({
        isLoading,
        resourceTypeInfo:
          data?.filter((t) => t.name === resourceName) || [],
      }),
    });

  const roleInfo = resourceTypeInfo && resourceTypeInfo[0] && resourceTypeInfo[0].resourceRolesList.filter((rr: ResourceTypeInfoRole) => (rr.name === resourceRoleName))[0];

  const description = roleInfo?.description;
  const displayName = roleInfo ? roleInfo.displayName : resourceRoleName;

  return (
    <Group position="apart">
      <Text>{displayName}</Text>
      <Popover position="bottom" withArrow shadow="md" opened={opened} onChange={setOpened}>
        <Popover.Target>
          <ActionIcon onClick={() => setOpened(!opened)}>
            <HelpIcon size={16} />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown>
          <Text size="sm">{description}</Text>
        </Popover.Dropdown>
      </Popover>
    </Group>
  );
};
