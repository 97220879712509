import React from "react";
import { LoadingOverlay } from "@mantine/core";

interface LWProps {
  isLoading?: boolean;
  children: React.ReactNode;
}

export const LoadWrapper = ({ isLoading, children }: LWProps) => {
  if (isLoading) {
    return (
      <div>
        {/*<p>Data is loading</p>*/}
        <LoadingOverlay visible/>
      </div>
    );
  }

  return <>{children}</>;
};
