import { Modal } from "@mantine/core";
import { GetIdentifierFromName } from "../../util/peopleProto";
import { CreatePermissionForm } from "../../components/CreatePermissionForm";
import {
  useCreateResourcePermissionMutation,
  useListResourceTypesInfoQuery,
} from "./resourcesSlice";
import { Group, useGetGroupQuery, useListGroupsQuery, useListPeopleAuthorizationRolesQuery } from "../groups/groupsSlice";
import { useSelector } from "react-redux";
import { isGpk, isParentOf, selectAccessToken, selectIsAdmin, selectUserRoles } from "../auth/authSlice";
import { usePeopleClient } from "../../app/store";
import { getSearchByUsernameFunction } from "../../components/SelectUsers";
import { LoadWrapper } from "../../components/LoadWrapper";
import { ResourceType } from "../../proto/sip/resources/resources_pb";
import { Group as GroupMessage } from "../../proto/sip/people/people_pb";

interface CPMProps {
  isOpen: boolean;
  resourceName: string;
  resourceType: ResourceType;
  ownerGroup: string;
  onCancel: () => void;
}

// CreatePermissionModal opens a modal where the user can create a new permission on a resource
export const CreatePermissionModal = ({
  isOpen,
  resourceName,
  resourceType,
  ownerGroup,
  onCancel,
}: CPMProps) => {
  // load the options for the group selection
  const { data: groups, isLoading: groupsLoading } = useListGroupsQuery({ withTags: true });

  const { data: peopleRoles } = useListPeopleAuthorizationRolesQuery(undefined);
  const userRoles = useSelector(selectUserRoles);
  const isAdmin = selectIsAdmin(peopleRoles, userRoles);
  const userIsGpk = isGpk(userRoles);
  const { data: gpkGroup } = useGetGroupQuery({ name: "groups/vseth-1001-gpk", view: GroupMessage.View.BASIC }, { skip: !userIsGpk });

  const {
    data: ownerGroupData,
  } = useGetGroupQuery(
    {
      name: ownerGroup,
      view: GroupMessage.View.INCLUDE_SUBGROUPS,
    },
  );

  const allowedGroups = userIsGpk ? [gpkGroup as Group] :
    (isAdmin ? groups : groups?.filter((group) => {
      return (isParentOf(ownerGroup, group.name, ownerGroupData) || ownerGroup === group.name);
    }));

  // load the different roles for the resource type
  const { resourceTypeInfo, isLoading: resourceTypeLoading } =
    useListResourceTypesInfoQuery(undefined, {
      selectFromResult: ({ data, isLoading }) => ({
        isLoading,
        resourceTypeInfo:
          data?.filter((t) => t.resourceType === resourceType) || [],
      }),
    });

  const [addResourcePermission, { isLoading: isUpdating }] =
    useCreateResourcePermissionMutation();

  const token = useSelector(selectAccessToken) || "";
  const peopleClient = usePeopleClient();

  const modalTitle = "Add permission to resource " + GetIdentifierFromName(resourceName);

  return (
    <Modal opened={isOpen} title={modalTitle} onClose={onCancel}
      styles={theme => ({   // fix cut off overflow of user select
        header: { borderRadius: theme.spacing.xs },
        inner: {
          '> section': {
            overflowY: 'visible',
          },
          '.mantine-ScrollArea-root': {
            overflow: 'visible',
          },
          '.mantine-ScrollArea-viewport': {
            overflow: 'visible !important',
          },
        },
      })}

    >
      <LoadWrapper
        isLoading={groupsLoading || resourceTypeLoading}
      >
        <CreatePermissionForm
          resourceName={resourceName}
          onCancel={onCancel}
          groups={allowedGroups || []}
          resourceTypeInfo={resourceTypeInfo[0]}
          loadUsers={getSearchByUsernameFunction(peopleClient, token)}
          isUpdating={isUpdating}
          onSubmit={(permission, auditMessage) => {
            addResourcePermission({ resourceName, permission, auditMessage })
              .unwrap()
              .then(onCancel);
          }}
        />
      </LoadWrapper>
    </Modal>
  );
};
