import React from "react";
import {
  Card,
  Table,
} from '@mantine/core';
import { Link, useNavigate } from "react-router-dom";
import { LoadWrapper } from "./LoadWrapper";
import { DownIcon, UpIcon } from "vseth-canine-ui";

export interface JsxValueElement {
  title: string;
  value?: string;
  formatter?: (val?: string) => JSX.Element | string;
}

interface DCBProps {
  title: string; // the title of the card, e.g. "Group Information"

  inFocus: boolean; // whether this detail card is in focus
  inFocusKey: string; // the key of the element

  baseUrl: string; // base url is used to create the page links

  children: React.ReactNode; // children are displayed when the card is extended
  overviewElements: JsxValueElement[];

  notAllowedToViewCard?: boolean; // set to true, to show a message that the user is not allowed to view this data.
  isLoading?: boolean;
}

// DetailCardBase is general component that can be used to display some detailed information about a resource, based
// on the Card component
export const DetailCardBase = ({
  inFocus,
  inFocusKey,
  overviewElements,
  baseUrl,
  children,
  title,
  notAllowedToViewCard,
  isLoading,
}: DCBProps) => {
  const navigate = useNavigate();

  const header = overviewElements.map((element) => (
    <th key={element.title}><b>{element.title}</b></th>
  ));

  const rows = overviewElements.map((element) => {
    // by default use the value provided, but if a formatter function was provided
    // use the value returned by the function.
    let jsx: JSX.Element | string = element.value || "";
    if (element.formatter !== undefined) {
      jsx = element.formatter(element.value);
    }
    return (
      <td key={element.title}>
        {jsx}
      </td>
    );
  });

  return (
    <Card withBorder mb="md" p={28} pt={32} pb={32} shadow="sm"
      onClick={() => {
        if (!inFocus) {
          navigate(`?${inFocusKey}=true`);
        }
      }}
    >
      <Card.Section mt="md" inheritPadding
        onClick={() => {
          if (inFocus) {
            navigate("");
          }
        }}
      >
        <small className="float-right">
          <Link to={inFocus ? "" : `?${inFocusKey}=true`}>
            {inFocus ? (
              <UpIcon size={16} />
            ) : (
              <DownIcon size={16} />
            )}
          </Link>
        </small>
        <h6>
          {title}
        </h6>
      </Card.Section>

      <Card.Section inheritPadding>
        {notAllowedToViewCard ? (
          <p>You are not allowed to view this information.</p>
        ) : (
          <LoadWrapper isLoading={isLoading}>
            {!inFocus ? (
              <Table>
                  <thead><tr>{header}</tr></thead>
                  <tbody><tr>{rows}</tr></tbody>
              </Table>
            ) : (
              children
            )}
          </LoadWrapper>
        )}
      </Card.Section>
    </Card>
  );
};
