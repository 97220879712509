import {
  AuthorizationRole as AuthorizationRoleMessage,
  ConfluenceSpaceResource as ConfluenceSpaceResourceMessage,
  CreateProposedChangeRequest,
  CreateResourcePermissionRequest,
  CreateResourceRequest,
  DeleteProposedChangeRequest,
  DeleteResourcePermissionRequest,
  DeleteResourceRequest,
  GetResourceRequest,
  GroupBasedScope as GroupBasedScopeMessage,
  GSuiteSharedDriveResource as GSuiteSharedDriveResourceMessage,
  ListAuditLogResponse,
  ListAuthorizationRolesResponse,
  ListProposedChangesRequest,
  ListProposedChangesResponse,
  ListResourcesRequest,
  ListResourcesResponse,
  ListResourceTypesResponse,
  MailContactResource as MailContactResourceMessage,
  MailDistributionListResource as MailDistributionListResourceMessage,
  MailServiceResource as MailServiceResourceMessage,
  MailSharedMailboxResource as MailSharedMailboxResourceMessage,
  ProposedChange as ProposedChangeMessage,
  Resource as ResourceMessage,
  ResourcePermission as ResourcePermissionMessage,
  ResourceTypeInfo as ResourceTypeInfoMessage,
  UpdateProposedChangeRequest,
  UpdateResourceRequest,
} from "../../proto/sip/resources/resources_pb";

import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import { ResourcesPromiseClient } from "../../proto/sip/resources/resources_grpc_web_pb";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { Group, GrpcBaseQueryArgs, GrpcQueryArgs } from "../groups/groupsSlice";
import { Empty } from "../../proto/google/protobuf/empty_pb";
import {
  getResourceNameFromResourcePermissionName,
  proposedChangeFilterToProposedChangeFilterMessage,
  proposedChangeToProposedChangeMessage,
  resourcePermissionToResourcePermissionMessage,
  resourceToResourceMessage,
} from "../../util/resourcesProto";
import { FieldMask } from "google-protobuf/google/protobuf/field_mask_pb";
import { Metadata } from "grpc-web";
import { AuthSliceRoot } from "../auth/authSlice";
import { getAuthMetadata } from "../../util/peopleProto";
import { MOCK_DISABLE } from "../../app/dev";
import { AuditLogEntry, ListAuditLogEntriesRequest, ListAuditLogEntriesResponse } from "../../proto/sip/people/people_pb";

export type ResourceTypeInfo = ResourceTypeInfoMessage.AsObject;
export type ResourceTypeInfoRole =
  ResourceTypeInfoMessage.ResourceRole.AsObject;
export type Resource = ResourceMessage.AsObject;
export type ProposedChange = ProposedChangeMessage.AsObject;
export type ResourcePermission = ResourcePermissionMessage.AsObject;

export type MailServiceResource = MailServiceResourceMessage.AsObject;
export type MailDistributionListResource =
  MailDistributionListResourceMessage.AsObject;
export type MailSharedMailboxResource =
  MailSharedMailboxResourceMessage.AsObject;
export type MailContactResource = MailContactResourceMessage.AsObject;
export type GSuiteSharedDriveResource =
  GSuiteSharedDriveResourceMessage.AsObject;
export type ConfluenceSpaceResource = ConfluenceSpaceResourceMessage.AsObject;

export type MailDomainInfo = MailServiceResourceMessage.MailDomainInfo.AsObject;
export const PrincipalType = ResourcePermissionMessage.PrincipalType;

export type ResourcesAuthorizationRole = AuthorizationRoleMessage.AsObject;
export type GroupBasedScope = GroupBasedScopeMessage.AsObject;

interface ExtraOptions {
  client: ResourcesPromiseClient;
}

type Meta = {
  requestId: string;
};

interface CreateResourcePermissionArgs {
  resourceName: string;
  permission: ResourcePermission;
  auditMessage: string;
}

interface DeleteResourcePermissionArgs {
  name: string;
  auditMessage: string;
}

interface CreateResourceArgs {
  resource: Resource;
  auditMessage: string;
}

interface UpdateResourceArgs {
  resource: Resource;
  updatePaths: string[];
  auditMessage: string;
}

interface UpdateProposedChangeArgs {
  proposedChange: ProposedChange;
  updatePaths: string[];
  auditMessage: string;
}

interface DeleteResourceArgs {
  name: string;
  auditMessage: string;
}

interface ListProposedChangesArgs {
  filter?: ProposedChangeMessage.Filter.AsObject | {};
  pageSize?: number;
  pageToken?: string;
}

export type WithAuditMessage<T> = { requestAuditMessage: string } & T;

const getGrpcBaseQuery =
  ({
    client,
    prepareMetadata,
  }: GrpcBaseQueryArgs<ResourcesPromiseClient>): BaseQueryFn<
    GrpcQueryArgs<ResourcesPromiseClient>,
    unknown,
    FetchBaseQueryError,
    {} | ExtraOptions,
    Meta & FetchBaseQueryMeta
  > =>
    async (req, api) => {
      let metadata: Metadata = {};
      if (prepareMetadata) {
        metadata = prepareMetadata(metadata, api);
      }

      const res = client[req.method](req.message as any, metadata);

      return res
        .then((result) => {
          return { data: result };
        })
        .catch((err) => {
          return { error: err };
        });
    };

let client = new ResourcesPromiseClient(
  "https://resources-manager.web.api.getsip.ethz.ch",
  {},
  {}
);
// let client = new ResourcesPromiseClient('http://localhost:8080', {}, {});

// only add the mock client in a development setup
if (process.env.NODE_ENV === "development" && !MOCK_DISABLE) {
  const ResourcesPromiseClientMock =
    require("../../mock/ResourcePromiseClientMock").ResourcePromiseClientMock;
  client = new ResourcesPromiseClientMock();
}

// todo: can use caching behavior with "extended / basic"  as part of the id to only refetch the right thing
// the provides tags can then be a function that returns "basic-$name"  and "details-$name"  for a "get query"

export const resourcesApi = createApi({
  reducerPath: "resourcesApi",
  baseQuery: getGrpcBaseQuery({
    client,
    prepareMetadata: (metadata, { getState }) => {
      const token = (getState() as AuthSliceRoot).auth.tokens?.token;
      return {
        ...metadata,
        ...getAuthMetadata(token),
      };
    },
  }),
  tagTypes: ["ResourceTypeInfo", "Resources", "ResourcesAuthorizationRole", "ProposedChanges"],
  endpoints: (builder) => ({
    listResourcesAuthorizationRoles: builder.query<
      ResourcesAuthorizationRole[],
      void
    >({
      query: () => ({
        method: "listAuthorizationRoles",
        message: new Empty(),
      }),
      transformResponse: (res: ListAuthorizationRolesResponse) =>
        res && res.getRolesList().map((r) => r.toObject()),
      providesTags: () => [{ type: "ResourcesAuthorizationRole", id: "LIST" }],
    }),
    listResourceTypesInfo: builder.query<ResourceTypeInfo[], void>({
      query: () => ({
        method: "listResourceTypes",
        message: new Empty(),
      }),
      transformResponse: (res: ListResourceTypesResponse) =>
        res && res.getResourceTypesList().map((r) => r.toObject()),
      providesTags: () => [{ type: "ResourceTypeInfo", id: "LIST" }],
    }),
    listResources: builder.query<Resource[], void>({
      query: () => {
        const message = new ListResourcesRequest();
        message.setView(ResourceMessage.View.INCLUDE_PERMISSIONS);
        return {
          method: "listResources",
          message: message,
        };
      },
      transformResponse: (res: ListResourcesResponse) =>
        res && res.getResourcesList().map((r) => r.toObject()),
      providesTags: (result: Resource[] | undefined) =>
        result
          ? [
            ...result.map(
              ({ name }) => ({ type: "Resources", id: name } as const)
            ),
            { type: "Resources", id: "LIST" },
          ]
          : [{ type: "Resources", id: "LIST" }],
    }),
    getResource: builder.query<Resource, string>({
      query: (name) => {
        const message = new GetResourceRequest();
        message.setName(name);
        message.setView(ResourceMessage.View.INCLUDE_PERMISSIONS);
        return {
          message,
          method: "getResource",
        };
      },
      transformResponse: (res: ResourceMessage) => res && res.toObject(),
      providesTags: (res, error, name) => [{ type: "Resources", id: name }],
    }),
    createResource: builder.mutation<Resource, CreateResourceArgs>({
      query: ({ resource, auditMessage }) => {
        const message = new CreateResourceRequest();
        const resourceMessage = resourceToResourceMessage(resource);
        resourceMessage.setPermissionsList([]);
        message.setResource(resourceMessage);
        message.setAuditMessage(auditMessage);
        return {
          message,
          method: "createResource",
        };
      },
      transformResponse: (res: ResourceMessage) => res && res.toObject(),
      invalidatesTags: [{ type: "Resources", id: "LIST" }],
    }),
    updateResource: builder.mutation<Resource, UpdateResourceArgs>({
      query: ({ resource, updatePaths, auditMessage }) => {
        const message = new UpdateResourceRequest();

        const resourceMessage = resourceToResourceMessage(resource);
        // set the permission list to empty

        resourceMessage.setPermissionsList([]);
        message.setResource(resourceToResourceMessage(resource));
        const mask = new FieldMask();
        mask.setPathsList(updatePaths);
        message.setUpdateMask(mask);
        message.setAuditMessage(auditMessage);
        return {
          message,
          method: "updateResource",
        };
      },
      transformResponse: (res: ResourceMessage) => res && res.toObject(),
      invalidatesTags: (res, error, { resource }) => [
        { type: "Resources", id: resource.name },
      ],
    }),
    deleteResource: builder.mutation<Resource, DeleteResourceArgs>({
      query: ({ name, auditMessage }) => {
        const message = new DeleteResourceRequest();
        message.setName(name);
        message.setAuditMessage(auditMessage);
        return {
          message,
          method: "deleteResource",
        };
      },
      invalidatesTags: (res, error, { name }) => [
        { type: "Resources", id: name },
      ],
    }),
    createResourcePermission: builder.mutation<
      ResourcePermission,
      CreateResourcePermissionArgs
    >({
      query: ({ resourceName, permission, auditMessage }) => {
        const message = new CreateResourcePermissionRequest();
        message.setResourceName(resourceName);
        message.setPermission(
          resourcePermissionToResourcePermissionMessage(permission)
        );
        message.setAuditMessage(auditMessage);
        return {
          message,
          method: "createResourcePermission",
        };
      },
      transformResponse: (res: ResourcePermissionMessage) =>
        res && res.toObject(),
      invalidatesTags: (res, error, { resourceName }) => [
        { type: "Resources", id: resourceName },
      ],
    }),
    deleteResourcePermission: builder.mutation<void, DeleteResourcePermissionArgs>({
      query: ({ name, auditMessage }) => {
        const message = new DeleteResourcePermissionRequest();
        message.setName(name);
        message.setAuditMessage(auditMessage);
        return {
          message,
          method: "deleteResourcePermission",
        };
      },
      invalidatesTags: (res, error, { name }) => [
        {
          type: "Resources",
          id: getResourceNameFromResourcePermissionName(name),
        },
      ],
    }),
    listProposedChanges: builder.query<ProposedChange[], ListProposedChangesArgs>({
      query: ({ filter, pageSize = undefined, pageToken = undefined }) => {
        const message = new ListProposedChangesRequest();
        const filterM = filter && proposedChangeFilterToProposedChangeFilterMessage(filter) as ProposedChangeMessage.Filter;
        if (filterM)
          message.setFilter(filterM);
        if (pageSize)
          message.setPageSize(pageSize);
        if (pageToken)
          message.setPageToken(pageToken);

        return {
          method: "listProposedChanges",
          message: message,
        };
      },
      transformResponse: (res: ListProposedChangesResponse) =>
        res && res.getChangesList().map((c) => c.toObject()),
      providesTags: (result: ProposedChange[] | undefined) =>
        result
          ? [
            ...result.map(
              ({ name }) => ({ type: "ProposedChanges", id: name } as const)
            ),
            { type: "ProposedChanges", id: "LIST" },
          ]
          : [{ type: "ProposedChanges", id: "LIST" }],
    }),
    createProposedChange: builder.mutation<ProposedChange, {pc: ProposedChange, auditMsg: string}>({
      query: ({pc: proposedChange, auditMsg}) => {
        const message = new CreateProposedChangeRequest();
        const proposedChangeMessage = proposedChangeToProposedChangeMessage(proposedChange);
        message.setChange(proposedChangeMessage);
        message.setAuditMessage(auditMsg);
        return {
          message,
          method: "createProposedChange",
        };
      },
      transformResponse: (pc: ProposedChangeMessage) =>
        pc && pc.toObject(),
      invalidatesTags: (res, error, {}) => [
        { type: "ProposedChanges", id: "LIST" },
      ],
    }),
    updateProposedChange: builder.mutation<ProposedChange, UpdateProposedChangeArgs>({
      query: ({ proposedChange, updatePaths, auditMessage }) => {
        const message = new UpdateProposedChangeRequest();

        const proposedChangeMessage = proposedChangeToProposedChangeMessage(proposedChange);
        // set the permission list to empty

        const mask = new FieldMask();
        mask.setPathsList(updatePaths);
        message.setUpdateMask(mask);
        message.setChange(proposedChangeMessage);
        message.setAuditMessage(auditMessage);
        return {
          message,
          method: "updateProposedChange",
        };
      },
      transformResponse: (pc: ProposedChangeMessage) => pc && pc.toObject(),
      invalidatesTags: (pc, error, { proposedChange }) => [
        { type: "ProposedChanges", id: proposedChange.name },
      ],
    }),
    deleteProposedChange: builder.mutation<void, {name: string, auditMsg: string}>({
      query: ({name, auditMsg}) => {
        const message = new DeleteProposedChangeRequest();
        message.setName(name);
        message.setAuditMessage(auditMsg);
        return {
          message,
          method: "deleteProposedChange",
        };
      },
      invalidatesTags: (pc, error, {name}) => [
        {
          type: "ProposedChanges",
          id: name,
        },
      ],
    }),
    listAuditLog: builder.query<ListAuditLogResponse.AsObject, void>({
      query: () => {
        const message = new ListAuditLogEntriesRequest();
        message.setPageSize(10);
        message.setView(AuditLogEntry.View.AUDIT_LOG_ENTRY_VIEW_UNSPECIFIED);
        message.setFilter(new AuditLogEntry.Filter());
        return {
          method: "listAuditLog",
          message: message,
        };
      },
      transformResponse: (laler: ListAuditLogResponse) => laler && laler.toObject(),
    }),
  }),
});

export const {
  useListResourcesAuthorizationRolesQuery,
  useListResourceTypesInfoQuery,
  useGetResourceQuery,
  useListResourcesQuery,
  useCreateResourceMutation,
  useDeleteResourceMutation,
  useUpdateResourceMutation,
  useCreateResourcePermissionMutation,
  useDeleteResourcePermissionMutation,
  useCreateProposedChangeMutation,
  useDeleteProposedChangeMutation,
  useUpdateProposedChangeMutation,
  useListProposedChangesQuery,
  useListAuditLogQuery,
} = resourcesApi;

// helper function to select the resource permissions that apply to a group.
export const selectResourcePermissionsForGroup = (
  groupName: string,
  { data }: { data?: Resource[] }
) => {
  return (
    data
      ?.map((resource) =>
        resource.permissionsList
          .filter((permission) => permission.principalName === groupName)
          .map((p) => ({ ...p, resourceName: resource.displayName }))
      )
      .flat() || []
  );
};

export const selectInheritedResourcePermissionsForGroup = (
  group: Group,
  { data }: { data?: Resource[] }
) => {
  return (
    data
      ?.map((resource) =>
        resource.permissionsList
          .filter((permission) =>
            group
              ? group?.parentNamesTransitiveList.indexOf(
                permission.principalName
              ) > -1
              : []
          )
          .map((p) => ({ ...p, resourceName: resource.displayName }))
      )
      .flat() || []
  );
};
