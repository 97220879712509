// noinspection TypeScriptValidateTypes

import {
  AuthorizationRole as AuthorizationRoleMessage,
  BatchGetUserRequest,
  BatchGetUserResponse,
  ChangeGroupMembersRequest as ChangeGroupMembersRequestMessage,
  EthStudyInfo as EthStudyInfoMessage,
  GetGroupRequest,
  GetUserRequest,
  Group as GroupMessage,
  GroupObjectType,
  ListAuthorizationRolesResponse,
  ListGroupsRequest,
  ListGroupsResponse,
  ListUsersRequest,
  ListUsersResponse,
  PostalAddress as PostalAddressMessage,
  UpdateGroupRequest,
  User as UserMessage,
  VsethMembershipInfo as VsethMembershipInfoMessage,
} from "../../proto/sip/people/people_pb";
import { Metadata } from "grpc-web";
import { PeoplePromiseClient } from "../../proto/sip/people/people_grpc_web_pb";
import { AuthSliceRoot } from "../auth/authSlice";
import { getAuthMetadata, groupToGroupMessage } from "../../util/peopleProto";
import { FieldMask } from "google-protobuf/google/protobuf/field_mask_pb";
import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import * as jspb from "google-protobuf";
import { regexConstants } from "../../util/regexConstants";
import { ResourcesPromiseClient } from "../../proto/sip/resources/resources_grpc_web_pb";
import { Empty } from "../../proto/google/protobuf/empty_pb";
import { MOCK_DISABLE } from "../../app/dev";

// some general types
export type Group = GroupMessage.AsObject;
export type PostalAddress = PostalAddressMessage.AsObject;

export type User = UserMessage.AsObject;
export type EthStudyInfo = EthStudyInfoMessage.AsObject;
export type VsethMembershipInfo = VsethMembershipInfoMessage.AsObject;

export type PeopleAuthorizationRole = AuthorizationRoleMessage.AsObject;

export interface GrpcBaseQueryArgs<
  T extends PeoplePromiseClient | ResourcesPromiseClient
> {
  client: T;
  prepareMetadata?: (
    metadata: Metadata,
    api: { getState: () => unknown }
  ) => Metadata;
}

export interface GrpcQueryArgs<
  T extends PeoplePromiseClient | ResourcesPromiseClient
> {
  message: jspb.Message;
  method: keyof T;
}

type Meta = {
  requestId: string;
};

const getGrpcBaseQuery =
  ({
    client,
    prepareMetadata,
  }: GrpcBaseQueryArgs<PeoplePromiseClient>): BaseQueryFn<
    GrpcQueryArgs<PeoplePromiseClient>,
    unknown,
    FetchBaseQueryError,
    {},
    Meta & FetchBaseQueryMeta
  > =>
  async (req, api) => {
    let metadata: Metadata = {};
    if (prepareMetadata) {
      metadata = prepareMetadata(metadata, api);
    }

    const res = client[req.method](req.message as any, metadata);

    return res
      .then((result) => {
        return { data: result };
      })
      .catch((err) => {
        return { error: err };
      });
  };

let client = new PeoplePromiseClient(
  "https://people.web.api.getsip.ethz.ch",
  {},
  {}
);
// let client = new PeoplePromiseClient('http://localhost:8081', {}, {});

// only add the mock client in a development setup
if (process.env.NODE_ENV === "development" && !MOCK_DISABLE) {
  const PeopleClientPromiseMock =
    require("../../mock/PeoplePromiseClientMock").PeoplePromiseClientMock;
  client = new PeopleClientPromiseMock();
}

interface GetGroupArgs {
  name: string;
  view: GroupMessage.View;
}

interface UpdateGroupArgs {
  name: string;
  paths: string[];
  group: Group;
  auditMessage: string;
}

type ChangeGroupMembersActionTyp =
  ChangeGroupMembersRequestMessage.ChangeGroupMembersAction;

interface ChangeGroupMembersArgs {
  name: string;
  auditMessage: string;
  userNamesList: string[];
  action: ChangeGroupMembersActionTyp;
}

interface ListUsersArgs {
  usernameFilter?: string;
  groupMembershipFilter?: string[];
}

interface BatchGetUserArgs {
  userNamesList: string[];
  fullView?: boolean;
}

interface GetUserArgs {
  userName: string;
  fullView?: boolean;
}

export const peopleApi = createApi({
  reducerPath: "peopleApi",
  baseQuery: getGrpcBaseQuery({
    client,
    prepareMetadata: (metadata, { getState }) => {
      const token = (getState() as AuthSliceRoot).auth.tokens?.token;
      return {
        ...metadata,
        ...getAuthMetadata(token),
      };
    },
  }),
  tagTypes: ["Groups", "Users", "PeopleAuthorizationRole"],
  endpoints: (builder) => ({
    listPeopleAuthorizationRoles: builder.query<
      PeopleAuthorizationRole[],
      void
    >({
      query: () => ({
        method: "listAuthorizationRoles",
        message: new Empty(),
      }),
      transformResponse: (res: ListAuthorizationRolesResponse) =>
        res && res.getRolesList().map((r) => r.toObject()),
      providesTags: () => [{ type: "PeopleAuthorizationRole", id: "LIST" }],
    }),
    listGroups: builder.query<Group[], {withTags?: boolean} >({
      query: ({ withTags }) => {
        const message = new ListGroupsRequest();
        message.setView(GroupMessage.View.BASIC);
        message.setFilter(new GroupMessage.Filter().setWithTags(withTags || false));
        return {
          method: "listGroups",
          message: message,
        };
      },
      transformResponse: (res: ListGroupsResponse) =>
        res.getGroupsList().map((g) => g.toObject()),
      providesTags: (result: Group[] | undefined) =>
        result
          ? [
              ...result.map(
                ({ name }) => ({ type: "Groups", id: "base-" + name } as const)
              ),
              { type: "Groups", id: "LIST" },
            ]
          : [{ type: "Groups", id: "LIST" }],
    }),
    getGroup: builder.query<Group, GetGroupArgs>({
      query: ({ name, view }) => {
        const message = new GetGroupRequest();
        message.setName(name);
        if (view) {
          message.setView(view);
        }

        return {
          message,
          method: "getGroup",
        };
      },
      transformResponse: (res: GroupMessage) => res.toObject(),
      providesTags: (res, error, { name }) => [
        { type: "Groups", id: "full-" + name },
      ],
    }),
    updateGroup: builder.mutation<Group, UpdateGroupArgs>({
      query: ({ name, paths, group, auditMessage }) => {
        const message = new UpdateGroupRequest();
        message.setName(name);
        if (group) {
          message.setGroup(groupToGroupMessage(group));
        }
        message.setAuditMessage(auditMessage);
        const fieldMask = new FieldMask();
        fieldMask.setPathsList(paths);
        message.setUpdateMask(fieldMask);

        return {
          message,
          method: "updateGroup",
        };
      },
      transformResponse: (res: GroupMessage) => res.toObject(),
      invalidatesTags: (group) =>
        group
          ? [
              { type: "Groups", id: "base-" + group.name },
              { type: "Groups", id: "full-" + group.name },
            ]
          : [],
    }),
    changeGroupMembers: builder.mutation<Group, ChangeGroupMembersArgs>({
      query: ({ name, auditMessage, userNamesList, action }) => {
        const message = new ChangeGroupMembersRequestMessage();
        message.setGroupName(name);
        message.setAuditMessage(auditMessage);
        message.setUserNamesList(userNamesList);
        message.setAction(action);
        return {
          message,
          method: "changeGroupMembers",
        };
      },
      transformResponse: (res: GroupMessage) => res.toObject(),
      invalidatesTags: (group) =>
        group
          ? [
              { type: "Groups", id: "full-" + group.name },
              { type: "Users", id: `LIST-${group.name}` },
            ]
          : [],
    }),
    listUsers: builder.query<User[], ListUsersArgs>({
      query: ({ usernameFilter, groupMembershipFilter }) => {
        const message = new ListUsersRequest();
        message.setView(UserMessage.View.BASIC);
        const filter = new UserMessage.Filter();
        if (usernameFilter && usernameFilter !== "") {
          filter.setUsername(usernameFilter);
        }
        if (groupMembershipFilter && groupMembershipFilter.length > 0) {
          filter.setGroupMembershipTransitiveList(groupMembershipFilter);
        }
        message.setFilter(filter);
        return {
          message,
          method: "listUsers",
        };
      },
      transformResponse: (res: ListUsersResponse) =>
        res.getUsersList().map((user) => user.toObject()),
      providesTags: (users, req, args) =>
        users
          ? [
              ...users.map(
                ({ name }) => ({ type: "Users", id: name } as const)
              ),
              { type: "Users", id: "LIST" },
              { type: "Users", id: `LIST-${args.groupMembershipFilter}` },
            ]
          : [
              { type: "Users", id: "LIST" },
              { type: "Users", id: `LIST-${args.groupMembershipFilter}` },
            ],
    }),
    batchGetUser: builder.query<User[], BatchGetUserArgs>({
      query: ({ userNamesList, fullView }) => {
        const message = new BatchGetUserRequest();
        message.setNamesList(userNamesList);
        if(fullView) {
          message.setView(UserMessage.View.INCLUDE_MEMBERSHIPS);
        } else {
          message.setView(UserMessage.View.BASIC);
        }
        return {
          message,
          method: "batchGetUser",
        };
      },
      transformResponse: (res: BatchGetUserResponse) =>
        res.getUsersList().map((user) => user.toObject()),
      providesTags: (users) =>
        users ? users.map((user) => ({ type: "Users", id: user.name })) : [],
    }),
    getUser: builder.query<User, GetUserArgs>({
      query: ({ userName, fullView }) => {
        const message = new GetUserRequest();
        message.setName(userName);
        if(fullView) {
          message.setView(UserMessage.View.INCLUDE_MEMBERSHIPS);
        } else {
          message.setView(UserMessage.View.BASIC);
        }
        return {
          message,
          method: "getUser",
        };
      },
      transformResponse: (res: UserMessage) =>
        res.toObject(),
      providesTags: (user) => [
        { type: "Users", id: user && user.name },
      ],
    }),
  }),
});

export const {
  useListPeopleAuthorizationRolesQuery,
  useGetGroupQuery,
  useListGroupsQuery,
  useUpdateGroupMutation,
  useChangeGroupMembersMutation,
  useListUsersQuery,
  useBatchGetUserQuery,
  useGetUserQuery,
} = peopleApi;

// selects the direct subgroups of a given group
export const selectDirectSubgroups = (
  parent: string,
  { data }: { data?: Group[] }
): Group[] => {
  return data?.filter((group) => group.parentName === parent) || [];
};

// select all the subgroups (recursive) of a given group
export const selectAllSubgroups = (
  parent: string,
  { data }: { data?: Group[] }
): Group[] => {
  // check if we have a ks group, in this case need to actually filter
  if (parent.match(regexConstants.ksGroup)) {
    const directSubgroups = selectDirectSubgroups(parent, { data });
    return [
      ...directSubgroups,
      ...directSubgroups
        .map((group) => selectAllSubgroups(group.name, { data }))
        .flat(),
    ];
  }

  // otherwise we can directly use the identifier
  return data?.filter((group) => group.name.startsWith(parent + "-")) || [];
};

// builds a map for ou / ks groups to their organization name
export function getOrganizationNameMap(groups: Group[]): {
  [key: string]: string;
} {
  const orgMap: { [key: string]: string } = {};
  groups.forEach((group) => {
    if (group.name.startsWith("groups/vseth-03")) {
      orgMap[group.name] = "Vorstand";
      return;
    }
    if (group.name.startsWith("groups/vseth-07")) {
      orgMap[group.name] = "Vertretungen";
      return;
    }
    if (group.name.startsWith("groups/vseth-16")) {
      orgMap[group.name] = "Mitglieder";
      return;
    }
    if (group.objectType === GroupObjectType.GROUP_OBJECT_TYPE_OU) {
      orgMap[group.name] = group.displayName;
    }
    if (group.objectType === GroupObjectType.GROUP_OBJECT_TYPE_KS) {
      orgMap[group.name] = "VSETH Allgemein";
    }
  });
  return orgMap;
}
