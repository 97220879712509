import React from "react";

import { 
  useAppSelector 
} from "../hooks/useAppSelector"

import { 
  useAppDispatch 
} from "../hooks/useAppDispatch"

import { 
  getAuthStateFromToken,
  selectTokens, 
  setAuthState 
} from "../features/auth/authSlice"

import { ReactNode } from "react"
import { AuthProvider } from "../features/auth/AuthProvider";

//Define interface
type CustomAuthProviderProps = {
  children: ReactNode
}

export const CustomAuthProvider: React.FC<CustomAuthProviderProps> = (props: CustomAuthProviderProps) => {
  const tokens = useAppSelector(selectTokens);

  const dispatch = useAppDispatch();

  return (
    <AuthProvider
      setAuthState={(authState) => {
        dispatch(setAuthState(authState));
      }}
      authState={getAuthStateFromToken(tokens)}
    >
      {props.children}
    </AuthProvider>
  );
};
