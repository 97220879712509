import { Provider } from "react-redux";
import {
  createRoot
} from "react-dom/client"

import App from "./app/App"

import * as serviceWorker from "./serviceWorker";
import "./locals/i18n";
import store from "./app/store";


const root = createRoot(
  document.getElementById("root") as HTMLElement
)


root.render(
  <Provider store={store}>
    <App />
  </Provider>
);


/*
if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./app/App", render);
}
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
