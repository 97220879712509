import { Group } from "../features/groups/groupsSlice";
import { GetIdentifierFromName } from "../util/peopleProto";
import Select from "react-select";

interface SGProps {
  // groups that can be selected
  groups: Group[];
  // initialValue (groupName)
  initialValue?: string;
  // callback when a group has been selected
  setGroupNames?: (g: string[]) => void;
}

// SelectGroup displays a react-select element to select a group from all the groups defined in the system.
export const SelectGroup = ({
  groups,
  setGroupNames,
  initialValue,
}: SGProps) => {
  const options = groups.map((g) => ({
    value: g.name,
    label: GetIdentifierFromName(g.name),
  }));

  return (
    <Select
      defaultValue={{
        value: initialValue || "",
        label: GetIdentifierFromName(initialValue || ""),
      }}
      options={options}
      placeholder={"Select Group..."}
      menuPlacement="bottom"
      maxMenuHeight={200}
      onChange={(selected) => {
        if (setGroupNames) {
          if (selected) {
            if (Array.isArray(selected)) {
              setGroupNames(selected.map((x) => x.value));
            } else {
              const res: string[] = [];
              res.push(selected.value);
              setGroupNames(res);
            }
          } else {
            setGroupNames([]);
          }
        }
      }}
    />
  );
};
