import { useState } from "react";
import { DetailCardProps } from "../../components/DetailPage";
import { ResourceType } from "../../proto/sip/resources/resources_pb";
import { Alert, Button, Group as LayoutGroup, Popover, Space, Text, TextInput, rem } from "@mantine/core";
import { DetailCardBase, JsxValueElement } from "../../components/DetailCardBase";
import {
  Resource,
  ResourcePermission,
  useDeleteResourceMutation,
  useListResourcesAuthorizationRolesQuery,
} from "./resourcesSlice";
import { useNavigate } from "react-router-dom";
import {
  GetStringForResourceState,
  GetStringForResourceType,
} from "../../util/resourcesProto";
import {
  InfoTableBase,
  InfoTableElement,
} from "../../components/InfoTableBase";
import { GetIdentifierFromName } from "../../util/peopleProto";
import { PermissionTable } from "../../components/PermissionTable";
import { ResourceLink } from "../../components/ResourceLink";
import { MailDistributionList, mailDistributionListTableElements } from "./details/MailDistributionList";
import { GsuiteDrive, gsuiteDriveTableElements } from "./details/GsuiteDrive";
import { ConfluenceSpace, confluenceSpaceTableElements } from "./details/ConfluenceSpace";
import { MailSharedMailbox, mailSharedMailboxTableElements } from "./details/MailSharedMailbox";
import { MailGlobal, mailGlobalTableElements } from "./details/MailGlobal";
import { MailContact, mailContactTableElements } from "./details/MailContact";
import { EditResourceDetailsForm } from "../../components/EditResourceDetailsForm";
import { Group } from "../groups/groupsSlice";
import { useSelector } from "react-redux";
import {
  isGpk,
  selectAllowedToApproveProposedChanges,
  selectAllowedToDeleteResource,
  selectAllowedToListPermissions,
  selectUserRoles,
} from "../auth/authSlice";
import { DeleteIcon, TasklistOkIcon } from "vseth-canine-ui";
import { EthIamGroup, ethIamGroupTableElements } from "./details/EthIamGroup";
import { GitlabGroup, gitlabGroupTableElements } from "./details/GitlabGroup";
import { GoogleCalendar, googleCalendarTableElements } from "./details/GoogleCalendar";
import { GroupTag, groupTagTableElements } from "./details/GroupTag";
import { KeycloackClient, keycloackClientTableElements } from "./details/KeycloakClient";
import { KubernetesPermission, kubernetesPermissionTableElements } from "./details/KubernetesPermission";
import { MailSpecialUserConfiguration, mailSpecialUserConfigurationTableElements } from "./details/MailSpecialUserConfigurations";
import { NASStorageFolder, nasStorageFolderTableElements } from "./details/NASStorageFolder";
import { OfficeKey, officeKeyTableElements } from "./details/OfficeKey";
import { SympaMailingList, sympaMailingListTableElements } from "./details/SympaMailingList";
import { UserLink } from "../../components/UserLink";
import { regexConstants } from "../../util/regexConstants";
import { getHotkeyHandler } from "@mantine/hooks";
import { MailService, mailServiceTableElements } from "./details/MailService";
import { omit } from "lodash";
import { selectAuxAuditMessage, setAuxAuditMessage } from "../queries/queriesSlice";
import { useDispatch } from "react-redux";

interface RDProps extends DetailCardProps<Resource> {
  // groups needed for the group selection component
  groups: Group[];

  onUpdateResource: (resource: Resource, auditMessage: string) => Promise<any>;
  // indicates whether the resource is currently updating
  isUpdatingResource: boolean;

  allowedToUpdateResource: boolean;
}

const tableElements: InfoTableElement<Resource>[] = [
  {
    title: "Identifier",
    key: "name",
    formatter: (name: string) => <ResourceLink resourceName={name} key={name} />,
  },
  {
    title: "Display Name",
    key: "displayName",
  },
  {
    title: "Owner Group",
    key: "ownerGroup",
    formatter: (ownerGroup: string) => <ResourceLink resourceName={ownerGroup} />,
  },
  {
    title: "Resource Type",
    key: "resourceType",
    formatter: GetStringForResourceType,
  },
  {
    title: "Description",
    key: "description",
  },
  {
    title: "Resource state",
    key: "resourceState",
    formatter: GetStringForResourceState,
  },
  {
    title: "Last update by",
    key: "lastUpdateBy",
    formatter: (userid: string) => (
      <UserLink user={userid} />
    ),
  },
];

export const ResourceDetailComponent = (props: RDProps) => {
  const { data, allowedToUpdateResource } = props;

  const [isEditing, setIsEditing] = useState(false);

  const overviewElements = [
    {
      title: "Identifier",
      value: GetIdentifierFromName(data.name),
    },
    {
      title: "Description",
      value: data.description,
    },
    {
      title: "Owner Group",
      value: data.ownerGroup,
    },
  ];

  const savedAuditMessage = useSelector(selectAuxAuditMessage);

  const dispatch = useDispatch();

  return (
    <DetailCardBase
      {...props}
      title={"General"}
      overviewElements={overviewElements}
    >
      {isEditing ? (
        <EditResourceDetailsForm
          data={{ ...data, requestAuditMessage: savedAuditMessage }}
          groups={props.groups}
          isUpdating={props.isUpdatingResource}
          onSubmit={(r) =>
            props.onUpdateResource(
              omit(r, "requestAuditMessage"),
              r.requestAuditMessage)
              .then(() => {
                dispatch(setAuxAuditMessage({ text: r.requestAuditMessage })); setIsEditing(false)
              })
          }
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <div>
          <InfoTableBase data={data} elements={tableElements} />
          <Button
            color="primary"
            className="float-right mb-3"
            disabled={!allowedToUpdateResource}
            onClick={() => setIsEditing(true)}
          >
            Edit
          </Button>
        </div>
      )}
    </DetailCardBase>
  );
};

interface RPCProps extends DetailCardProps<Resource> {
  resourceName: string;
  enableAddPermissions?: boolean;
  enableRemovePermissions?: boolean;
  onRemove?: (names: ResourcePermission[], auditMessage: string) => Promise<any>;
}

interface RRCProps extends DetailCardProps<Resource> {
  resourceName: string;
  routeRef?: string;
}

interface RDCProps extends DetailCardProps<Resource> {
  resourceName: string;
  routeRef?: string;
  setIsDeleted: (isDeleted: boolean) => void;
}

export const ResourcePermissionComponent = (props: RPCProps) => {
  const { data } = props;

  const { data: resourceApiRoles } =
    useListResourcesAuthorizationRolesQuery(undefined);

  // get the roles of the user (including debug roles)
  const userRoles = useSelector(selectUserRoles);

  const overviewElements = [
    {
      title: "Count",
      value: data.permissionsList.length.toString(),
    },
  ];

  return (
    <DetailCardBase
      {...props}
      title={"Permissions"}
      overviewElements={overviewElements}
    >
      {selectAllowedToListPermissions(resourceApiRoles, userRoles) || isGpk(userRoles) ?
        <PermissionTable {...props} resourceType={data.resourceType} ownerGroup={data.ownerGroup} permissions={data.permissionsList} />
        : "You are not allowed to list permissions"
      }
    </DetailCardBase>
  );
};

export const ResourceRequestComponent = (props: RRCProps) => {
  const { data } = props;

  const resourceStateString = GetStringForResourceState(data.resourceState);

  const overviewElements = [
    {
      title: "Current state",
      value: resourceStateString,
    },
  ];

  // load the people and resources api authorization information
  const { data: resourceApiRoles } =
    useListResourcesAuthorizationRolesQuery(undefined);

  // get the roles of the user (including debug roles)
  const userRoles = useSelector(selectUserRoles);

  const isAdmin = selectAllowedToApproveProposedChanges(
    resourceApiRoles || [],
    userRoles
  );

  const navigate = useNavigate();

  const backToReqOrRes = () => {
    if (props.routeRef === "/requests") {
      navigate("/requests");
    } else {
      navigate(-1);
    }
  };

  return (
    <DetailCardBase
      {...props}
      title={"Outstanding Request"}
      overviewElements={overviewElements}
    >
      <Text size="sm" p="sm">This resource is a request ({resourceStateString}). It needs to be approved by the ISG.</Text>
      {(isAdmin && <Alert icon={<TasklistOkIcon />} title="Admin approval" color="red">
        <Text size="sm" pb="sm" pt="sm">Do you want to approve this resource request with the properties shown above?
        </Text>
        <LayoutGroup position="apart">
          <Button color="red" onClick={() => navigate(-1)}>Cancel</Button>
          <Button color="green" onClick={() => backToReqOrRes()}>Approve</Button>
        </LayoutGroup>
      </Alert>)}
    </DetailCardBase>
  );
};


export const ResourceDeletionComponent = (props: RDCProps) => {
  const { data } = props;

  const overviewElements: JsxValueElement[] = [];

  const navigate = useNavigate();

  const [auditPromptOpened, setAuditPromptOpened] = useState(false);
  const [deletionAuditMsg, setDeletionAuditMsg] = useState("");

  // load the people and resources api authorization information
  const { data: resourceApiRoles, isLoading: isLoadingResourceRoles } =
    useListResourcesAuthorizationRolesQuery(undefined);

  // get the roles of the user (including debug roles)
  const userRoles = useSelector(selectUserRoles);

  const isAdmin = selectAllowedToApproveProposedChanges(
    resourceApiRoles || [],
    userRoles
  );

  const [deleteResource, { isLoading: isDeletingResource }] =
    useDeleteResourceMutation();

  const backToReqOrRes = () => {
    if (props.routeRef === "/requests") {
      navigate("/requests");
    } else {
      navigate("/resources");
    }

  };

  const handleDeletedResource = () => backToReqOrRes();
  const handleErrorDeleteResource = () => {
    props.setIsDeleted(false);
    console.log("Error deleting resource.");
  };

  const onDeleteClick = (auditMessage: string) => {
    props.setIsDeleted(true);
    const resourceDeletion = deleteResource({ name: data.name, auditMessage });
    resourceDeletion.then(handleDeletedResource, handleErrorDeleteResource);
  };

  const canDelete = isAdmin || selectAllowedToDeleteResource(resourceApiRoles, userRoles, data.ownerGroup);
  const handleSubmitAuditMessage = () => {
    if (regexConstants.group.auditMessage.test(deletionAuditMsg)) {
      onDeleteClick(deletionAuditMsg);
      setAuditPromptOpened(false);
      //setRemovalAuditMsg("");
    }
  };
  const deleteButtonWithStuff = (
    <Popover position="bottom" withArrow shadow="md" opened={auditPromptOpened} onChange={setAuditPromptOpened}
      middlewares={{ flip: true, shift: true, inline: true }}
    >
      <Popover.Target>
        <Button disabled={!canDelete || (auditPromptOpened && !regexConstants.group.auditMessage.test(deletionAuditMsg))}
          loading={isDeletingResource || isLoadingResourceRoles}
          onClick={(e) => {
            if (auditPromptOpened) {
              handleSubmitAuditMessage();
            } else {
              setAuditPromptOpened(true);
            }
          }
          }
          leftIcon={<DeleteIcon />} color="red">
          Delete {props.data.displayName} permanently and immediately
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <LayoutGroup >
          <TextInput required label="Audit message required" placeholder="Enter message"
            value={deletionAuditMsg}
            onChange={(ev) => setDeletionAuditMsg(ev.currentTarget.value)}
            error={(!regexConstants.group.auditMessage.test(deletionAuditMsg) && "Invalid audit message")}
            onKeyDown={getHotkeyHandler([
              ['Enter', (() => {
                handleSubmitAuditMessage();
              })],
            ])}
          />
          <Button color="red" onClick={() => {
            handleSubmitAuditMessage();
          }}>Confirm deletion</Button>
          <Button color="yellow" onClick={() => setAuditPromptOpened(false)}>Cancel</Button>
        </LayoutGroup>
      </Popover.Dropdown>
    </Popover>);

  return (
    <DetailCardBase
      {...props}
      title={"Resource deletion"}
      overviewElements={overviewElements}
    >
      {<Alert icon={<TasklistOkIcon />} title="Delete resource" color="red">
        <Space h={auditPromptOpened ? "3rem" : "0.5rem"} />
        <Text size="sm" pb="sm" pt="sm">Do you want to {!isAdmin && "request to"} delete this Resource?  </Text>
        <LayoutGroup position="apart">
          {deleteButtonWithStuff}
          <Button color="yellow" onClick={() => navigate(-1)}>Cancel</Button>
        </LayoutGroup>
      </Alert>}
    </DetailCardBase>
  );
};

interface RSDProps extends DetailCardProps<Resource> {
  onUpdateResource: (resource: Resource, auditMessage: string) => Promise<any>;
  // indicates whether the resource is currently updating
  isUpdatingResource: boolean;

  allowedToUpdateResource: boolean;
}

export const ResourceSpecificComponent = (props: RSDProps) => {
  const { data, isUpdatingResource, onUpdateResource } = props;

  switch (data.resourceType) {
    case ResourceType.MAIL_GLOBAL:
      if (data.mailGlobal) {
        return (
          <MailGlobal
            {...props}
            key={"specific"}
            data={data}
            isUpdating={isUpdatingResource}
            onUpdate={(data, auditMsg) =>
              onUpdateResource(data, auditMsg)
            }
          />
        );
      }
      break;
    case ResourceType.MAIL_SERVICE:
      if (data.mailService) {
        return (
          <MailService
            {...props}
            key={"specific"}
            data={data}
            isUpdating={isUpdatingResource}
            onUpdate={(data, auditmsg) =>
              onUpdateResource(data, auditmsg)
            }
          />
        );
      }
      break;
    case ResourceType.MAIL_DISTRIBUTION_LIST:
      if (data.mailDistributionList) {
        return (
          <MailDistributionList
            {...props}
            key={"specific"}
            data={data}
            isUpdating={isUpdatingResource}
            onUpdate={(data, auditmsg) =>
              onUpdateResource(data, auditmsg)
            }
          />
        );
      }
      break;
    case ResourceType.MAIL_SHARED_MAILBOX:
      if (data.mailSharedMailbox) {
        return (
          <MailSharedMailbox
            {...props}
            key={"specific"}
            data={data}
            isUpdating={isUpdatingResource}
            onUpdate={(data, auditmsg) =>
              onUpdateResource(data, auditmsg)
            }
          />
        );
      }
      break;
    case ResourceType.MAIL_CONTACT:
      if (data.mailContact) {
        return (
          <MailContact
            {...props}
            key={"specific"}
            data={data}
            isUpdating={isUpdatingResource}
            onUpdate={(data, auditmsg) =>
              onUpdateResource(data, auditmsg)
            }
          />
        );
      }
      break;
    case ResourceType.GSUITE_SHARED_DRIVE:
      if (data.gsuiteSharedDrive) {
        return (
          <GsuiteDrive
            {...props}
            key={"specific"}
            data={data}
            isUpdating={isUpdatingResource}
            onUpdate={(data, auditmsg) =>
              onUpdateResource(data, auditmsg)
            }
          />
        );
      }
      break;
    case ResourceType.CONFLUENCE_SPACE:
      if (data.confluenceSpace) {
        return (
          <ConfluenceSpace
            {...props}
            key={"specific"}
            data={data}
            isUpdating={isUpdatingResource}
            onUpdate={(data, auditmsg) =>
              onUpdateResource(data, auditmsg)
            }
          />
        );
      }
      break;
    case ResourceType.ETH_IAM_GROUP:
      if (data.ethIamGroup) {
        return (
          <EthIamGroup
            {...props}
            key={"specific"}
            data={data}
            isUpdating={isUpdatingResource}
            onUpdate={(data, auditmsg) =>
              onUpdateResource(data, auditmsg)
            }
          />
        );
      }
      break
    case ResourceType.GITLAB_GROUP:
      if (data.gitlabGroup) {
        return (
          <GitlabGroup
            {...props}
            key={"specific"}
            data={data}
            isUpdating={isUpdatingResource}
            onUpdate={(data, auditmsg) =>
              onUpdateResource(data, auditmsg)
            }
          />
        );
      }
      break;
    case ResourceType.GOOGLE_CALENDAR:
      if (data.googleCalendar) {
        return (
          <GoogleCalendar
            {...props}
            key={"specific"}
            data={data}
            isUpdating={isUpdatingResource}
            onUpdate={(data, auditmsg) =>
              onUpdateResource(data, auditmsg)
            }
          />
        );
      }
      break;
    case ResourceType.GROUP_TAG:
      if (data.groupTag) {
        return (
          <GroupTag
            {...props}
            key={"specific"}
            data={data}
            isUpdating={isUpdatingResource}
            onUpdate={(data, auditmsg) =>
              onUpdateResource(data, auditmsg)
            }
          />
        );
      }
      break;
    case ResourceType.KEYCLOAK_CLIENT:
      if (data.keycloakClient) {
        return (
          <KeycloackClient
            {...props}
            key={"specific"}
            data={data}
            isUpdating={isUpdatingResource}
            onUpdate={(data, auditmsg) =>
              onUpdateResource(data, auditmsg)
            }
          />
        );
      }
      break;
    case ResourceType.KUBERNETES_PERMISSION:
      if (data.kubernetesPermission) {
        return (
          <KubernetesPermission
            {...props}
            key={"specific"}
            data={data}
            isUpdating={isUpdatingResource}
            onUpdate={(data, auditmsg) =>
              onUpdateResource(data, auditmsg)
            }
          />
        );
      }
      break;
    case ResourceType.MAIL_SPECIAL_USER_CONFIGURATION:
      if (data.mailSpecialUserConfiguration) {
        return (
          <MailSpecialUserConfiguration
            {...props}
            key={"specific"}
            data={data}
            isUpdating={isUpdatingResource}
            onUpdate={(data, auditmsg) =>
              onUpdateResource(data, auditmsg)
            }
          />
        );
      }
      break;
    case ResourceType.NAS_STORAGE_FOLDER:
      if (data.nasStorageFolder) {
        return (
          <NASStorageFolder
            {...props}
            key={"specific"}
            data={data}
            isUpdating={isUpdatingResource}
            onUpdate={(data, auditmsg) =>
              onUpdateResource(data, auditmsg)
            }
          />
        );
      }
      break;
    case ResourceType.OFFICE_KEY:
      if (data.officeKey) {
        return (
          <OfficeKey
            {...props}
            key={"specific"}
            data={data}
            isUpdating={isUpdatingResource}
            onUpdate={(data, auditmsg) =>
              onUpdateResource(data, auditmsg)
            }
          />
        );
      }
      break;
    case ResourceType.SYMPA_MAILING_LIST:
      if (data.mailSympa) {
        return (
          <SympaMailingList
            {...props}
            key={"specific"}
            data={data}
            isUpdating={isUpdatingResource}
            onUpdate={(data, auditmsg) =>
              onUpdateResource(data, auditmsg)
            }
          />
        );
      }
      break;
  }

  return (
    <><p key={"specific"}>Resource type unrecognized ({data.resourceType}) or specific data empty. Specific data can not be displayed.</p></>
  );

};

export const getSpecificResourceTableElements = (type: ResourceType) => {
  switch (type) {
    case ResourceType.CONFLUENCE_SPACE:
      return confluenceSpaceTableElements;
    case ResourceType.ETH_IAM_GROUP:
      return ethIamGroupTableElements;
    case ResourceType.GITLAB_GROUP:
      return gitlabGroupTableElements;
    case ResourceType.GOOGLE_CALENDAR:
      return googleCalendarTableElements;
    case ResourceType.GROUP_TAG:
      return groupTagTableElements;
    case ResourceType.GSUITE_SHARED_DRIVE:
      return gsuiteDriveTableElements;
    case ResourceType.KEYCLOAK_CLIENT:
      return keycloackClientTableElements;
    case ResourceType.KUBERNETES_PERMISSION:
      return kubernetesPermissionTableElements;
    case ResourceType.MAIL_CONTACT:
      return mailContactTableElements;
    case ResourceType.MAIL_DISTRIBUTION_LIST:
      return mailDistributionListTableElements;
    case ResourceType.MAIL_GLOBAL:
      return mailGlobalTableElements;
    case ResourceType.MAIL_SERVICE:
      return mailServiceTableElements;
    case ResourceType.MAIL_SHARED_MAILBOX:
      return mailSharedMailboxTableElements;
    case ResourceType.MAIL_SPECIAL_USER_CONFIGURATION:
      return mailSpecialUserConfigurationTableElements;
    case ResourceType.NAS_STORAGE_FOLDER:
      return nasStorageFolderTableElements;
    case ResourceType.OFFICE_KEY:
      return officeKeyTableElements;
    case ResourceType.SYMPA_MAILING_LIST:
      return sympaMailingListTableElements;
    case ResourceType.RESOURCE_TYPE_UNSPECIFIED:
      break;
  }
  return [];
}