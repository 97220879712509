import { ResourceType } from "../proto/sip/resources/resources_pb";
import {
  CalendarIcon,
  ConnectionObjectBothIcon,
  EmailIcon,
  FileIcon,
  FolderLockIcon,
  FolderUpIcon,
  GitlabIcon,
  GoogleDriveIcon,
  IconComponentProps,
  KeyIcon,
  KubernetesIcon,
  LeekIcon,
  ScriptIcon,
  TagIcon,
  UserBoxStarIcon,
} from "vseth-canine-ui";

interface RIProps extends IconComponentProps {
  resourceType: ResourceType;
}

// ResourceIcon returns an Icon that represents the resource type
export const ResourceIcon = ({ resourceType, ...props }: RIProps) => {
  let Icon = LeekIcon;
  switch (resourceType) {
    case ResourceType.MAIL_GLOBAL:
    case ResourceType.MAIL_SERVICE:
    case ResourceType.MAIL_DISTRIBUTION_LIST:
    case ResourceType.MAIL_CONTACT:
    case ResourceType.MAIL_SHARED_MAILBOX:
      Icon = EmailIcon;
      break;
    case ResourceType.ETH_IAM_GROUP:
      Icon = FolderLockIcon;
      break;
    case ResourceType.SYMPA_MAILING_LIST:
      Icon = ScriptIcon;
      break;
    case ResourceType.GSUITE_SHARED_DRIVE:
      Icon = GoogleDriveIcon;
      break;
    case ResourceType.CONFLUENCE_SPACE:
      Icon = FileIcon;
      break;
    case ResourceType.GITLAB_GROUP:
      Icon = GitlabIcon;
      break;
    case ResourceType.GROUP_TAG:
      Icon = TagIcon;
      break;
    case ResourceType.KEYCLOAK_CLIENT:
      Icon = ConnectionObjectBothIcon;
      break;
    case ResourceType.NAS_STORAGE_FOLDER:
      Icon = FolderUpIcon;
      break;
    case ResourceType.GOOGLE_CALENDAR:
      Icon = CalendarIcon;
      break;
    case ResourceType.OFFICE_KEY:
      Icon = KeyIcon;
      break;
    case ResourceType.KUBERNETES_PERMISSION:
      Icon = KubernetesIcon;
      break;
    case ResourceType.MAIL_SPECIAL_USER_CONFIGURATION:
      Icon = UserBoxStarIcon;
      break;
  }

  return <Icon size="1em" {...props} />;
};
