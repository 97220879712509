import React from "react";
import { useCreateProposedChangeMutation, useCreateResourceMutation, useListResourcesAuthorizationRolesQuery, useListResourcesQuery } from "./resourcesSlice";
import { Button, Container, Group as LayoutGroup } from "@mantine/core";
import { ReloadButton } from "../../components/ReloadButton";
import { ResourceTable } from "../../components/ResourceTable";
import { useNavigate } from "react-router-dom";
import { BreadcrumbBase } from "../../components/BreadcrumbBase";
import { ErrorScreen } from "../../components/ErrorScreen";
import { useDisclosure } from "@mantine/hooks";
import { CreateResourceModal } from "./CreateResourceModal";
import { Group, useListGroupsQuery } from "../groups/groupsSlice";
import { selectAllowedToApproveProposedChanges, selectAllowedToCreateProposedChange, selectAllowedToCreateResource, selectUserRoles } from "../auth/authSlice";
import { useSelector } from "react-redux";

function groupMerge(a: Group[], b: Group[]) {
  const res = a;
  for (const group of b) {
    if (!res.find((g) => g.name === group.name)) {
      res.push(group);
    }
  }
  return res;
}

// Displays a list of resources
export const ResourceListPage: React.FC = () => {
  const { data, error, refetch, isFetching } = useListResourcesQuery();

  const navigate = useNavigate();

  const [openedAdd, { open: openAdd, close: closeAdd }] = useDisclosure(false);
  const { data: groups } = useListGroupsQuery({}); // for createResourceModal and more

  // update resource query
  const [createResource, { isLoading: isCreatingResource }] =
    useCreateResourceMutation();

  const [createProposedChange, { isLoading: isCreatingProposedChange }] =
    useCreateProposedChangeMutation();

  const isLoading = isFetching || isCreatingResource || isCreatingProposedChange;

  // load the people and resources api authorization information
  const { data: resourceApiRoles, isLoading: isLoadingResourceRoles } =
    useListResourcesAuthorizationRolesQuery(undefined);

  // get the roles of the user (including debug roles)
  const userRoles = useSelector(selectUserRoles);

  const isAdmin = selectAllowedToApproveProposedChanges(
    resourceApiRoles || [],
    userRoles
  );

  const userPossibleOwnerGroups = groups && groups.filter((g)=>
    isAdmin || selectAllowedToCreateResource(resourceApiRoles, userRoles, g.name)
  );

  const userPossibleOwnerGroupsForRequest = groups && groups.filter((g) =>
    isAdmin || selectAllowedToCreateProposedChange(resourceApiRoles, userRoles, g.name)
 );

  const canCreateSomeResource = userPossibleOwnerGroups && userPossibleOwnerGroups.length > 0;
  const canRequest = (userPossibleOwnerGroupsForRequest && userPossibleOwnerGroupsForRequest.length > 0);

  const modal = ( canCreateSomeResource &&
    <CreateResourceModal opened={openedAdd}
      onClose={closeAdd}
      isUpdating={isLoading || isLoadingResourceRoles}
      groups={groupMerge((userPossibleOwnerGroups || []), userPossibleOwnerGroupsForRequest || [])}
      createResource={createResource}
      createProposedChange={createProposedChange}
      afterCreate={(r, p) => p ? navigate(`/${p.name}?specific=true`) :  navigate(`/${r?.name}?specific=true`)} />
  );

  if (error) {
    return <ErrorScreen error={error} />;
  }

  return (
    <>
      {modal}
      <Container>
        <BreadcrumbBase resourceName={"Resources"} />
        <LayoutGroup position="apart">
          <h2>Resource Management</h2>
          <Button.Group>
            <ReloadButton reloadAction={refetch} isLoading={isLoading || isLoadingResourceRoles} />
            <Button onClick={openAdd} disabled={!canCreateSomeResource && !canRequest}>{canCreateSomeResource ? "Create" : "Request new"}</Button> 
          </Button.Group>
        </LayoutGroup>
        {data && (
          <ResourceTable
            resources={data}
            onResourceSelect={(name) => navigate(`/${name}`)}
          />
        )}
      </Container>
    </>
  );
};
