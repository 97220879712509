import {
  Anchor,
  Breadcrumbs,
  Container,
  Group,
  Text,
  Space,
  Button
} from "@mantine/core";
import {
  selectAccessToken,
} from "../auth/authSlice";
import { useSelector } from "react-redux";
import { getSearchUserWithNameFunction } from "../../components/SelectUsers";
import { useState } from "react";
import { LookupUserSearch } from "./LookupUserSearch";
import { UserInfoTable } from "./UserInfoTable";
import { usePeopleClient } from "../../app/store";

export const LookupPage = () => {
  const [selectedUser, setSelectedUser] = useState<string | null>(null);

  const token = useSelector(selectAccessToken) || "";

  const peopleClient = usePeopleClient();

  return (
    <Container>
      <Breadcrumbs>
        <Anchor href="/lookup">
          Lookup
        </Anchor>
      </Breadcrumbs>
      <Group position="apart">
        <h2>Search persons</h2>
      </Group>
      <Text pb="sm">Coming soon: Search in PeopleAPI database</Text>
      <LookupUserSearch
        value={selectedUser}
        loadUsers={getSearchUserWithNameFunction(peopleClient, token)}
        setValue={setSelectedUser}
      />
      <Space h="md" />
      {(selectedUser && <UserInfoTable username={selectedUser} isAdmin={false} />)}
      { selectedUser && <Group position="right">
        <Button disabled>
          Request details
        </Button>
      </Group> }
    </Container>
  );
};
