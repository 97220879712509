import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AuthorizationRole as PeopleAuthorizationRoleMessage,
  GlobalScope as PeopleGlobalScope,
  ResourceScope,
  ScopesOnResource as ScopesOnResourceMessage,
} from "../../proto/sip/people/people_pb";
import { containsAtLeastOneOf } from "../../util/util";
import {
  GlobalScope as ResourcesGlobalScope,
  GroupSpecificScope,
} from "../../proto/sip/resources/resources_pb";
import { ResourcesAuthorizationRole } from "../resources/resourcesSlice";

import Cookies from 'universal-cookie';
import { Group } from "../groups/groupsSlice";
import { jwtDecode } from "jwt-decode";

export type PeopleAuthorizationRole = PeopleAuthorizationRoleMessage.AsObject;
export type ScopesOnResource = ScopesOnResourceMessage.AsObject;

export interface AuthState {
  isAuthenticated: boolean;
  tokens?: any;
  userInfo?: {
    azp: string;
    exp: number;
    prefered_username: string;
    family_name: string;
    given_name: string;
    name: string;
    resource_access: {
      [id: string]: {
        roles: string[];
      };
    };
  };
}


interface AuthSliceState extends AuthState {
  // ONLY FOR DEBUGGING
  // if this is set the user will be assumed to have the following roles
  debugRoles: string[];
}

/**
 * Extracts the infomration of an auth Token and creates an AuthState
 * @param tokens Keycloak tokens
 */
export const getAuthStateFromToken = (
  tokens: { idToken: string, refreshToken: string, token: string } | null = {
    idToken: "",
    refreshToken: "",
    token: ""
  }
): AuthState => {
  const decodedJwt =
    tokens && tokens.idToken ? (jwtDecode(tokens.idToken) as any) : {};
  const authState = {
    isAuthenticated: !!(tokens && tokens.token),
    tokens,
    userInfo: {
      resource_access: {},
      azp: "",
      exp: 0,
      preferred_username: "",
      family_name: "",
      given_name: "",
      name: "",
      ...decodedJwt
    }
  }

  return authState;
}


const cookies = new Cookies();
const cookieTokens = {
  idToken: cookies.get("idToken",),
  token: cookies.get("token"),
  refreshToken: "",
}

const initialState: AuthSliceState = getAuthStateFromToken((!!cookieTokens.idToken && !!cookieTokens.token) ? cookieTokens : null) as AuthSliceState;
initialState.debugRoles = [];

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthState(state, { payload }: PayloadAction<AuthState>) {
      const cookieOptions = { path: "", secure: true, sameSite: true, maxAge: 3600 };
      cookies.set("idToken", payload.tokens.idToken, cookieOptions);
      cookies.set("token", payload.tokens.token, cookieOptions);
      return {
        ...state,
        ...payload,
      };
    },
    setDebugRoles(state, { payload }: PayloadAction<string[]>) {
      return {
        ...state,
        debugRoles: payload.reduce((res, role) => {
          if (res.indexOf(role) < 0) {
            res.push(role);
          }
          return res;
        }, [] as string[]),
      };
    },
  },
});

export const { setAuthState, setDebugRoles } = auth.actions;

export default auth.reducer;

// selectors
export type AuthSliceRoot = {
  auth: ReturnType<typeof auth.reducer>;
};

export const selectAuth = (state: AuthSliceRoot) => state.auth;
export const selectTokens = (state: AuthSliceRoot) => state.auth.tokens;
export const selectUserInfo = (state: AuthSliceRoot) => state.auth.userInfo;
export const selectIsAuthenticated = (state: AuthSliceRoot) =>
  state.auth.isAuthenticated;

export const selectAccessToken = createSelector([selectTokens], (tokens) => {
  if (tokens) {
    return tokens.token;
  }
  return undefined;
});

const peopleClientId = "api_prod_peopleapi_peopleapi";
const resourcesClientId = "api_prod_resources_prod_backend";

export const selectUserIdentifier = (
  state: AuthSliceRoot,
) => (state.auth.userInfo &&
  ("users/" + (state.auth.userInfo as unknown as { sub: string }).sub));

export const selectDebugRoles = (state: AuthSliceRoot) => state.auth.debugRoles;

// select all the roles that are mapped into the users access token
export const selectUserRoles = createSelector(
  [selectUserInfo, selectDebugRoles],
  (userInfo, debugRoles) => {
    if (debugRoles.length > 0) {
      return debugRoles;
    }

    const roles: string[] = [];

    if (userInfo && userInfo.resource_access) {
      if (userInfo.resource_access[peopleClientId]) {
        roles.push(
          ...userInfo.resource_access[peopleClientId].roles.map(
            (r) => `people.authorizationroles/${r}`
          )
        );
      }
      if (userInfo.resource_access[resourcesClientId]) {
        roles.push(
          ...userInfo.resource_access[resourcesClientId].roles.map(
            (r) => `resources.authorizationroles/${r}`
          )
        );
      }
    }
    return roles;
  }
);

//// People API Permissions

// selector for people permissions
const selectPeopleGlobalAuthorizationScopesForRoles = (
  roles: PeopleAuthorizationRole[],
  userRoles: string[]
): PeopleGlobalScope[] => {
  return roles
    .filter((r) => userRoles.indexOf(`people.${r.name}`) > -1)
    .map((r) => r.globalScopesList)
    .flat();
};

const selectPeopleResourceAuthorizationScopesForRoles = (
  roles: PeopleAuthorizationRole[],
  userRoles: string[]
): ScopesOnResource[] => {
  return roles
    .filter((r) => userRoles.indexOf(`people.${r.name}`) > -1)
    .map((r) => r.scopesOnResourceList)
    .flat();
};

// select the resource based permissions on a given group
const selectScopesOnGroupForUser = (
  roles: PeopleAuthorizationRole[],
  userRoles: string[],
  groupName: string
): ResourceScope[] =>
  selectPeopleResourceAuthorizationScopesForRoles(roles, userRoles)
    .filter(
      (role) =>
        role.resourceName === groupName ||
        isParentOf(role.resourceName, groupName)
    )
    .map((role) => role.scopesList)
    // flatten
    .flat()
    // unique
    .reduce((res, scope) => {
      if (res.indexOf(scope) < 0) {
        res.push(scope);
      }
      return res;
    }, [] as ResourceScope[]);

const peopleSelectorGenerator =
  (required: PeopleGlobalScope[]) =>
    (
      roles: PeopleAuthorizationRole[] | undefined,
      userRoles: string[]
    ): boolean =>
      containsAtLeastOneOf(
        selectPeopleGlobalAuthorizationScopesForRoles(roles || [], userRoles),
        required
      );

// global Permissions
export const selectIsAdmin = peopleSelectorGenerator([PeopleGlobalScope.ADMIN]);
export const selectAllowedToListGroups = peopleSelectorGenerator([
  PeopleGlobalScope.ADMIN,
  PeopleGlobalScope.GROUPS_LIST,
]);
export const selectAllowedToUpdateGroup = peopleSelectorGenerator([
  PeopleGlobalScope.ADMIN,
  PeopleGlobalScope.GROUPS_UPDATE,
]);

export const isGpk = (userRoles: string[]) => (userRoles.some((r) => r === "people.authorizationroles/1001-gpk-active")); // TODO(kledavid): replace with resource role

const peopleResourceBasedSelectorGenerator =
  (globalRequired: PeopleGlobalScope[], resourceRequired: ResourceScope[]) =>
    (
      roles: PeopleAuthorizationRole[] | undefined,
      userRoles: string[],
      groupName: string
    ): boolean =>
      containsAtLeastOneOf(
        selectPeopleGlobalAuthorizationScopesForRoles(roles || [], userRoles),
        globalRequired
      ) ||
      containsAtLeastOneOf(
        selectScopesOnGroupForUser(roles || [], userRoles, groupName),
        resourceRequired
      );

export const selectAllowedToViewGroup = peopleResourceBasedSelectorGenerator(
  [PeopleGlobalScope.ADMIN],
  [ResourceScope.GROUPS_GET]
);
export const selectAllowedToViewMembersOfGroup =
  peopleResourceBasedSelectorGenerator(
    [PeopleGlobalScope.ADMIN],
    [ResourceScope.GROUPS_MEMBERS_GET]
  );
export const selectAllowedToEditMembersOfGroup =
  peopleResourceBasedSelectorGenerator(
    [PeopleGlobalScope.ADMIN],
    [ResourceScope.GROUPS_MEMBERS_UPDATE]
  );

//// Resources API Permissions

const selectResourcesGlobalScopesForRoles = (
  roles: ResourcesAuthorizationRole[],
  userRoles: string[]
): ResourcesGlobalScope[] => {
  return roles
    .filter((r) => userRoles.indexOf(`resources.${r.name}`) > -1)
    .map((r) => r.globalScopesList)
    .flat();
};

const selectResourcesGroupBasedScopesForRoles = (
  roles: ResourcesAuthorizationRole[],
  userRoles: string[],
  groupName?: string
): GroupSpecificScope[] => {
  return roles
    .filter((r) => userRoles.indexOf(`resources.${r.name}`) > -1)
    .map((r) => r.groupBasedScopeList)
    .flat()
    .filter((scope) => groupName === undefined || (scope.inherit ?
      isParentOf(scope.groupName, groupName) || scope.groupName === groupName
      : scope.groupName === groupName))
    .map((scope) => scope.scopesList)
    .flat();
};

const resourcesSelectorGenerator =
  (required: ResourcesGlobalScope[]) =>
    (
      roles: ResourcesAuthorizationRole[] | undefined,
      userRoles: string[]
    ): boolean =>
      containsAtLeastOneOf(
        selectResourcesGlobalScopesForRoles(roles || [], userRoles),
        required
      );

export const selectIsResourcesAdmin = resourcesSelectorGenerator([ResourcesGlobalScope.ADMIN]);
export const selectAllowedToListResources = resourcesSelectorGenerator([
  ResourcesGlobalScope.ADMIN,
  ResourcesGlobalScope.RESOURCES_LIST_GLOBAL,
]);
export const selectAllowedToListPermissions = resourcesSelectorGenerator([
  ResourcesGlobalScope.ADMIN,
  ResourcesGlobalScope.PERMISSIONS_LIST_GLOBAL,
]);
export const selectAllowedToApproveProposedChanges = resourcesSelectorGenerator([
  ResourcesGlobalScope.ADMIN,
]);
export const selectAllowedToListProposedChanges = resourcesSelectorGenerator([
  ResourcesGlobalScope.ADMIN, ResourcesGlobalScope.CHANGES_LIST_GLOBAL
]);

/*
export const selectAllowedToListSomeProposedChanges = ( roles: ResourcesAuthorizationRole[], userRoles: string[] ) => {
  console.log("rgbs for roles",selectResourcesGroupBasedScopesForRoles(roles, userRoles));
  return (selectAllowedToListProposedChanges(roles, userRoles) || selectResourcesGroupBasedScopesForRoles(roles, userRoles).includes(GroupSpecificScope.CHANGES_LIST));
}
* NOT NEEDED IF OIVS HAVE CHANGES_LIST_GLOBAL */

const resourcesGroupBasedSelectorGenerator =
  (
    globalRequired: ResourcesGlobalScope[],
    resourceRequired: GroupSpecificScope[]
  ) =>
    (
      roles: ResourcesAuthorizationRole[] | undefined,
      userRoles: string[],
      groupName: string | undefined
    ): boolean =>
      containsAtLeastOneOf(
        selectResourcesGlobalScopesForRoles(roles || [], userRoles),
        globalRequired
      ) ||
      containsAtLeastOneOf(
        selectResourcesGroupBasedScopesForRoles(
          roles || [],
          userRoles,
          groupName || ""
        ),
        resourceRequired
      );

export const selectAllowedToViewResource = resourcesGroupBasedSelectorGenerator(
  [ResourcesGlobalScope.ADMIN, ResourcesGlobalScope.RESOURCES_LIST_GLOBAL],
  []
);
export const selectAllowedToCreateResource =
  resourcesGroupBasedSelectorGenerator(
    [ResourcesGlobalScope.ADMIN],
    [GroupSpecificScope.RESOURCES_CREATE]
  );
export const selectAllowedToUpdateResource =
  resourcesGroupBasedSelectorGenerator(
    [ResourcesGlobalScope.ADMIN],
    [GroupSpecificScope.RESOURCES_UPDATE]
  );
export const selectAllowedToDeleteResource =
  resourcesGroupBasedSelectorGenerator(
    [ResourcesGlobalScope.ADMIN],
    [GroupSpecificScope.RESOURCES_DELETE]
  );

export const selectAllowedToCreatePermissionOnResource =
  resourcesGroupBasedSelectorGenerator(
    [ResourcesGlobalScope.ADMIN],
    [GroupSpecificScope.PERMISSIONS_CREATE]
  );

export const selectAllowedToUpdatePermissionOnResource =
  resourcesGroupBasedSelectorGenerator(
    [ResourcesGlobalScope.ADMIN],
    [GroupSpecificScope.PERMISSIONS_UPDATE]
  );

export const selectAllowedToDeletePermissionOnResource =
  resourcesGroupBasedSelectorGenerator(
    [ResourcesGlobalScope.ADMIN],
    [GroupSpecificScope.PERMISSIONS_DELETE]
  );

export const selectAllowedToViewProposedChange =
  resourcesGroupBasedSelectorGenerator(
    [ResourcesGlobalScope.ADMIN, ResourcesGlobalScope.CHANGES_LIST_GLOBAL],
    [GroupSpecificScope.CHANGES_LIST]
  );
export const selectAllowedToCreateProposedChange =
  resourcesGroupBasedSelectorGenerator(
    [ResourcesGlobalScope.ADMIN],
    [GroupSpecificScope.CHANGES_CREATE]
  );
export const selectAllowedToUpdateProposedChange =
  resourcesGroupBasedSelectorGenerator(
    [ResourcesGlobalScope.ADMIN],
    [GroupSpecificScope.CHANGES_UPDATE]
  );
export const selectAllowedToDeleteProposedChange =
  resourcesGroupBasedSelectorGenerator(
    [ResourcesGlobalScope.ADMIN],
    [GroupSpecificScope.CHANGES_DELETE]
  );

export const isParentOf = (parent: string, group: string, parentGroupData?: Group) => {
  /*
    Helper function to determine if group is subroup of a parent group, with or without 
    group data (parentNamesTransitiveList). Without the optional groupData, it may handle
    certain groups incorrectly (KS ?)
  */
  if (parent === "groups/vseth-0000-vseth") {
    return true;
  }


  if (parentGroupData) {
    return parentGroupData?.subGroupsTransitiveList.includes(group);
  } else {
    // check if we are dealing with a ks
    if (/^groups\/vseth-\d\d00-[a-z0-9]{2,10}$/.test(parent)) {
      // check if the first 7 characters match (e.g. `groups/vseth-0300-vs` and `groups/vseth-0301-vsethp` would match)
      return group.substr(0, 15) === parent.substr(0, 15);
    }

    // simple implementation for everything else
    return group.startsWith(parent + "-");
  }
};
