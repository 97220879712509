import { Link } from "react-router-dom";
import { GetIdentifierFromName } from "../util/peopleProto";
import { Group } from "../features/groups/groupsSlice";
import { ResourceLink } from "./ResourceLink";
import { Breadcrumbs, Anchor, ScrollArea } from '@mantine/core';

interface GBProps {
  group?: Group;
  focusKey?: string;
}

// GroupBreadcrumb displays the breadcrumb at the top of the group detail page.
export const GroupBreadcrumb = ({ group, focusKey }: GBProps) => {
  if (!group) {
    return (
      <Breadcrumbs>
        <Anchor>Groups</Anchor>
      </Breadcrumbs>
    );
  }

  const groupIdentifier = GetIdentifierFromName(group.name);
  const inFocusView = focusKey !== undefined && focusKey !== "";

  return (
    <ScrollArea h={32} mb="sm">
      <Breadcrumbs>
        <Anchor>
          <Link to="/groups">
            Groups
          </Link>
        </Anchor>
        {group.parentNamesTransitiveList.map((parentName) => (
          <Anchor key={parentName}>
            <ResourceLink resourceName={parentName} key={parentName}/>
          </Anchor>
        ))}
        {!inFocusView && (
          <Anchor>{groupIdentifier}</Anchor>
        )}
        {inFocusView && (
          <Anchor>
            <ResourceLink resourceName={group.name} key={group.name}/>
          </Anchor>
        )}
        {inFocusView && <Anchor>{focusKey}</Anchor>}
      </Breadcrumbs>
    </ScrollArea>
  );
};
