import {
  ResourceDetailCardBase,
  TableFormElement,
} from "./ResourceDetailCardBase";
import { EditableResourceDetailsProps } from "./MailResourceHelpers";
import { Resource } from "../resourcesSlice";

const tableElements: TableFormElement<Resource>[] = [
  {
    title: "Path",
    key: "nasStorageFolder.path" as keyof Resource,
  },
];

export const NASStorageFolder = (
  props: EditableResourceDetailsProps<Resource>
) => (
  <ResourceDetailCardBase {...props}>{tableElements}</ResourceDetailCardBase>
);

export const nasStorageFolderTableElements = tableElements;