import { ResourceDetailCardBase, TableFormElement } from "./ResourceDetailCardBase";
import { Resource } from "../resourcesSlice";
import {
  EditableResourceDetailsProps,
  mailBaseElements,
} from "./MailResourceHelpers";

const tableElements: TableFormElement<Resource>[] = [
  ...mailBaseElements("mailSharedMailbox"),
  {
    title: "Identifier",
    key: "mailSharedMailbox.identifier" as keyof Resource,
    disabled: true,
  },
];

export const MailSharedMailbox = (
  props: EditableResourceDetailsProps<Resource>
) => (
  <ResourceDetailCardBase {...props}>{tableElements}</ResourceDetailCardBase>
);

export const mailSharedMailboxTableElements = tableElements;