import {
  ResourceDetailCardBase,
  TableFormElement,
} from "./ResourceDetailCardBase";
import { EditableResourceDetailsProps, emailAliasesFieldArray } from "./MailResourceHelpers";
import { Resource } from "../resourcesSlice";
import { regexConstants } from "../../../util/regexConstants";
import * as Yup from "yup";

const tableElements: TableFormElement<Resource>[] = [
  {
    title: "Prestige name",
    key: "mailSpecialUserConfiguration.prestigeName" as keyof Resource,
  }, {
    title: "Quota",
    key: "mailSpecialUserConfiguration.quota" as keyof Resource,
    formatter: (quota: number) => String(quota),
    customizeField: () => ({ type: "number" }),
  },
  {
    title: "Account name",
    key: "mailSpecialUserConfiguration.account" as keyof Resource,
    validation: Yup.string().required("User account name is required").matches(regexConstants.resource.msucAccount, "Must be valid account name"),
  },
  {
    title: "Primary email address",
    key: "mailSpecialUserConfiguration.primaryEmailAddress" as keyof Resource,
    validation: Yup.string().matches(regexConstants.resource.email, "Must be valid email address"),
  },
  {
    title: "Email aliases",
    key: "mailSpecialUserConfiguration.emailAliasesList" as keyof Resource,
    formatter: (aliases: string[]) => <table><tbody>{aliases.map((alias, index) => <tr key={index}><td>{alias}</td></tr>)}</tbody></table>,
    completelyCustomField: (key, formik) => emailAliasesFieldArray(key, formik.values.mailSpecialUserConfiguration?.emailAliasesList || [], formik.errors),
  },
  {
    title: "Hidden in ETH address book",
    key: "mailSpecialUserConfiguration.hiddenInEthAddressBook" as keyof Resource,
    formatter: (hiddenInEthAddressBook: boolean) => String(hiddenInEthAddressBook),
    customizeField: () => ({ type: "checkbox" }),
  },
];

export const MailSpecialUserConfiguration = (
  props: EditableResourceDetailsProps<Resource>
) => (
  <ResourceDetailCardBase {...props}>{tableElements}</ResourceDetailCardBase>
);

export const mailSpecialUserConfigurationTableElements = tableElements;
