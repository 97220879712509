/* eslint-disable */ 
/**
 * @fileoverview gRPC-Web generated client stub for sip.resources
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: sip/resources/resources.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var github_com_mwitkow_go$proto$validators_validator_pb = require('../../github.com/mwitkow/go-proto-validators/validator_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.sip = {};
proto.sip.resources = require('./resources_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.sip.resources.ResourcesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.sip.resources.ResourcesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.sip.resources.ListAuthorizationRolesResponse>}
 */
const methodDescriptor_Resources_ListAuthorizationRoles = new grpc.web.MethodDescriptor(
  '/sip.resources.Resources/ListAuthorizationRoles',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.sip.resources.ListAuthorizationRolesResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.resources.ListAuthorizationRolesResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.resources.ListAuthorizationRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.resources.ListAuthorizationRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.resources.ResourcesClient.prototype.listAuthorizationRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.resources.Resources/ListAuthorizationRoles',
      request,
      metadata || {},
      methodDescriptor_Resources_ListAuthorizationRoles,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.resources.ListAuthorizationRolesResponse>}
 *     Promise that resolves to the response
 */
proto.sip.resources.ResourcesPromiseClient.prototype.listAuthorizationRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.resources.Resources/ListAuthorizationRoles',
      request,
      metadata || {},
      methodDescriptor_Resources_ListAuthorizationRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.sip.resources.ListResourceTypesResponse>}
 */
const methodDescriptor_Resources_ListResourceTypes = new grpc.web.MethodDescriptor(
  '/sip.resources.Resources/ListResourceTypes',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.sip.resources.ListResourceTypesResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.resources.ListResourceTypesResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.resources.ListResourceTypesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.resources.ListResourceTypesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.resources.ResourcesClient.prototype.listResourceTypes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.resources.Resources/ListResourceTypes',
      request,
      metadata || {},
      methodDescriptor_Resources_ListResourceTypes,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.resources.ListResourceTypesResponse>}
 *     Promise that resolves to the response
 */
proto.sip.resources.ResourcesPromiseClient.prototype.listResourceTypes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.resources.Resources/ListResourceTypes',
      request,
      metadata || {},
      methodDescriptor_Resources_ListResourceTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.resources.ListResourcesRequest,
 *   !proto.sip.resources.ListResourcesResponse>}
 */
const methodDescriptor_Resources_ListResources = new grpc.web.MethodDescriptor(
  '/sip.resources.Resources/ListResources',
  grpc.web.MethodType.UNARY,
  proto.sip.resources.ListResourcesRequest,
  proto.sip.resources.ListResourcesResponse,
  /**
   * @param {!proto.sip.resources.ListResourcesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.resources.ListResourcesResponse.deserializeBinary
);


/**
 * @param {!proto.sip.resources.ListResourcesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.resources.ListResourcesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.resources.ListResourcesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.resources.ResourcesClient.prototype.listResources =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.resources.Resources/ListResources',
      request,
      metadata || {},
      methodDescriptor_Resources_ListResources,
      callback);
};


/**
 * @param {!proto.sip.resources.ListResourcesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.resources.ListResourcesResponse>}
 *     Promise that resolves to the response
 */
proto.sip.resources.ResourcesPromiseClient.prototype.listResources =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.resources.Resources/ListResources',
      request,
      metadata || {},
      methodDescriptor_Resources_ListResources);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.resources.GetResourceRequest,
 *   !proto.sip.resources.Resource>}
 */
const methodDescriptor_Resources_GetResource = new grpc.web.MethodDescriptor(
  '/sip.resources.Resources/GetResource',
  grpc.web.MethodType.UNARY,
  proto.sip.resources.GetResourceRequest,
  proto.sip.resources.Resource,
  /**
   * @param {!proto.sip.resources.GetResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.resources.Resource.deserializeBinary
);


/**
 * @param {!proto.sip.resources.GetResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.resources.Resource)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.resources.Resource>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.resources.ResourcesClient.prototype.getResource =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.resources.Resources/GetResource',
      request,
      metadata || {},
      methodDescriptor_Resources_GetResource,
      callback);
};


/**
 * @param {!proto.sip.resources.GetResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.resources.Resource>}
 *     Promise that resolves to the response
 */
proto.sip.resources.ResourcesPromiseClient.prototype.getResource =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.resources.Resources/GetResource',
      request,
      metadata || {},
      methodDescriptor_Resources_GetResource);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.resources.CreateResourceRequest,
 *   !proto.sip.resources.Resource>}
 */
const methodDescriptor_Resources_CreateResource = new grpc.web.MethodDescriptor(
  '/sip.resources.Resources/CreateResource',
  grpc.web.MethodType.UNARY,
  proto.sip.resources.CreateResourceRequest,
  proto.sip.resources.Resource,
  /**
   * @param {!proto.sip.resources.CreateResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.resources.Resource.deserializeBinary
);


/**
 * @param {!proto.sip.resources.CreateResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.resources.Resource)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.resources.Resource>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.resources.ResourcesClient.prototype.createResource =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.resources.Resources/CreateResource',
      request,
      metadata || {},
      methodDescriptor_Resources_CreateResource,
      callback);
};


/**
 * @param {!proto.sip.resources.CreateResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.resources.Resource>}
 *     Promise that resolves to the response
 */
proto.sip.resources.ResourcesPromiseClient.prototype.createResource =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.resources.Resources/CreateResource',
      request,
      metadata || {},
      methodDescriptor_Resources_CreateResource);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.resources.UpdateResourceRequest,
 *   !proto.sip.resources.Resource>}
 */
const methodDescriptor_Resources_UpdateResource = new grpc.web.MethodDescriptor(
  '/sip.resources.Resources/UpdateResource',
  grpc.web.MethodType.UNARY,
  proto.sip.resources.UpdateResourceRequest,
  proto.sip.resources.Resource,
  /**
   * @param {!proto.sip.resources.UpdateResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.resources.Resource.deserializeBinary
);


/**
 * @param {!proto.sip.resources.UpdateResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.resources.Resource)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.resources.Resource>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.resources.ResourcesClient.prototype.updateResource =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.resources.Resources/UpdateResource',
      request,
      metadata || {},
      methodDescriptor_Resources_UpdateResource,
      callback);
};


/**
 * @param {!proto.sip.resources.UpdateResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.resources.Resource>}
 *     Promise that resolves to the response
 */
proto.sip.resources.ResourcesPromiseClient.prototype.updateResource =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.resources.Resources/UpdateResource',
      request,
      metadata || {},
      methodDescriptor_Resources_UpdateResource);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.resources.DeleteResourceRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Resources_DeleteResource = new grpc.web.MethodDescriptor(
  '/sip.resources.Resources/DeleteResource',
  grpc.web.MethodType.UNARY,
  proto.sip.resources.DeleteResourceRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.sip.resources.DeleteResourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.sip.resources.DeleteResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.resources.ResourcesClient.prototype.deleteResource =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.resources.Resources/DeleteResource',
      request,
      metadata || {},
      methodDescriptor_Resources_DeleteResource,
      callback);
};


/**
 * @param {!proto.sip.resources.DeleteResourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.sip.resources.ResourcesPromiseClient.prototype.deleteResource =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.resources.Resources/DeleteResource',
      request,
      metadata || {},
      methodDescriptor_Resources_DeleteResource);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.resources.ListResourcePermissionsRequest,
 *   !proto.sip.resources.ListResourcePermissionsResponse>}
 */
const methodDescriptor_Resources_ListResourcePermissions = new grpc.web.MethodDescriptor(
  '/sip.resources.Resources/ListResourcePermissions',
  grpc.web.MethodType.UNARY,
  proto.sip.resources.ListResourcePermissionsRequest,
  proto.sip.resources.ListResourcePermissionsResponse,
  /**
   * @param {!proto.sip.resources.ListResourcePermissionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.resources.ListResourcePermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.sip.resources.ListResourcePermissionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.resources.ListResourcePermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.resources.ListResourcePermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.resources.ResourcesClient.prototype.listResourcePermissions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.resources.Resources/ListResourcePermissions',
      request,
      metadata || {},
      methodDescriptor_Resources_ListResourcePermissions,
      callback);
};


/**
 * @param {!proto.sip.resources.ListResourcePermissionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.resources.ListResourcePermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.sip.resources.ResourcesPromiseClient.prototype.listResourcePermissions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.resources.Resources/ListResourcePermissions',
      request,
      metadata || {},
      methodDescriptor_Resources_ListResourcePermissions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.resources.CreateResourcePermissionRequest,
 *   !proto.sip.resources.ResourcePermission>}
 */
const methodDescriptor_Resources_CreateResourcePermission = new grpc.web.MethodDescriptor(
  '/sip.resources.Resources/CreateResourcePermission',
  grpc.web.MethodType.UNARY,
  proto.sip.resources.CreateResourcePermissionRequest,
  proto.sip.resources.ResourcePermission,
  /**
   * @param {!proto.sip.resources.CreateResourcePermissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.resources.ResourcePermission.deserializeBinary
);


/**
 * @param {!proto.sip.resources.CreateResourcePermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.resources.ResourcePermission)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.resources.ResourcePermission>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.resources.ResourcesClient.prototype.createResourcePermission =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.resources.Resources/CreateResourcePermission',
      request,
      metadata || {},
      methodDescriptor_Resources_CreateResourcePermission,
      callback);
};


/**
 * @param {!proto.sip.resources.CreateResourcePermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.resources.ResourcePermission>}
 *     Promise that resolves to the response
 */
proto.sip.resources.ResourcesPromiseClient.prototype.createResourcePermission =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.resources.Resources/CreateResourcePermission',
      request,
      metadata || {},
      methodDescriptor_Resources_CreateResourcePermission);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.resources.DeleteResourcePermissionRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Resources_DeleteResourcePermission = new grpc.web.MethodDescriptor(
  '/sip.resources.Resources/DeleteResourcePermission',
  grpc.web.MethodType.UNARY,
  proto.sip.resources.DeleteResourcePermissionRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.sip.resources.DeleteResourcePermissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.sip.resources.DeleteResourcePermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.resources.ResourcesClient.prototype.deleteResourcePermission =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.resources.Resources/DeleteResourcePermission',
      request,
      metadata || {},
      methodDescriptor_Resources_DeleteResourcePermission,
      callback);
};


/**
 * @param {!proto.sip.resources.DeleteResourcePermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.sip.resources.ResourcesPromiseClient.prototype.deleteResourcePermission =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.resources.Resources/DeleteResourcePermission',
      request,
      metadata || {},
      methodDescriptor_Resources_DeleteResourcePermission);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.resources.ListProposedChangesRequest,
 *   !proto.sip.resources.ListProposedChangesResponse>}
 */
const methodDescriptor_Resources_ListProposedChanges = new grpc.web.MethodDescriptor(
  '/sip.resources.Resources/ListProposedChanges',
  grpc.web.MethodType.UNARY,
  proto.sip.resources.ListProposedChangesRequest,
  proto.sip.resources.ListProposedChangesResponse,
  /**
   * @param {!proto.sip.resources.ListProposedChangesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.resources.ListProposedChangesResponse.deserializeBinary
);


/**
 * @param {!proto.sip.resources.ListProposedChangesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.resources.ListProposedChangesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.resources.ListProposedChangesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.resources.ResourcesClient.prototype.listProposedChanges =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.resources.Resources/ListProposedChanges',
      request,
      metadata || {},
      methodDescriptor_Resources_ListProposedChanges,
      callback);
};


/**
 * @param {!proto.sip.resources.ListProposedChangesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.resources.ListProposedChangesResponse>}
 *     Promise that resolves to the response
 */
proto.sip.resources.ResourcesPromiseClient.prototype.listProposedChanges =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.resources.Resources/ListProposedChanges',
      request,
      metadata || {},
      methodDescriptor_Resources_ListProposedChanges);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.resources.CreateProposedChangeRequest,
 *   !proto.sip.resources.ProposedChange>}
 */
const methodDescriptor_Resources_CreateProposedChange = new grpc.web.MethodDescriptor(
  '/sip.resources.Resources/CreateProposedChange',
  grpc.web.MethodType.UNARY,
  proto.sip.resources.CreateProposedChangeRequest,
  proto.sip.resources.ProposedChange,
  /**
   * @param {!proto.sip.resources.CreateProposedChangeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.resources.ProposedChange.deserializeBinary
);


/**
 * @param {!proto.sip.resources.CreateProposedChangeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.resources.ProposedChange)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.resources.ProposedChange>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.resources.ResourcesClient.prototype.createProposedChange =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.resources.Resources/CreateProposedChange',
      request,
      metadata || {},
      methodDescriptor_Resources_CreateProposedChange,
      callback);
};


/**
 * @param {!proto.sip.resources.CreateProposedChangeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.resources.ProposedChange>}
 *     Promise that resolves to the response
 */
proto.sip.resources.ResourcesPromiseClient.prototype.createProposedChange =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.resources.Resources/CreateProposedChange',
      request,
      metadata || {},
      methodDescriptor_Resources_CreateProposedChange);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.resources.UpdateProposedChangeRequest,
 *   !proto.sip.resources.ProposedChange>}
 */
const methodDescriptor_Resources_UpdateProposedChange = new grpc.web.MethodDescriptor(
  '/sip.resources.Resources/UpdateProposedChange',
  grpc.web.MethodType.UNARY,
  proto.sip.resources.UpdateProposedChangeRequest,
  proto.sip.resources.ProposedChange,
  /**
   * @param {!proto.sip.resources.UpdateProposedChangeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.resources.ProposedChange.deserializeBinary
);


/**
 * @param {!proto.sip.resources.UpdateProposedChangeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.resources.ProposedChange)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.resources.ProposedChange>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.resources.ResourcesClient.prototype.updateProposedChange =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.resources.Resources/UpdateProposedChange',
      request,
      metadata || {},
      methodDescriptor_Resources_UpdateProposedChange,
      callback);
};


/**
 * @param {!proto.sip.resources.UpdateProposedChangeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.resources.ProposedChange>}
 *     Promise that resolves to the response
 */
proto.sip.resources.ResourcesPromiseClient.prototype.updateProposedChange =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.resources.Resources/UpdateProposedChange',
      request,
      metadata || {},
      methodDescriptor_Resources_UpdateProposedChange);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.resources.DeleteProposedChangeRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Resources_DeleteProposedChange = new grpc.web.MethodDescriptor(
  '/sip.resources.Resources/DeleteProposedChange',
  grpc.web.MethodType.UNARY,
  proto.sip.resources.DeleteProposedChangeRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.sip.resources.DeleteProposedChangeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.sip.resources.DeleteProposedChangeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.resources.ResourcesClient.prototype.deleteProposedChange =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.resources.Resources/DeleteProposedChange',
      request,
      metadata || {},
      methodDescriptor_Resources_DeleteProposedChange,
      callback);
};


/**
 * @param {!proto.sip.resources.DeleteProposedChangeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.sip.resources.ResourcesPromiseClient.prototype.deleteProposedChange =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.resources.Resources/DeleteProposedChange',
      request,
      metadata || {},
      methodDescriptor_Resources_DeleteProposedChange);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sip.resources.ListAuditLogRequest,
 *   !proto.sip.resources.ListAuditLogResponse>}
 */
const methodDescriptor_Resources_ListAuditLog = new grpc.web.MethodDescriptor(
  '/sip.resources.Resources/ListAuditLog',
  grpc.web.MethodType.UNARY,
  proto.sip.resources.ListAuditLogRequest,
  proto.sip.resources.ListAuditLogResponse,
  /**
   * @param {!proto.sip.resources.ListAuditLogRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.resources.ListAuditLogResponse.deserializeBinary
);


/**
 * @param {!proto.sip.resources.ListAuditLogRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.resources.ListAuditLogResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.resources.ListAuditLogResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.resources.ResourcesClient.prototype.listAuditLog =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.resources.Resources/ListAuditLog',
      request,
      metadata || {},
      methodDescriptor_Resources_ListAuditLog,
      callback);
};


/**
 * @param {!proto.sip.resources.ListAuditLogRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.resources.ListAuditLogResponse>}
 *     Promise that resolves to the response
 */
proto.sip.resources.ResourcesPromiseClient.prototype.listAuditLog =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.resources.Resources/ListAuditLog',
      request,
      metadata || {},
      methodDescriptor_Resources_ListAuditLog);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.sip.resources.ValidateDBResponse>}
 */
const methodDescriptor_Resources_ValidateDB = new grpc.web.MethodDescriptor(
  '/sip.resources.Resources/ValidateDB',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.sip.resources.ValidateDBResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sip.resources.ValidateDBResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sip.resources.ValidateDBResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sip.resources.ValidateDBResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sip.resources.ResourcesClient.prototype.validateDB =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sip.resources.Resources/ValidateDB',
      request,
      metadata || {},
      methodDescriptor_Resources_ValidateDB,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sip.resources.ValidateDBResponse>}
 *     Promise that resolves to the response
 */
proto.sip.resources.ResourcesPromiseClient.prototype.validateDB =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sip.resources.Resources/ValidateDB',
      request,
      metadata || {},
      methodDescriptor_Resources_ValidateDB);
};


module.exports = proto.sip.resources;

