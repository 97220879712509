import React from "react";
import { useCreateProposedChangeMutation, useCreateResourceMutation, useListProposedChangesQuery, useListResourcesAuthorizationRolesQuery, useListResourcesQuery } from "./resourcesSlice";
import { Button, Container, Group } from "@mantine/core";
import { ReloadButton } from "../../components/ReloadButton";
import { ProposedChangeTable } from "../../components/ProposedChangeTable";
import { useNavigate } from "react-router-dom";
import { BreadcrumbBase } from "../../components/BreadcrumbBase";
import { ErrorScreen } from "../../components/ErrorScreen";
import { useListGroupsQuery } from "../groups/groupsSlice";
import { selectAllowedToApproveProposedChanges, selectAllowedToCreateProposedChange, selectAllowedToListProposedChanges, selectUserRoles } from "../auth/authSlice";
import { useSelector } from "react-redux";
import { CreateResourceModal } from "./CreateResourceModal";
import { useDisclosure } from "@mantine/hooks";


export const ProposedChangeListPage: React.FC = () => {

  // TODO(kledavid): why is this needed? Cache?
  const { error: errorResources, refetch: refetchResource, isFetching: isFetchingResource } = useListResourcesQuery();

  // load the people and resources api authorization information
  const { data: resourceApiRoles, isLoading: isLoadingResourceRoles } =
    useListResourcesAuthorizationRolesQuery(undefined);

  // get the roles of the user (including debug roles)
  const userRoles = useSelector(selectUserRoles);

  const isAdmin = selectAllowedToApproveProposedChanges(
    resourceApiRoles || [],
    userRoles
  );

  const isAllowedToListProposedChanges = selectAllowedToListProposedChanges(
    resourceApiRoles || [],
    userRoles
  );

  const { data, error, refetch, isFetching } = useListProposedChangesQuery({}, { skip: !isAllowedToListProposedChanges });

  /* const userRelatedProposedChanges = ((data && data.filter((pc: ProposedChange) => (
    isAdmin || selectAllowedToViewProposedChange(resourceApiRoles, userRoles, pc.data?.ownerGroup) ||
    pc.data?.lastUpdateBy === userInfo?.name
  ))) || []); FILTERED BY BACKEND. */
  const userRelatedProposedChanges = data;

  const navigate = useNavigate();

  const [openedAdd, { open: openAdd, close: closeAdd }] = useDisclosure(false);
  const { data: groups } = useListGroupsQuery({}); // for createResourceModal

  const [createResource, { isLoading: isCreatingResource }] =
    useCreateResourceMutation();

  const [createProposedChange, { isLoading: isCreatingProposedChange }] =
    useCreateProposedChangeMutation();

  const isLoading = isFetching || isCreatingResource || isCreatingProposedChange;

  /* const userPossibleOwnerGroups = groups && groups.filter((g) =>
    selectAllowedToCreateResource(resourceApiRoles, userRoles, g.name)
  ); */

  const userPossibleOwnerGroupsForRequest = groups && groups.filter((g) =>
    selectAllowedToCreateProposedChange(resourceApiRoles, userRoles, g.name)
  );

  //const canCreateSomeResource = isAdmin || (userPossibleOwnerGroups && userPossibleOwnerGroups.length > 0);
  const canRequest = isAdmin || (userPossibleOwnerGroupsForRequest && userPossibleOwnerGroupsForRequest.length > 0);

  const modal = (canRequest &&
    <CreateResourceModal opened={openedAdd}
      onClose={closeAdd}
      isUpdating={isLoading}
      groups={userPossibleOwnerGroupsForRequest || []}
      forceProposedChange={true}
      createResource={createResource}
      createProposedChange={createProposedChange}
      afterCreate={(r, p) => p && navigate(`/${p.name}?specific=true`)} /> // todo proposedchange is null??
  );

  if (error) {
    return <ErrorScreen error={error} />;
  }
  if (errorResources) {
    return <ErrorScreen error={errorResources} />;
  }

  return (
    <>
      {modal}
      <Container>
        <BreadcrumbBase resourceName={"Resources"} />
        <Group position="apart">
          <h2>Proposed Changes {isAdmin && "- Admin View"}</h2>
          <Button.Group>
            <ReloadButton reloadAction={() => { refetch(); refetchResource(); }} isLoading={isFetching || isFetchingResource || isLoadingResourceRoles} />
            <Button disabled={!canRequest} onClick={openAdd}>Request new</Button>
          </Button.Group>
        </Group>
        {(userRelatedProposedChanges) && (
          <ProposedChangeTable
            changes={userRelatedProposedChanges}
            onChangeSelect={(name) => navigate(`/${name}`)}
          />
        )}
      </Container>
    </>
  );
};
