import AsyncSelect from "react-select/async";
import { User } from "../features/groups/groupsSlice";
import {
  ListUsersRequest,
  User as UserMessage,
} from "../proto/sip/people/people_pb";
import { PeoplePromiseClient } from "../proto/sip/people/people_grpc_web_pb";
import { getAuthMetadata } from "../util/peopleProto";

// getSearchByUsernameFunction creates a search function that can be passed as the
// loadUsers parameter for the SelectUsers component
export const getSearchByUsernameFunction =
  (client: PeoplePromiseClient, token: string, filterOn: string = "username") =>
    (inputValue: string): Promise<User[]> => {
      if (inputValue.length < 3) {
        return Promise.resolve([]);
      }
      const request = new ListUsersRequest();
      request.setView(UserMessage.View.BASIC);
      const filter = new UserMessage.Filter();

      if (filterOn === "username") {
        if ((inputValue.match("^[*a-z0-9-.]{1,30}$") || []).length > 0) {
          filter.setUsername(inputValue);
        } else {
          return Promise.resolve([]);
        }
      } else if (filterOn === "given") {
        filter.setGivenName(inputValue);
      } else if (filterOn === "family") {
        filter.setFamilyName(inputValue);
      }

      request.setFilter(filter);

      return client
        .listUsers(request, getAuthMetadata(token))
        .then((response) => {
          return response.toObject().usersList;
        });
    };

const nameRegex = /^[ -~\u00A0-\u02AF]{1,150}$/;

export const getSearchUserWithNameFunction =
  (client: PeoplePromiseClient, token: string) =>
    (inputValue: string): Promise<User[]> => {
      const nameTokens = inputValue.trim().split(' ');
      const firstName = "*" + ( nameTokens.length > 1 ? nameTokens.slice(0,-1).join(' ') : nameTokens[0] ) + "*";
      const lastName = nameTokens[nameTokens.length - 1];
      console.log(firstName);
      console.log(lastName);
      return Promise.all([
        getSearchByUsernameFunction(client, token, "username")(inputValue),
        getSearchByUsernameFunction(client, token, "given")(firstName.match(nameRegex) ? firstName : ""),
        getSearchByUsernameFunction(client, token, "family")(lastName.match(nameRegex) ? lastName : "")
      ]).then(
        (values) => {
          const fullResults = values[0].reduce((acc, eachArrElem) => {
            if (values[1].findIndex((e) => e.name === eachArrElem.name) === -1) {
              acc.push(eachArrElem)
            }
            return acc
          }, [...values[1]]).reduce((acc, eachArrElem) => {
            if (values[2].findIndex((e) => e.name === eachArrElem.name) === -1) {
              acc.push(eachArrElem)
            }
            return acc
          }, [...values[2]]);

          return fullResults;
        }
      )
    };

interface SUProps {
  loadUsers: (search: string) => Promise<User[]>;
  setUserNames?: (names: string[]) => void;
  isError?: boolean;
  isMulti?: boolean; // whether multiple users can be selected
}

// SelectUser implements an multi select component to select users
export const SelectUsers = ({ loadUsers, setUserNames, isError, isMulti }: SUProps) => {
  return (
    <AsyncSelect
      isMulti={isMulti}
      placeholder={"Select User(s)..."}
      cacheOptions
      defaultOptions
      data-autofocus
      autoFocus={true}
      loadOptions={(search: string) =>
        loadUsers(search).then((users) =>
          users.map((u) => ({ value: u.name, label: u.username }))
        )
      }
      styles={{
        control: (provided, state) => (isError ? {
          ...provided, borderColor: 'red',
        } : provided)
      }}
      onChange={(selected) => {
        if (setUserNames) {
          if (selected) {
            if (Array.isArray(selected)) {
              setUserNames(selected.map((x) => x.value));
            } else {
              const res: string[] = [];
              //@ts-ignore
              res.push(selected.value);
              setUserNames(res);
            }
          } else {
            setUserNames([]);
          }
        }
      }}
    />
  );
};
