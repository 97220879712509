import { Mailto } from "../../../components/Mailto";
import {
  ErrorMessage,
  Field,
  FieldArray,
  FieldArrayRenderProps,
  FieldProps,
  FormikErrors,
  FormikProps,
  getIn,
} from "formik";
import { Button } from "@mantine/core";
import * as Yup from "yup";
import { regexConstants } from "../../../util/regexConstants";
import { DetailCardProps } from "../../../components/DetailPage";
import { MailContactResource, MailDomainInfo, Resource } from "../resourcesSlice";
import { MailDistributionListResource, MailboxQuota } from "../../../proto/sip/resources/resources_pb";
import { GetStringForMailboxQuota } from "../../../util/resourcesProto";
import Select from "react-select";
import { TableFormElement } from "./ResourceDetailCardBase";

export const emailArrayFormatter = (emails: string[]) => (
  <div>
    {emails.map((email: string) => (
      <div key={email}>
        <Mailto email={email} key={email}>
          {email}
        </Mailto>
        <br />
      </div>
    ))}
  </div>
);

export const dblTypeFormatter = (type: Number) => {
  switch (type) {
    case 0:
      return "Unspecified";
    case 1:
      return "Group members";
    case 2:
      return "Use group contacts";
    default:
      return "Invalid";
  };
};

const ErrorMessageArray = ({ name }: { name: string }) => (
  <Field className="text-danger" name={name}>
    {({ form }: { form: FormikProps<any> }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      // the error.length > 1 is a hack to filter out the global errors
      return touch && error && error.length > 1 ? error : null;
    }}
  </Field>
);

export const emailAliasesFieldArray = <T extends object>(
  key: keyof T,
  emailAliasesList: string[],
  errors: FormikErrors<T>,
  keyPrefix: string = "",
  addCaption: string = "Add alias",
  justOneAlias: boolean = false,
) => {

  const prefixedKey = keyPrefix + (key as string)
  const addButton = (arrayHelpers: FieldArrayRenderProps) => (
    <Button
      size="sm"
      color="secondary"
      className="m-1"
      disabled={justOneAlias && emailAliasesList.length > 0}
      onClick={() => arrayHelpers.push("")}
    >
      {addCaption}
    </Button>
  );

  return (
    <FieldArray name={prefixedKey}>
      {(arrayHelpers) => (
        <div>
          {(emailAliasesList && emailAliasesList.length > 0) && (
            emailAliasesList.map((alias, index) => (
              <div className="row" key={index}>
                <div className="col">
                  <Field
                    name={`${prefixedKey}.${index}`}
                    type="text"
                  />
                  {typeof getIn(errors, key as string) !== "string" ? (
                    <ErrorMessage
                      component="div"
                      name={`${prefixedKey}.${index}`}
                      className="field-error"
                    />
                  ) : null}
                </div>
                <div className="col">
                  <Button
                    size="sm"
                    color="secondary"
                    className="m-1"
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            ))
          )}
          {
            addButton(arrayHelpers)
          }
          {typeof getIn(errors, key as string) === "string" ? (
            <ErrorMessage
              component="span"
              name={prefixedKey}
              className="text-danger"
            />
          ) : null}
        </div>
      )}
    </FieldArray>
  )
};

export const yumTestArrayUniqueness = <T extends unknown>(
  list: (T | undefined)[] | undefined
) => {
  if (!list) {
    list = [];
  }
  return list.length === new Set(list).size;
};

export const yupSingleEmailValidator = Yup.string()
  .trim()
  .matches(regexConstants.resource.email, "Invalid email")
  .required("Email can not be empty");

export const yupEmailArrayValidator = (max: number) =>
  Yup.array()
    .of(
      Yup.string()
        .trim()
        .matches(regexConstants.resource.email, "Invalid email")
        .required("Email can not be empty")
    )
    .max(max, `Maximum of ${max} entries allowed`)
    .test("unique", "Duplicate email entry", yumTestArrayUniqueness)
    .test("mainused", "Alias cannot be main email", (val, ctx) => !val?.includes(ctx.resolve(Yup.ref("email"))));

export const mailBaseElements = (specificDataKey: keyof Resource) => {
  return [
    {
      title: "Email",
      key: specificDataKey + ".email" as keyof Resource,
      validation: Yup.string()
        .trim()
        .matches(regexConstants.resource.email, "Invalid email")
        .required("Email is required"),
      formatter: (email: string) => <Mailto email={email}>{email}</Mailto>,
    },
    {
      title: "Org Prefix",
      key: specificDataKey + ".orgPrefix" as keyof Resource,
      validation: Yup.string().required("An existing org prefix is required"),
    },
    {
      title: "Aliases",
      key: specificDataKey + ".emailAliasesList" as keyof Resource,
      formatter: emailArrayFormatter,
      validation: yupEmailArrayValidator(60),
      completelyCustomField: (key: keyof Resource, formik: FormikProps<Resource>) => (
        emailAliasesFieldArray(key, (formik.values[specificDataKey] as MailContactResource).emailAliasesList || [], formik.errors)
      ),
    },
  ]
};

export interface EditableResourceDetailsProps<T extends object>
  extends DetailCardProps<T> {
  isUpdating: boolean;
  onUpdate: (v: T, auditMsg: string) => Promise<any>;
  allowedToUpdateResource: boolean;
  editModalMode?: boolean;
}

const mailDomainKeys = ["domain", "prestigeAlias", "primaryMailPriority"];

const mailboxQuotaSelectValues = Object.values(MailboxQuota)
  .filter((val) => val !== MailboxQuota.MAILBOX_QUOTA_UNSPECIFIED)
  .map((val) => ({
    value: val as MailboxQuota,
    label: GetStringForMailboxQuota(val as MailboxQuota),
  }));

export const mailboxQuotaField = <T extends object>(formik: FieldProps<any, T>) => {
  const value = formik.field.value;
  return (
    <Select
      options={mailboxQuotaSelectValues}
      defaultValue={{ value: value, label: GetStringForMailboxQuota(value) }}
      onChange={(e) =>
        formik.form.setFieldValue(formik.field.name as string, e?.value)
      }
    />
  );
};

export const dblTypeField = <T extends object>(formik: FieldProps<any, T>) => {
  const value = formik.field.value;
  return (
    <>
      <Select
        options={Object.values(MailDistributionListResource.DblType).filter((v) => v !== 0)
          .map((val) => ({ value: val, label: dblTypeFormatter(parseInt(val as string, 10)) }))}
        defaultValue={{ value: value, label: dblTypeFormatter(parseInt(value, 10)) }}
        onChange={(e) =>
          formik.form.setFieldValue(formik.field.name as string, e?.value)
        }
      />
    </>
  );
};

const domainValidator = () =>
  Yup.string()
    .trim()
    .matches(regexConstants.resource.domain, "Invalid domain")
    .required("Domain is required");

export const domainInfoElementValidator = {
  domain: domainValidator(),
  aliasDomainsList: Yup.array()
    .of(domainValidator())
    .max(5, `Maximum of 5 entries allowed`)
    .test("unique", "Duplicate email entry", yumTestArrayUniqueness),
  primaryMailPriority: Yup.number()
    .min(1)
    .max(10)
    .required("Priority is required"),
};

export const domainInfoTableElements: TableFormElement<MailDomainInfo>[] = [
  {
    title: "Domain",
    key: "domain",
    validation: domainValidator(),
  },
  {
    title: "Prestige Alias",
    key: "prestigeAlias",
    formatter: (prestigeAlias: string) => prestigeAlias,
  },
  {
    title: "Alias Domains",
    key: "aliasDomainsList",
    formatter: (domains: string[]) => (
      <div>
        {domains.map((domain: string) => (
          <div key={domain}>
            {domain}
            <br />
          </div>
        ))}
      </div>
    ),
  },
  {
    title: "Primary Mail Priority",
    key: "primaryMailPriority",
  },
];

export const mailDomainInfoArray = <T extends object>(
  key: keyof T,
  domains: MailDomainInfo[],
  errors: FormikErrors<T>,
  justOneAliasAllowed?: boolean,
) => (
  <FieldArray name={key as string}>
    {(arrayHelpers) => (
      <div>
        {domains && domains.length > 0 ? (
          domains.map((domain, index) => (
            <div key={index}>
              {mailDomainKeys.map((domainFieldKey) => (
                <div
                  className="form-group"
                  key={"form-field-" + domainFieldKey}
                >
                  <label>
                    <i>{domainFieldKey}</i>
                  </label>
                  <br />
                  <Field
                    name={`${key as string}.${index}.${domainFieldKey}`}
                    type={
                      domainFieldKey === "prestigeAlias" ? "checkbox" : "text"
                    }
                  />
                  <ErrorMessage
                    component="span"
                    name={`${key as string}.${index}.${domainFieldKey}`}
                    className="text-danger"
                  />
                </div>
              ))}
              <div className="form-group" key={"form-field-alias-domain-list"}>
                <label>
                  <i>Alias Domain List</i>
                </label>
                <br />
                {emailAliasesFieldArray<MailDomainInfo>(
                  "aliasDomainsList",
                  domain.aliasDomainsList,
                  arrayHelpers.form.errors,
                  `${key as string}.${index}.`,
                  "Add alias",
                  justOneAliasAllowed,
                )}
              </div>
              <Button
                size="sm"
                color="secondary"
                className="m-1"
                onClick={() => arrayHelpers.remove(index)} // remove from the list
              >
                Remove
              </Button>
              <Button
                size="sm"
                color="secondary"
                className="m-1"
                onClick={() => arrayHelpers.insert(index + 1, "")} // insert an empty string at a position
              >
                Add
              </Button>
              <ErrorMessageArray name={`${key as string}[${index}]`} />
            </div>
          ))
        ) : (
          <Button
            size="sm"
            color="secondary"
            className="m-1"
            onClick={() => arrayHelpers.push({
              domain: "",
              prestigeAlias: false,
              aliasDomainsList: [],
              primaryMailPriority: 1
            })}
          >
            Add Domain Info
          </Button>
        )}
        {typeof errors[key] === "string" ? (
          <ErrorMessage
            component="span"
            name={key as string}
            className="text-danger"
          />
        ) : null}
      </div>
    )}
  </FieldArray>
);
