import {
  ResourceDetailCardBase,
  TableFormElement,
} from "./ResourceDetailCardBase";
import { EditableResourceDetailsProps } from "./MailResourceHelpers";
import { Resource } from "../resourcesSlice";
import * as Yup from "yup";
import Select from "react-select";

const namespaceOptions = [
  "aaz-apps", "aaz-prod", "aaz-staging", "aaz-test",
  "aiv-apps", "aiv-prod", "aiv-staging", "aiv-test",
  "amiv-apps", "amiv-prod", "amiv-staging", "amiv-test",
  "api-apps", "api-prod", "api-staging", "api-test",
  "apv-apps", "apv-prod", "apv-staging", "apv-test",
  "cert-manager", "chal-apps", "chal-prod", "chal-staging",
  "chal-test", "com-apps", "com-prod", "com-staging",
  "com-test", "cyber-apps", "cyber-prod", "cyber-staging",
  "cyber-test", "deb-apps", "deb-prod", "deb-staging",
  "deb-test", "default", "erfa-apps", "erfa-prod",
  "erfa-staging", "erfa-test", "esf-apps", "esf-prod",
  "esf-staging", "esf-test", "exbe-apps", "exbe-prod",
  "exbe-staging", "exbe-test", "film-apps", "film-prod",
  "film-staging", "film-test", "flik-apps", "flik-prod",
  "flik-staging", "flik-test", "fotoko-apps", "fotoko-prod",
  "fotoko-staging", "fotoko-test", "fuc-apps", "fuc-prod",
  "fuc-staging", "fuc-test", "geco-apps", "geco-prod",
  "geco-staging", "geco-test", "geso-apps", "geso-prod",
  "geso-staging", "geso-test", "heat-apps", "heat-prod",
  "heat-staging", "heat-test", "hg-apps", "hg-prod",
  "hg-staging", "hg-test", "ingress-nginx", "kube-node-lease",
  "kube-public", "kube-system", "kult-apps", "kult-prod",
  "kult-staging", "kult-test", "minio", "minio-operator",
  "monitoring", "mun-apps", "mun-prod", "mun-staging",
  "mun-test", "musikp-apps", "musikp-prod", "musikp-staging",
  "musikp-test", "nafe-apps", "nafe-prod", "nafe-staging",
  "nafe-test", "obis-apps", "obis-prod", "obis-staging",
  "obis-test", "pk-apps", "pk-prod", "pk-staging",
  "pk-test", "play-apps", "play-prod", "play-staging",
  "play-test", "pub-apps", "pub-prod", "pub-staging",
  "pub-test", "public-apps", "public-prod", "public-staging",
  "public-test", "sealed-secrets", "shoqata-apps", "shoqata-prod",
  "shoqata-staging", "shoqata-test", "sip-apps", "sip-prod",
  "sip-staging", "sip-system", "sip-test", "smw-apps",
  "smw-prod", "smw-staging", "smw-test", "spod-apps",
  "spod-prod", "spod-staging", "spod-test", "ssc-apps",
  "ssc-prod", "ssc-staging", "ssc-test", "swaz-apps",
  "swaz-prod", "swaz-staging", "swaz-test", "thealt-apps",
  "thealt-prod", "thealt-staging", "thealt-test", "tq-apps",
  "tq-prod", "tq-staging", "tq-test", "ufo-apps",
  "ufo-prod", "ufo-staging", "ufo-test", "vcs-apps",
  "vcs-prod", "vcs-staging", "vcs-test", "vebis-apps",
  "vebis-prod", "vebis-staging", "vebis-test", "vial-apps",
  "vial-prod", "vial-staging", "vial-test", "vmp-apps",
  "vmp-prod", "vmp-staging", "vmp-test", "vseth-apps",
  "vseth-prod", "vseth-staging", "vseth-test"
];

const tableElements: TableFormElement<Resource>[] = [
  {
    title: "Kubernetes namespace",
    key: "kubernetesPermission.namespace" as keyof Resource,
    validation: Yup.string().required("Kubernetes namespace required").oneOf(namespaceOptions, "Invalid namespace"),
    customFieldInputElement: (formik) => (
      <Select
        options={namespaceOptions.map((nsp) => ({ value: nsp, label: nsp }))}
        placeholder={"Select namespace..."}
        defaultValue={{
          value: formik.form.values.kubernetesPermission?.namespace || "",
          label: formik.form.values.kubernetesPermission?.namespace || ""
        }}
        menuPlacement="top"
        maxMenuHeight={200}
        styles={{
          control: (provided, state) => ((Object.keys(formik.form.errors).length !== 0) ? {
            ...provided, borderColor: 'red',
          } : provided)
        }}
        onChange={(selected) => {
          if (selected) {
            if (Array.isArray(selected)) {
              formik.form.setFieldValue("kubernetesPermission.namespace", selected[0].value);
            } else {
              formik.form.setFieldValue("kubernetesPermission.namespace", selected.value);
            }
          }
        }}
      />
    ),
  },
];

export const KubernetesPermission = (
  props: EditableResourceDetailsProps<Resource>
) => (
  <ResourceDetailCardBase {...props}>{tableElements}</ResourceDetailCardBase>
);

export const kubernetesPermissionTableElements = tableElements;