import React from "react";

interface MailtoProps {
  email: string;
  subject?: string;
  body?: string;
  children: React.ReactNode;
}

// Mailto is a simple component for a <a> with a mailto link
export const Mailto = ({
  email,
  subject = "",
  body = "",
  children,
}: MailtoProps) => {
  let params = subject || body ? "?" : "";
  if (subject) {
    params += `subject=${encodeURIComponent(subject)}`;
  }
  if (body) {
    params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;
  }

  return <a href={`mailto:${email}${params}`}>{children}</a>;
};
