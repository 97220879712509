import { Resource } from "../resourcesSlice";
import {
  ResourceDetailCardBase,
  TableFormElement,
} from "./ResourceDetailCardBase";
import {
  EditableResourceDetailsProps,
  emailArrayFormatter,
  mailBaseElements,
  yupEmailArrayValidator,
  dblTypeFormatter,
  emailAliasesFieldArray,
  dblTypeField,
} from "./MailResourceHelpers";
import { MailDistributionListResource } from "../../../proto/sip/resources/resources_pb";

import * as Yup from "yup";

const tableElements: TableFormElement<Resource>[] = [
  ...mailBaseElements("mailDistributionList"),
  {
    title: "Identifier",
    key: "mailDistributionList.identifier" as keyof Resource,
    disabled: true,
  },
  {
    title: "Distribution list type",
    key: "mailDistributionList.dblType" as keyof Resource,
    disabled: false,  // maybe disable later
    formatter: dblTypeFormatter,
    customFieldInputElement: dblTypeField,
    validation: Yup.string().notOneOf([String(MailDistributionListResource.DblType.DBL_TYPE_UNSPECIFIED)],
      "Distribution list type can not be unspecified"),
  },
  {
    title: "Eth AD Member Emails",
    key: "mailDistributionList.ethAdMemberEmailsList" as keyof Resource,
    formatter: emailArrayFormatter,
    validation: yupEmailArrayValidator(10),
    completelyCustomField: (key, formik) => emailAliasesFieldArray(key,
      formik.values.mailDistributionList?.ethAdMemberEmailsList || [], formik.errors, "", "Add ETH AD Member Email"),
  },
];

export const MailDistributionList = (
  props: EditableResourceDetailsProps<Resource>
) => (
  <ResourceDetailCardBase {...props}>{tableElements}</ResourceDetailCardBase>
);

export const mailDistributionListTableElements = tableElements;