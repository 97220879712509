import { useState } from "react";
import {
  InfoTableBase,
  InfoTableElement,
} from "../../../components/InfoTableBase";
import { DetailCardProps } from "../../../components/DetailPage";
import {
  DetailCardBase,
  JsxValueElement,
} from "../../../components/DetailCardBase";
import { Button } from "@mantine/core";
import { EditFormBase, FormField } from "../../../components/EditFormBase";
import { getIn } from "formik";
import { WithAuditMessage } from "../resourcesSlice";
import { omit } from "lodash";
import { useSelector } from "react-redux";
import { selectAuxAuditMessage, setAuxAuditMessage } from "../../queries/queriesSlice";
import { useDispatch } from "react-redux";

const tableToJsxValueElements = <T extends object>(
  ele: InfoTableElement<T>[],
  data: any,
  keys: (keyof T)[]
): JsxValueElement[] => {
  if (!data) {
    return [];
  }
  return ele
    .filter(({ key }) => keys.length === 0 || keys.indexOf(key) > -1)
    .map(({ title, key, formatter }) => ({
      title,
      value: getIn(data, key as string),
      formatter: formatter ? (value: any) => formatter(value, key) : undefined,
    }));
};

export type TableFormElement<T extends object> = InfoTableElement<T> &
  FormField<T>;

export interface RDCBProps<T extends object> extends DetailCardProps<T> {
  children: TableFormElement<T>[];
  isUpdating: boolean;
  onUpdate: (v: Pick<WithAuditMessage<T>, Exclude<keyof T, "requestAuditMessage">>, auditMessage: string) => Promise<any>;
  allowedToUpdateResource: boolean;
}

export const ResourceDetailCardBase = <T extends object>(
  props: RDCBProps<T>
) => {
  const { data } = props;

  const [isEditing, setIsEditing] = useState(false);

  const savedAuditMessage = useSelector(selectAuxAuditMessage);

  const dispatch = useDispatch();

  if (!data) {
    return <p>Resource Data is undefined</p>;
  }

  return (
    <DetailCardBase
      {...props}
      title={"Specific"}
      overviewElements={tableToJsxValueElements(
        props.children.slice(0, 3),
        data,
        []
      )}
    >
      {isEditing ? (
        <EditFormBase<WithAuditMessage<T>>
          children={(props.children as unknown as TableFormElement<WithAuditMessage<T>>[])
            .concat([{title: "Audit message", key:"requestAuditMessage"} as TableFormElement<WithAuditMessage<T>>])}
          data={{ ...data, requestAuditMessage: savedAuditMessage} as WithAuditMessage<T>}
          isUpdating={props.isUpdating}
          onSubmit={(v) => props.onUpdate(omit(v,"requestAuditMessage"), v.requestAuditMessage).then(() => {
            dispatch(setAuxAuditMessage({text: v.requestAuditMessage}));
            setIsEditing(false);
          })}
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <div>
          <InfoTableBase data={data} elements={props.children} />
          <Button
            color="primary"
            className="float-right mb-3"
            disabled={!props.allowedToUpdateResource}
            onClick={() => setIsEditing(true)}
          >
            Edit
          </Button>
        </div>
      )}
    </DetailCardBase>
  );
};
