import { PrincipalType, ResourcePermission } from "../features/resources/resourcesSlice";
import { ResourceLink } from "./ResourceLink";
import { GetIdentifierFromName } from "../util/peopleProto";
import { GetStringForPrincipalType } from "../util/resourcesProto";
import { CreatePermissionModal } from "../features/resources/CreatePermissionModal";
import { FilePlusIcon } from "vseth-canine-ui";
import { ModElementsTableBase } from "./ModElementsTableBase";
import { ResourceRole } from "./ResourceRole";
import { UserLink } from "./UserLink";
import { ResourceType } from "../proto/sip/resources/resources_pb";


const columns = [
  {
    accessor: "name",
    title: "Identifier",
    sortable: true,
    render: (row: ResourcePermission) =>
      GetIdentifierFromName(row.name),
  },
  {
    accessor: "principalType",
    title: "Principal Type",
    sortable: true,
    render: (row: ResourcePermission) =>
      GetStringForPrincipalType(row.principalType),
  },
  {
    accessor: "principalName",
    title: "Principal",
    sortable: true,
    render: (row: ResourcePermission) => (
      row.principalType !== PrincipalType.USER ?
        <ResourceLink resourceName={row.principalName} isLink={true} />
        : <UserLink user={row.principalName} />
    ),
  },
  {
    accessor: "resourceRole",
    title: "Role",
    sortable: true,
    render: (row: ResourcePermission) => (
      <ResourceRole resourceRoleName={row.resourceRole} />
    ),
  },
];

interface RPTProps {
  // name of the resource we are showing the permissions for
  resourceName: string;

  resourceType: ResourceType; // type of the resource we are showing the permissions for

  ownerGroup: string; // name of the owner group

  // the permissions to display
  permissions: ResourcePermission[];
  enableAddPermissions?: boolean; // whether permissions can be added
  enableRemovePermissions?: boolean; // whether permissions can be removed
  onRemove?: (rp: ResourcePermission[], auditMessage: string) => Promise<any>; // callback when removing a permission
}

// PermissionTable displays an array of permissions in a single table
// probably used to display the permissions of a single resource since no resource information is displayed
export const PermissionTable = ({
  resourceName,
  resourceType,
  ownerGroup,
  permissions,
  enableAddPermissions,
  enableRemovePermissions,
  onRemove,
}: RPTProps) => {
  return (
    <div>
      <ModElementsTableBase
        data={permissions}
        columns={columns}
        keyAccessor="name"
        elementName={"permission"}
        enableAddElements={enableAddPermissions}
        enableRemoveElements={enableRemovePermissions}
        addElementIcon={FilePlusIcon}
        addElementModal={(isOpen, onCancel) => (
          <CreatePermissionModal
            isOpen={isOpen}
            resourceName={resourceName}
            resourceType={resourceType}
            ownerGroup={ownerGroup}
            onCancel={onCancel}
          />
        )}
        onRemoveWithAuditMsg={onRemove}
        enableSelection={onRemove !== undefined}
      />
    </div>
    /*
    */
  );
};
