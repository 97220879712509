// selection of some algorithms taken form elsewhere

import i18n from "../locals/i18n";

// source: https://stackoverflow.com/questions/11935175/sampling-a-random-subset-from-an-array
export function getRandomSubarray<T>(arr: T[], size: number): T[] {
  let shuffled = arr.slice(0),
    i = arr.length,
    min = i - size,
    temp,
    index;
  while (i-- > min) {
    index = Math.floor((i + 1) * Math.random());
    temp = shuffled[index];
    shuffled[index] = shuffled[i];
    shuffled[i] = temp;
  }
  return shuffled.slice(min);
}

// source: https://stackoverflow.com/questions/5915096/get-a-random-item-from-a-javascript-array
export function pickRandomElement<T>(list: T[]): T {
  return list[Math.floor(Math.random() * list.length)];
}

export function containsAtLeastOneOf<T>(all: T[], required: T[]): boolean {
  for (let r of required) {
    if (all.indexOf(r) > -1) {
      return true;
    }
  }
  return false;
}

export function contains<T>(all: T[], element: T): boolean {
  return all.indexOf(element) > -1;
}

export function unique<T>(arr: T[]): T[] {
  return arr.filter(function (elem, index, self) {
    return index === self.indexOf(elem);
  });
}

const dtformat = new Intl.DateTimeFormat('en-GB', { dateStyle: 'medium', timeStyle: 'medium', timeZone: 'Europe/Berlin' }); // todo locale based on preference setting
export function dateToString(date: Date) {
  return dtformat.format(date);
}

export function isValidRegex(r: string) {
  var isValid = true;
  try {
    new RegExp(r);
  } catch (e) {
    isValid = false;
  }
  return isValid;
}

export function getRegexErr(enable: boolean, regex: string) {
  return (enable ? (isValidRegex(regex) ? undefined : "Regular expression invalid") : undefined);
}

const coerceToNumber = (value: any) => {
  const stringAfterPrefix = String(value).split('/').findLast(() => true);
  return Number(stringAfterPrefix);
}

export function compareFn<T extends Object>(dir: string, accessor: keyof T, numeric: boolean = false) {
  const f = dir === 'asc' ? 1 : -1;
  return ((da: T, db: T) => {
    const a = numeric ? coerceToNumber(da[accessor]) : da[accessor];
    const b = numeric ? coerceToNumber(db[accessor]) : db[accessor];
    if (typeof a === 'number' && typeof b === 'number') {
      if (a < b) {
        return -1 * f;
      } else if (a > b) {
        return 1 * f;
      }
      return 0;
    } else if (typeof a === 'string' && typeof b === 'string') {
      return a.localeCompare(b, i18n.language) * f;
    } else {
      const sa = JSON.stringify(a) || "";
      const sb = JSON.stringify(b) || "";
      return sa.localeCompare(sb, i18n.language) * f;
    }
  });
}