import {
  ResourceDetailCardBase,
  TableFormElement,
} from "./ResourceDetailCardBase";
import { Resource } from "../resourcesSlice";
import { EditableResourceDetailsProps } from "./MailResourceHelpers";

const tableElements: TableFormElement<Resource>[] = [
  {
    title: "Space Link",
    key: "confluenceSpace.key" as keyof Resource,
    disabled: true,
    formatter: (key: string) => (
      <a
        href={"https://wiki.vseth.ethz.ch/display/" + key}
        target="_blank"
        rel="noreferrer"
      >
        {"https://wiki.vseth.ethz.ch/display/" + key}
      </a>
    ),
  },
  {
    title: "Is Public",
    key: "confluenceSpace.isPublic" as keyof Resource,
    formatter: (isPublic: string) => String(isPublic),
    customizeField: () => ({ type: "checkbox" }),
  },
];

export const ConfluenceSpace = (
  props: EditableResourceDetailsProps<Resource>
) => (
  <ResourceDetailCardBase {...props}>{tableElements}</ResourceDetailCardBase>
);

export const confluenceSpaceTableElements = tableElements;