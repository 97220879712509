import {
  ResourceDetailCardBase,
  TableFormElement,
} from "./ResourceDetailCardBase";
import { EditableResourceDetailsProps } from "./MailResourceHelpers";
import { Resource } from "../resourcesSlice";

const tableElements: TableFormElement<Resource>[] = [ ];

export const EthIamGroup = (
  props: EditableResourceDetailsProps<Resource>
) => (
  <ResourceDetailCardBase {...props}>{tableElements}</ResourceDetailCardBase>
);

export const ethIamGroupTableElements = tableElements;