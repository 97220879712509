import * as React from "react";
import { useMemo, useState } from "react";
import Tree from "react-d3-tree";
import {
  Button,
  Card,
  Text,
} from "@mantine/core";
import { ObjectTypeBadge } from "./ObjectTypeBadge";
import { Link } from "react-router-dom";
import { Group } from "../features/groups/groupsSlice";
import { GetIdentifierFromName } from "../util/peopleProto";
import { arrayToTree } from "performant-array-to-tree";
import { GroupObjectType } from "../proto/sip/people/people_pb";
import { CustomNodeElementProps, SyntheticEventHandler, TreeNodeDatum } from "react-d3-tree/lib/types/types/common";

interface OTProps {
  groupMap: Record<string, Group>;
}

/**
 * Transform the data loaded from the backend into the TreeNode format that can be displayed by
 * the application.
 * @param groupMap {Object} The data
 */
function generateTreeData(groupMap: Record<string, Group>) {
  const mappedGroups = Object.values(groupMap).map((group) => ({
    name: GetIdentifierFromName(group.name),
    parentName: group.parentName,
    groupName: group.name,
    objectType: group.objectType,
    attributes: {
      // name needs to be overwritten here because this is a field that is used by the tree
      id: GetIdentifierFromName(group.name),
      displayName: group.displayName,
    },
  }));

  const tree = arrayToTree(mappedGroups, {
    id: "groupName",
    parentId: "parentName",
    dataField: null,
  });

  return tree as TreeNodeDatum[];
}

const renderRectSvgNode = ({
  nodeDatum,
  toggleNode,
  onNodeClick,
}: CustomNodeElementProps) => {
  const objectType = (nodeDatum as unknown as { objectType: GroupObjectType })
    .objectType;

  const strokeColor = "#183059";
  const ksColor = "#F03A47";
  const ouColor = "#009FE3";
  const ioeColor =
    objectType === GroupObjectType.GROUP_OBJECT_TYPE_OIOE
      ? "#AF5B5B"
      : "#183059";

  let iconElement;

  const toggleOnClick: SyntheticEventHandler = (e) => {
    toggleNode();
    return onNodeClick(e);
  };

  switch (objectType) {
    case GroupObjectType.GROUP_OBJECT_TYPE_KS:
      iconElement = (
        <rect
          width="20"
          height="20"
          x="-10"
          y="-10"
          onClick={toggleOnClick}
          stroke={strokeColor}
          fill={ksColor}
        />
      );
      break;
    case GroupObjectType.GROUP_OBJECT_TYPE_OU:
      iconElement = (
        <circle
          r={12}
          fill={ouColor}
          stroke={strokeColor}
          onClick={toggleOnClick}
        />
      );
      break;
    default:
      iconElement = (
        <ellipse
          rx="10"
          ry="15"
          fill={ioeColor}
          stroke={strokeColor}
          onClick={toggleOnClick}
        />
      );
  }
  return (
    <g>
      {iconElement}
      <text onClick={onNodeClick} fill={strokeColor} strokeWidth="1" x="20">
        {nodeDatum.name}
      </text>
      {nodeDatum.attributes?.displayName && (
        <text fill="grey" x="20" dy="20" strokeWidth="0.5">
          {nodeDatum.attributes?.displayName}
        </text>
      )}
    </g>
  );
};


/**
 * The OrgTree renders an array of groups using the react-3d-tree library.
 * @param groupMap the map to lookup the group data in
 * @param treeData the data to render
 */
export const OrgTree: React.FC<OTProps> = ({ groupMap }) => {
  const treeData = useMemo((() => generateTreeData(groupMap)), [groupMap]);

  const [selectGroupName, setSelectedGroupName] = useState(
    "groups/vseth-0000-vseth"
  );

  const group = groupMap[selectGroupName];

  const down = window.innerHeight/3;
  const right = window.innerWidth/3; 

  return (
    <div id="treeWrapper" style={{ width: "100%", height: "100%" }}>
      {selectGroupName && (
        <Card withBorder radius="sm" style={{ position: "absolute" }} title={GetIdentifierFromName(selectGroupName)}>
          <Card.Section className="card-header" >
            <ObjectTypeBadge className="mb-5" objectType={group.objectType} />
            <Button.Group className="float-right">
              <Link to={`/${selectGroupName}`}>
                <Button>Show Details</Button>
              </Link>
            </Button.Group>
            <Text className="h5">
              {GetIdentifierFromName(selectGroupName)}
            </Text>
          </Card.Section>
        </Card>
      )}
      <Tree
        data={treeData}
        orientation="vertical"
        separation={{ siblings: 1.2, nonSiblings: 2 }}
        pathFunc="elbow"
        initialDepth={1}
        renderCustomNodeElement={renderRectSvgNode}
        onNodeClick={(item) => {
          if (item) {
            const groupName = (item.data as unknown as {groupName: string}).groupName;
            setSelectedGroupName(groupName);
          }
        }}
        nodeSize={{ x: 140, y: 200 }}
        translate={{ x: right, y: down }}
      />
    </div>
  );
};
