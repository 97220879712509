import {
  ResourceDetailCardBase,
  TableFormElement,
} from "./ResourceDetailCardBase";
import { EditableResourceDetailsProps } from "./MailResourceHelpers";
import { Resource } from "../resourcesSlice";

const tableElements: TableFormElement<Resource>[] = [
  {
    title: "Client ID",
    key: "keycloakClient.clientId" as keyof Resource,
  },
  {
    title: "Enabled",
    key: "keycloakClient.enabled" as keyof Resource,
    formatter: (e: boolean) => String(e),
    customizeField: () => ({ type: "checkbox" }),
  },
  {
    title: "Client protocol",
    key: "keycloakClient.clientProtocol" as keyof Resource,
  },
];

export const KeycloackClient = (
  props: EditableResourceDetailsProps<Resource>
) => (
  <ResourceDetailCardBase {...props}>{tableElements}</ResourceDetailCardBase>
);

export const keycloackClientTableElements = tableElements;