import React, { useState } from "react";

import {
  Text,
  TextInput,
  Modal,
  Checkbox,
  FocusTrap
} from "@mantine/core";
import { usePeopleClient } from "../../app/store";
import { ChangeGroupMembersRequest } from "../../proto/sip/people/people_pb";
import { User, useListPeopleAuthorizationRolesQuery } from "./groupsSlice";
import { useSelector } from "react-redux";
import { useBatchGetUserQuery, useChangeGroupMembersMutation } from "./groupsSlice";
import { AsyncActionButton } from "../../components/AsyncActionButton";
import { regexConstants } from "../../util/regexConstants";
import { GetIdentifierFromName } from "../../util/peopleProto";
import { selectAccessToken, selectIsAdmin, selectUserRoles } from "../auth/authSlice";
import {
  getSearchByUsernameFunction,
  SelectUsers,
} from "../../components/SelectUsers";

interface USProps {
  isOpen: boolean;
  groupName: string;
  onCancelOrFinish: () => void;
  getDefaultAuditMessage: () => string;
  saveAuditMessage: (message: string) => void;
  validateMembers?: (users: (User | undefined)[]) => { isError: boolean, errorText: JSX.Element };
}

export const GroupMemberModal: React.FC<USProps> = ({
  onCancelOrFinish,
  groupName,
  isOpen,
  getDefaultAuditMessage,
  saveAuditMessage,
  validateMembers,
}: USProps) => {
  const [userNames, setUserNames] = useState([] as string[]);

  const [auditMessage, setAuditMessage] = useState(getDefaultAuditMessage());
  const hasDefaultAuditMessage = (getDefaultAuditMessage() !== "");
  const [checked, setChecked] = useState(hasDefaultAuditMessage);
  const auditMessageValid =
    regexConstants.group.auditMessage.test(auditMessage);

  const token = useSelector(selectAccessToken) || "";

  const peopleClient = usePeopleClient();

  const [changeGroupMembers] =
    useChangeGroupMembersMutation();

  const modalTitle = "Add members to " + GetIdentifierFromName(groupName);

  const { data: peopleRoles } = useListPeopleAuthorizationRolesQuery(undefined);
  const userRoles = useSelector(selectUserRoles);
  const isAdmin = selectIsAdmin(peopleRoles, userRoles); 

  const {
    data: selectedUsersData,
    isLoading: isLoadingSelectValidation,
    isFetching: isFetchingSelectValidation,
  } = useBatchGetUserQuery({
    userNamesList: userNames,
    fullView: isAdmin,
  }, {});

  const selectedUsers = selectedUsersData && userNames.map((name) => selectedUsersData.find((u => u.name === name)));

  const {isError: isSelectError, errorText} = (validateMembers && selectedUsers) ? validateMembers(selectedUsers) : {isError: false, errorText: ""};

  const handleRejected = (error: any) => {
    console.log(error);
  };

  return (
    <Modal onClose={onCancelOrFinish} opened={isOpen} title={modalTitle}>
      <Text pb="sm">Type to search</Text>
      <FocusTrap active={true} refProp="innerRef">
        <SelectUsers
          isMulti={true}
          loadUsers={getSearchByUsernameFunction(peopleClient, token)}
          setUserNames={setUserNames}
          isError={isSelectError}
          data-autofocus
        />
      </FocusTrap>
      {isSelectError && errorText}

      <hr />

      <TextInput
        placeholder="Please provide an audit message"
        value={auditMessage}
        onChange={(e) => {
          setAuditMessage(e.target.value);
        }}
        size="md"
        pb="md"
        error={!auditMessageValid && <Text pb="md">{auditMessage.length < 10 ? "Audit message too short" : "Audit message not valid"}</Text>}
      />
      <Checkbox checked={checked} onChange={(event) => {
        setChecked(event.currentTarget.checked);
        (!event.currentTarget.checked && setAuditMessage(""));
      }}
        size="md"
        label="Keep audit message for adding new members"
      />
      <AsyncActionButton
        onCancel={onCancelOrFinish}
        disabled={!auditMessageValid || isSelectError || isLoadingSelectValidation || isFetchingSelectValidation}
        onAction={() => {
          if (checked && auditMessageValid) {
            saveAuditMessage(auditMessage);
          }
          const request = {
            name: groupName,
            auditMessage: auditMessage,
            userNamesList: userNames,
            action:
              ChangeGroupMembersRequest.ChangeGroupMembersAction
                .CHANGE_GROUP_MEMBERS_ACTION_ADD,
          };
          return changeGroupMembers(request).unwrap().then(onCancelOrFinish, handleRejected);
        }}
        buttonSize="sm"
      >
        Add to group
      </AsyncActionButton>
    </Modal>
  );
};
