import { Resource, WithAuditMessage } from "../features/resources/resourcesSlice";
import * as Yup from "yup";
import { SelectGroup } from "./SelectGroup";
import { regexConstants } from "../util/regexConstants";
import { EditFormBase, EditFormProps } from "./EditFormBase";
import { Group } from "../features/groups/groupsSlice";
import { SelectResourceType } from "./SelectResourceType";
import { ResourceType } from "../proto/sip/resources/resources_pb";

interface ERDFProps extends EditFormProps<WithAuditMessage<Resource>> {
  groups: Group[];
  createNew?: boolean;
  saveLabel?: string;
}

const validResourceTypes = Object.values(ResourceType).filter(n => (n !== 0)).map((n)=>(String(n)));

// EditResourceDetailsForm can be used to edit the (general) details of a resource
export const EditResourceDetailsForm = (props: ERDFProps) => {
  return (
    <EditFormBase {...props}>
      {[
        {
          title: "Identifier",
          key: "name",
          disabled: true,
        },
        {
          title: "Display Name",
          key: "displayName",
          validation: Yup.string()
            .trim()
            .matches(
              regexConstants.resource.display_name,
              "Invalid Display Name"
            )
            .required("Display Name is required"),
        },
        {
          title: "Owner Group",
          key: "ownerGroup",
          validation: Yup.string()
            .trim()
            .matches(regexConstants.groupNameRegex, "Invalid Group Name")
            .required("Owner group is required"),
          customFieldInputElement: (formik) => (
            <SelectGroup
              groups={props.groups}
              initialValue={props.data.ownerGroup}
              setGroupNames={(g) =>
                formik.form.setFieldValue("ownerGroup", g[0])
              }
            />
          ),
        },
        {
          title: "Resource Type",
          key: "resourceType",
          disabled: (props.createNew ? false : true),
          validation: Yup.string().oneOf(validResourceTypes),
          customFieldInputElement: (formik) => (
            <SelectResourceType
              initialValue={props.data.resourceType}
              setResourceType={(t) => formik.form.setFieldValue("resourceType", parseInt(t,10))}/>
          ),
        },
        {
          title: "Description",
          key: "description",
          validation: Yup.string()
            .trim()
            .matches(regexConstants.resource.description, "Invalid Description")
            .required("Description is required"),
        },
        {
          title: "Audit message",
          key: "requestAuditMessage",
          validation: Yup.string()
            .trim()
            .matches(regexConstants.group.auditMessage, "Invalid audit message")
            .required("Audit message is required"),
        },
      ]}
    </EditFormBase>
  );
};
