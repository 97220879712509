import { ProposedChange } from "../features/resources/resourcesSlice";
import { EditFormBase, EditFormProps } from "./EditFormBase";

interface EPCDFProps extends EditFormProps<ProposedChange> {
}

// EditProposedChangeDetailsForm can be used to edit the (general) details of a proposed change
export const EditProposedChangeDetailsForm = (props: EPCDFProps) => {
  return (
    <EditFormBase
      {...props}
    >
      {[
        {
          title: "Identifier",
          key: "name",
          disabled: true,
        },
        {
          title: "Comment",
          key: "comment",
        },
        {
          title: "Audit message",
          key: "auditMessage" as keyof ProposedChange,
        }
      ]}
    </EditFormBase>
  );
};
