import {
  GroupBasedScope,
  MailDomainInfo,
  MailServiceResource,
  PrincipalType,
  ProposedChange,
  Resource,
  ResourcePermission,
  ResourcesAuthorizationRole,
  ResourceTypeInfo,
  ResourceTypeInfoRole,
} from "../features/resources/resourcesSlice";
import {
  AuthorizationRole as AuthorizationRoleMessage,
  ConfluenceSpaceResource as ConfluenceSpaceResourceMessage,
  EthIamGroupResource as EthIamGroupResourceMessage,
  SympaMailingListResource as SympaMailingListResourceMessage,
  GitlabGroupResource as GitlabGroupResourceMessage,
  GroupTagResource as GroupTagResourceMessage,
  KeycloakClientResource as KeycloakClientResourceMessage,
  NASStorageFolderResource as NASStorageFolderResourceMessage,
  GoogleCalendarResource as GoogleCalendarResourceMessage,
  OfficeKeyResource as OfficeKeyResourceMessage,
  KubernetesPermissionResource as KubernetesPermissionResourceMessage,
  MailSpecialUserConfigurationsResource as MailSpecialUserConfigurationsResourceMessage,
  GroupBasedScope as GroupBasedScopeMessage,
  GSuiteSharedDriveResource as GSuiteSharedDriveResourceMessage,
  MailboxQuota,
  MailContactResource as MailContactResourceMessage,
  MailDistributionListResource as MailDistributionListResourceMessage,
  MailServiceResource as MailServiceResourceMessage,
  MailSharedMailboxResource as MailSharedMailboxResourceMessage,
  Resource as ResourceMessage,
  ResourcePermission as ResourcePermissionMessage,
  ResourceState,
  ResourceType,
  ResourceTypeInfo as ResourceTypeInfoMessage,
  ProposedChange as ProposedChangeMessage,
  ProposedChangeState,
} from "../proto/sip/resources/resources_pb";
import { timeObjToMessage } from "./peopleProto";
import { regexConstants } from "./regexConstants";
import { diff } from "deep-diff";

const mailDomainToMailDomainMessage = (
  domainInfo: MailDomainInfo
): MailServiceResourceMessage.MailDomainInfo => {
  const message = new MailServiceResourceMessage.MailDomainInfo();
  message.setDomain(domainInfo.domain);
  message.setPrestigeAlias(domainInfo.prestigeAlias);
  message.setAliasDomainsList(domainInfo.aliasDomainsList);
  message.setPrimaryMailPriority(domainInfo.primaryMailPriority);
  return message;
};

const mailServiceToMailServiceMessage = (
  service: MailServiceResource
): MailServiceResourceMessage => {
  const message = new MailServiceResourceMessage();

  message.setOrgPrefix(service.orgPrefix);
  message.setMailboxQuota(service.mailboxQuota);
  message.setSharedMailboxQuota(service.sharedMailboxQuota);
  message.setSharedMailboxOwner(service.sharedMailboxOwner);
  message.setDomainsList(
    service.domainsList.map(mailDomainToMailDomainMessage)
  );
  message.setDisplayNameSuffix(service.displayNameSuffix);

  return message;
};

// turns a resource object into a resource message
export const resourceToResourceMessage = (
  resource: Resource
): ResourceMessage => {
  const message = new ResourceMessage();

  message.setName(resource.name);
  message.setOwnerGroup(resource.ownerGroup);
  message.setResourceType(resource.resourceType);
  message.setCreateTime(timeObjToMessage(resource.createTime));
  message.setUpdateTime(timeObjToMessage(resource.updateTime));
  message.setDisplayName(resource.displayName);
  message.setDescription(resource.description);
  message.setResourceState(resource.resourceState);
  message.setLastUpdateBy(resource.lastUpdateBy);
  message.setPermissionsList(
    resource.permissionsList.map(resourcePermissionToResourcePermissionMessage)
  );

  if (resource.mailGlobal) {
    message.setMailGlobal(mailServiceToMailServiceMessage(resource.mailGlobal));
  }

  if (resource.mailService) {
    message.setMailService(
      mailServiceToMailServiceMessage(resource.mailService)
    );
  }

  if (resource.mailDistributionList) {
    const dbl = resource.mailDistributionList;
    const subMessage = new MailDistributionListResourceMessage();
    if (!Number.isNaN(dbl.identifier))
      subMessage.setIdentifier(dbl.identifier);
    subMessage.setEmail(dbl.email);
    subMessage.setEmailAliasesList(dbl.emailAliasesList);
    subMessage.setOrgPrefix(dbl.orgPrefix);
    subMessage.setEthAdMemberEmailsList(dbl.ethAdMemberEmailsList);
    subMessage.setDblType(dbl.dblType);
    message.setMailDistributionList(subMessage);
  }

  if (resource.mailSharedMailbox) {
    const smb = resource.mailSharedMailbox;
    const subMessage = new MailSharedMailboxResourceMessage();
    if (!Number.isNaN(smb.identifier))
      subMessage.setIdentifier(smb.identifier);
    subMessage.setEmail(smb.email);
    subMessage.setEmailAliasesList(smb.emailAliasesList);
    subMessage.setOrgPrefix(smb.orgPrefix);
    message.setMailSharedMailbox(subMessage);
  }

  if (resource.mailContact) {
    const contact = resource.mailContact;
    const subMessage = new MailContactResourceMessage();
    subMessage.setShortName(contact.shortName);
    subMessage.setFullName(contact.fullName);
    subMessage.setEmail(contact.email);
    subMessage.setEmailAliasesList(contact.emailAliasesList);
    subMessage.setOrgPrefix(contact.orgPrefix);
    message.setMailContact(subMessage);
  }

  if (resource.gsuiteSharedDrive) {
    const drive = resource.gsuiteSharedDrive;
    const subMessage = new GSuiteSharedDriveResourceMessage();
    subMessage.setIdentifier(drive.identifier);
    message.setGsuiteSharedDrive(subMessage);
  }

  if (resource.confluenceSpace) {
    const space = resource.confluenceSpace;
    const subMessage = new ConfluenceSpaceResourceMessage();
    subMessage.setKey(space.key);
    subMessage.setIsPublic(space.isPublic);
    message.setConfluenceSpace(subMessage);
  }

  if (resource.ethIamGroup) {
    const subMessage = new EthIamGroupResourceMessage();
    message.setEthIamGroup(subMessage);
  }

  if (resource.mailSympa) {
    const subMessage = new SympaMailingListResourceMessage();
    subMessage.setEmail(resource.mailSympa.email);
    subMessage.setSubject(resource.mailSympa.subject);
    subMessage.setSubjectTag(resource.mailSympa.subjectTag);
    subMessage.setType(resource.mailSympa.type);
    subMessage.setSendSetup(resource.mailSympa.sendSetup);
    message.setMailSympa(subMessage);
  }

  if (resource.gitlabGroup) {
    const subMessage = new GitlabGroupResourceMessage();
    subMessage.setPath(resource.gitlabGroup.path);
    subMessage.setIdentifier(resource.gitlabGroup.identifier);
    message.setGitlabGroup(subMessage);
  }

  if (resource.groupTag) {
    const subMessage = new GroupTagResourceMessage();
    subMessage.setTagKey(resource.groupTag.tagKey);
    message.setGroupTag(subMessage);
  }

  if (resource.keycloakClient) {
    const subMessage = new KeycloakClientResourceMessage();
    subMessage.setClientId(resource.keycloakClient.clientId);
    subMessage.setClientProtocol(resource.keycloakClient.clientProtocol);
    subMessage.setEnabled(resource.keycloakClient.enabled);
    message.setKeycloakClient(subMessage);
  }

  if (resource.nasStorageFolder) {
    const subMessage = new NASStorageFolderResourceMessage();
    subMessage.setPath(resource.nasStorageFolder.path);
    message.setNasStorageFolder(subMessage);
  }

  if (resource.googleCalendar) {
    const subMessage = new GoogleCalendarResourceMessage();
    subMessage.setIdentifier(resource.googleCalendar.identifier);
    subMessage.setIsPublic(resource.googleCalendar.isPublic);
    message.setGoogleCalendar(subMessage);
  }

  if (resource.officeKey) {
    const subMessage = new OfficeKeyResourceMessage();
    subMessage.setIdentifier(resource.officeKey.identifier);
    subMessage.setRooms(resource.officeKey.rooms);
    message.setOfficeKey(subMessage);
  }

  if (resource.kubernetesPermission) {
    const subMessage = new KubernetesPermissionResourceMessage();
    subMessage.setNamespace(resource.kubernetesPermission.namespace);
    message.setKubernetesPermission(subMessage);
  }

  if (resource.mailSpecialUserConfiguration) {
    const subMessage = new MailSpecialUserConfigurationsResourceMessage();
    subMessage.setPrestigeName(resource.mailSpecialUserConfiguration.prestigeName);
    subMessage.setQuota(resource.mailSpecialUserConfiguration.quota);
    subMessage.setPrimaryEmailAddress(resource.mailSpecialUserConfiguration.primaryEmailAddress);
    subMessage.setEmailAliasesList(resource.mailSpecialUserConfiguration.emailAliasesList);
    subMessage.setHiddenInEthAddressBook(resource.mailSpecialUserConfiguration.hiddenInEthAddressBook);
    message.setMailSpecialUserConfiguration(subMessage);
  }


  return message;
};

// turns a resource permission object into a resource permission message
export const resourcePermissionToResourcePermissionMessage = (
  permission: ResourcePermission
): ResourcePermissionMessage => {
  const message = new ResourcePermissionMessage();

  message.setName(permission.name);
  message.setPrincipalName(permission.principalName);
  message.setPrincipalType(permission.principalType);
  message.setResourceRole(permission.resourceRole);
  message.setCreateTime(timeObjToMessage(permission.createTime));
  message.setUpdateTime(timeObjToMessage(permission.updateTime));

  return message;
};

export const resourceTypeInfoToResourceTypeInfoMessage = (
  typeInfo: ResourceTypeInfo
): ResourceTypeInfoMessage => {
  const message = new ResourceTypeInfoMessage();

  message.setName(typeInfo.name);
  message.setResourceType(typeInfo.resourceType);
  message.setDisplayName(typeInfo.displayName);
  message.setDescription(typeInfo.description);
  message.setDocumentationUrl(typeInfo.documentationUrl);
  message.setIconUrl(typeInfo.iconUrl);
  message.setResourceRolesList(
    typeInfo.resourceRolesList.map(resourceTypeRoleToResourceTypeRoleMessage)
  );

  return message;
};

export const resourceTypeRoleToResourceTypeRoleMessage = (
  role: ResourceTypeInfoRole
): ResourceTypeInfoMessage.ResourceRole => {
  const message = new ResourceTypeInfoMessage.ResourceRole();
  message.setName(role.name);
  message.setDisplayName(role.displayName);
  message.setDescription(role.description);

  return message;
};

export const groupBasedScopeToGroupBasedScopeMessage = (
  scope: GroupBasedScope
): GroupBasedScopeMessage => {
  const message = new GroupBasedScopeMessage();
  message.setGroupName(scope.groupName);
  message.setInherit(scope.inherit);
  message.setScopesList(scope.scopesList);
  return message;
};

export const authorizationRoleToAuthorizationRoleMessage = (
  role: ResourcesAuthorizationRole
): AuthorizationRoleMessage => {
  const message = new AuthorizationRoleMessage();
  message.setName(role.name);
  message.setDisplayName(role.displayName);
  message.setGlobalScopesList(role.globalScopesList);
  message.setGroupBasedScopeList(
    role.groupBasedScopeList.map(groupBasedScopeToGroupBasedScopeMessage)
  );
  return message;
};

// returns a display string for a resource type
export const GetStringForResourceType = (type: ResourceType) => {
  switch (type) {
    case ResourceType.MAIL_GLOBAL:
      return "Global Mail Configuration";
    case ResourceType.MAIL_SERVICE:
      return "Mail Service";
    case ResourceType.MAIL_DISTRIBUTION_LIST:
      return "Distribution List";
    case ResourceType.MAIL_SHARED_MAILBOX:
      return "Shared Mailbox";
    case ResourceType.MAIL_CONTACT:
      return "Mail Contact";
    case ResourceType.ETH_IAM_GROUP:
      return "ETH IAM Group";
    case ResourceType.SYMPA_MAILING_LIST:
      return "Sympa Mailing List";
    case ResourceType.GSUITE_SHARED_DRIVE:
      return "Shared Drive";
    case ResourceType.CONFLUENCE_SPACE:
      return "Confluence Space";
    case ResourceType.GITLAB_GROUP:
      return "Gitlab Group";
    case ResourceType.GROUP_TAG:
      return "Group Tag";
    case ResourceType.KEYCLOAK_CLIENT:
      return "Keycloak Client";
    //case ResourceType.NAS_STORAGE_FOLDER:
    //  return "NAS Storage Folder";
    case ResourceType.GOOGLE_CALENDAR:
      return "Google Calendar";
    //case ResourceType.OFFICE_KEY:
    //  return "Office key";
    //case ResourceType.KUBERNETES_PERMISSION:
    //  return "Kubernetes Permission";
    case ResourceType.MAIL_SPECIAL_USER_CONFIGURATION:
      return "Mail Special User Configuration";
  }
  return "Resource Type not known";
};

// returns a display string for a resource state 
export const GetStringForResourceState = (state: ResourceState) => {
  switch (state) {
    case ResourceState.CREATED:
      return "Created";
    case ResourceState.DELETED:
      return "Deleted";
    case ResourceState.RESOURCE_STATE_UNSPECIFIED:
      return "Resource State unspecified";
  }
  return "Resource State not known";
};

export const GetStringForMailboxQuota = (quota: MailboxQuota) => {
  switch (quota) {
    case MailboxQuota.GB_1:
      return "1 GB";
    case MailboxQuota.GB_5:
      return "5 GB";
    case MailboxQuota.GB_10:
      return "10 GB";
    case MailboxQuota.GB_15:
      return "15 GB";
  }
  return "Quota unspecified";
};

export const GetStringForPrincipalType = (
  type: ResourcePermissionMessage.PrincipalType
) => {
  switch (type) {
    case PrincipalType.GROUP:
      return "Group";
    case PrincipalType.USER:
      return "User";
  }
  return "Principal Type not known";
};

export const GetStringForSympaMailinglistType = (
  type: SympaMailingListResourceMessage.ListType
) => {
  switch (type) {
    case SympaMailingListResourceMessage.ListType.LIST_TYPE_UNSPECIFIED:
      return "Unspecified type";
    case SympaMailingListResourceMessage.ListType.CONTACT_EMAIL_OF_GROUPS:
      return "Contact email of groups";
    case SympaMailingListResourceMessage.ListType.GROUP_MEMBERS:
      return "Group members";
    case SympaMailingListResourceMessage.ListType.IMPORT_FROM_FILE:
      return "Import from file";
    case SympaMailingListResourceMessage.ListType.INTERNAL_SYMPA_MANAGED:
      return "Internal sympa managed";
    case SympaMailingListResourceMessage.ListType.NEWSLETTER_PUBLIC:
      return "Public Newsletter";
    case SympaMailingListResourceMessage.ListType.NEWSLETTER_SWITCH:
      return "Switch Newsletter";
  }
  return "Sympa Mailinglist type not known";
};

export const GetStringForSympaSendSetup = (
  type: SympaMailingListResourceMessage.SendSetup
) => {
  switch (type) {
    case SympaMailingListResourceMessage.SendSetup.MODERATED:
      return "Moderated";
    case SympaMailingListResourceMessage.SendSetup.MODERATED_FOR_NON_SUBSCRIBERS:
      return "Moderated for non subscribers";
    case SympaMailingListResourceMessage.SendSetup.PUBLIC:
      return "Public";
    case SympaMailingListResourceMessage.SendSetup.SEND_SETUP_UNSPECIFIED:
      return "Send setup unspecified";
  }
  return "Sympla send setup not known";
};

// extracts the resource name from a resource permission name
export const getResourceNameFromResourcePermissionName = (name: string) => {
  const m = name.match(regexConstants.resourceNameRegex);
  return m && m.length === 1 ? m[0] : "";
};

export const getResourceTypeFromRoleName = (name: string): ResourceType => {
  const m = name.match(/[0-9]{1,2}/);
  return m && m.length === 1
    ? (parseInt(m[0]) as ResourceType)
    : ResourceType.RESOURCE_TYPE_UNSPECIFIED;
};

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

// helper method that generates the field mask paths that are needed to update
// a user object through the people api
export function GenerateDiffPaths<T>(
  initialObject: T,
  updateObject: T
): string[] {
  const differences = diff(initialObject, updateObject);

  if (!differences) {
    return [];
  }

  return differences
    .map((change) => {
      if (!change.path) {
        return "";
      }

      // remove numbers .9 and stuff from the end -> indicate an array
      const joinedPath = camelToSnakeCase(change.path.join("."));

      return (
        joinedPath
          // remove the _list suffix (added by the JS protoc generator for arrays)
          .replaceAll("_list", "")
          // change back mail_distribution to mail_distribution_list
          .replaceAll("mail_distribution", "mail_distribution_list")
          // replace the array [$number] notation at the end of the string
          .replaceAll(/\.\d+($|\..*$)/g, "")
      );
    })
    .filter((path) => path !== "");
}

export const proposedChangeToProposedChangeMessage = (
  pc: ProposedChange
): ProposedChangeMessage => {
  const message = new ProposedChangeMessage();
  message.setName(pc.name);
  message.setResourceName(pc.resourceName);

  if (pc.data) {
    const dataResourceMessage = resourceToResourceMessage(pc.data);
    message.setData(dataResourceMessage);
  }

  message.setPathList(pc.pathList);
  message.setLastUpdatedBy(pc.lastUpdatedBy);
  message.setState(pc.state);
  message.setComment(pc.comment);
  message.setCreateTime(timeObjToMessage(pc.createTime));
  message.setUpdateTime(timeObjToMessage(pc.updateTime));
  message.setFinishTime(timeObjToMessage(pc.finishTime));

  return message;
};

export const proposedChangeFilterToProposedChangeFilterMessage = (
  filter: ProposedChangeMessage.Filter.AsObject | {}
): ProposedChangeMessage.Filter => {
  const filterM = new ProposedChangeMessage.Filter();

  if("name" in filter)
    filterM.setName(filter.name);
  if("resourceName" in filter)
    filterM.setResourceName(filter.resourceName);
  if("state" in filter)
    filterM.setState(filter.state);
  if("sortBy" in filter)
    filterM.setSortBy(filter.sortBy);
  if("sortDescending" in filter)
    filterM.setSortDescending(filter.sortDescending);

  return filterM;
};

export const GetStringForProposedChangeState = (state: ProposedChangeState) => {
  switch (state) {
    case ProposedChangeState.ACCEPTED:
      return "Change accepted";
    case ProposedChangeState.DENIED:
      return "Change denied";
    case ProposedChangeState.OPEN:
      return "Change open";
    case ProposedChangeState.PROPOSED_CHANGE_STATE_UNDEFINED:
      return "Change status undefined";
  }
  return "STATE UNDEFINED";
};
