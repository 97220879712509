import { useState } from "react";
import { Text, Affix, Alert, rem } from "@mantine/core";
import { WarningIcon } from "vseth-canine-ui";
import { Link } from "react-router-dom";
import { selectTestingAffix, setTestingAffix } from "../features/queries/queriesSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";

/**
 * TestingFeedbackAffix displays a message to remind the user to provide feedback and report bugs
 * for versions that are still being developed
 */
export const TestingFeedbackAffix = () => {

  const savedTestingAffix = useSelector(selectTestingAffix);
  const cookies = new Cookies();
  const lsStoredTestingAffix = cookies.get('hideTestingAffix');
  const [hidden, setHidden] = useState(savedTestingAffix && lsStoredTestingAffix);

  const dispatch = useDispatch();

  return (
    !hidden ? (
    <Affix miw={rem("400")} maw={rem("40%")} position={{ top: rem(10), right: rem(10) }}>
      <Alert icon={<WarningIcon />} title="New release" color="yellow" withCloseButton 
      onClose={() => {setHidden(true); dispatch(setTestingAffix({hidden: true})); cookies.set('hideTestingAffix', 'true', {expires: new Date(9999,10,30)}); }}>
        <Text>This version uses the new resources backend and is a work-in-progress. Please report bugs and provide <Link to="/about">feedback</Link> if something is broken 
          or uncomfortable!
        </Text>
      </Alert>
    </Affix>
    ) : <></>
  );
};
