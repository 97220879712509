const uuidRegex =
  "[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}";
const groupIdentifier = "vseth-([a-zA-Z0-9]{1,10}-){1,}([a-zA-Z0-9]{1,10})";

export const regexConstants = {
  ksGroup: /^groups\/vseth-[0-9]{2}00-[a-z]{2,10}$/,

  userNameRegex: new RegExp("^users/" + uuidRegex + "$"),
  groupNameRegex: new RegExp("^groups/" + groupIdentifier + "$"),

  resourceNameRegex: /^resources\/[0-9]+/,

  gitlabVsethUrl: /^vseth\/\S+$/,

  group: {
    // audit message for the people api
    auditMessage: /^[ -~]{10,1024}$/,
    phone: /^[0-9+ x()\-.]{1,30}/,
    display_name: /^[ -~\u00A0-\u00FF]{3,50}$/,
  },
  resource: {
    display_name: /^[ -~\u00A0-\u00FF]{3,50}$/,
    description: /^[ -~\u00A0-\u00FF]{8,500}$/,
    domain: /^[a-z0-9-]{2,15}\.(ch|org|ethz\.ch|vseth\.ch)$/,
    email: /^[a-z0-9\-._]{2,40}@[a-z-]{2,15}\.(ch|org|ethz\.ch|vseth\.ch)$/,
    msucAccount: /^[a-z]{3,5}-[a-z0-9\-._]{2,40}$/,
    orgPrefix: /^[a-z]{3,6}$/,
    mailDisplayNameSuffix: /(^$|^[a-zA-Z]{3,15}$)/,
  },
};
