import { OrgTree } from "../../components/OrgTree";
import { LoadScreen } from "../../components/LoadScreen";
import { Group, useListGroupsQuery, useListPeopleAuthorizationRolesQuery } from "./groupsSlice";
import { selectAllowedToListGroups, selectIsAuthenticated, selectUserRoles } from "../auth/authSlice";
import { useSelector } from "react-redux";
import { Options } from "react-select";

/**
 * Display the Org Tree as a Page
 */
export const OrgStrucPage = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  // load the people and resources api authorization information
  const { data: peopleRoles, isLoading: isLoadingPeopleRoles } =
    useListPeopleAuthorizationRolesQuery(undefined, {
      skip: !isAuthenticated,
    });

  const userRoles = useSelector(selectUserRoles);

  const isAllowedToListGroups = selectAllowedToListGroups(peopleRoles, userRoles);

  const {
    data: groups,
    isLoading,
  } = useListGroupsQuery({}, {skip: !isAllowedToListGroups});

let groupMap: { [name: string]: Group } = {};
if (groups) {
  groups.forEach((group) => (groupMap[group.name] = group));
}

if (isLoading || !isAuthenticated || Object.keys(groupMap).length === 0) {
  return <LoadScreen />;
}

return <OrgTree key={"tree-key"} groupMap={groupMap} />;
};
