import { useState } from "react";
import { JsxValueElement } from "./DetailCardBase";
import { Tabs } from "@mantine/core";

interface TBProps {
  tabs: JsxValueElement[];
}

// TabBase implements a simple tab view
export const TabBase = ({ tabs }: TBProps) => {
  const [activeTab, setActiveTab] = useState("0");

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div>
      <Tabs value={activeTab} onTabChange={(value) => toggle(value ? value : "0")}>
        <Tabs.List>
        {tabs.map((t, i) => (
          <Tabs.Tab value={String(i)} >{t.title}</Tabs.Tab>
        ))}
        </Tabs.List>
      <br />
        {tabs.map((t, i) => {
          let jsx: JSX.Element | string = t.value || "";
          if (t.formatter !== undefined) {
            jsx = t.formatter(t.value);
          }
          return <Tabs.Panel value={String(i)}>{jsx}</Tabs.Panel>;
        })}
      </Tabs>
    </div>
  );
};
