import { createSelector, createSlice } from '@reduxjs/toolkit'

const initialState = {
  groups: {
    text: "",
    regex: false,
  },
  resources: {
    text: "",
    type: [],
    regex: false,
  },
  proposedChanges: {
    text: "",
    type: [],
    regex: false,
  },
  requestedResources: {
    text: "",
    type: [],
    regex: false,
  },
  addUserAuditMsg: "",
  auxAuditMsg: "",
  testingAffix: false,
};

const queriesSlice = createSlice({
  name: 'queries',
  initialState,
  reducers: {
    setGroupsQuery(state, action) {
      state.groups = {
        text: action.payload.text,
        regex: action.payload.regex || false,
      };
    },
    setResourcesQuery(state, action) {
      state.resources = {
        text: action.payload.text,
        type: action.payload.type ? action.payload.type : [],
        regex: action.payload.regex || false,
      };
    },
    setProposedChangesQuery(state, action) {
      state.proposedChanges = {
        text: action.payload.text,
        type: action.payload.type ? action.payload.type : [],
        regex: action.payload.regex || false,
      };
    },
    setRequestedResourcesQuery(state, action) {
      state.requestedResources = {
        text: action.payload.text,
        type: action.payload.type ? action.payload.type : [],
        regex: action.payload.regex || false,
      };
    },
    setAddUserAuditMessage(state, action) {
      state.addUserAuditMsg = action.payload.text;
    },
    setAuxAuditMessage(state, action) {
      state.auxAuditMsg = action.payload.text;
    },
    setTestingAffix(state, action) {
      state.testingAffix = action.payload.hidden;
    }
  }
});

const selectProposedChangesQuery = createSelector(
  (state: QueriesSliceRoot) => state.queries,
  (queries: typeof initialState) => queries.proposedChanges
);

const selectRequestedResourcesQuery = createSelector(
  (state: QueriesSliceRoot) => state.queries,
  (queries: typeof initialState) => queries.requestedResources
);

const selectAddUserAuditMessage = createSelector(
  (state: QueriesSliceRoot) => state.queries,
  (queries: typeof initialState) => queries.addUserAuditMsg,
);

const selectAuxAuditMessage = createSelector(
  (state: QueriesSliceRoot) => state.queries,
  (queries: typeof initialState) => queries.auxAuditMsg,
);

const selectResourcesQuery = createSelector(
  (state: QueriesSliceRoot) => state.queries,
  (queries: typeof initialState) => queries.resources
);

const selectGroupsQuery = createSelector(
  (state: QueriesSliceRoot) => state.queries,
  (queries: typeof initialState) => queries.groups
);

const selectTestingAffix = createSelector(
  (state: QueriesSliceRoot) => state.queries,
  (queries: typeof initialState) => queries.testingAffix,
);

export const { setGroupsQuery, setResourcesQuery, setProposedChangesQuery, setRequestedResourcesQuery, setAddUserAuditMessage, setAuxAuditMessage, setTestingAffix } = queriesSlice.actions;

export { selectAddUserAuditMessage, selectAuxAuditMessage, selectGroupsQuery, selectProposedChangesQuery, selectRequestedResourcesQuery, selectResourcesQuery, selectTestingAffix };

export default queriesSlice.reducer;

export type QueriesSliceRoot = {
  queries: ReturnType<typeof queriesSlice.reducer>;
};