import {
  ErrorMessage,
  Field,
  FieldProps,
  Form,
  Formik,
  FormikProps,
} from "formik";
import {
  PrincipalType,
  ResourcePermission,
  ResourceTypeInfo,
  WithAuditMessage,
} from "../features/resources/resourcesSlice";
import { Button, Tooltip } from "@mantine/core";
import { SelectGroup } from "./SelectGroup";
import { Group, User } from "../features/groups/groupsSlice";
import { SelectUsers } from "./SelectUsers";
import Select from "react-select";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { selectAuxAuditMessage, setAuxAuditMessage } from "../features/queries/queriesSlice";
import { useDispatch } from "react-redux";
import { ResourceType } from "../proto/sip/resources/resources_pb";

const formatOptionLabel = ({ value, label, hint }: { value: string, label: string, hint: string }) => (
  <Tooltip
    label={hint}
    multiline
    openDelay={250}
  >
    <div style={{ height: "100%" }}>{label}</div>
  </Tooltip>
);

interface CPFProps {
  resourceName: string;

  resourceTypeInfo: ResourceTypeInfo;

  onCancel?: () => void;
  isUpdating: boolean;
  onSubmit: (permission: ResourcePermission, auditMessage: string) => void;

  // the possible options for the group select
  groups: Group[];
  // async loading of the user options
  loadUsers: (search: string) => Promise<User[]>;
}

// todo: the forms (also the addmember form) should have the resource / group as a disabled value
// but also support a search field for this value, so we can also open up the modal from other pages.

export const CreatePermissionForm = ({
  resourceName,
  resourceTypeInfo,
  onCancel,
  isUpdating,
  onSubmit,
  groups,
  loadUsers,
}: CPFProps) => {

  const savedAuxAuditMessage = useSelector(selectAuxAuditMessage);

  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        name: "",
        principalType: PrincipalType.GROUP,
        resourceRole: "",
        principalName: "",
        requestAuditMessage: savedAuxAuditMessage,
      }}
      validateOnMount={true}
      validationSchema={Yup.object({
        principalType: Yup.number()
          .min(1)
          .max(2)
          .required("Principal Type is required"),
        principalName: Yup.string()
          .matches(/^(users|groups)\//, "Valid resource principal")
          .required("Permission Principal is required"),
        resourceRole: (
          ( resourceTypeInfo.resourceType !== ResourceType.KEYCLOAK_CLIENT ) ?
          Yup.string().oneOf(
            resourceTypeInfo.resourceRolesList.map((r) => r.name),
            "Should be one of the allowed roles"
          ).required("resource role is required")
          :
          Yup.string().matches(/^resourcetypes\/12\/roles\/.+/, "Must start with resourcetypes/12/roles/ and be non-empty").required("resource role is required")
        ),
        requestAuditMessage: Yup.string().required("Audit message required").min(10),
      })}
      onSubmit={(permission) => {
        dispatch(setAuxAuditMessage({ text: permission.requestAuditMessage }));
        onSubmit(permission, permission.requestAuditMessage);
      }}
    >
      {(formik: FormikProps<WithAuditMessage<ResourcePermission>>) => (
        <div className="col-lg-12">
          <Form onSubmit={formik.handleSubmit}>
            {/*<div className="form-group">*/}
            {/*  <label>Resource</label>*/}
            {/*  <Field*/}
            {/*    name="resourceName"*/}
            {/*    type="text"*/}
            {/*    disabled={true}*/}
            {/*    // className={`form-control ${formik.touched.resourceName && formik.errors.resourceName ? "is-invalid" : ""*/}
            {/*    // }`}*/}
            {/*  />*/}
            {/*  <ErrorMessage component="span" name="displayName" className="text-danger"/>*/}
            {/*</div>*/}
            <div className="form-group">
              <label>Resource Role </label>
              <Field
                name="resourceRole"
                className={`form-control ${formik.touched.resourceRole && formik.errors.resourceRole
                  ? "is-invalid"
                  : ""
                  }`}
              >
                {(formik: FieldProps<any, WithAuditMessage<ResourcePermission>>) => (
                  (resourceTypeInfo.resourceType !== ResourceType.KEYCLOAK_CLIENT) ?
                    <Select
                      options={resourceTypeInfo.resourceRolesList.map((r) => ({
                        value: r.name,
                        label: r.displayName,
                        hint: r.description,
                      }))}
                      formatOptionLabel={formatOptionLabel}
                      onChange={(e) =>
                        formik.form.setFieldValue(formik.field.name, e?.value)
                      }
                    />
                    :
                    <Field name="resourceRole" type="text" className="form-control" />
                )}
              </Field>
              <ErrorMessage
                component="span"
                name="resourceRole"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <label>Principal Type</label>
              <Field name="principalType">
                {(formik: FieldProps<any, WithAuditMessage<ResourcePermission>>) => (
                  <Select
                    options={[
                      { value: PrincipalType.GROUP, label: "Group" },
                      {
                        value: PrincipalType.USER,
                        label: "User",
                      },
                    ]}
                    defaultValue={{ value: formik.field.value, label: "Group" }}
                    onChange={(e) =>
                      formik.form.setFieldValue(formik.field.name, e?.value)
                    }
                  />
                )}
              </Field>
              <ErrorMessage
                component="span"
                name="principalType"
                className="text-danger"
              />
            </div>
            {formik.values.principalType === PrincipalType.GROUP && (
              <div className="form-group">
                <label>Group</label>
                <Field name="principalName">
                  {(formik: FieldProps<any, WithAuditMessage<ResourcePermission>>) => (
                    <SelectGroup
                      groups={groups}
                      setGroupNames={(g) =>
                        formik.form.setFieldValue(formik.field.name, g[0])
                      }
                    />
                  )}
                </Field>
              </div>
            )}
            {formik.values.principalType === PrincipalType.USER && (
              <div className="form-group">
                <label>User</label>
                <Field name="principalName">
                  {(formik: FieldProps<any, WithAuditMessage<ResourcePermission>>) => (
                    <SelectUsers
                      isMulti={false}
                      loadUsers={loadUsers}
                      setUserNames={(u) =>
                        formik.form.setFieldValue(formik.field.name, u[0])
                      }
                    />
                  )}
                </Field>
              </div>
            )}
            <div className="form-group">
              <label>Audit message</label>
              <Field name="requestAuditMessage" type="text"
                className={`form-control ${formik.touched.requestAuditMessage && formik.errors.requestAuditMessage
                  ? "is-invalid"
                  : ""
                  }`} />
              <ErrorMessage
                component="span"
                name="requestAuditMessage"
                className="text-danger"
              />
            </div>
            {(
              <Button.Group className="d-flex justify-content-end">
                <Button color="secondary" onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  loading={isUpdating}
                  disabled={!formik.dirty || !formik.isValid}
                >
                  Save
                </Button>
              </Button.Group>
            )}
          </Form>
        </div>
      )}
    </Formik>
  );
};
