export const i18n_german = {
  translation: {
    lang: "de",
    ErrorScreen: {
      title: "An error occurred.",
      body: "Try reloading the page and contact the administrator if the error persists.",
    },
    NotAuthorizedScreen: {
      title: "Not Authorized to access this page",
      body: "You are not authorized to access this page, if you think this is an error please contact the VSETH ISG.",
    },
    "Display Name": "Anzeigename",
    "Email" : "E-Mail",
    "Organization" : "Organisation",
    "Title (DE)" : "Titel DE",
    "Title (EN)" : "Titel EN",
    "Group Type" : "Gruppentyp",
    "Identifier" : "ID",
  },

};
