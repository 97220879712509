import { combineReducers } from "@reduxjs/toolkit";

import { peopleApi } from "../features/groups/groupsSlice";
import auth from "../features/auth/authSlice";
import { resourcesApi } from "../features/resources/resourcesSlice";
import queriesReducer from "../features/queries/queriesSlice";
import { persistReducer } from "redux-persist";
import session from 'redux-persist/lib/storage/session'
import localStorage from "redux-persist/es/storage";

/**
 * Construct and export the root reducer used in this redux application
 */

const authPersistConfig = {
  key: 'auth',
  storage: session,
  whitelist: ['debugRoles'],
}

const queriesPersistConfig = {
  key: 'queries',
  storage: localStorage,
}

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  queries: persistReducer(queriesPersistConfig, queriesReducer),
  [peopleApi.reducerPath]: peopleApi.reducer,
  [resourcesApi.reducerPath]: resourcesApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
