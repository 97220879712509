import { DetailCardProps } from "../../components/DetailPage";
import { Group } from "./groupsSlice";
import { DetailCardBase } from "../../components/DetailCardBase";
import { TabBase } from "../../components/TabBase";
import {
  ResourcePermissionTable,
  ResourcePermissionWithExtraFields,
} from "../../components/ResourcePermissionTable";

interface GPProps extends DetailCardProps<Group> {
  permissions: ResourcePermissionWithExtraFields[];
  inheritedPermissions: ResourcePermissionWithExtraFields[];
}

// GroupPermissions shows the different resource permissions of a group
export const GroupPermissions = (props: GPProps) => {
  const { permissions, inheritedPermissions } = props;

  const overviewElements = [
    {
      title: "Permissions",
      value: permissions.length.toString(),
    },
    {
      title: "Inherited Permissions",
      value: inheritedPermissions.length.toString(),
    },
    {
      title: "Total",
      value: (permissions.length + inheritedPermissions.length).toString(),
    },
  ];

  return (
    <DetailCardBase
      {...props}
      title={"Permissions on Resources"}
      overviewElements={overviewElements}
    >
      <TabBase
        tabs={[
          {
            title: `Direct Permissions (${permissions.length})`,
            formatter: () => (
              <ResourcePermissionTable permissions={permissions} />
            ),
          },
          {
            title: `Inherited Permissions (${inheritedPermissions.length})`,
            formatter: () => (
              <ResourcePermissionTable permissions={inheritedPermissions} />
            ),
          },
        ]}
      />
    </DetailCardBase>
  );
};
