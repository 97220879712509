import { PrincipalType, ResourcePermission } from "../features/resources/resourcesSlice";
import { ResourceLink } from "./ResourceLink";
import { ResourceIcon } from "./ResourceIcon";
import {
  getResourceNameFromResourcePermissionName,
  getResourceTypeFromRoleName,
} from "../util/resourcesProto";
import { ModElementsTableBase } from "./ModElementsTableBase";
import { ResourceRole } from "./ResourceRole";
import { UserLink } from "./UserLink";

const columns = [
  {
    accessor: "name",
    title: "Identifier",
    sortable: true,
    render: (permission: ResourcePermission) => (
      <ResourceLink
        resourceName={getResourceNameFromResourcePermissionName(
          permission.name
        )}
      />
    ),
  },
  {
    accessor: "_resourceRole",
    title: "Resource Type",
    sortable: true,
    render: (permission: ResourcePermissionWithExtraFields) => (
      <ResourceIcon
        resourceType={getResourceTypeFromRoleName(permission.resourceRole)}
      />
    ),
  },
  {
    accessor: "resourceName",
    title: "Display Name",
    sortable: true,
  },
  {
    accessor: "principalName",
    title: "Principal",
    sortable: true,
    render: (row: ResourcePermission) => (
      row.principalType !== PrincipalType.USER ?
        <ResourceLink resourceName={row.principalName} isLink={true} />
        : <UserLink user={row.principalName} />
    ),
  },
  {
    accessor: "resourceRole",
    title: "Role",
    sortable: true,
    render: (row: ResourcePermission) => (
      <ResourceRole resourceRoleName={row.resourceRole} />
    ),
  },
];

export interface ResourcePermissionWithExtraFields extends ResourcePermission {
  resourceName: string;
}

interface RPTProps {
  permissions: ResourcePermissionWithExtraFields[];
}

// ResourcePermissionTable displays the permissions for a resource in a table
export const ResourcePermissionTable = ({ permissions }: RPTProps) => {

  return (
    <div>
      <hr />
      <ModElementsTableBase
        data={permissions}
        columns={columns}
        keyAccessor="name"
        elementName={"permission"}
        enableAddElements={false}
        enableRemoveElements={false}
      />
    </div>
  );
};
