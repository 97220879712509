import {
  ResourceDetailCardBase,
  TableFormElement,
} from "./ResourceDetailCardBase";
import { Resource } from "../resourcesSlice";
import { EditableResourceDetailsProps } from "./MailResourceHelpers";

const tableElements: TableFormElement<Resource>[] = [
  {
    title: "Drive Link",
    key: "gsuiteSharedDrive.identifier" as keyof Resource,
    disabled: true,
    formatter: (identifier: string) => (
      <a
        href={"https://drive.google.com/drive/u/0/folders/" + identifier}
        target="_blank"
        rel="noreferrer"
      >
        {"https://drive.google.com/drive/u/0/folders/" + identifier}
      </a>
    ),
  },
];

export const GsuiteDrive = (
  props: EditableResourceDetailsProps<Resource>
) => (
  <ResourceDetailCardBase {...props}>{tableElements}</ResourceDetailCardBase>
);

export const gsuiteDriveTableElements = tableElements;