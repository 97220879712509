import { Accordion, Chip, Container, Grid, Space, Text } from "@mantine/core";
import { PostalAddress, User, VsethMembershipInfo, useGetUserQuery } from "../groups/groupsSlice";
import { InfoTableBase, InfoTableElement } from "../../components/InfoTableBase";
import { GetIdentifierFromName } from "../../util/peopleProto";
import { LoadScreen } from "../../components/LoadScreen";
import { BankAccount, VsethMembership, VsethMembershipType } from "../../proto/sip/people/people_pb";
import { Date } from "../../proto/google/type/date_pb";
import React from "react";
import { ResourceLink } from "../../components/ResourceLink";

const formatVsethMembershipType = (
  membershipType: VsethMembershipType
): string => {
  switch (membershipType) {
    case VsethMembershipType.VSETH_MEMBERSHIP_TYPE_BACHELOR_OR_MASTER:
      return "Bachelor or Master";
    case VsethMembershipType.VSETH_MEMBERSHIP_TYPE_PHD:
      return "PhD";
    case VsethMembershipType.VSETH_MEMBERSHIP_TYPE_VSETH_EMPLOYEES:
      return "Vseth Employee";
    case VsethMembershipType.VSETH_MEMBERSHIP_TYPE_ETH_APPRENTICES:
      return "ETH Apprentice";
    case VsethMembershipType.VSETH_MEMBERSHIP_TYPE_MOBILITY_STUDENTS:
      return "Nobility Student";
    case VsethMembershipType.VSETH_MEMBERSHIP_TYPE_STUDENTS_IN_CONTINUING_EDUCATION:
      return "In continuing Education";
    case VsethMembershipType.VSETH_MEMBERSHIPO_TYPE_AUDITORS:
      return "Auditor";
    default:
      return "unspecified";
  }
};

const basicInfoTableElements: InfoTableElement<User>[] = [
  {
    title: "First name",
    key: "givenName",
  },
  {
    title: "Last Name",
    key: "familyName",
  },
  {
    title: "Birthdate",
    key: "birthdate",
    formatter: (b: Date.AsObject | undefined) => b ? `${b.day}.${b.month}.${b.year}` : "No birthday set",
    sensitiveHidden: true,
  },
  {
    title: "Email",
    key: "email",
  },
  {
    title: "Telephone",
    key: "telephone",
  },
  {
    title: "Swiss EDU ID identifier",
    key: "swissEduPersonUniqueId",
  },
  {
    title: "Student Number",
    key: "studentNumber",
  },
  {
    title: "Study semester",
    key: "studySemester",
  },
  {
    title: "VSETH Membership",
    key: "vsethMembershipInfo",
    formatter: (info: VsethMembershipInfo) => (
      (info.membership === VsethMembership.VSETH_MEMBERSHIP_MEMBER ?
        <Text>Member, {formatVsethMembershipType(info.membershipType)}</Text>
        : <Text>Not Member</Text>)
    ),
  },
  {
    title: "Group Membership",
    key: "groupMembershipList",
    formatter: (groups: string[]) => (
      <Accordion
        defaultValue=""
        variant="default"
        styles={{
          item: {
            borderBottom: "none",
            '&:hover': { backgroundColor: "none" },
          },
        }}
      >
        <Accordion.Item value="groups">
          <Accordion.Control style={{ fontSize: "1em", padding: "0" }}>Groups</Accordion.Control>
          <Accordion.Panel>
            <Grid>
              {groups.map((group, index) => (
                <React.Fragment key={group}>
                  <Chip checked={false}><ResourceLink resourceName={group} /></Chip>
                </React.Fragment>
              ))}
            </Grid>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    ),
  },
  {
    title: "Identifier",
    key: "name",
    formatter: GetIdentifierFromName,
  },
];

const addressInfoTableElements: InfoTableElement<PostalAddress>[] = [
  {
    title: "Street Address",
    key: "streetAddress",
  },
  {
    title: "Locality",
    key: "locality",
  },
  {
    title: "Postal code",
    key: "postalCode",
  },
  {
    title: "Country",
    key: "country",
  },
];

const bankAccoutInfoTableElements: InfoTableElement<BankAccount.AsObject>[] = [
  {
    title: "Account holder name",
    key: "accountHolderName",
  },
  {
    title: "IBAN",
    key: "iban",
  },
  {
    title: "BIC",
    key: "bic",
  },
  {
    title: "Bank name",
    key: "bankName",
  },
  {
    title: "bankingAddress",
    key: "bankingAddress",
    formatter: (addr: PostalAddress | undefined) => (addr ?
      <>
        <Text>{addr.streetAddress}, {addr.postalCode} {addr.locality}, {addr.country}</Text>
      </>
      : "No address saved"
    ),
  },
];


interface UITProps {
  username: string;
  isAdmin: boolean;
};

export const UserInfoTable = ({
  username,
  isAdmin
}: UITProps) => {

  const {
    data: userData,
    isLoading: userDataLoading,
    isFetching: userDataFetching,
    error,
  } = useGetUserQuery(
    {
      userName: username,
      fullView: true,
    }, {}
  );

  const basicInfo = <><h5>Basic info</h5>
    {((userDataFetching || userDataLoading) ?
      <LoadScreen />
      : (userData &&
        <InfoTableBase data={userData} elements={basicInfoTableElements} enableToggles={!isAdmin} />))}
  </>;

  const addressInfo = <><h5>Postal address</h5>
    {((userDataFetching || userDataLoading) ?
      <LoadScreen />
      : (userData && userData.postalAddress &&
        <InfoTableBase data={userData.postalAddress} elements={addressInfoTableElements} enableToggles={!isAdmin} />))}
  </>;

  const bankAccountInfo = <><h5>Bank account</h5>
    {((userDataFetching || userDataLoading) ?
      <LoadScreen />
      : (userData && userData.bankAccount &&
        <InfoTableBase data={userData.bankAccount} elements={bankAccoutInfoTableElements} enableToggles={!isAdmin} />))}
  </>;

  const allInfo = <>{basicInfo}{addressInfo}{bankAccountInfo}</>;

  return (
    <Container>
      { isAdmin ? <h5>Admin mode</h5> : <><Text>Please select additional information to request:</Text><Space h="md"/></>}
      {(error ? "An error occured loading the data" :
        allInfo
      )}
    </Container>
  )
}