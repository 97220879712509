import {
  Breadcrumbs,
  Group,
  Container,
  Anchor,
  Checkbox,
} from "@mantine/core";
import { useSelector } from "react-redux";
import { GroupTable } from "../../components/GroupTable";
import { useNavigate } from "react-router-dom";
import { ReloadButton } from "../../components/ReloadButton";
import { selectIsAdmin, selectUserRoles } from "../auth/authSlice";
import {
  useListGroupsQuery,
  useListPeopleAuthorizationRolesQuery,
} from "./groupsSlice";
import { useState } from "react";

export const GroupListPage = () => {

  const { data: peopleRoles } = useListPeopleAuthorizationRolesQuery(undefined);
  const userRoles = useSelector(selectUserRoles);
  const isAdmin = selectIsAdmin(peopleRoles, userRoles);

  const [showTags, setShowTags] = useState(false);
  const listGroupTags = isAdmin && showTags;

  const {
    data: groups,
    isLoading,
    isFetching,
    refetch,
  } = useListGroupsQuery({ withTags: listGroupTags });

  const navigate = useNavigate();

  return (
    <Container>
      <Breadcrumbs>
        <Anchor href="/groups">
          Groups
        </Anchor>
      </Breadcrumbs>
      <Group position="apart">
        <h2>Group Management</h2>
        <ReloadButton reloadAction={refetch} isLoading={isFetching || isLoading} />
      </Group>

      {isAdmin &&
        <Checkbox checked={showTags} onChange={(e) => setShowTags(e.currentTarget.checked)} label="Show Group tags" />
      }
      
      <GroupTable
        groups={groups || []}
        allowTableEdit={isAdmin}
        onGroupSelect={(name) => navigate(`/${name}`)}
      />
    </Container>
  );
};
