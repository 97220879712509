import React from "react";
import { GroupObjectType } from "../proto/sip/people/people_pb";
import { Badge, BadgeProps } from "@mantine/core";
import { GroupObjectTypeToString } from "../util/peopleProto";

interface OTBProps extends BadgeProps {
  objectType: GroupObjectType;
}

export const ObjectTypeBadge = ({ objectType, ...props }: OTBProps) => {
  let color;
  const name = GroupObjectTypeToString(objectType);
  switch (objectType) {
    case GroupObjectType.GROUP_OBJECT_TYPE_KS:
      color = "#F03A47";
      break;
    case GroupObjectType.GROUP_OBJECT_TYPE_OU:
      color = "#009FE3";
      break;
    case GroupObjectType.GROUP_OBJECT_TYPE_OIOE:
      color = "#AF5B5B";
      break;
    case GroupObjectType.GROUP_OBJECT_TYPE_TAG:
      color = "#7DE369";
      break;
    default:
      color = "#183059";
  }

  return (
    <Badge size="md" p="sm" {...props} style={{ backgroundColor: color, color: "white" }}>
      <span>{name}</span>
    </Badge>
  );
};
