import {
  ResourceDetailCardBase,
  TableFormElement,
} from "./ResourceDetailCardBase";
import { EditableResourceDetailsProps, yupSingleEmailValidator } from "./MailResourceHelpers";
import { Anchor } from "@mantine/core";
import Select from "react-select";
import { Resource } from "../resourcesSlice";
import * as Yup from "yup";
import { FieldProps } from "formik";
import { SympaMailingListResource } from "../../../proto/sip/resources/resources_pb";
import { GetStringForSympaMailinglistType, GetStringForSympaSendSetup } from "../../../util/resourcesProto";

const tableElements: TableFormElement<Resource>[] = [
  {
    title: "Email address",
    key: "mailSympa.email" as keyof Resource,
    formatter: (addr: String) => (
      <Anchor
        href={"mailto:" + addr}
        target="_blank"
        rel="noreferrer"
      >
        {addr}
      </Anchor>
    ),
    validation: yupSingleEmailValidator,
  },
  {
    title: "Messages subject",
    key: "mailSympa.subject" as keyof Resource,
    validation: Yup.string().required(),
  },
  {
    title: "Messages subject tag",
    key: "mailSympa.subjectTag" as keyof Resource,
    validation: Yup.string().required(),
  },
  {
    title: "List type",
    key: "mailSympa.type" as keyof Resource,
    formatter: (type: Number) => GetStringForSympaMailinglistType(type as SympaMailingListResource.ListType),
    customFieldInputElement: (formik: FieldProps<any, Resource>) => {
      const value = formik.field.value as SympaMailingListResource.ListType;
      return (
        <Select
          options={Object.values(SympaMailingListResource.ListType).filter((v) => v !== 0)
            .map((val) => ({
              value: val as SympaMailingListResource.ListType,
              label: GetStringForSympaMailinglistType(val as SympaMailingListResource.ListType)
            }))
          }
          defaultValue={{ value: value, label: GetStringForSympaMailinglistType(value) }}
          menuPlacement="top"
          onChange={(e) =>
            formik.form.setFieldValue(formik.field.name as string, e?.value)
          }
        />);
    },
  },
  {
    title: "Send setup",
    key: "mailSympa.sendSetup" as keyof Resource,
    formatter: (type: Number) => GetStringForSympaSendSetup(type as SympaMailingListResource.SendSetup),
    customFieldInputElement: (formik: FieldProps<any, Resource>) => {
      const value = formik.field.value as SympaMailingListResource.SendSetup;
      return (
        <Select
          options={Object.values(SympaMailingListResource.SendSetup).filter((v) => v !== 0)
            .map((val) => ({
              value: val as SympaMailingListResource.SendSetup,
              label: GetStringForSympaSendSetup(val as SympaMailingListResource.SendSetup)
            }))
          }
          defaultValue={{ value: value, label: GetStringForSympaSendSetup(value) }}
          menuPlacement="top"
          onChange={(e) =>
            formik.form.setFieldValue(formik.field.name as string, e?.value)
          }
        />);
    },
  },
];

export const SympaMailingList = (
  props: EditableResourceDetailsProps<Resource>
) => (
  <ResourceDetailCardBase {...props}>{tableElements}</ResourceDetailCardBase>
);

export const sympaMailingListTableElements = tableElements;