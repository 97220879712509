import Select from "react-select";
import { ResourceType } from "../proto/sip/resources/resources_pb";
import { GetStringForResourceType } from "../util/resourcesProto";
import { toInteger } from "lodash";

interface SRTProps {
  setResourceType?: (type: string) => void;
  initialValue?: number; 
}

// SelectOrganization displays a multi selector to select organizations
export const SelectResourceType = ({ setResourceType,  initialValue }: SRTProps) => {
  const resourceTypes = Object.values(ResourceType).map((type) => {
    const str = GetStringForResourceType(toInteger(type));
    return { value: String(type), label: str };
  }).filter((item) => {
    const unknownRTypeMessage = GetStringForResourceType(ResourceType.RESOURCE_TYPE_UNSPECIFIED);
    if (item.label === unknownRTypeMessage) {
      return false;           // Remove resource type without description string
    }
    return true;
  });

  return (
    <Select
      defaultValue={{
        value: String(initialValue) || "0",
        label: GetStringForResourceType(initialValue || 0),
      }}
      menuPlacement="top"
      maxMenuHeight={200}
      options={resourceTypes}
      placeholder={"Resource type..."}
      onChange={(selected) => {
        setResourceType && setResourceType(selected ? selected.value : "");
      }}
    />
  );
};
