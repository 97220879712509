import { ReactElement } from "react";
import * as Yup from "yup";
import {
  ErrorMessage,
  Field,
  FieldConfig,
  FieldProps,
  Form,
  Formik,
  FormikFormProps,
  FormikProps,
} from "formik";
import { Button } from "@mantine/core";

// FormField configures a field for the EditFormBase
export interface FormField<T> {
  title?: string; // label displayed for the form field
  disabled?: boolean; // whether the field should be disabled (default is enabled)
  key: keyof T; // key of the data to display

  validation?: Yup.Schema; // validation of the form field

  // allows the customization of the rendered field
  customizeField?: () => Partial<FieldConfig<T>>;

  // render a custom input field (default is text) for this data element
  customFieldInputElement?: (formik: FieldProps<any, T>) => JSX.Element;
  // render a completely custom field (such as an FieldArray) for this element.
  completelyCustomField?: (
    key: keyof T,
    formik: FormikProps<T>
  ) => ReactElement<FormikFormProps>;
}

export interface EditFormProps<T> {
  data: T;
  isUpdating: boolean;
  onSubmit: (object: T) => void;
  onCancel?: () => void;
  createNew?: boolean;
  saveLabel?: string;
}

export interface EFBProps<T> extends EditFormProps<T> {
  children: FormField<T>[];
}

function buildValidationTree (obj: {[key: string]: any},path: string[], val: any) {
  if (path.length === 0) return val;
  if (obj === undefined) obj = {};
  obj[path[0]] = buildValidationTree(obj[path[0]], path.slice(1), val);
  return obj;
}

function recurseBuildYupObj (input: {[key: string]: any}) : any {
  var output : any = {};
  for (var key in input) {
    if (!input.hasOwnProperty(key))
        continue;       // skip this property
    if (typeof input[key] === "object" && input[key] !== null && input[key].type === undefined){
      output[key] = (recurseBuildYupObj(input[key]));
    } else {
      output[key] = input[key];
    }
  }
  return Yup.object(output);
}

// EditFormBase is the Base Component for a Form that allows to edit a data object of Type T.
export const EditFormBase = <T extends object>({
  data,
  isUpdating,
  onSubmit,
  onCancel,
  children,
  createNew,
  saveLabel = "Save",
}: EFBProps<T>) => {
  // construct the validation object
  const validationObject: { [k in keyof T]?: any } = {};
  children.forEach(({ key, validation }) => {
    if (validation) {
      if(key.toString().includes(".")) {
        const path: string[] = key.toString().split(".");
        validationObject[path[0] as keyof T] = buildValidationTree(validationObject[path[0] as keyof T], path.slice(1), validation);
      } else {
        validationObject[key] = validation;
      }
    }
  });

  const validationSchema = recurseBuildYupObj(validationObject);

  return (
    <Formik
      initialValues={data}
      validateOnMount={true}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values) => {
        onSubmit({
          ...data,
          ...values,
        });
      }}
    >
      {(formik) => (
        <div className="col-lg-12">
          <Form onSubmit={formik.handleSubmit}>
            {children.map(
              ({
                key,
                title,
                disabled,
                customizeField,
                customFieldInputElement,
                completelyCustomField,
              }) => {
                return (
                  <div className="form-group" key={"form-field-" + String(key)}>
                    <label>
                      <b>{title ? title : String(key)}</b>
                    </label>
                    {completelyCustomField ? (
                      completelyCustomField(key, formik)
                    ) : customFieldInputElement ? (
                      <Field name={key}>{customFieldInputElement}</Field>
                    ) : (
                      <Field
                        name={key}
                        type="text"
                        disabled={disabled}
                        className={`form-control ${formik.touched[key] && formik.errors[key]
                            ? "is-invalid"
                            : ""
                          }`}
                        {...(customizeField && customizeField())}
                      />
                    )}
                    {!completelyCustomField && (
                      <ErrorMessage
                        component="span"
                        name={(key as string)}
                        className="text-danger"
                      />
                    )}
                  </div>
                );
              }
            )}
            {(
              <Button.Group className="d-flex justify-content-end">
                { onCancel && 
                <Button color="secondary" onClick={onCancel}>
                  Cancel
                </Button>
                }
                <Button
                  color="primary"
                  type="submit"
                  loading={isUpdating}
                  disabled={(!formik.dirty && !createNew) || !formik.isValid}
                >
                  {saveLabel}
                </Button>
              </Button.Group>
            )}
          </Form>
        </div>
      )}
    </Formik>
  );
};
