import { Group, User } from "./groupsSlice";
import { UserTable } from "../../components/UserTable";
import { DetailCardProps } from "../../components/DetailPage";
import { DetailCardBase } from "../../components/DetailCardBase";
import { TabBase } from "../../components/TabBase";
import { useState } from "react";
import { selectAddUserAuditMessage, setAddUserAuditMessage } from "../queries/queriesSlice";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "@mantine/core";

function stringArrayIntersect(a: string[], b: string[]) {
  return (a.filter((value) => b.includes(value))).length > 0;
}

function validateSelectedUsers(group: Group, users: (User | undefined)[]) {
  let errorTextElem: JSX.Element = <></>;
  let isError = false;
  for (const user of users) {
    if (user) {
      if (group.membersList.includes(user.name)) {
        isError = true;
        errorTextElem = <Text color="red" size="sm" pt="xs">User {user.username} is already a direct member of this group.</Text>;
      } else if (group.membersTransitiveList.includes(user.name)) {
        isError = true;
        errorTextElem = <Text color="red" size="sm" pt="xs">User {user.username}  is already an indirect member (i.e. member of a subgroup).</Text>;
      } else if (user.groupMembershipList && stringArrayIntersect(group.parentNamesTransitiveList, user.groupMembershipList)) { // Should work, but not for non-admins
        isError = true;
        errorTextElem = <Text color="red" size="sm" pt="xs">User {user.username} is already a member of a
        parent group of this group. You first need to remove this user from the parent group, then you can
        add them here.</Text>; 
      }
    }
  }

  return { isError: isError, errorText: errorTextElem }
}

interface GMProps extends DetailCardProps<Group> {
  allowedToEditMembers: boolean;
  allowedToViewMembers: boolean;
  transitiveMembers: User[];
  onRemoveUsers: (userIdentifiers: string[]) => Promise<any>;
}

// GroupMembers displays the members of a group (direct and indirect members)
export const GroupMembers = (props: GMProps) => {
  const {
    data: group,
    transitiveMembers,
    allowedToViewMembers,
    allowedToEditMembers,
    onRemoveUsers,
  } = props;

  const overviewElements = [
    {
      title: "Direct Members",
      value: allowedToViewMembers
        ? group.membersList.length.toString()
        : "Not allowed",
    },
    {
      title: "All Members",
      value: allowedToViewMembers
        ? group.membersTransitiveList.length.toString()
        : "Not allowed",
    },
    {
      title: "Subgroups",
      value: group.subGroupsList.length.toString(),
    },
  ];

  const members = transitiveMembers.filter(
    (u) => group.membersList.indexOf(u.name) > -1
  );

  const indirectMembers = transitiveMembers.filter(
    (u) => group.membersList.indexOf(u.name) < 0
  );

  const savedAuditMessage = useSelector(selectAddUserAuditMessage);
  const [userAddAuditMessage, setUserAddAuditMessage] = useState(savedAuditMessage || "");
  const dispatch = useDispatch();

  return (
    <DetailCardBase
      {...props}
      title={"Members"}
      overviewElements={overviewElements}
    >
      {!allowedToViewMembers && (
        <p>You're not allowed to view the members of this group</p>
      )}

      {allowedToViewMembers && (
        <TabBase
          tabs={[
            {
              title: `Direct Members (${members.length})`,
              formatter: () => (
                <UserTable
                  users={members}
                  allowedToEditMembers={allowedToEditMembers}
                  onRemove={onRemoveUsers}
                  groupName={group.name}
                  getDefaultAuditMessage={() => userAddAuditMessage}
                  saveAuditMessage={(message: string) => {
                    setUserAddAuditMessage(message);
                    dispatch(setAddUserAuditMessage({ text: message }));
                  }}
                  validateMembers={(users) => validateSelectedUsers(group, users)}
                />
              ),
            },
            {
              title: `Indirect Members (${indirectMembers.length})`,
              formatter: () => (
                <UserTable
                  users={indirectMembers}
                  allowedToEditMembers={false}
                  groupName={group.name}
                  getDefaultAuditMessage={() => userAddAuditMessage}
                  saveAuditMessage={(message: string) => setUserAddAuditMessage(message)}
                />
              ),
            },
          ]}
        />
      )}
    </DetailCardBase>
  );
};
