import { useState } from "react";
import { DetailCardProps, DetailPage } from "../../components/DetailPage";
import { ResourceIcon } from "../../components/ResourceIcon";
import { ProposedChangeState, ResourceType } from "../../proto/sip/resources/resources_pb";
import { Alert, Badge, Button, Group as LayoutGroup, Space, Text, TextInput, Mark } from "@mantine/core";
import { DetailCardBase, JsxValueElement } from "../../components/DetailCardBase";
import { BreadcrumbBase } from "../../components/BreadcrumbBase";
import {
  ProposedChange,
  Resource,
  useDeleteProposedChangeMutation,
  useListProposedChangesQuery,
  useListResourcesAuthorizationRolesQuery,
  useUpdateProposedChangeMutation,
} from "./resourcesSlice";
import { useNavigate, useParams } from "react-router-dom";
import {
  GenerateDiffPaths,
  GetStringForProposedChangeState,
  GetStringForResourceType,
} from "../../util/resourcesProto";
import {
  InfoTableBase,
  InfoTableElement,
} from "../../components/InfoTableBase";
import { GetIdentifierFromName } from "../../util/peopleProto";
import { useListGroupsQuery } from "../groups/groupsSlice";
import { useSelector } from "react-redux";
import {
  selectAllowedToApproveProposedChanges,
  selectAllowedToUpdateResource,
  selectIsResourcesAdmin,
  selectUserRoles,
} from "../auth/authSlice";
import { TasklistOkIcon } from "vseth-canine-ui";
import { UserLink } from "../../components/UserLink";
import { ResourceDetailComponent, ResourceSpecificComponent } from "./ResourceDetailPageComponents";
import { toast } from "react-toastify";
import { dateToString } from "../../util/util";
import { ResourceLink } from "../../components/ResourceLink";
import { EditProposedChangeDetailsForm } from "../../components/EditProposedChangeDetailsForm";
import { regexConstants } from "../../util/regexConstants";

const proposedChangeElements: InfoTableElement<ProposedChange>[] = [
  {
    title: "Identifier",
    key: "name",
    formatter: GetIdentifierFromName,
  },
  {
    title: "Resource identifier",
    key: "resourceName",
    formatter: (name: string) => <ResourceLink resourceName={name} key={name} />,
  },
  {
    title: "Update paths",
    key: "pathList",
    formatter: (pathList: string[]) => (
      <Text>
        {pathList.join(", ")}
      </Text>
    ),
  },
  {
    title: "Last updated by",
    key: "lastUpdatedBy",
    formatter: (user?: string) => (
      user ? <UserLink user={user} /> : <Text>Not set</Text>
    ),
  },
  {
    title: "State of the proposed change",
    key: "state",
    formatter: GetStringForProposedChangeState
  },
  {
    title: "Comment",
    key: "comment",
    formatter: (comment?: string) => (
      <Text>
        {comment || ""}
      </Text>
    )
  },
  {
    title: "Created at",
    key: "createTime",
    formatter: (createTime?: { seconds: number }) => (
      createTime ?
        dateToString(new Date(createTime.seconds * 1000))
        : "Not set"
    )
  },
  {
    title: "Updated at",
    key: "updateTime",
    formatter: (updateTime?: { seconds: number }) => (
      updateTime ?
        dateToString(new Date(updateTime.seconds * 1000))
        : "Not set"
    )
  },
  {
    title: "Closed at",
    key: "finishTime",
    formatter: (finishTime?: { seconds: number }) => (
      finishTime ?
        dateToString(new Date(finishTime.seconds * 1000))
        : "Not set"
    )
  },
];

interface PCCProps extends DetailCardProps<ProposedChange> {
  isLoading: boolean;

  onUpdatePC: (newPc: ProposedChange, auditMessage: string) => Promise<any>;
}

const ProposedChangeComponent = (props: PCCProps) => {
  const { data } = props;

  const [isEditing, setIsEditing] = useState(false);

  const overviewElements = [
    {
      title: "Identifier",
      value: GetIdentifierFromName(data.name),
    },
    {
      title: "Last updated by",
      formatter: (user?: string) => (
        user ? <UserLink user={user} /> : <Text>Not set</Text>
      ),
    },
    {
      title: "Comment",
      value: data.comment,
    },
    {
      title: "State",
      formatter: (state?: string) => (
        <Text>
          {data.state && GetStringForProposedChangeState(data.state)}
        </Text>
      ),
    },
  ];

  return (
    <DetailCardBase
      {...props}
      title={"Proposed change details"}
      overviewElements={overviewElements}
    >
      {isEditing ? (
        <EditProposedChangeDetailsForm
          data={data}
          isUpdating={props.isLoading}
          onSubmit={(c) =>
            props.onUpdatePC(c, c['auditMessage' as keyof ProposedChange] as string).then(() => setIsEditing(false))
          }
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <div>
          <InfoTableBase data={data} elements={proposedChangeElements} />
          <Button
            color="primary"
            className="float-right mb-3"
            disabled={!true}
            onClick={() => setIsEditing(true)}
          >
            Edit
          </Button>
        </div>
      )}
    </DetailCardBase>
  );
};

interface PCCCProps extends DetailCardProps<ProposedChange> {
  routeRef?: string;
}

const ProposedChangeControlComponent = (props: PCCCProps) => {
  const { data } = props;

  const overviewElements: JsxValueElement[] = [];

  const navigate = useNavigate();

  // load the people and resources api authorization information
  const { data: resourceApiRoles } =
    useListResourcesAuthorizationRolesQuery(undefined);

  // get the roles of the user (including debug roles)
  const userRoles = useSelector(selectUserRoles);

  const isAdmin = selectIsResourcesAdmin(resourceApiRoles, userRoles);

  const isAcceptableOrDeniable = data.state === ProposedChangeState.OPEN || isAdmin;

  const canApprove = selectAllowedToApproveProposedChanges(
    resourceApiRoles || [],
    userRoles
  );

  const [deleteProposedChange, { isLoading: isDeletingProposedChange }] =
    useDeleteProposedChangeMutation();

  const [updateProposedChange, { isLoading: isUpdatingProposedChange }] =
    useUpdateProposedChangeMutation();

  const backToReqOrRes = () => {
    /*
    if (props.routeRef === "/requests") {
      navigate("/requests");
    } else {
      navigate("/resources");
    }
    */
  };

  const [auditMsg, setAuditMsg] = useState("");

  const auditMessageValid = regexConstants.group.auditMessage.test(auditMsg);

  const handleDeletedProposedChange = () => backToReqOrRes();
  const handleErrorDeletePC = () => toast.error("Error deleting proposed change.");

  const onDeleteClick = () => {
    const proposedChangeDeletion = deleteProposedChange({name: data.name, auditMsg});
    proposedChangeDeletion.then(handleDeletedProposedChange, handleErrorDeletePC);
    navigate("/proposedchanges");
  };

  const handleErrorApprovePC = () => toast.error("Error accepting proposed change.");
  const onApproveClick = () => {
    const acceptedChange = { ...data, ...{ state: ProposedChangeState.ACCEPTED } };
    const proposedChangeApproval = updateProposedChange({ proposedChange: acceptedChange, updatePaths: ["state"], auditMessage: auditMsg });
    proposedChangeApproval.then(() => navigate(-1), handleErrorApprovePC);
  };

  const handleErrorDenyPC = () => toast.error("Error denying proposed change.");
  const onDenyClick = () => {
    const deniedChange = { ...data, ...{ state: ProposedChangeState.DENIED } };
    const proposedChangeApproval = updateProposedChange({ proposedChange: deniedChange, updatePaths: ["state"], auditMessage: auditMsg});
    proposedChangeApproval.then(() => navigate(-1), handleErrorDenyPC);
  };

  const canDelete = canApprove;

  const auditMessageField = <TextInput
    label="Audit message" required
    placeholder="Enter audit message"
    error={auditMessageValid ? false : "Invalid audit message"}
    pb="md"
    value={auditMsg} onChange={(event) => setAuditMsg(event.currentTarget.value)} />;

  return (
    <DetailCardBase
      {...props}
      title={"Accept, Deny or Delete"}
      overviewElements={overviewElements}
    >
      {
        <>
          <Alert icon={<TasklistOkIcon />} title="Accept proposed change" color="yellow">
            <Text size="sm" pb="sm" pt="sm">Do you want to accept or deny this proposed change? {data.state !== ProposedChangeState.OPEN ? <Mark>Overrides current proposal state</Mark> : <></>} </Text>
            {auditMessageField}
            <LayoutGroup position="apart">
              <LayoutGroup position="left">
                <Button disabled={!(isAcceptableOrDeniable && canApprove) || !auditMessageValid} color="green" loading={isUpdatingProposedChange} onClick={onApproveClick}>Accept</Button>
                <Button disabled={!(isAcceptableOrDeniable && canApprove) || !auditMessageValid} color="orange" loading={isUpdatingProposedChange} onClick={onDenyClick}>Deny</Button>
              </LayoutGroup>
              <Button color="yellow" onClick={() => navigate(-1)}>Cancel</Button>
            </LayoutGroup>
          </Alert>
          <Space h="md" />
          <Alert icon={<TasklistOkIcon />} title="Delete proposed change" color="red" >
            <Text size="sm" pb="sm" pt="sm">Do you want to delete this proposed change?  </Text>
            {auditMessageField}
            <LayoutGroup position="apart">
              <Button disabled={!canDelete || !auditMessageValid} color="red" onClick={onDeleteClick} loading={isDeletingProposedChange}>Delete completely</Button>
            </LayoutGroup>
          </Alert>
        </>
      }
    </DetailCardBase>
  );
};


// ProposedChangeDetailPage is the detail page of a Resource
export const ProposedChangeDetailPage = ({ routeRef }: { routeRef: string | undefined }) => {
  const { id } = useParams<{ id: string }>();

  // retrieve the resource
  const {
    data: rdata,
    error,
    refetch,
    isFetching,
    isLoading,
  } = useListProposedChangesQuery({ filter: { name: `proposedchanges/${id}` }, pageSize: 1 });

  const proposedChange = rdata && rdata[0];
  const resource = proposedChange?.data as Resource;
  const resourceType =
    resource?.resourceType || ResourceType.RESOURCE_TYPE_UNSPECIFIED;

  // retrieve resource permissions
  const { data: resourceRoles } =
    useListResourcesAuthorizationRolesQuery(undefined);
  const userRoles = useSelector(selectUserRoles);
  const allowedToUpdateResource = selectAllowedToUpdateResource(
    resourceRoles,
    userRoles,
    resource?.ownerGroup || ""
  );

  // update resource query
  const [updateProposedChange, { isLoading: isUpdatingResource }] =
    useUpdateProposedChangeMutation();
  const onUpdateResource = (updatedResource: Resource, auditMessage: string) => {
    if (!proposedChange) {
      return Promise.reject();
    }
    const newProposedChange = { ...proposedChange, ...{ data: updatedResource } };
    delete newProposedChange['auditMessage' as keyof ProposedChange];
    const updatePaths = GenerateDiffPaths(proposedChange, newProposedChange).map(dp => (dp.includes("data") ? "data" : dp));
    return updateProposedChange({ proposedChange: newProposedChange, updatePaths, auditMessage }).unwrap();
  };

  const onUpdatePC = (newPC: ProposedChange, auditMessage: string) => {
    if (!newPC) {
      return Promise.reject();
    }
    const updatedProposedChange = { ...proposedChange, ...newPC };
    delete updatedProposedChange['auditMessage' as keyof ProposedChange];
    const updatePaths = GenerateDiffPaths(proposedChange, updatedProposedChange).map(dp => (dp.includes("data") ? "data" : dp));
    return updateProposedChange({ proposedChange: updatedProposedChange, updatePaths, auditMessage }).unwrap();
  };

  /* delete resource permission query - not possible
  const [deleteResourcePermission, { isLoading: isUpdatingPermissions }] =
    useDeleteResourcePermissionMutation(); */

  // load the groups (needed for the group selection of the edit resource detail component)
  const { data: groups } = useListGroupsQuery({});

  const isUpdating = isFetching || isLoading;

  return (
    <DetailPage
      data={proposedChange}
      error={error}
      isLoading={isUpdating}
      title={<>
        <Text fz="md">
          {"Proposed change for resource: "}
        </Text>
        <Text fz="lg" className="h5">
          {resource?.displayName}
        </Text>
      </> || ""}
      subtitle={GetStringForResourceType(resourceType)}
      breadcrumb={(focusedElement) => (
        <BreadcrumbBase
          resourceName={"proposedchanges"}
          identifier={id}
          focusKey={focusedElement}
        />
      )}
      icon={
        <Badge size="md" p="sm">
          <span>
            <ResourceIcon resourceType={resourceType} size="1.25em" />
          </span>
        </Badge>
      }
      reloadButton={true}
      reloadAction={refetch}
    >
      {[
        {
          key: "change",
          formatter: (props) => {
            return (<ProposedChangeComponent
              key="change"
              isLoading={isUpdating}
              onUpdatePC={onUpdatePC}
              {...props}
            />);
          }
        },
        {
          key: "details",
          formatter: (props) => {
            const mergedProps = { ...props, ...({ data: props.data.data as Resource }) };
            return (<ResourceDetailComponent
              {...mergedProps}
              onUpdateResource={onUpdateResource}
              isUpdatingResource={isUpdatingResource}
              allowedToUpdateResource={allowedToUpdateResource}
              groups={groups || []}
              key={"details"}
            />);
          },
        },
        {
          key: "specific",
          formatter: (props) => {
            const mergedProps = { ...props, ...({ data: props.data.data as Resource }) };

            return (<ResourceSpecificComponent
              {...mergedProps}
              onUpdateResource={onUpdateResource}
              isUpdatingResource={isUpdatingResource}
              allowedToUpdateResource={allowedToUpdateResource}
              key={"specific"}
            />);
          },
        },
        /* permissions are not supported in ProposedChanges
        {
          key: "permissions",
          formatter: (props) => {
            const mergedProps = { ...props, ...({ data: props.data.data as Resource }) };
            return (<ResourcePermissionComponent
              {...mergedProps}
              key={"permissions"}
              resourceName={resource?.name || ""}
              onRemove={onRemovePermission}
              enableAddPermissions={allowedToUpdateResource}
              enableRemovePermissions={allowedToUpdateResource}
            />);
          },
        },
        */
        {
          key: "control",
          formatter: (props) => {
            return (<ProposedChangeControlComponent
              {...props}
              key={"control"}
              routeRef={routeRef}
            />);
          },
        },
      ]}
    </DetailPage>
  );
};
