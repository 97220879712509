import React from "react";
import { useTranslation } from "react-i18next";

export const NotAuthorizedScreen: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-center" style={{ height: "80vh" }}>
      <form className="text-center my-auto">
        <h1>{t("NotAuthorizedScreen.title")}</h1>
        <p>{t("NotAuthorizedScreen.body")}</p>
      </form>
    </div>
  );
};
