import { LoginButton } from "./LoginButton";
import { useAuth } from "./useAuth";
import { Group, Text } from '@mantine/core';

export interface ProtectedRouteProps {
	children?: any;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
	const { authState } = useAuth();

	// handle users that are not authenticated
	if (!authState.isAuthenticated) {
		return (
			<Group>
				<LoginButton />
				<Text fz="sm">Please Log in to continue</Text>
			</Group>
		);
	}

	return children;
};
