import {
  ResourceDetailCardBase,
  TableFormElement,
} from "./ResourceDetailCardBase";
import { EditableResourceDetailsProps } from "./MailResourceHelpers";
import { Resource } from "../resourcesSlice";
import * as Yup from "yup";
import { regexConstants } from "../../../util/regexConstants";

const gitlabPrefix = "https://gitlab.ethz.ch/";

const tableElements: TableFormElement<Resource>[] = [
  {
    title: "Gitlab ID",
    key: "gitlabGroup.identifier" as keyof Resource,
    disabled: true,
  },
  {
    title: "Gitlab group path",
    key: "gitlabGroup.path" as keyof Resource,
    validation: Yup.string().required("Please enter Gitlab path of the group").matches(regexConstants.gitlabVsethUrl, "Invalid Gitlab URL, must start with vseth/"),
    formatter: (path: string) => (
      <a
        href={gitlabPrefix + path}
        target="_blank"
        rel="noreferrer"
      >
        {gitlabPrefix + path}
      </a>
    ),
  },
];

export const GitlabGroup = (
  props: EditableResourceDetailsProps<Resource>
) => (
  <ResourceDetailCardBase {...props}>{tableElements}</ResourceDetailCardBase>
);

export const gitlabGroupTableElements = tableElements;