import { DetailPage } from "../../components/DetailPage";
import { ResourceIcon } from "../../components/ResourceIcon";
import { ProposedChangeState, ResourceType } from "../../proto/sip/resources/resources_pb";
import { Badge } from "@mantine/core";
import { BreadcrumbBase } from "../../components/BreadcrumbBase";
import {
  ProposedChange,
  Resource,
  ResourcePermission,
  useCreateProposedChangeMutation,
  useDeleteResourcePermissionMutation,
  useGetResourceQuery,
  useListResourcesAuthorizationRolesQuery,
  useUpdateResourceMutation,
} from "./resourcesSlice";
import { useNavigate, useParams } from "react-router-dom";
import {
  GenerateDiffPaths,
  GetStringForResourceType,
} from "../../util/resourcesProto";
import { dateToTimestampObj } from "../../util/peopleProto";
import { useListGroupsQuery } from "../groups/groupsSlice";
import { useSelector } from "react-redux";
import {
  isGpk,
  selectAllowedToCreatePermissionOnResource,
  selectAllowedToCreateProposedChange,
  selectAllowedToDeletePermissionOnResource,
  selectAllowedToUpdateResource,
  selectUserRoles,
} from "../auth/authSlice";
import { ResourceDeletionComponent, ResourceDetailComponent, ResourcePermissionComponent, ResourceSpecificComponent } from "./ResourceDetailPageComponents";
import { useState } from "react";


// ResourceDetailPage is the detail page of a Resource
export const ResourceDetailPage = ({ routeRef }: { routeRef: string | undefined }) => {
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();
  const [isDeleted, setIsDeleted] = useState(false);

  // retrieve the resource
  const {
    data: resource,
    error,
    refetch,
    isFetching,
    isLoading,
  } = useGetResourceQuery(`resources/${id}`, {skip: isDeleted});
  const resourceType =
    resource?.resourceType || ResourceType.RESOURCE_TYPE_UNSPECIFIED;

  // retrieve resource permissions
  const { data: resourceRoles } =
    useListResourcesAuthorizationRolesQuery(undefined);
  const userRoles = useSelector(selectUserRoles);
  const allowedToUpdateResource = (resource?.ownerGroup !== undefined) && selectAllowedToUpdateResource( // i.e. update Resource directly as admin
    resourceRoles,
    userRoles,
    resource?.ownerGroup
  );
  const allowedToMakeProposedChange = (resource?.ownerGroup !== undefined) && selectAllowedToCreateProposedChange(
    resourceRoles,
    userRoles,
    resource?.ownerGroup
  );

  const allowedToEdit = allowedToUpdateResource || allowedToMakeProposedChange;

  const allowedToAddPermissions = selectAllowedToCreatePermissionOnResource(
    resourceRoles,
    userRoles,
    resource?.ownerGroup
  ) || isGpk(userRoles);

  const allowedToRemovePermissions = selectAllowedToDeletePermissionOnResource(
    resourceRoles,
    userRoles,
    resource?.ownerGroup
  );

  // update resource query
  const [updateResource, { isLoading: isUpdatingResource }] =
    useUpdateResourceMutation();

  const [createProposedChange, { isLoading: isCreatingProposedChange }] =
    useCreateProposedChangeMutation();

  const onUpdateResource = (updatedResource: Resource, auditMessage: string) => {
    if (!resource) {
      return Promise.reject("Resource undefined");
    }


    const updatePaths = GenerateDiffPaths(resource, updatedResource);
    if (allowedToUpdateResource) {
      return updateResource({ resource: updatedResource, updatePaths, auditMessage }).unwrap();
    } else {
      // if not admin, make proposed change
      const now = new Date();

      const change = {
        name: "",
        resourceName: resource.name,
        data: updatedResource,
        pathList: updatePaths,
        lastUpdatedBy: "",
        comment: "Autogenerated by edit via groupmanager",
        state: ProposedChangeState.OPEN,
        createTime: dateToTimestampObj(now),
        updateTime: dateToTimestampObj(now),
        finishTime: dateToTimestampObj(now),
      };

      return new Promise((resolve: ((change: ProposedChange) => void), reject) =>
        createProposedChange({pc: change, auditMsg: "Autogenerated by edit via groupmanager"}).unwrap().then(
          (pc: ProposedChange) => navigate("/" + pc.name)
        )
      );
    }
  };

  // todo: have a closer look at all the isLoading / isUpdating variables

  // delete resource permission query
  const [deleteResourcePermission, { isLoading: isUpdatingPermissions }] =
    useDeleteResourcePermissionMutation();
  const onRemovePermission = (rp: ResourcePermission[], auditMessage: string) => {
    if (!isUpdatingPermissions) {
      return Promise.all(
        rp.map((p) => deleteResourcePermission({ name: p.name, auditMessage }).unwrap())
      );
    }
    return Promise.reject();
  };

  // load the groups (needed for the group selection of the edit resource detail component)
  const { data: groups } = useListGroupsQuery({});

  return (
    <DetailPage
      data={resource}
      error={error}
      isLoading={isFetching || isLoading}
      title={resource?.displayName || ""}
      subtitle={GetStringForResourceType(resourceType)}
      breadcrumb={(focusedElement) => (
        <BreadcrumbBase
          resourceName={"resources"}
          identifier={id}
          focusKey={focusedElement}
        />
      )}
      icon={
        <Badge size="md" p="sm">
          <span>
            <ResourceIcon resourceType={resourceType} size="1.25em" />
          </span>
        </Badge>
      }
      reloadButton={true}
      reloadAction={refetch}
    >
      {[
        {
          key: "details",
          formatter: (props) => (
            <ResourceDetailComponent
              {...props}
              onUpdateResource={onUpdateResource}
              isUpdatingResource={isUpdatingResource || isCreatingProposedChange}
              allowedToUpdateResource={allowedToEdit}
              groups={groups || []}
              key={"details"}
            />
          ),
        },
        {
          key: "specific",
          formatter: (props) => (
            <ResourceSpecificComponent
              {...props}
              onUpdateResource={onUpdateResource}
              isUpdatingResource={isUpdatingResource || isCreatingProposedChange}
              allowedToUpdateResource={allowedToEdit}
              key={"specific"}
            />
          ),
        },
        {
          key: "permissions",
          formatter: (props) => (
            <ResourcePermissionComponent
              {...props}
              key={"permissions"}
              resourceName={resource?.name || ""}
              onRemove={onRemovePermission}
              enableAddPermissions={allowedToAddPermissions}
              enableRemovePermissions={allowedToRemovePermissions}
            />
          ),
        },
        {
          key: "deletion",
          formatter: (props) => (
            <ResourceDeletionComponent
              {...props}
              key={"deletion"}
              resourceName={resource?.name || ""}
              routeRef={routeRef}
              setIsDeleted={setIsDeleted}
            />
          ),
        },
      ]}
    </DetailPage>
  );
};
