import * as Yup from "yup";
import { Group } from "../features/groups/groupsSlice";
import { GroupObjectType } from "../proto/sip/people/people_pb";
import { regexConstants } from "../util/regexConstants";
import { EditFormBase, EditFormProps } from "./EditFormBase";

const displayNameValidation = (name: string) =>
  Yup.string()
    .trim()
    .matches(regexConstants.group.display_name, "Valid name")
    .required(name + " is required");

// todo: the pattern of a component using:
// <InfoTableBase> and <EditFormBase> with an edit button in between could be generalised
// it is used by "Group", "Resource" and already generalized in the "ResourceDetails" component

// EditGroupsDetailsForm is a form that can be used to edit the basic info of a group
export const EditGroupDetailsForm = (props: EditFormProps<Group>) => {
  const { data: group } = props;

  const isFioeGroup =
    group.objectType === GroupObjectType.GROUP_OBJECT_TYPE_FIOE;
  const isIoeGroup =
    isFioeGroup || group.objectType === GroupObjectType.GROUP_OBJECT_TYPE_OIOE;

  return (
    <EditFormBase {...props}>
      {[
        {
          title: "Display Name",
          key: "displayName",
          disabled: !isFioeGroup,
          validation: displayNameValidation("Display Name"),
        },
        {
          title: "Full Name DE",
          key: "titleDe",
          disabled: !isFioeGroup,
          validation: displayNameValidation("Full Name DE"),
        },
        {
          title: "Full Name EN",
          key: "titleEn",
          disabled: !isFioeGroup,
          validation: displayNameValidation("Full Name EN"),
        },
        {
          title: "Description",
          key: "description",
          disabled: !isFioeGroup,
          validation: Yup.string()
            .trim()
            .matches(regexConstants.group.auditMessage, "Valid description")
            .required("Description is required"),
        },
        {
          title: "Email",
          key: "email",
          disabled: !isIoeGroup,
          validation: Yup.string().trim().email("Please provide a valid email"),
        },
        {
          title: "Telephone",
          key: "telephone",
          disabled: !isIoeGroup,
          validation: Yup.string()
            .trim()
            .matches(
              regexConstants.group.phone,
              "Must be a valid phone number"
            ),
        },
        {
          title: "Website",
          key: "website",
          disabled: !isIoeGroup,
          validation: Yup.string().trim().url("Should be a url"),
        },
      ]}
    </EditFormBase>
  );
};
