import { Resource } from "../resourcesSlice";
import {
  EditableResourceDetailsProps,
  mailBaseElements,
} from "./MailResourceHelpers";
import {
  ResourceDetailCardBase,
  TableFormElement,
} from "./ResourceDetailCardBase";
import * as Yup from "yup";

const tableElements: TableFormElement<Resource>[] = [
  ...mailBaseElements("mailContact"),
  {
    title: "Short Name",
    key: "mailContact.shortName" as keyof Resource,
    validation: Yup.string().required("Short name is required")
  },
  {
    title: "Full Name",
    key: "mailContact.fullName" as keyof Resource,
    validation: Yup.string().required("Full name is required")
  },
];

export const MailContact = (
  props: EditableResourceDetailsProps<Resource>
) => (
  <ResourceDetailCardBase {...props}>{tableElements}</ResourceDetailCardBase>
);

export const mailContactTableElements = tableElements;