import { Anchor, Breadcrumbs } from "@mantine/core";
import { Link } from "react-router-dom";

interface BBProps {
  resourceName: string; // name of the "resource" for example "users", "groups" or "resources"
  identifier?: string; // the identifier of the selected resource
  focusKey?: string;
}

// BreadcrumbBase displays a navigation breadcrumb, can be used for different resources.
export const BreadcrumbBase = ({
  resourceName,
  identifier,
  focusKey,
}: BBProps) => {
  const identifierPresent = identifier !== undefined && identifier !== "";
  const inFocusView = focusKey !== undefined && focusKey !== "";
  const resourceNameCapitalized = resourceName.charAt(0).toUpperCase() + resourceName.slice(1);

  if (!identifierPresent) {
    return (
      <Breadcrumbs>
        <Link to={'/' + resourceName}>{resourceNameCapitalized}</Link>
      </Breadcrumbs>
    );
  }

  return (
    <Breadcrumbs mb="md" >
      <Link to={`/${resourceName}`}>{resourceName}</Link>
      {!inFocusView && <Anchor>{identifier}</Anchor>}
      {inFocusView && (
        <Link to={`/${resourceName}/${identifier}`}>{identifier}</Link>
      )}
      {inFocusView && <Anchor>{focusKey}</Anchor>}
    </Breadcrumbs>
  );
};
