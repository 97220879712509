import { Group, Select, Text } from "@mantine/core";
import { forwardRef, useEffect, useState } from "react";
import { User } from "../groups/groupsSlice";
import { useDebouncedValue } from "@mantine/hooks";


interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  value: string;
  username: string;
}

const LookupSelectUserItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, value, username, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" opacity={0.65}>
            {username}
          </Text>
        </div>
      </Group>
    </div>
  )
);

interface LUSProps {
  value: string | null;
  setValue: ((value: string | null) => void);
  loadUsers: (search: string) => Promise<User[]>;
};

export const LookupUserSearch = ({
  value,
  setValue,
  loadUsers
}: LUSProps) => {
  const [searchValue, onSearchChange] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [debouncedSearchValue] = useDebouncedValue(searchValue, 300);

  const [options, setOptions] = useState([] as ItemProps[]);

  useEffect(() => {
    let ignore = false;  // Race, see https://react.dev/learn/you-might-not-need-an-effect#fetching-data
    setIsLoading(true);
    loadUsers(debouncedSearchValue).then((users) => {
      const opt = (users.map((u) => ({ value: u.name, username: u.username, label: u.givenName + " " + u.familyName })))
      if (!ignore) {
        setOptions(opt);
        setIsLoading(false);
      }
    }
    )
    return () => {
      ignore = true;
    };
  }, [debouncedSearchValue, loadUsers]);

  const sc = (
    <Select
      label={"Enter first name and last name or username"}
      placeholder="Pick one"
      itemComponent={LookupSelectUserItem}
      value={value}
      onChange={setValue}
      searchable
      filter={(search: string, item: ItemProps) =>
        item.username.toLowerCase().includes(search.toLowerCase())
        || item.label.toLowerCase().includes(search.toLowerCase())}
      onSearchChange={onSearchChange}
      searchValue={searchValue}
      nothingFound={isLoading ? "Loading options..." : "No options"}
      maxDropdownHeight={380}
      data={options}
    />
  );

  return sc;
}