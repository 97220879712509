import * as google_date_pb from "../proto/google/type/date_pb";
import {
  EthStudyInfo,
  Group,
  PostalAddress,
  User,
  VsethMembershipInfo,
} from "../features/groups/groupsSlice";
import * as google_timestamp_pb from "google-protobuf/google/protobuf/timestamp_pb";
import {
  PeopleAuthorizationRole,
  ScopesOnResource,
} from "../features/auth/authSlice";
import {
  AuthorizationRole as AuthorizationRoleMessage,
  BankAccount as BankAccountMessage,
  EthStudyInfo as EthStudyInfoMessage,
  Group as GroupMessage,
  GroupObjectType,
  PostalAddress as PostalAddressMessage,
  ScopesOnResource as ScopeOnResourceMessage,
  User as UserMessage,
  VsethMembershipInfo as VsethMembershipInfoMessage,
} from "../proto/sip/people/people_pb";
import { diff } from "deep-diff";

export function getAuthMetadata(token?: string) {
  return {
    authorization: `Bearer ${token}`,
  };
}

/**
 * Helper method that turns a Date.AsObject into a Date
 * @param date
 */
export const dateToDateMessage = (
  date?: google_date_pb.Date.AsObject
): google_date_pb.Date => {
  const dateMessage = new google_date_pb.Date();

  if (date) {
    dateMessage.setYear(date.year);
    dateMessage.setMonth(date.month);
    dateMessage.setDay(date.day);
  }

  return dateMessage;
};

/**
 * Helper method that turns an address into an AddressMessage.
 * @param address
 */
export const addressToAddressMessage = (address?: PostalAddress) => {
  const addressMessage = new PostalAddressMessage();

  if (address) {
    addressMessage.setStreetAddress(address.streetAddress);
    addressMessage.setLocality(address.locality);
    addressMessage.setPostalCode(address.postalCode);
    addressMessage.setCountry(address.country);
  }

  return addressMessage;
};

/**
 * Helper method that turns an bank account into an BankAccountMessage.
 * @param address
 */
export const bankAccountToBankAccountMessage = (bankaccount?: BankAccountMessage.AsObject) => {
  const bankAccountMessage = new BankAccountMessage();

  if (bankaccount) {
    bankAccountMessage.setAccountHolderName(bankaccount.accountHolderName);
    bankAccountMessage.setBankName(bankaccount.bankName);
    bankAccountMessage.setBankingAddress(addressToAddressMessage(bankaccount.bankingAddress));
    bankAccountMessage.setBic(bankaccount.bic);
    bankAccountMessage.setIban(bankaccount.iban);
  }

  return bankAccountMessage;
};

/**
 * Helper Method that turns a gorup into a group message.
 * @param group
 */
export const groupToGroupMessage = (group: Group) => {
  const groupMessage = new GroupMessage();
  groupMessage.setName(group.name);
  groupMessage.setParentName(group.parentName);
  groupMessage.setParentNamesTransitiveList(group.parentNamesTransitiveList);
  groupMessage.setObjectType(group.objectType);
  groupMessage.setOrgId(group.orgId);
  groupMessage.setMembersList(group.membersList);
  groupMessage.setMembersTransitiveList(group.membersTransitiveList);
  groupMessage.setSubGroupsList(group.subGroupsList);
  groupMessage.setDescription(group.description);
  groupMessage.setDisplayName(group.displayName);
  groupMessage.setTitleDe(group.titleDe);
  groupMessage.setTitleEn(group.titleEn);
  groupMessage.setEmail(group.email);
  groupMessage.setPostalAddress(addressToAddressMessage(group.postalAddress));
  groupMessage.setEthOffice(group.ethOffice);
  groupMessage.setWebsite(group.website);
  groupMessage.setTelephone(group.telephone);
  groupMessage.setEthOrgId(group.ethOrgId);

  return groupMessage;
};

const ethStudyInfoToEthStudyInfoMessage = (
  ethStudyInfo?: EthStudyInfo
): EthStudyInfoMessage => {
  const ethStudyInfoMessage = new EthStudyInfoMessage();

  if (ethStudyInfo) {
    ethStudyInfoMessage.setEthFachid(ethStudyInfo.ethFachid);
    ethStudyInfoMessage.setDepartment(ethStudyInfo.department);
    ethStudyInfoMessage.setDepartmentDisplayLabelDe(
      ethStudyInfo.departmentDisplayLabelDe
    );
    ethStudyInfoMessage.setDepartmentDisplayLabelEn(
      ethStudyInfo.departmentDisplayLabelEn
    );
    ethStudyInfoMessage.setStudyProgramDisplayLabelDe(
      ethStudyInfo.studyProgramDisplayLabelDe
    );
    ethStudyInfoMessage.setStudyProgramDisplayLabelEn(
      ethStudyInfo.studyProgramDisplayLabelEn
    );
    ethStudyInfoMessage.setStudyFachrichtungDisplayLabelDe(
      ethStudyInfo.studyFachrichtungDisplayLabelDe
    );
    ethStudyInfoMessage.setStudyFachrichtungDisplayLabelEn(
      ethStudyInfo.studyFachrichtungDisplayLabelEn
    );
    ethStudyInfoMessage.setExitDate(dateToDateMessage(ethStudyInfo.exitDate));
  }

  return ethStudyInfoMessage;
};

const vsethMembershipInfoToVsethMembershipInfoMessage = (
  vsethMembershipInfo?: VsethMembershipInfo
): VsethMembershipInfoMessage => {
  const vsethMembershipInfoMessage = new VsethMembershipInfoMessage();

  if (vsethMembershipInfo) {
    vsethMembershipInfoMessage.setMembership(vsethMembershipInfo.membership);
    vsethMembershipInfoMessage.setMembershipType(
      vsethMembershipInfo.membershipType
    );
    vsethMembershipInfoMessage.setFvOrgId(vsethMembershipInfo.fvOrgId);
    vsethMembershipInfoMessage.setFvOrgKey(vsethMembershipInfo.fvOrgKey);
  }

  return vsethMembershipInfoMessage;
};

/**
 * Helper method that turns a user into a user message.
 * @param user
 */
export const userToUserMessage = (user: User) => {
  const userMessage = new UserMessage();

  userMessage.setName(user.name);
  userMessage.setUsername(user.username);
  userMessage.setEmail(user.email);
  userMessage.setGivenName(user.givenName);
  userMessage.setFamilyName(user.familyName);
  userMessage.setSwissEduPersonUniqueId(user.swissEduPersonUniqueId);
  userMessage.setStudentNumber(user.studentNumber);
  userMessage.setStudySemester(user.studySemester);
  userMessage.setEthStudyInfo(
    ethStudyInfoToEthStudyInfoMessage(user.ethStudyInfo)
  );
  userMessage.setTelephone(user.telephone);
  userMessage.setBirthdate(dateToDateMessage(user.birthdate));
  userMessage.setVsethMembershipInfo(
    vsethMembershipInfoToVsethMembershipInfoMessage(user.vsethMembershipInfo)
  );
  userMessage.setPostalAddress(addressToAddressMessage(user.postalAddress));
  userMessage.setGroupMembershipList(user.groupMembershipList);
  userMessage.setGroupMembershipTransitiveList(
    user.groupMembershipTransitiveList
  );
  userMessage.setBankAccount(bankAccountToBankAccountMessage(user.bankAccount));

  return userMessage;
};

export const scopesOnResourceToScopesOnResourceMessage = (
  resourceScope: ScopesOnResource
) => {
  const message = new ScopeOnResourceMessage();

  message.setScopesList(resourceScope.scopesList);
  message.setResourceName(resourceScope.resourceName);
  message.setInherit(resourceScope.inherit);

  return message;
};

export const authorizationRoleToAuthorizationRoleMessage = (
  authorizationRole: PeopleAuthorizationRole
) => {
  const message = new AuthorizationRoleMessage();

  message.setName(authorizationRole.name);
  message.setDisplayName(authorizationRole.displayName);
  message.setGlobalScopesList(authorizationRole.globalScopesList);
  message.setScopesOnResourceList(
    authorizationRole.scopesOnResourceList.map(
      scopesOnResourceToScopesOnResourceMessage
    )
  );

  return message;
};

export const GroupObjectTypeToString = (type: GroupObjectType) => {
  switch (type) {
    case GroupObjectType.GROUP_OBJECT_TYPE_KS:
      return "KS";
    case GroupObjectType.GROUP_OBJECT_TYPE_OU:
      return "OU";
    case GroupObjectType.GROUP_OBJECT_TYPE_OIOE:
      return "OIOE";
    case GroupObjectType.GROUP_OBJECT_TYPE_FIOE:
      return "FIOE";
    case GroupObjectType.GROUP_OBJECT_TYPE_TAG:
      return "TAG";
    default:
      return "Object Type not defined";
  }
};

// returns the identifier of a grpc style named resource based on the name
export const GetIdentifierFromName = (name: string) => {
  return name.substring(name.lastIndexOf("/") + 1);
};

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const GenerateDiffPathsForGroup = (group: Group, newGroup: Group) => {
  const differences = diff(group, newGroup);
  if (!differences) {
    return [];
  }

  return differences
    .map((change) => {
      if (!change.path) {
        return "";
      }

      return camelToSnakeCase(change.path.join("."));
    })
    .filter((path) => path !== "");
};

/**
 * Helper method that turns Timestamp.AsObject into Timestamp
 */
export const timeObjToMessage = (
  time?: google_timestamp_pb.Timestamp.AsObject
): google_timestamp_pb.Timestamp => {
  const timestamp = new google_timestamp_pb.Timestamp();
  if (time) {
    timestamp.setSeconds(time.seconds);
    timestamp.setNanos(time.nanos);
  }
  return timestamp;
};

/**
 * Helper method that turns Date into Timestamp.AsObject
 */
export const dateToTimestampObj = (
  date?: Date
): google_timestamp_pb.Timestamp.AsObject => {
  const timestamp = new google_timestamp_pb.Timestamp();
  if (date) {
    timestamp.fromDate(date);
  }
  return timestamp.toObject();
};
