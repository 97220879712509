import React, { useEffect, useMemo, useState } from "react";

import {
  ActionIcon,
  Chip,
  FocusTrap,
  Group as LayoutGroup, Modal, TextInput
} from "@mantine/core";
import { ObjectTypeBadge } from "./ObjectTypeBadge";
import { getOrganizationNameMap, Group } from "../features/groups/groupsSlice";
import { GroupObjectType } from "../proto/sip/people/people_pb";
import {
  GetIdentifierFromName,
  GroupObjectTypeToString,
} from "../util/peopleProto";
import {
  Button,
} from '@mantine/core';
import {
  DataTable,
  DataTableSortStatus
} from "mantine-datatable"
import { useTranslation } from "react-i18next";
import { useDebouncedValue, useDisclosure } from "@mantine/hooks";
import { EditIcon } from "vseth-canine-ui";
import { ExportCSVButton } from "./ExportCSVButton";
import { selectGroupsQuery, setGroupsQuery } from "../features/queries/queriesSlice";
import { useDispatch, useSelector } from "react-redux";
import { SearchBar } from "./SearchBar";
import { getRegexErr, compareFn } from "../util/util";

interface GTProps {
  groups: Group[];
  onGroupSelect: (name: string) => void;
  allowTableEdit: boolean;
}

// returns the options object that is used by the selectFilter of bootstrap table for the resource type values
export function getGroupObjectTypeSelectionOptions() {
  let res = {};
  Object.values(GroupObjectType).forEach((val, i) => {
    if (i !== 0) {
      res = {
        ...res,
        [i]: GroupObjectTypeToString(val as GroupObjectType),
      };
    }
  });
  return res;
}


function GetColumns(selectedColumns: String[]) {

  const { t } = useTranslation();

  const cols = [
    {
      accessor: "name",
      title: t("Identifier"),
      sortable: true,
      render: (group: Group) => (
        GetIdentifierFromName(group.name)
      ),
    },
    {
      accessor: "organization",
      title: t("Organization"),
      sortable: true,
    },
    {
      accessor: "displayName",
      title: t("Display Name"),
      sortable: true,
    },
    {
      accessor: "objectType",
      title: t("Group Type"),
      sortable: true,
      render: (group: Group) => (
        <ObjectTypeBadge objectType={group.objectType} />
      ),
    },
    {
      accessor: "titleDe",
      title: t("Title (DE)"),
      sortable: true,
    },
    {
      accessor: "titleEn",
      title: t("Title (EN)"),
      sortable: true,
    },
    {
      accessor: "email",
      title: t("Email"),
      sortable: true,
    },
    {
      accessor: "edit",
      title: "Edit",
      sortable: false,
      render: (group: Group) => (
        <ActionIcon>
          <EditIcon size={16} />
        </ActionIcon>
      ),
    },
  ];

  return cols.filter((col) => {
    return selectedColumns.includes(col.accessor);
  });
}

function withOrg(groups: Group[]) {
  const orgMap = getOrganizationNameMap(groups);

  return (
    groups.map((group) => {
      let organization = "";

      const regexRes = extractBeginningOfGroupName.exec(group.name);
      if (regexRes && orgMap[regexRes[1]]) {
        organization = orgMap[regexRes[1]];
      }

      return {
        ...group,
        organization,
      };
    })
  );
}

const extractBeginningOfGroupName =
  /^(groups\/vseth-[0-9]{4}-[a-z0-9]{2,10})($|-)/;

/**
 * Display an array of group objects as a table.
 */
export const GroupTable: React.FC<GTProps> = ({
  groups,
  onGroupSelect,
  allowTableEdit,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // state setup
  const [numberOfObjects, setNumberOfObjects] = useState(groups.length);
  const [openedEdit, { /* not supported open: openEdit, */ close: closeEdit }] = useDisclosure(false);
  const [editDisplayName, setEditDisplayName] = useState("");
  const [editTitleDe, setEditTitleDe] = useState("");
  const [editTitleEn, setEditTitleEn] = useState("");
  const [editEmail, setEditEmail] = useState("");

  const [selectedColumns, setSelectedColumns] = useState(["name", "organization"]);

  const afterFilter = (newResults: any[]) => {
    if (newResults) {
      setNumberOfObjects(newResults.length);
    }
  };

  const selectionMessage = (
    <div className="mb-3 mt-3">
      {numberOfObjects !== groups.length &&
        `Showing ${numberOfObjects} of ${groups.length} groups`}
      {numberOfObjects === groups.length &&
        `Showing all ${groups.length} groups`}
    </div>
  );

  const groupsWithOrg = useMemo(() => withOrg(groups), [groups]);
  const page_sizes = [10, 20, 50];

  const savedQuery = useSelector(selectGroupsQuery);
  const [query, setQuery] = useState(savedQuery.text ? savedQuery.text : "");
  const [debouncedQuery] = useDebouncedValue(query, 100);
  const [useRegex, setUseRegex] = useState(savedQuery.regex || false);

  const regexError = getRegexErr(useRegex, debouncedQuery);

  const [pageSize, setPageSize] = useState(page_sizes[1]);

  useEffect(() => {  // go back to page 1 to avoid landing on nonexisting page when changing page size
    setPage(1);
  }, [pageSize, debouncedQuery]);

  useEffect(() => {   // Save query in redux store to reload later for convenicence
    dispatch(setGroupsQuery({ text: debouncedQuery, regex: useRegex }));
  }, [debouncedQuery, useRegex, dispatch]);

  const [page, setPage] = useState(1);
  const [records, setRecords] = useState(groupsWithOrg.slice(0, pageSize));
  const [sortableStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'name', direction: 'asc' });

  const filterMatchingGroups = useMemo(() => ((group: Group) => {
    const go = Object(group);
    const searchInFields = selectedColumns.map((accessor) => {
      if (accessor === "name") {
        return (GetIdentifierFromName(group.name));
      }
      if (accessor === "objectType") {
        return (GroupObjectTypeToString(group.objectType));
      }
      return String(go[accessor]);
    });
    if (debouncedQuery === "") return true;
    for (const field of searchInFields) {
      if (useRegex) {
        try {    // regex can contain errors
          if (String(field).toLowerCase().match(debouncedQuery.toLowerCase())) {
            return true;
          }
        } catch (e) {
          return false;
        }
      } else {
        if (String(field).toLowerCase().includes(debouncedQuery.toLowerCase())) {
          return true;
        }
      }
    }
    return false;
  }), [debouncedQuery, selectedColumns, useRegex]);

  useEffect(() => {
    const matchingGroupsWithOrg = groupsWithOrg.filter(filterMatchingGroups);
    afterFilter(matchingGroupsWithOrg);
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    matchingGroupsWithOrg.sort(compareFn(sortableStatus.direction, sortableStatus.columnAccessor as keyof Group));
    setRecords(matchingGroupsWithOrg.slice(from, to));
  }, [page, pageSize, sortableStatus, groupsWithOrg, filterMatchingGroups])


  return (
    <>
      <Modal opened={openedEdit} onClose={closeEdit} title="Edit group">
        <TextInput
          label="Display Name"
          value={editDisplayName}
          onChange={(e) => { setEditDisplayName(e.target.value) }}
        />
        <TextInput
          label={t("Title (DE)")}
          value={editTitleDe}
          onChange={(e) => { setEditTitleDe(e.target.value) }}
        />
        <TextInput
          label={t("Title (EN)")}
          value={editTitleEn}
          onChange={(e) => { setEditTitleEn(e.target.value) }}
        />
        <TextInput
          label={"Email"}
          value={editEmail}
          onChange={(e) => { setEditEmail(e.target.value) }}
        />
        <LayoutGroup position="apart" mt="md">
          {/* <Button onClick={closeEdit}>Save</Button> */}
          <Button onClick={closeEdit} color="red" >Cancel</Button>
        </LayoutGroup>
      </Modal>
      <div>
        <hr />
        <LayoutGroup position="apart">
          {selectionMessage}
          <ExportCSVButton data={groupsWithOrg.filter(filterMatchingGroups)} columns={GetColumns(selectedColumns)} >Export CSV</ExportCSVButton>
        </LayoutGroup>
        <FocusTrap>
          <SearchBar useRegex={useRegex} setUseRegex={setUseRegex} query={query} setQuery={setQuery} itemPlural="groups"  error={regexError}/>
        </FocusTrap>
        <hr />
        <Chip.Group multiple value={selectedColumns} onChange={(value) => (value.length > 0) && setSelectedColumns(value)}>
          <LayoutGroup p="xs">
            <Chip variant="filled" value="name">{t("Identifier")}</Chip>
            <Chip variant="filled" value="organization">{t("Organization")}</Chip>
            <Chip variant="filled" value="displayName">{t("Display Name")}</Chip>
            <Chip variant="filled" value="objectType">{t("Group Type")}</Chip>
            <Chip variant="filled" value="titleDe">{t("Title (DE)")}</Chip>
            <Chip variant="filled" value="titleEn">{t("Title (EN)")}</Chip>
            <Chip variant="filled" value="email">{t("Email")}</Chip>
          </LayoutGroup>
        </Chip.Group>
        <DataTable
          striped
          highlightOnHover
          idAccessor="name"
          records={records}
          columns={GetColumns(selectedColumns)}
          totalRecords={numberOfObjects}
          recordsPerPage={pageSize}
          onRowClick={(group, rowIndex) => {
            onGroupSelect(group.name);
          }}
          page={page}
          onPageChange={(p) => setPage(p)}
          recordsPerPageOptions={page_sizes}
          onRecordsPerPageChange={setPageSize}
          sortStatus={sortableStatus}
          onSortStatusChange={setSortStatus}
        />
      </div >
    </>
  );
};
