import { DetailCardBase } from "../../components/DetailCardBase";
import { ResourceTable } from "../../components/ResourceTable";
import { DetailCardProps } from "../../components/DetailPage";
import { Group } from "./groupsSlice";
import { Resource } from "../resources/resourcesSlice";
import { TabBase } from "../../components/TabBase";

interface GRProps extends DetailCardProps<Group> {
  resources: Resource[];
  indirectResources: Resource[];
}

// GroupResources shows the resources a group owns
export const GroupResources = (props: GRProps) => {
  const { resources, indirectResources } = props;

  const overviewElements = [
    {
      title: "Resources",
      value: resources.length.toString(),
    },
    {
      title: "Resources of Subgroups",
      value: indirectResources.length.toString(),
    },
    {
      title: "Total",
      value: (resources.length + indirectResources.length).toString(),
    },
  ];

  return (
    <DetailCardBase
      {...props}
      title={"Owned Resources"}
      overviewElements={overviewElements}
    >
      <TabBase
        tabs={[
          {
            title: `Directly Owned Resources (${resources.length})`,
            formatter: () => <ResourceTable resources={resources} />,
          },
          {
            title: `Indirectly Owned Resources (${indirectResources.length})`,
            formatter: () => <ResourceTable resources={indirectResources} />,
          },
        ]}
      />
    </DetailCardBase>
  );
};
