import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import rootReducer from "./rootReducer";
import { PeoplePromiseClient } from "../proto/sip/people/people_grpc_web_pb";
import { resourcesApi } from "../features/resources/resourcesSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import { peopleApi } from "../features/groups/groupsSlice";
import { rtkQueryErrorLogger } from "./rtkQueryErrorLogger";
import persistStore from "redux-persist/es/persistStore";
import { MOCK_DISABLE } from "./dev";
 
/**
 * Construct the redux store used in the application.
 */

let client = new PeoplePromiseClient(
  "https://people.web.api.getsip.ethz.ch",
  {},
  {}
);

// only add the mock client in a development setup
if (process.env.NODE_ENV === "development" && !MOCK_DISABLE) {
  const PeopleClientPromiseMock =
    require("../mock/PeoplePromiseClientMock").PeoplePromiseClientMock;
  client = new PeopleClientPromiseMock();
}

const customizedMiddleware = getDefaultMiddleware({
  thunk: {
    extraArgument: client,
  },
  serializableCheck: false,
});

export function usePeopleClient() {
  return client;
}

const store = configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware
    .concat(resourcesApi.middleware)
    .concat(peopleApi.middleware)
    .concat(rtkQueryErrorLogger),
});

persistStore(store)

setupListeners(store.dispatch);

if (process.env.NOVE_ENV === "development" && module.hot) {
  module.hot.accept("./rootReducer.ts", () => {
    const newRootReducer = require("./rootReducer.ts").default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;

export default store;