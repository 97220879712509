import { Modal } from "@mantine/core";
import { Group } from "../groups/groupsSlice";
import { MailDistributionListResource, MailboxQuota, Resource, ResourceState, ResourceType as RT, SympaMailingListResource, ProposedChange, ProposedChangeState, ResourceType } from "../../proto/sip/resources/resources_pb";
import { useState } from "react";
import { GenerateDiffPaths } from "../../util/resourcesProto";
import { EditFormBase, FormField } from "../../components/EditFormBase";
import { MutationTrigger, UseMutation } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { WithAuditMessage, useCreateProposedChangeMutation, useCreateResourceMutation, useListResourcesAuthorizationRolesQuery } from "./resourcesSlice";
import * as Yup from "yup";
import { selectAllowedToCreateResource, selectUserIdentifier, selectUserRoles } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { ErrorScreen } from "../../components/ErrorScreen";
import { SerializedError } from "@reduxjs/toolkit";
import { getSpecificResourceTableElements } from "./ResourceDetailPageComponents";
import { regexConstants } from "../../util/regexConstants";
import { SelectGroup } from "../../components/SelectGroup";
import { SelectResourceType } from "../../components/SelectResourceType";
import { selectAuxAuditMessage, setAuxAuditMessage } from "../queries/queriesSlice";

type UnpackedMutationTrigger<T> = T extends UseMutation<(infer U)> ? MutationTrigger<U> : T;

type ResourceOrProposedChange = {
  resource: Resource.AsObject;
  proposedChange?: ProposedChange.AsObject
};

interface CRMProps {
  opened: boolean,
  onClose: () => void,
  isUpdating: boolean,
  groups: Group[],
  forceProposedChange?: boolean,
  createResource: UnpackedMutationTrigger<typeof useCreateResourceMutation>,
  createProposedChange: UnpackedMutationTrigger<typeof useCreateProposedChangeMutation>,
  afterCreate?: (resource?: Resource.AsObject, proposedChange?: ProposedChange.AsObject) => void,
}

export const CreateResourceModal = ({
  opened,
  onClose,
  isUpdating,
  groups,
  forceProposedChange,
  createResource,
  createProposedChange,
  afterCreate,
}: CRMProps) => {

  const userId = useSelector(selectUserIdentifier);

  // load the people and resources api authorization information
  const { data: resourceApiRoles } =
    useListResourcesAuthorizationRolesQuery(undefined);

  // get the roles of the user (including debug roles)
  const userRoles = useSelector(selectUserRoles);

  const savedAuditMessage = useSelector(selectAuxAuditMessage);
  const [auditMessage, setAuditMessage] = useState(savedAuditMessage);

  const dispatch = useDispatch();

  const initialResourceData: Resource.AsObject = {
    name: "",
    ownerGroup: "",
    resourceType: ResourceType.GSUITE_SHARED_DRIVE,
    displayName: "",
    description: "",
    resourceState: ResourceState.RESOURCE_STATE_UNSPECIFIED,
    view: Resource.View.BASIC,
    lastUpdateBy: userId || "",
    permissionsList: [],
    changesList: [],
  };

  const [currentResource, setCurrentResource] = useState(initialResourceData);

  if (!userId) {
    const error: SerializedError = {
      name: "Unknown user name",
      message: "Error in frontend: Current user has no identifier"
    };
    return (<ErrorScreen error={error} />);
  }

  const rewriteResource = <T extends Resource.AsObject>(r: T, finalize?: boolean) => {
    const mailEmpty = {
      orgPrefix: "", sharedMailboxOwner: "", mailboxQuota: MailboxQuota.MAILBOX_QUOTA_UNSPECIFIED,
      sharedMailboxQuota: MailboxQuota.MAILBOX_QUOTA_UNSPECIFIED, domainsList: [], displayNameSuffix: ""
    };
    const mailListEmpty = {
      identifier: 0, email: "", emailAliasesList: [], orgPrefix: "",
      dblType: MailDistributionListResource.DblType.GROUP_MEMBERS, ethAdMemberEmailsList: []
    };
    const mailContactEmpty = { email: "", emailAliasesList: [], shortName: "", fullName: "", orgPrefix: "" };
    const mailSympaEmpty = {
      email: "", subject: "", subjectTag: "", type: SympaMailingListResource.ListType.LIST_TYPE_UNSPECIFIED,
      sendSetup: SympaMailingListResource.SendSetup.SEND_SETUP_UNSPECIFIED
    };
    const mailSpecialUserConfigurationEmpty = { email: "", orgPrefix: "", prestigeName: "",
      account: "", quota: 0, primaryEmailAddress: "", emailAliasesList: [], hiddenInEthAddressBook: false,
    };

    const generalKeys = [
      "name", "ownerGroup", "resourceType", "displayName", "description", "resourceState",
      "view", "lastUpdateBy", "permissionsList", "changesList", "requestAuditMessage"];

    var whitelist = generalKeys;

    switch (r.resourceType) {
      case RT.MAIL_GLOBAL:
        whitelist.push("mailGlobal");
        if (!r.mailGlobal)
          r.mailGlobal = mailEmpty;
        break;
      case RT.MAIL_SERVICE:
        whitelist.push("mailService");
        if (!r.mailService)
          r.mailService = mailEmpty;
        break;
      case RT.MAIL_DISTRIBUTION_LIST:
        whitelist.push("mailDistributionList");
        if (!r.mailDistributionList)
          r.mailDistributionList = mailListEmpty;
        break;
      case RT.MAIL_SHARED_MAILBOX:
        whitelist.push("mailSharedMailbox");
        if (!r.mailSharedMailbox)
          r.mailSharedMailbox = mailListEmpty;
        break;
      case RT.MAIL_SPECIAL_USER_CONFIGURATION:
        whitelist.push("mailSpecialUserConfiguration");
        if (!r.mailSpecialUserConfiguration)
          r.mailSpecialUserConfiguration = mailSpecialUserConfigurationEmpty;
        break;
      case RT.MAIL_CONTACT:
        whitelist.push("mailContact");
        if (!r.mailContact)
          r.mailContact = mailContactEmpty;
        break;
      case RT.ETH_IAM_GROUP:
        whitelist.push("ethIamGroup");
        if (!r.ethIamGroup)
          r.ethIamGroup = mailContactEmpty;
        break;
      case RT.SYMPA_MAILING_LIST:
        whitelist.push("mailSympa");
        if (!r.mailSympa)
          r.mailSympa = mailSympaEmpty;
        break;
      case RT.GROUP_TAG:
        whitelist.push("groupTag");
        if (!r.groupTag)
          r.groupTag = { tagKey: "" };
        break;
      case RT.KEYCLOAK_CLIENT:
        whitelist.push("keycloakClient");
        if (!r.keycloakClient)
          r.keycloakClient = { clientId: "", enabled: false, clientProtocol: "" };
        break;
      case RT.NAS_STORAGE_FOLDER:
        whitelist.push("nasStorageFolder");
        if (!r.nasStorageFolder)
          r.nasStorageFolder = { path: "" };
        break;
      case RT.GOOGLE_CALENDAR:
        whitelist.push("googleCalendar");
        if (!r.googleCalendar)
          r.googleCalendar = { identifier: "", isPublic: false };
        break;
      case RT.OFFICE_KEY:
        whitelist.push("officeKey");
        if (!r.officeKey)
          r.officeKey = { identifier: "", rooms: "" };
        break;
      case RT.KUBERNETES_PERMISSION:
        whitelist.push("kubernetesPermission");
        if (!r.kubernetesPermission)
          r.kubernetesPermission = { namespace: "" };
        break;
      case RT.GSUITE_SHARED_DRIVE:
        whitelist.push("gsuiteSharedDrive");
        if (!r.gsuiteSharedDrive)
          r.gsuiteSharedDrive = { identifier: "?ephemeral=" + r.displayName };
        break;
      case RT.CONFLUENCE_SPACE:
        whitelist.push("confluenceSpace");
        if (!r.confluenceSpace)
          r.confluenceSpace = { key: "", isPublic: false };
        break;
      case RT.GITLAB_GROUP:
        whitelist.push("gitlabGroup");
        if (!r.gitlabGroup)
          r.gitlabGroup = { identifier: "", path: "" };
        break;
      default:
        console.log("Unimplemented resource type, rewriteInitialResource :", r.resourceType);
    };

    if (finalize) {
      Object.keys(r).map((k) => (!(whitelist.includes(k))) ? delete r[k as keyof T] : null);
    }

    return (r);
  };

  const onCreateResource = (resource: Resource.AsObject, newAuditMessage: string) => {
    dispatch(setAuxAuditMessage({ text: newAuditMessage }));
    setCurrentResource(resource);
    setAuditMessage(newAuditMessage);
    const createDirectly = !forceProposedChange && selectAllowedToCreateResource(resourceApiRoles, userRoles, resource.ownerGroup);
    if (createDirectly) {
      return new Promise((resolve: ((value: ResourceOrProposedChange) => void), reject) =>
        createResource({ resource: resource, auditMessage: newAuditMessage }).unwrap().then(
          ((r: Resource.AsObject) => resolve({ resource: r as Resource.AsObject, proposedChange: undefined })), reject
        )
      );
    } else {
      const proposedChange: ProposedChange.AsObject = {
        name: "",
        resourceName: resource.name,
        data: resource,
        pathList: GenerateDiffPaths({} as Resource.AsObject, resource),
        lastUpdatedBy: "",
        state: ProposedChangeState.PROPOSED_CHANGE_STATE_UNDEFINED,
        comment: `Generated via groupmanager. Audit message: "${newAuditMessage}"`,
      };
      return new Promise((resolve: ((value: ResourceOrProposedChange) => void), reject) =>
        createProposedChange({pc: proposedChange, auditMsg: auditMessage}).unwrap().then(
          ((pc: ProposedChange.AsObject) => {
            resolve({ resource: pc.data as Resource.AsObject, proposedChange: pc })
          }), reject
        )
      );
    }
  };

  const handleErrorCreateResource = (r: any) => console.log("Error creating resource:", r);
  const handleCreatedResource = ({ resource, proposedChange }: ResourceOrProposedChange) => {
    afterCreate && afterCreate(resource, proposedChange);
    onClose();
  };
  const onSubmitCreateResource = (resource: Resource.AsObject, auditMessage: string) => {
    const newResource = onCreateResource(resource, auditMessage);
    newResource.then(handleCreatedResource, handleErrorCreateResource);
  };

  const validResourceTypes = Object.values(RT).filter(n => (n !== 0)).map((n) => (String(n)));

  const generalForm = (groups &&
    <EditFormBase
      data={currentResource}
      isUpdating={isUpdating}
      onSubmit={(r) => {
        return onSubmitCreateResource(rewriteResource(r, true), r.requestAuditMessage);
      }}
      onCancel={() => onClose()}
      createNew={true}
      saveLabel="Submit"
    >
      {[
        {
          title: "Identifier",
          key: "name",
          disabled: true,
        },
        {
          title: "Display Name",
          key: "displayName",
          validation: Yup.string()
            .trim()
            .matches(
              regexConstants.resource.display_name,
              "Invalid Display Name"
            )
            .required("Display Name is required"),
        },
        {
          title: "Owner Group",
          key: "ownerGroup",
          validation: Yup.string()
            .trim()
            .matches(regexConstants.groupNameRegex, "Invalid Group Name")
            .required("Owner group is required"),
          customFieldInputElement: (formik) => (
            <SelectGroup
              groups={groups}
              initialValue={initialResourceData.ownerGroup}
              setGroupNames={(g) =>
                formik.form.setFieldValue("ownerGroup", g[0])
              }
            />
          ),
        },
        {
          title: "Resource Type",
          key: "resourceType",
          validation: Yup.string().oneOf(validResourceTypes),
          customFieldInputElement: (formik) => (
            <SelectResourceType
              initialValue={initialResourceData.resourceType}
              setResourceType={(t) => {
                const prt = parseInt(t, 10);
                const newResource = rewriteResource({ ...currentResource, ...formik.form.values, ...({ resourceType: prt }) });
                formik.form.setValues(newResource);
                setCurrentResource(newResource);
              }} />
          ),
        },
        {
          title: "Description",
          key: "description",
          validation: Yup.string()
            .trim()
            .matches(regexConstants.resource.description, "Invalid Description")
            .required("Description is required"),
        },
        {
          title: "Audit message",
          key: "requestAuditMessage",
          validation: Yup.string().required("Please enter an audit message for the admins").matches(regexConstants.group.auditMessage, "Invalid audit message, min. 10 characters"),
        },
        ...(getSpecificResourceTableElements(currentResource.resourceType))
      ] as FormField<any>[]}
    </EditFormBase>
  );

  return (
    <Modal opened={opened} onClose={onClose} title="Add resource" size="lg">
      {generalForm}
    </Modal>
  )
}