import React, { ReactElement, useState, useEffect, useMemo } from "react";
import { CloseIcon, FilePlusIcon, DeleteIcon, IconProps, SearchIcon } from "vseth-canine-ui";
import { ActionIcon, Button, Flex, ModalProps, Popover, TextInput } from "@mantine/core";
import { Group as LayoutGroup, Text } from '@mantine/core';
import { DataTable, DataTableColumn, DataTableSortStatus } from 'mantine-datatable';
import { useDebouncedValue, useDisclosure } from "@mantine/hooks";
import { getHotkeyHandler } from '@mantine/hooks';
import { regexConstants } from "../util/regexConstants";
import { compareFn } from "../util/util";

export const TABLE_PAGE_SIZES = [10, 20, 50];

interface ARETBProps<T extends { name: string }> {
  data: T[]; // the data displayed in the table
  columns: DataTableColumn<T>[];
  elementName: string; // the display name of an data element, e.g. "user"

  keyAccessor: string; // the accessor for the primary key of the data element, e.g. "name"

  // the icon that should be displayed for adding new elements to the table, defaults to PlusIcon
  addElementIcon?: React.FC<IconProps>;

  // the icon that should be displayed for adding new elements to the table, defaults to DeleteIcon
  removeElementIcon?: React.FC<IconProps>;

  // the modal that should be opened for adding elements
  addElementModal?: (
    isOpen: boolean,
    onCancel: () => void
  ) => ReactElement<ModalProps>;
  enableAddElements?: boolean; // whether adding elements is possible
  enableRemoveElements?: boolean; // whether removing elements in possible
  enableSelection?: boolean; // whether selecting elements in possible
  onRemove?: (names: T[]) => Promise<any>; // callback for elements that should be removed
  onRemoveWithAuditMsg?: (names: T[], auditMessage: string) => Promise<any>; // callback for elements that should be removed with audit message
}

// ModElementsTableBase wraps a Mantine datatable to support adding and removing elements from the table
export const ModElementsTableBase = <T extends { name: string }>({
  data,
  columns,
  elementName,
  keyAccessor,
  addElementIcon,
  addElementModal,
  removeElementIcon,
  enableAddElements,
  enableRemoveElements,
  enableSelection,
  onRemove,
  onRemoveWithAuditMsg,
}: ARETBProps<T>) => {

  const dataWithId = useMemo(() => data.map((d) => ({
    ...d,
    id: d.name,
  })), [data]);

  const page_sizes = TABLE_PAGE_SIZES;

  // state setup
  const [numberOfObjects, setNumberOfObjects] = useState(data.length);
  const [pageSize, setPageSize] = useState(page_sizes[1]);
  const [selectedItems, setSelectedItems] = useState([] as T[]);
  const [openedAdd, { open: openAdd, close: closeAdd }] = useDisclosure(false);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState(dataWithId.slice(0, pageSize));
  const [sortableStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'name', direction: 'asc' });
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 100);
  const [matchingWithId, setMatchingWithId] = useState(dataWithId);
  const [auditPromptOpened, setAuditPromptOpened] = useState(false);
  const [removalAuditMsg, setRemovalAuditMsg] = useState("");

  useEffect(() => {  // Go back to page 1 to avoid landing on nonexisting page when changing page size
    setPage(1);
  }, [pageSize, debouncedQuery]);

  useEffect(() => {
    const filterMatching = (e: T) => {
      const eo = Object(e);
      const searchInFields = columns.map((col) => {
        return String(eo[col.accessor]);
      });
      if (debouncedQuery === "") return true;
      for (const field of searchInFields) {
        if (String(field).toLowerCase().includes(debouncedQuery.toLowerCase())) {
          return true;
        }
      }
      return false;
    }

    const filtered = dataWithId.filter(filterMatching);

    if (debouncedQuery !== "") {
      setMatchingWithId(filtered);
      setNumberOfObjects(filtered.length);
    } else {
      setMatchingWithId(dataWithId);
      setNumberOfObjects(dataWithId.length);
    }

    setSelectedItems([]);     // to avoid invisible selected items 
  }, [debouncedQuery, dataWithId, columns]);

  useEffect(() => {
    const sorted = matchingWithId.sort(compareFn(sortableStatus.direction, sortableStatus.columnAccessor.replace('_','') as keyof T, sortableStatus.columnAccessor === 'name'));
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    setRecords(sorted.slice(from, to));
  }, [page, pageSize, sortableStatus, matchingWithId]);

  let AddIcon = addElementIcon;
  if (!AddIcon) {
    AddIcon = (props) => <FilePlusIcon {...props} />;
  }

  let RemoveIcon = removeElementIcon ?
    removeElementIcon
    : (props: IconProps) => <DeleteIcon {...props} />;

  const selectionMessage = (
    <Text>
      {numberOfObjects !== data.length &&
        `Showing ${numberOfObjects} of ${data.length} ${elementName}s`}
      {numberOfObjects === data.length &&
        `Showing all ${data.length} ${elementName}s`}
    </Text>
  );

  const removeButtonWithStuff = (
    <Popover position="bottom" withArrow shadow="md" opened={auditPromptOpened} onChange={setAuditPromptOpened}>
      <Popover.Target>
        <Button disabled={(!onRemoveWithAuditMsg && !onRemove) || auditPromptOpened}
          onClick={(e) => onRemoveWithAuditMsg ? setAuditPromptOpened(!auditPromptOpened) : onRemove && onRemove(selectedItems)}
          leftIcon={<RemoveIcon icon="delete" />} color="red">
          Remove
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Flex
          gap="sm"
          justify="flex-start"
          align="flex-end"
          direction="row"
        >
          <TextInput required label="Audit message required" placeholder="Enter message"
            value={removalAuditMsg}
            onChange={(ev) => setRemovalAuditMsg(ev.currentTarget.value)}
            error={(removalAuditMsg !== "" && !regexConstants.group.auditMessage.test(removalAuditMsg) && "Invalid audit message")}
            onKeyDown={getHotkeyHandler([
              ['Enter', (() => {
                if (regexConstants.group.auditMessage.test(removalAuditMsg) && onRemoveWithAuditMsg) {
                  onRemoveWithAuditMsg(selectedItems, removalAuditMsg);
                  setAuditPromptOpened(false);
                  //setRemovalAuditMsg("");
                }
              })],
            ])}
          />
          <Button
            color="red" variant="filled" aria-label="Delete" rightIcon={<RemoveIcon icon="delete" />}
            disabled={onRemoveWithAuditMsg && auditPromptOpened && !regexConstants.group.auditMessage.test(removalAuditMsg)}
            onClick={(e) => {
              if (onRemoveWithAuditMsg) {
                if (auditPromptOpened) {
                  if (regexConstants.group.auditMessage.test(removalAuditMsg)) {
                    onRemoveWithAuditMsg(selectedItems, removalAuditMsg);
                    setAuditPromptOpened(false);
                    //setRemovalAuditMsg("");
                  }
                }
              } else if (onRemove) {
                return onRemove(selectedItems);
              }
            }
            }
          >
            Remove
          </Button>
        </Flex>
      </Popover.Dropdown>
    </Popover>);

  return (
    <div>
      {enableAddElements &&
        addElementModal &&
        addElementModal(openedAdd, closeAdd)}
      <TextInput
        placeholder={`Search ${elementName}s ...`}
        icon={<SearchIcon />}
        rightSection={<ActionIcon onClick={(e) => setQuery("")}><CloseIcon color="grey" /></ActionIcon>}
        value={query}
        onChange={(e) => setQuery(e.currentTarget.value)}
      />
      <hr />
      <LayoutGroup position="apart">
        {selectionMessage}
        <Button.Group>
          {(selectedItems.length > 0
            && enableRemoveElements)
            && removeButtonWithStuff}
          {enableAddElements && <Button onClick={openAdd} leftIcon={<AddIcon icon="PLUS" />}>Add </Button>}
        </Button.Group>
      </LayoutGroup>
      <hr />
      {
        (enableSelection ?
          <DataTable
            striped
            highlightOnHover
            idAccessor={keyAccessor}
            records={records}
            columns={columns}
            totalRecords={numberOfObjects}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={page_sizes}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortableStatus}
            onSortStatusChange={setSortStatus}
            selectedRecords={selectedItems}
            onSelectedRecordsChange={((selected) => setSelectedItems(selected))}
          />
          :
          <DataTable
            striped
            highlightOnHover
            idAccessor={keyAccessor}
            records={records}
            columns={columns}
            totalRecords={numberOfObjects}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={page_sizes}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortableStatus}
            onSortStatusChange={setSortStatus}
          />
        )
      }
    </div >
  );
};