import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { timeObjToMessage } from "../../util/peopleProto";
import { selectIsResourcesAdmin, selectUserRoles } from "../auth/authSlice";
import { useListAuditLogQuery, useListResourcesAuthorizationRolesQuery } from "../resources/resourcesSlice";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TABLE_PAGE_SIZES } from "../../components/ModElementsTableBase";
import { compareFn } from "../../util/util";
import { AuditLogEntry } from "../../proto/sip/resources/resources_pb";

export const AuditLogTable: React.FC<{}> = ({ }) => {
  const userRoles = useSelector(selectUserRoles);

  const { data: resourcesRoles, isLoading: isLoadingResourcesRoles } =
    useListResourcesAuthorizationRolesQuery(undefined);

  const isAdmin = selectIsResourcesAdmin(resourcesRoles, userRoles);

  const { data: auditLog } = useListAuditLogQuery(undefined, { skip: !isAdmin });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZES[1]);
  const [records, setRecords] = useState(auditLog?.entryList.slice(0, pageSize));
  const [sortableStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'name', direction: 'asc' });


  useEffect(() => {
    const sorted = auditLog?.entryList /*.sort(
      compareFn(sortableStatus.direction,
        sortableStatus.columnAccessor as any,
        sortableStatus.columnAccessor === 'name')
    ) */ || [];
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    setRecords(sorted.slice(from, to));
  }, [page, pageSize, sortableStatus, auditLog]);


  return (
    <DataTable
      striped
      highlightOnHover
      idAccessor={"name"}
      records={records}
      columns={[
        {
          accessor: "name",
          title: ("Identifier"),
          sortable: true,
        },
        {
          accessor: "resourceName",
          title: ("resourceName"),
          sortable: true,
        },
        {
          accessor: "permissionName",
          title: ("permissionName"),
          sortable: true,
        },
        {
          accessor: "changeName",
          title: ("changeName"),
          sortable: true,
        },
        {
          accessor: "createTime",
          title: ("createTime"),
          render: (v) => timeObjToMessage(v.createTime).toDate().toLocaleString(),
          sortable: true,
        },
        {
          accessor: "username",
          title: ("Username"),
          sortable: true,
        },
        {
          accessor: "auditMessage",
          title: ("auditMessage"),
          sortable: true,
        },
      ]}
      totalRecords={(auditLog && auditLog.entryList) ? auditLog.totalSize : 0}
      recordsPerPage={pageSize}
      page={page}
      onPageChange={(p) => { setPage(p); }}
      recordsPerPageOptions={TABLE_PAGE_SIZES}
      onRecordsPerPageChange={() => { }}
      withBorder
    />);
}