import React, { useEffect, useMemo, useState } from "react";
import { DeleteIcon, SearchIcon, UndoIcon, UserMinusIcon, UserPlusIcon } from "vseth-canine-ui";
import { User } from "../features/groups/groupsSlice";
import { GroupMemberModal } from "../features/groups/GroupMemberModal";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import {
  Button,
  Group as LayoutGroup, 
  Modal, TextInput, Text  
} from "@mantine/core";
import { useDebouncedValue, useDisclosure } from "@mantine/hooks";
import { ExportCSVButton } from "./ExportCSVButton";

interface UTProps {
  users: User[];
  onRemove?: (selectedUserIdentifiers: string[]) => Promise<any>;
  allowedToEditMembers: boolean;
  groupName: string;
  getDefaultAuditMessage: () => string;
  saveAuditMessage: (message: string) => void;
  validateMembers?: (users: (User | undefined)[]) => {isError: boolean, errorText: JSX.Element};
}

const columns = [
  {
    accessor: "username",
    title: "Username",
    sortable: true,
  },
  {
    accessor: "familyName",
    title: "Surname",
    sortable: true,
  },
  {
    accessor: "givenName",
    title: "Given Name",
    sortable: true,
  },
  {
    accessor: "email",
    title: "Email",
    sortable: true,
  },
];

// UserTable is a component that displays an array of users in a table.
export const UserTable: React.FC<UTProps> = ({
  users,
  onRemove,
  allowedToEditMembers,
  groupName,
  getDefaultAuditMessage,
  saveAuditMessage,
  validateMembers,
}) => {

  const usersWithId = useMemo(() => users.map((u) => ({
    ...u,
    id: u.username,
  })), [users]);
  const [numberOfObjects, setNumberOfObjects] = useState(users.length);
  const page_sizes = [10, 20, 50];
  const [pageSize, setPageSize] = useState(page_sizes[1]);
  const [selectedUsers, setSelectedUsers] = useState([] as User[]);
  const [openedAdd, { open: openAdd, close: closeAdd }] = useDisclosure(false);
  const [openedRemove, { open: openRemove, close: closeRemove }] = useDisclosure(false);

  const afterFilter = (newResults: any[]) => {
    if (newResults) {
      setNumberOfObjects(newResults.length);
    }
  };

  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 100);
  useEffect(() => {  // Go back to page 1 to avoid landing on nonexisting page when changing page size
    setPage(1);
  }, [pageSize, debouncedQuery]);

  const [page, setPage] = useState(1);
  const [records, setRecords] = useState(usersWithId.slice(0, pageSize));
  const [sortableStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'name', direction: 'asc' });

  useEffect(() => {
    const filterMatchingUsers = (user: User) => {
      const uo = Object(user);
      const searchInFields = columns.map((col) => {
        return String(uo[col.accessor]);
      });
      if (debouncedQuery === "") return true;
      for (const field of searchInFields) {
        if (String(field).toLowerCase().includes(debouncedQuery.toLowerCase())) {
          return true;
        }
      }
      return false;
    }

    const matchingUsersWithId = usersWithId.filter(filterMatchingUsers);
    afterFilter(matchingUsersWithId);
    const asc = (sortableStatus.direction === 'desc') ? -1 : 1;
    const sortFn = ((a: { [index: string]: any }, b: { [index: string]: any }) => {
      const av = a[sortableStatus.columnAccessor];
      const bv = b[sortableStatus.columnAccessor];
      if (av === bv) {
        return 0;
      } else if (av < bv) {
        return -asc;
      } else if (av > bv) {
        return asc;
      }
      return asc;
    });
    const sorted = matchingUsersWithId.sort(sortFn);
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    setRecords(sorted.slice(from, to));
  }, [page, pageSize, usersWithId, sortableStatus, debouncedQuery]);

  const selectionMessage = (
    <p>
      {numberOfObjects !== users.length &&
        `Showing ${numberOfObjects} of ${users.length} users`}
      {numberOfObjects === users.length &&
        `Showing all ${users.length} users`}
    </p>
  );
  return (
    <div>
      {openedAdd && <GroupMemberModal
        isOpen={openedAdd}
        groupName={groupName}
        onCancelOrFinish={closeAdd}
        getDefaultAuditMessage={getDefaultAuditMessage}
        saveAuditMessage={saveAuditMessage}
        validateMembers={validateMembers}
      />}
      <Modal opened={openedRemove} onClose={closeRemove} title="Remove users" size="sm">
        <p>Delete {selectedUsers.length} users from group {groupName}?</p>
        <LayoutGroup position="apart">
          <Button leftIcon={<UndoIcon />} onClick={closeRemove}>
            Cancel
          </Button>
          <Button leftIcon={<DeleteIcon />} onClick={() => onRemove ? onRemove(selectedUsers.map((u)=> u.name)) : undefined} color="red" disabled={onRemove === undefined}>
            Remove
          </Button>
        </LayoutGroup>
      </Modal>
      <LayoutGroup position="apart">
        <Text>{selectionMessage}</Text>
        <LayoutGroup>
        <ExportCSVButton data={users} columns={columns}>Export CSV (all)</ExportCSVButton>
        <Button.Group>
          <Button leftIcon={<UserMinusIcon />} onClick={openRemove} color="red" disabled={selectedUsers.length === 0}>
            Remove
          </Button>
          <Button disabled={!allowedToEditMembers} leftIcon={<UserPlusIcon />} onClick={openAdd}>
            Add
          </Button>
        </Button.Group>
        </LayoutGroup>
      </LayoutGroup>
      <TextInput
        placeholder="Search groups for values in selected fields..."
        icon={<SearchIcon />}
        value={query}
        onChange={(e) => setQuery(e.currentTarget.value)}
      />
      <DataTable
        striped
        highlightOnHover
        idAccessor="username"
        records={records}
        columns={columns}
        totalRecords={numberOfObjects}
        recordsPerPage={pageSize}
        page={page}
        onPageChange={(p) => setPage(p)}
        recordsPerPageOptions={page_sizes}
        onRecordsPerPageChange={setPageSize}
        sortStatus={sortableStatus}
        onSortStatusChange={setSortStatus}
        selectedRecords={selectedUsers}
        onSelectedRecordsChange={(selected) => setSelectedUsers(selected)}
      />
    </div>
  );
};
