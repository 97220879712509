import React, { useEffect, useMemo, useState } from "react";
import { Resource } from "../features/resources/resourcesSlice";
import { ResourceIcon } from "./ResourceIcon";
import { ResourceType } from "../proto/sip/resources/resources_pb";
import { GetStringForResourceType } from "../util/resourcesProto";
import { ResourceLink } from "./ResourceLink";
import {
  DataTable,
  DataTableSortStatus
} from "mantine-datatable";
import { useDebouncedValue } from "@mantine/hooks";
import {
  Anchor, FocusTrap,
  Grid,
  Group,
  MultiSelect, Text
} from "@mantine/core";
import { GetIdentifierFromName } from "../util/peopleProto";
import { toInteger } from "lodash";
import { ExportCSVButton } from "./ExportCSVButton";
import { selectResourcesQuery, setResourcesQuery } from "../features/queries/queriesSlice";
import { useDispatch, useSelector } from "react-redux";
import { SearchBar } from "./SearchBar";
import { compareFn, getRegexErr } from "../util/util";

// returns the options object that is used by the selectFilter of bootstrap table for the resource type values
export function getResourceTypeSelectionOptions() {
  let res = {};
  Object.values(ResourceType).forEach((val, i) => {
    if (i !== 0) {
      res = {
        ...res,
        [i]: GetStringForResourceType(val as ResourceType),
      };
    }
  });
  return res;
}

const columns = [
  {
    accessor: "name",
    title: "Identifier",
    sortable: true,
    render: (resource: Resource) => (
      <ResourceLink resourceName={resource.name} key={resource.name} />
    ),
  },
  {
    accessor: "resourceType",
    title: "Resource Type",
    sortable: true,
    render: (resource: Resource) => (
      <ResourceIcon resourceType={resource.resourceType} />
    ),
  },
  {
    accessor: "displayName",
    title: "Display Name",
    sortable: true,
  },
  {
    accessor: "ownerGroup",
    title: "Owner Group",
    sortable: true,
    render: (resource: Resource) => (
      <ResourceLink resourceName={resource.ownerGroup} key={resource.name} />
    ),
  },
];

interface RTProps {
  resources: Resource[];
  onResourceSelect?: (name: string) => void;
}

// ResourceTable displays a list of resources in a BootstrapTable
export const ResourceTable: React.FC<RTProps> = ({
  resources,
  onResourceSelect,
}) => {
  const page_sizes = [10, 20, 50];
  const dispatch = useDispatch();

  const [numberOfObjects, setNumberOfObjects] = useState(resources.length);

  const afterFilter = (newResults: any[]) => {
    if (newResults) {
      setNumberOfObjects(newResults.length);
    }
  };

  const savedQuery = useSelector(selectResourcesQuery);
  const [query, setQuery] = useState(savedQuery ? savedQuery.text : "");
  const [debouncedQuery] = useDebouncedValue(query, 100);
  const [selectedResourceTypes, setSelectedResourceTypes] = useState<string[]>(savedQuery ? savedQuery.type : []);
  const [useRegex, setUseRegex] = useState(savedQuery.regex || false);
  
  const regexError = getRegexErr(useRegex, debouncedQuery);

  const [pageSize, setPageSize] = useState(page_sizes[1]);

  useEffect(() => {  // Go back to page 1 to avoid landing on nonexisting page when changing page size
    setPage(1);
  }, [pageSize, debouncedQuery]);

  useEffect(() => {   // Save query in redux store to reload later for convenicence
    dispatch(setResourcesQuery({ text: debouncedQuery, type: selectedResourceTypes, regex: useRegex }));
  }, [debouncedQuery, selectedResourceTypes, useRegex, dispatch]);

  const [page, setPage] = useState(1);
  const [records, setRecords] = useState(resources.slice(0, pageSize));
  const [sortableStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'name', direction: 'asc' });

  const resourceTypes = Object.values(ResourceType).map((type) => {
    const str = GetStringForResourceType(toInteger(type));
    return { value: String(type), label: str };
  }).filter((item) => {
    const unknownRTypeMessage = GetStringForResourceType(ResourceType.RESOURCE_TYPE_UNSPECIFIED);
    if (item.label === unknownRTypeMessage) {
      return false;           // Remove resource type without description string
    }
    return true;
  });

  const filterResources = useMemo(() => ((resource: Resource) => {
    if ((selectedResourceTypes.length > 0) && !(selectedResourceTypes.includes(String(resource.resourceType)))) {
      return false;
    }
    if (debouncedQuery === "") return true;
    const searchInFields = [resource.displayName, GetIdentifierFromName(resource.name), GetIdentifierFromName(resource.ownerGroup)];
    for (const field of searchInFields) {
      if (useRegex) {
        try {    // regex can contain errors
          if (String(field).toLowerCase().match(debouncedQuery.toLowerCase())) {
            return true;
          }
        } catch (e) {
          return false;
        }
      } else {
        if (String(field).toLowerCase().includes(debouncedQuery.toLowerCase())) {
          return true;
        }
      }
    }
    return false;
  }), [debouncedQuery, selectedResourceTypes, useRegex]);

  useEffect(() => {
    const matchingResources = resources.filter(filterResources);
    afterFilter(matchingResources);
    const from = (page - 1) * pageSize;
    const to = from + pageSize;

    /* Remove last argument if resource name is not integer */
    matchingResources.sort(compareFn(sortableStatus.direction, sortableStatus.columnAccessor as keyof Resource, sortableStatus.columnAccessor === 'name'));

    setRecords(matchingResources.slice(from, to));
  }, [page, pageSize, sortableStatus, resources, debouncedQuery, selectedResourceTypes, useRegex, filterResources])

  return (
    <div>
      <Group position="apart">
        <Text size="sm">For information on resources and resource types see&nbsp;
          <Anchor size="sm" href="https://docs.vseth.ethz.ch/de/isg-it-dienstleistungen/groupmanager#Groupmanager-Dokumentationf%C3%BCrNutzerInnen">docs page</Anchor>
        </Text>
        <ExportCSVButton data={resources.filter(filterResources)} columns={columns}>Export CSV</ExportCSVButton>
      </Group>
      <FocusTrap>
        <Grid>
          <Grid.Col span={7}>
            <SearchBar useRegex={useRegex} setUseRegex={setUseRegex} query={query} setQuery={setQuery} label="Search by resource field values" error={regexError} />
          </Grid.Col>
          <Grid.Col span="auto" >
            <MultiSelect
              data={resourceTypes}
              label="Filter by resource type"
              placeholder="All resources"
              value={selectedResourceTypes} onChange={(values) => setSelectedResourceTypes(values)}
            />
          </Grid.Col>
        </Grid>
      </FocusTrap>
      <hr />
      <DataTable
        striped
        highlightOnHover
        idAccessor="name"
        records={records}
        columns={columns}
        totalRecords={numberOfObjects}
        recordsPerPage={pageSize}
        onRowClick={(resource, rowIndex) => onResourceSelect && onResourceSelect(resource.name)}
        page={page}
        onPageChange={(p) => setPage(p)}
        recordsPerPageOptions={page_sizes}
        onRecordsPerPageChange={setPageSize}
        sortStatus={sortableStatus}
        onSortStatusChange={setSortStatus}
      />
    </div>

  );
};
