import React from "react";
import { useTranslation } from "react-i18next";
import { RpcError } from "grpc-web";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { Link, isRouteErrorResponse, useRouteError } from "react-router-dom";

interface ErrorScreenProps {
  error: FetchBaseQueryError | SerializedError | RpcError | "route-error";
}

function routeErrorMessage(error: unknown) {
  if (isRouteErrorResponse(error) && error.data?.message) {
    <p>{error.data.message}</p>
  } else {
    return <></>
  }
}

// ErrorScreen can be used to display an error in the application
export const ErrorScreen: React.FC<ErrorScreenProps> = ({ error }) => {
  const { t } = useTranslation();
  const rerror = useRouteError();
  console.log("An error has occurred. Please show this to the isg:");
  console.log(error);

  const errorExplanation = ((error === "route-error" && isRouteErrorResponse(rerror)) ?
    <><h2>{rerror.status}: {rerror.statusText}</h2>{routeErrorMessage(rerror)}</>
    : <p>{((error as SerializedError).name && (error as SerializedError).message) ?
      (error as SerializedError).name + ", " + (error as SerializedError).message
      : error.toString()}</p>
  );

  return (
    <div className="d-flex justify-content-center" style={{ height: "80vh" }}>
      <form className="text-center my-auto">
        <h1>{t("ErrorScreen.title")}</h1>
        {error !== "route-error" && <p>{t("ErrorScreen.body")}</p>}
        {errorExplanation}
        <Link to="/">Return to home page</Link>
      </form>
    </div>
  );
};
