import {
  ResourceDetailCardBase,
  TableFormElement,
} from "./ResourceDetailCardBase";
import { EditableResourceDetailsProps } from "./MailResourceHelpers";
import { Anchor } from "@mantine/core";
import { Resource } from "../resourcesSlice";

const tableElements: TableFormElement<Resource>[] = [
  {
    title: "Identifier",
    key: "googleCalendar.identifier" as keyof Resource,
    disabled: true,
    formatter: (identifier: string) => (
      <Anchor
        target="_blank"
        rel="noreferrer"
      >
        {identifier}
      </Anchor>
    ),
  },
  {
    title: "Is Public",
    key: "googleCalendar.isPublic" as keyof Resource,
    formatter: (isPublic: boolean) => String(isPublic),
    customizeField: () => ({ type: "checkbox" }),
  },
];

export const GoogleCalendar = (
  props: EditableResourceDetailsProps<Resource>
) => (
  <ResourceDetailCardBase {...props}>{tableElements}</ResourceDetailCardBase>
);

export const googleCalendarTableElements = tableElements;