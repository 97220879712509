import { Accordion, Code, Container, Group, Text } from "@mantine/core";
import {
  PeopleAuthorizationRole,
  selectDebugRoles,
  selectIsResourcesAdmin,
  selectUserRoles,
  setDebugRoles,
} from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { LoadWrapper } from "../../components/LoadWrapper";
import {
  ResourcesAuthorizationRole,
  useListAuditLogQuery,
  useListResourcesAuthorizationRolesQuery,
} from "../resources/resourcesSlice";
import { useListPeopleAuthorizationRolesQuery } from "../groups/groupsSlice";
import { DataTable } from "mantine-datatable";
import { CloseIcon, LoginButton } from "vseth-canine-ui";
import { timeObjToMessage } from "../../util/peopleProto";
import { useState } from "react";
import { AuditLogTable } from "./AuditLogTable";

interface PrefixedPeopleAuthorizationRole extends PeopleAuthorizationRole {
  prefixedName: string;
}

interface PrefixedResourcesAuthorizationRole
  extends ResourcesAuthorizationRole {
  prefixedName: string;
}

export const AboutPage = () => {
  const debugRoles = useSelector(selectDebugRoles);
  const userRoles = useSelector(selectUserRoles);
  const dispatch = useDispatch();

  const { data: peopleRoles, isLoading: isLoadingPeopleRoles } =
    useListPeopleAuthorizationRolesQuery(undefined);
  const { data: resourcesRoles, isLoading: isLoadingResourcesRoles } =
    useListResourcesAuthorizationRolesQuery(undefined);

  const prefixedPeopleRoles = (peopleRoles || []).map((r) => ({
    ...r,
    prefixedName: "people." + r.name,
    id: "people." + r.name,
  })) as PrefixedPeopleAuthorizationRole[];
  const prefixedResourcesRoles = (resourcesRoles || []).map((r) => ({
    ...r,
    prefixedName: "resources." + r.name,
    id: "resources." + r.name,
  })) as PrefixedResourcesAuthorizationRole[];

  const getSelectedPeopleRoles = (
    prefixedRoles: PrefixedPeopleAuthorizationRole[],
    roleNames: string[]
  ) => {
    const sel = prefixedRoles.filter((r) => (
      !!(roleNames.find((e) => e === r.prefixedName))
    ));
    return sel;
  };

  const getSelectedResourcesRoles = (
    prefixedRoles: PrefixedResourcesAuthorizationRole[],
    roleNames: string[]
  ) => {
    const sel = prefixedRoles.filter((r) => (
      !!(roleNames.find((e) => e === r.prefixedName))
    ));
    return sel;
  };

  const handleUpdateSelectPeopleRoles = (rows: PrefixedPeopleAuthorizationRole[]) => {
    const names = rows.map((r) => r.prefixedName).concat(getSelectedResourcesRoles(prefixedResourcesRoles, debugRoles).map((r) => r.prefixedName));
    dispatch(setDebugRoles(names));
  };

  const handleUpdateSelectResourcesRoles = (rows: PrefixedResourcesAuthorizationRole[]) => {
    const names = rows.map((r) => r.prefixedName).concat(getSelectedPeopleRoles(prefixedPeopleRoles, debugRoles).map((r) => r.prefixedName));
    dispatch(setDebugRoles(names));
  };

  const isAdmin = selectIsResourcesAdmin(resourcesRoles, userRoles);

  return (
    <Container>
      <section className="mb-4">
        <h1 className="mb-3">VSETH Groupmanager</h1>
        <p>
          The VSETH Groupmanager is the central application to manage the groups,
          group memberships and resources inside VSETH. It can be used by actives to view
          the members of groups as well as by OIVs to change the group memberships, request
          resources and change permissions on resources. If you're new to the VSETH group
          concept or resources, you can find more information in the&nbsp;
          <a
            href="https://docs.vseth.ethz.ch/de/allgemein/gruppenkonzept"
            target="_blank"
            rel="noopener noreferrer"
          >
            IT Documentation
          </a>
        </p>
      </section>
      <section className="mb-4" id="feedback">
        <h2 className="mb-2">Suggestions and new Features</h2>
        <p>
          If you have feature requests or encounter any bugs, please open an
          issue on the Gitlab repository of this project. You can access the ETH
          Gitlab by clicking the link below and signing in with your nethz
          account.
          Alternatively, you can write a ticket to the IT support.
          &nbsp;
          <a
            href="https://gitlab.ethz.ch/vseth/0403-isg/sip-vseth-apps/groupmanager/-/issues"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gitlab repository
          </a>
        </p>
      </section>
      <section className="mb-4">
        <h2 className="mb-2">Major Changes</h2>
        <p>
          This release adds support for changing and requesting new resources via "Proposed changes".
          It also adds the "Lookup" page that will in the future provide a way to request addional
          data on users from the PeopleApi.
          Please feel free to provide feedback in the form of issues in the&nbsp;
          <a
            href="https://gitlab.ethz.ch/vseth/0403-isg/sip-vseth-apps/groupmanager/-/issues"
            target="_blank"
            rel="noopener noreferrer"
          >Gitlab repository</a>. Be aware of the following resulting changes to the Groupmanager:

        </p>
        <ul>
          <li>
            <b>Resources</b> are generally created and changed by requesting them in a <b>proposed change</b>.
            However, <b>Permissions</b> on resources need to be added on the detailed page on a particular created resource.
            Many operations will require you to add an audit message.
          </li>
          <li>
            Admins can deny or accept your proposed change. When it is accepted, it will be used to create or change the
            actual described resource (e.g. change Permissions on a shared Google Drive).
          </li>
          <li>
            Resource deletion and direct resource creation requires special permission.
          </li>
        </ul>
        Previously also changed:
        <ul>
          <li> Search bar inputs are stored in your browser's local storage. To clear them, use the <CloseIcon color="grey" /> button in the search bar on the right.</li>
          <li> The CSV export buttons either export all records (when the button is labelled "all") or just the ones that match your search.</li>
          <li> The search bar searches all displayed fields. If you want to find all groups of type OU, select the "Group type" to display it in the table and then type in OU. </li>
          <li> For tables displaying resources, you can filter by type using the multi-select input field right of the search bar</li>
          <li> The <Code>.*</Code> button enables regex search </li>
        </ul>
      </section>

      <section className="mb-5">
        <Accordion>
          <Accordion.Item value="debug">
            <Accordion.Control><h4>Debug Section</h4></Accordion.Control>
            <Accordion.Panel>
              <p>
                WARNING: using this section you can debug some features in the
                frontend, however as a normal user you should not use these. (Although
                it might seem that using this you are able to change your access
                privileges, this really only changes the privileges the frontend
                thinks you have and can be used to debug the frontend. Of course it is
                not possible for any user to change these permissions). The following
                roles are defined and should reflect the following scopes: (keep in
                mind if no role is selected the role of you user (in the access token)
                will be used):
              </p>
              <h3>People API</h3>
              <ul>
                <li>
                  <b>admin:</b> should be able to view all groups, all group members
                  and also edit all group members
                </li>
                <li>
                  <b>vseth-active:</b> should be able to view all groups, view group
                  members of 0300-vs and 0700-vert
                </li>
                <li>
                  <b>vis-active:</b> should be able to view all groups, view group
                  members of 0300-vs, 0700-vert and 1116-vis
                </li>
                <li>
                  <b>vis-oiv:</b> should be able to view all groups, view group
                  members of 0300-vs, 0700-vert and view and edit group members on
                  1116-vis
                </li>
              </ul>
              <LoadWrapper isLoading={isLoadingPeopleRoles}>
                <div>
                  <Text fz="sm">Current roles: debugRoles = {JSON.stringify(debugRoles)}  userRoles = {JSON.stringify(userRoles)}</Text>
                </div>
                <DataTable
                  records={prefixedPeopleRoles}
                  idAccessor="name"
                  columns={[
                    { accessor: "name", title: "Name" },
                    {
                      accessor: "displayName",
                      title: "Display Name",
                    },
                  ]}
                  selectedRecords={getSelectedPeopleRoles(prefixedPeopleRoles, debugRoles)}
                  onSelectedRecordsChange={(selected) => handleUpdateSelectPeopleRoles(selected)}
                />
              </LoadWrapper>
              <h3>Resource API</h3>
              <ul>
                <li>
                  <b>admin:</b> can do everything, including see everything and edit
                  everything
                </li>
                <li>
                  <b>vis-oiv:</b> can view all resources and change/create vis parent group resources
                </li>
                <li>
                  <b>vseth-active:</b> can view all resources but not change anything
                </li>
              </ul>
              <LoadWrapper isLoading={isLoadingResourcesRoles}>
                <DataTable
                  records={prefixedResourcesRoles}
                  idAccessor="name"
                  columns={[
                    { accessor: "name", title: "Name" },
                    {
                      accessor: "displayName",
                      title: "Display Name",
                    },
                  ]}
                  selectedRecords={getSelectedResourcesRoles(prefixedResourcesRoles, debugRoles)}
                  onSelectedRecordsChange={(selected) => handleUpdateSelectResourcesRoles(selected)}
                />
              </LoadWrapper>
            </Accordion.Panel>
          </Accordion.Item>
          {isAdmin &&
            <Accordion.Item value="auditlog">
              <Accordion.Control><h4>Audit log</h4></Accordion.Control>
              <Accordion.Panel>
                <AuditLogTable />
              </Accordion.Panel>
            </Accordion.Item>
          }
        </Accordion>
      </section>
    </Container>
  );
};
